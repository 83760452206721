import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Dropdown,
  Spinner,
  ButtonGroup
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import TimesheetTable from "./TimesheetTable";
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import Cleave from 'cleave.js/react';
import 'react-toastify/dist/ReactToastify.css';
import SimpleBar from "simplebar-react";
import Select from "react-select";
import { useFormik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import { getTimesheetList, getClientDropdownDetail, exportTimesheetDetail, getStaffDropdownList, getLocationDropdownDetail, getLocationFilterDropdownDetail, getStaffDropdownDetail, approveByIdTimesheetDetail, updateTimesheetDetail, addTimesheetDetail, getBreakListDetail, getByTimesheetDetail } from "helpers/saibackend_helper";

interface BreakRow {
  isChecked: boolean;
  break_type: number;
  break_duration: number;
  start_time: string;
  end_time: string;
}

interface ClientData {
  value: number;
  label: string;
}

interface LocationData {
  value: number;
  label: string;
}


interface StaffData {
  value: number;
  label: string;
}

interface BreakData {
  id: number;
  break_type: string;
}


interface TimesheetData {
  id: number;
  date: string,
  client_name: string,
  location: string,
  staff: string,
  time: string,
  total_break_hrs: number,
  total_hrs: number,
  employee_notes: string,
  status: string
}

type PayloadValueType = {
  page: number;
  page_size: string;
  search_term: string;
  sort: {
    sort_order: string;
    sort_by: string;
  };
  is_archive: number;
  start_date: string;
  end_date: string;
  client_id: number[];
  location_id: number[];
  is_approve: number,
  is_invoice: number,
  is_payroll: number,
  staff_id: number | number[];
};

type PayloadValueLocationType = {
  search_term: string;
  client_ids: number[];
  is_filter: number
};



const TimesheetPage = () => {
  document.title = "Timesheet | The Guard Duty";

  const [courseFilters, setCourseFilters] = useState<boolean>(false);
  const handleFilterShow = () => setCourseFilters(!courseFilters);

  const [isBottom, setIsBottom] = useState(false);
  const toggleBottomCanvas = () => {
    setIsBottom(!isBottom);
  };

  const [addtimesheet, setaddtimesheet] = useState(false);
  const toggleAddTimesheetCanvas = () => {
    setaddtimesheet(!addtimesheet);
  };



  // List Timesheet API Integration //
  // =============================================================================================================//

  const [TimesheetDetail, setTimesheetDetail] = useState<TimesheetData[]>([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [payloadValue, setPayloadValue] = useState<PayloadValueType>({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "DESC",
      sort_by: ""
    },
    is_archive: 0,
    start_date: "",
    end_date: "",
    client_id: [],
    location_id: [],
    is_approve: 4,
    is_invoice: 0,
    is_payroll: 0,
    staff_id: [],
  });

  const [timesheetSummary, setTimesheetSummary] = useState<any>({
    pending_timesheet: 0,
    approve_timesheet: 0,
    rejected_timesheet: 0,
    total_timesheet_hrs: 0
  });

  const fetchTimesheetDetails = async () => {
    try {
      const response = await getTimesheetList(
        payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.start_date,
        payloadValue.end_date,
        payloadValue.client_id,
        payloadValue.location_id,
        payloadValue.is_approve,
        payloadValue.is_invoice,
        payloadValue.is_payroll,
        payloadValue.staff_id
      );
      setTimesheetDetail(response.data.data_list);
      setTotalPages(response.data.total_pages);
      setCurrentPage(payloadValue.page);
      setTotalRecords(response.data.total_records);
      setTimesheetSummary(response.data.summary);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTimesheetDetails();
  }, [payloadValue]);


  const handlePageChange = (page: number) => {
    setPayloadValue({ ...payloadValue, page: page });
    setSelectAll(false)
    setTimesheetIds([]);
  };

  const handleSortChange = (column: string) => {
    setPayloadValue(prevState => ({
      ...prevState,
      sort: {
        sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
        sort_by: column
      },
      page: 1
    }));
    setSelectAll(false)
    setTimesheetIds([]);
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setPayloadValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
        page: 1
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };


  // Filter //
  // =============================================================================================================//

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [dateKey, setDateKey] = useState(0);

  const [selectedClient, setSelectedClient] = useState<number[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<number[]>([]);

  const [invoiceChecked, setInvoiceChecked] = useState<number>(0);
  const [payrollChecked, setPayrollChecked] = useState<number>(0);

  const handleCheckboxFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (id === 'Invoice') {
      setInvoiceChecked(checked ? 1 : 0);
    } else if (id === 'Payroll') {
      setPayrollChecked(checked ? 1 : 0);
    }
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleDateRangeChange = (dates: Date[]) => {
    if (dates.length === 2) {
      setSelectedDates(dates);
    } else {
      console.error('Invalid date range selection:', dates);
    }
  };

  const [defaultLocationFilterValue, setDefaultLocationFilterValue] = useState<PayloadValueLocationType>({
    search_term: "",
    client_ids: [],
    is_filter: 1
  })

  const [LocationFilterDropdown, setLocationFilterDropdown] = useState<LocationData[]>([])

  const fetchLocationFilterDropdownListDetails = async () => {
    try {
      const response = await getLocationFilterDropdownDetail(defaultLocationFilterValue.search_term, selectedClient?.map((option: any) => option.value) ?? [], defaultLocationFilterValue.is_filter);
      setLocationFilterDropdown(response.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    if (courseFilters) {
      fetchLocationFilterDropdownListDetails();
    }
  }, [defaultLocationFilterValue, selectedClient, courseFilters]);

  const [statusID, setStatusID] = useState('4')

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    if (selectedId) {
      setStatusID(selectedId)
    }
  };

  const [StaffFilterDropdown, setStaffFilterDropdown] = useState<ClientData[]>([])
  const [selectedStaff, setSelectedStaff] = useState<number[]>([]);

  const fetchStaffDropdownListFilterDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getStaffDropdownList(searchTerm);
      setStaffFilterDropdown(response.data.data_list.map((staff: any) => ({
        value: staff.id,
        label: staff.staff_name
      })));
    } catch (error) {
    }
  };



  const applyFilters = () => {
    if (selectedDates.length === 2) {
      setPayloadValue((prevValue) => ({
        ...prevValue,
        start_date: formatDate(selectedDates[0]),
        end_date: formatDate(selectedDates[1]),
        client_id: selectedClient?.map((option: any) => option.value) ?? [],
        location_id: selectedLocation?.map((option: any) => option.value) ?? [],
        is_approve: Number(statusID),
        is_invoice: invoiceChecked ? invoiceChecked : 0,
        is_payroll: payrollChecked ? payrollChecked : 0,
        staff_id: selectedStaff.map((selectedStaff: any) => selectedStaff.value),
        page: 1,
      }));
      setCourseFilters(false)
    } else {
      setPayloadValue((prevValue) => ({
        ...prevValue,
        client_id: selectedClient?.map((option: any) => option.value) ?? [],
        location_id: selectedLocation?.map((option: any) => option.value) ?? [],
        is_approve: Number(statusID),
        is_invoice: invoiceChecked ? invoiceChecked : 0,
        is_payroll: payrollChecked ? payrollChecked : 0,
        staff_id: selectedStaff.map((selectedStaff: any) => selectedStaff.value),
        page: 1
      }));
      setCourseFilters(false)
    }
  };

  const clearFilters = () => {
    setPayloadValue((prevValue) => ({
      ...prevValue,
      start_date: "",
      end_date: "",
      client_id: [],
      location_id: [],
      is_approve: 4,
      is_invoice: 0,
      is_payroll: 0,
      staff_id: [],
      page: 1
    }));
    setDateKey((prevKey) => prevKey + 1);
    setSelectedDates([])
    setSelectedClient([])
    setSelectedLocation([])
    setStatusID('4')
    setSelectedStaff([])
    setInvoiceChecked(0)
    setPayrollChecked(0)
    setCourseFilters(false)
  }
  // Client Dropdown //
  // =============================================================================================================//

  const [ClientDetail, setClientDetail] = useState<ClientData[]>([])

  const fetchClientDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getClientDropdownDetail(searchTerm);
      setClientDetail(response.data);
    } catch (error) {
      console.log(error);

    }
  };

  const handleClientChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      setDefaultLocationDropdownValue(prevState => ({
        ...prevState,
        client_id: selectedOption.value,
      }));
      formik.setFieldValue('client_id', selectedOption.value)
    }
  };

  // Location Dropdown //
  // =============================================================================================================//

  const [defaultLocationDropdownValue, setDefaultLocationDropdownValue] = useState({
    search_term: "",
    client_id: 0,
  })

  const [LocationDropdown, setLocationDropdown] = useState<LocationData[]>([])

  const fetchLocationDropdownListDetails = async () => {
    try {
      const response = await getLocationDropdownDetail(defaultLocationDropdownValue.search_term, defaultLocationDropdownValue.client_id);
      setLocationDropdown(response.data);
    } catch (error) {
    }
  };

  const handleLocationChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      setDefaultStaffDropdownValue(prevState => ({
        ...prevState,
        location_id: selectedOption.value,
      }));
      formik.setFieldValue('location_id', selectedOption.value)
    }
  };

  const handleStaffChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      formik.setFieldValue('staff_id', selectedOption.value)
    }
  };

  useEffect(() => {
    if (courseFilters) {
      fetchLocationDropdownListDetails();
      fetchClientDetails();
      fetchStaffDropdownListFilterDetails()
    }
  }, [courseFilters, defaultLocationDropdownValue]);

  // Staff Dropdown //
  // =============================================================================================================//

  const [defaultStaffDropdownValue, setDefaultStaffDropdownValue] = useState({
    search_term: "",
    location_id: 0,
  })

  const [StaffDropdown, setStaffDropdown] = useState<StaffData[]>([])

  const fetchStaffDropdownListDetails = async () => {
    try {
      const response = await getStaffDropdownDetail(defaultStaffDropdownValue.search_term, defaultStaffDropdownValue.location_id,);
      setStaffDropdown(response.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    if(courseFilters || addtimesheet){
    fetchStaffDropdownListDetails();
    }
  }, [defaultStaffDropdownValue, courseFilters , addtimesheet]);


  // Break List Dropdown //
  // =============================================================================================================//

  const [BreakDropdown, setBreakDropdown] = useState<BreakData[]>([])

  const fetchBreakDropdownListDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getBreakListDetail(searchTerm);
      setBreakDropdown(response.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    if (addtimesheet) {
      fetchLocationDropdownListDetails();
      fetchClientDetails();
      fetchBreakDropdownListDetails();
    }
  }, [addtimesheet, defaultLocationDropdownValue]);

  // ADD Timesheet API Integration //
  // =============================================================================================================//

  const Minutes = [
    {
      value: 5,
      label: "5 minutes",
    },
    {
      value: 10,
      label: "10 minutes",
    },
    {
      value: 15,
      label: "15 minutes",
    },
    {
      value: 20,
      label: "20 minutes",
    },
    {
      value: 25,
      label: "25 minutes",
    },
    {
      value: 30,
      label: "30 minutes",
    },
    {
      value: 35,
      label: "35 minutes",
    },
    {
      value: 40,
      label: "40 minutes",
    },
    {
      value: 45,
      label: "45 minutes",
    },
    {
      value: 50,
      label: "50 minutes",
    },
    {
      value: 55,
      label: "55 minutes",
    },
    {
      value: 60,
      label: "60 minutes",
    },
  ]

  const [isApprove, setIsApprove] = useState(0);

  const [breakRows, setBreakRows] = useState<BreakRow[]>([
    { isChecked: false, break_type: 0, break_duration: 0, start_time: "", end_time: "" },
  ]);

  const handleAddBreak = () => {
    setBreakRows([...breakRows, { isChecked: false, break_type: 0, break_duration: 0, start_time: "", end_time: "" }]);
  };

  const handleDeleteBreak = (index: number) => {
    const updatedRows = [...breakRows];
    updatedRows.splice(index, 1);
    setBreakRows(updatedRows);
  };

  const handleCheckboxChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedRows = [...breakRows];
    updatedRows[index].isChecked = event.target.checked;
    if (event.target.checked) {
      updatedRows[index].break_duration = 0;
    }
    setBreakRows(updatedRows);
  };


  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    field: keyof BreakRow
  ) => {
    const updatedRows = [...breakRows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: Number(event.target.value),
    };
    setBreakRows(updatedRows);
  };


  // const handleTimeChange = (time: string, index: number, field: keyof BreakRow) => {
  //   const formattedTime = time.replace(/^(\d{2})(\d{2})$/, '$1:$2');
  //   const updatedRows = [...breakRows];
  //   updatedRows[index] = {
  //     ...updatedRows[index],
  //     [field]: formattedTime,
  //   };
  //   setBreakRows(updatedRows);
  // };

  const handleTimeChange = (time: string, index: number, field: keyof BreakRow) => {
    if (/^\d{4}$/.test(time)) {
      const formattedTime = time.replace(/^(\d{2})(\d{2})$/, '$1:$2');
      const updatedRows = [...breakRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: formattedTime,
      };
      setBreakRows(updatedRows);
    } else {
      console.error("Invalid time format");
    }
  };


  const handleTimeShiftChange = (time: string, field: string) => {
    const formattedTime = time.replace(/^(\d{2})(\d{2})$/, '$1:$2');
    formik.setFieldValue(field, formattedTime);
  };

  // const calculateTotalBreakHours = () => {
  //   let totalMinutes = 0;

  //   breakRows.forEach(row => {
  //     if (row.isChecked && row.start_time && row.end_time) {
  //       const [startHours, startMinutes] = row.start_time.split(':').map(Number);
  //       const [endHours, endMinutes] = row.end_time.split(':').map(Number);

  //       let breakMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
  //       if (breakMinutes < 0) breakMinutes += 24 * 60;

  //       totalMinutes += breakMinutes;
  //     } else {
  //       totalMinutes += isNaN(row.break_duration) ? 0 : row.break_duration;
  //     }
  //   });
  //   if (totalMinutes > shiftPaidBreak) {
  //     formik.setFieldValue('total_paid_break', shiftPaidBreak);
  //     formik.setFieldValue('total_unpaid_break', totalMinutes - shiftPaidBreak);

  //     const tHours = totalWorkHours - ((totalMinutes - shiftPaidBreak) / 60);

  //     setTotalHours(parseFloat(tHours.toFixed(2)));
  //   } else {
  //     formik.setFieldValue('total_paid_break', totalMinutes);
  //     formik.setFieldValue('total_unpaid_break', 0);
  //   }
  // };

  const [unPaid, setUnPaid] = useState("")
  const [Paid, setPaid] = useState("")

  const handlePaidBreakChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const breakMinutes = parseFloat(e.target.value) || 0;
    setPaid(breakMinutes.toString())
    formik.setFieldValue("total_paid_break", breakMinutes);
  };

  const handleBreakChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const breakMinutes = parseFloat(e.target.value) || 0;
    setUnPaid(breakMinutes.toString())
    const updatedTotalWorkHours = totalWorkHours - breakMinutes / 60;
    const formattedTotalWorkHours = parseFloat(updatedTotalWorkHours.toFixed(2));
    setTotalHours(formattedTotalWorkHours);
    formik.setFieldValue("total_unpaid_break", breakMinutes);
  };


  // useEffect(() => {
  //   calculateTotalBreakHours();
  // }, [breakRows]);


  const validationSchema = Yup.object().shape({
    // client_id: Yup.number().positive('Client is Required'),
    location_id: Yup.number().positive('Location is Required'),
    staff_id: Yup.number().positive('Staff is Required'),
    date: Yup.string().required("Date is required"),
    start_time: Yup.string().required("Start time is required"),
    end_time: Yup.string().required("End time is required"),
  });

  const formik = useFormik({
    initialValues: {
      client_id: 0,
      location_id: 0,
      staff_id: 0,
      date: "",
      start_time: "",
      end_time: "",
      notes: "",
      is_approve: 0,
      total_paid_break: "",
      total_unpaid_break: "",
      total_hrs: "",
      timesheet_breaks: [
        {
          break_type: 0,
          is_hrs: 0,
          start_time: "",
          end_time: "",
          break_hrs: 0
        },
      ]
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const updatedValues = {
          ...values,
          is_approve: isApprove,
          timesheet_breaks: breakRows.map((row) => ({
            break_type: row.break_type || 0,
            is_hrs: row.isChecked ? 1 : 0,
            start_time: row.start_time || "",
            end_time: row.end_time || "",
            break_hrs: row.break_duration || 0,
          }))
        }
        if (isEditing && editId !== null) {
          const update = {
            ...updatedValues,
            id: editId,
          }
          const response = await updateTimesheetDetail(update)
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          const response = await addTimesheetDetail(updatedValues)
          if (response.status === 1) {
            toast.success(response.message)
          } else {
            toast.error(response.message)
            return;
          }
        }
        formik.resetForm();
        setPayloadValue(prevState => ({
          ...prevState,
          sort: {
            sort_order: "DESC",
            sort_by: ""
          },
          page: 1
        }));
        fetchTimesheetDetails()
        setaddtimesheet(false)
        setIsApprove(0)
        setBreakRows([{ isChecked: false, break_type: 0, break_duration: 0, start_time: "", end_time: "" }])
      } catch (error) {
        console.error("Error Adding/Updating Timesheet:", error);
        toast.error("Error Timesheet. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const [totalWorkHours, setTotalWorkHours] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);

  const formatTime = (date: { getHours: () => any; getMinutes: () => any; }) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const calculateTotalHours = (startTime: string, endTime: string, unpaidBreak: number) => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    const duration = moment.duration(end.diff(start));
    const totalWorkHours = parseFloat(duration.asHours().toFixed(2));

    setTotalWorkHours(parseFloat(totalWorkHours.toFixed(2)));

    // Deduct unpaid break time (convert minutes to hours)
    const unpaidBreakHours = unpaidBreak / 60;
    const netHours = totalWorkHours - unpaidBreakHours;
    setTotalHours(parseFloat(netHours.toFixed(2)));
  };

  // const calculateTotalHours = (start: string, end: string) => {
  //   const [startHours, startMinutes] = start.split(':').map(Number);
  //   const [endHours, endMinutes] = end.split(':').map(Number);

  //   let totalMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
  //   if (totalMinutes < 0) totalMinutes += 24 * 60;

  //   const hours = totalMinutes / 60;
  //   setTotalWorkHours(parseFloat(hours.toFixed(2)));
  // };

  useEffect(() => {
    if (formik.values.start_time && formik.values.end_time) {
      calculateTotalHours(formik.values.start_time, formik.values.end_time, Number(formik.values.total_unpaid_break));
    }
  }, [formik.values.start_time, formik.values.end_time]);


  const handleAddAndApprove = () => {
    setIsApprove(1);
    formik.handleSubmit();
  };

  const handleReject = () => {
    setIsApprove(2);
    formik.handleSubmit();
  }


  const handlePending = () => {
    setIsApprove(0);
    formik.handleSubmit();
  }


  // Edit Timesheet //
  // =============================================================================================================//

  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);
  const [shiftPaidBreak, setShiftPaidBreakEditId] = useState<number>(0);
  const [is_invoice, setIs_invoice] = useState(1)

  const editTimesheet = async (id: number) => {
    try {
      const response = await getByTimesheetDetail(id);
      const data = response.data;
      setIs_invoice(data.is_invoice)

      const mappedBreakRows = data.timesheet_breaks.map((row: any) => ({
        break_type: row.break_type,
        isChecked: row.is_hrs === 1,
        start_time: row.start_time,
        end_time: row.end_time,
        break_duration: row.break_hrs,
      }));

      formik.setValues({
        client_id: data.client_id,
        location_id: data.location_id,
        staff_id: data.staff_id,
        start_time: data.start_time,
        end_time: data.end_time,
        date: data.date,
        notes: data.notes,
        is_approve: data.is_approve,
        timesheet_breaks: mappedBreakRows,
        total_paid_break: data.total_paid_break,
        total_unpaid_break: data.total_unpaid_break,
        total_hrs: data.total_hrs
      });
      setTotalHours(data.total_hrs)
      setIsApprove(data.is_approve)
      setUnPaid(data.total_unpaid_break ? data.total_unpaid_break : "")
      setPaid(data.total_paid_break ? data.total_paid_break : "")
      setShiftPaidBreakEditId(data.shift_paid_break ?? 0);
      setDefaultLocationDropdownValue(prevState => ({
        ...prevState,
        client_id: data.client_id,
      }));
      setDefaultStaffDropdownValue(prevState => ({
        ...prevState,
        location_id: data.location_id,
      }));
      setBreakRows(mappedBreakRows);
      setIsEditing(true);
      setEditId(id);
      setaddtimesheet(true)

    } catch (error) {
      console.error("Error fetching Timesheet details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  // Approve By ID Timesheet //
  // =============================================================================================================//

  const [isByIdApprove, setIsByIdApprove] = useState(0);
  const [timesheetIds, setTimesheetIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectedTimesheetIdsChange = (ids: number[]) => {
    setTimesheetIds(ids);
  };

  useEffect(() => {
    handleSelectedTimesheetIdsChange(timesheetIds);
  }, [timesheetIds]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const isChecked = event.target.checked;
    const updatedIds = isChecked ? [...timesheetIds, id] : timesheetIds.filter(timesheetId => timesheetId !== id);
    setTimesheetIds(updatedIds);
    setSelectAll(updatedIds.length === TimesheetDetail.length);
  };

  const handleCheckAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedIds = isChecked ? TimesheetDetail.map((timesheet: { id: any; }) => timesheet.id) : [];
    setSelectAll(isChecked);
    setTimesheetIds(updatedIds);
  };


  const approveFormik = useFormik({
    initialValues: {
      timesheet_ids: [],
      is_approve: 0
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const updatedValues = {
          ...values,
          timesheet_ids: timesheetIds,
          is_approve: isByIdApprove
        }

        const response = await approveByIdTimesheetDetail(updatedValues)

        if (response.status === 1) {
          toast.success(response.message);
          setTimesheetIds([]);
          setSelectAll(false)
        } else {
          toast.error(response.message);
          return;
        }
        fetchTimesheetDetails()
      } catch (error) {
        console.error("Error Approve/Reject Timesheet:", error);
        toast.error("Error Timesheet. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleApproveByID = () => {
    setIsByIdApprove(1);
    approveFormik.handleSubmit();
  };

  const handleRejectByID = () => {
    setIsByIdApprove(2);
    approveFormik.handleSubmit();
  };

  const openAddForm = () => {
    setIsEditing(false);
    setShiftPaidBreakEditId(0);
    setEditId(null);
    setPaid("")
    setUnPaid("")
    formik.resetForm()
    setaddtimesheet(true);
    setIsApprove(0)
    setBreakRows([{ isChecked: false, break_type: 0, break_duration: 0, start_time: "", end_time: "" }])
  };

  const [expLoader, setExpLoader] = useState(false)

  const downloadTimesheet = async () => {
    setExpLoader(true)
    try {
      await exportTimesheetDetail(
        payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.start_date,
        payloadValue.end_date,
        payloadValue.client_id,
        payloadValue.location_id,
        payloadValue.is_approve,
        payloadValue.is_invoice,
        payloadValue.is_payroll
      );
      setExpLoader(false)
    } catch (error) {
      console.error("Error downloading Timesheet:", error);
      toast.error("Error downloading Timesheet. Please try again later.");
    }
  }


  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Timesheet" pageTitle="Dashboard" />
            {
              loading ?
                <Row className="placeholder-glow">
                  <Col lg={3}>
                    <div className="placeholder bg-light card-placeholder col-12"></div>
                  </Col>
                  <Col lg={3}>
                    <div className="placeholder bg-light card-placeholder col-12"></div>
                  </Col>
                  <Col lg={3}>
                    <div className="placeholder bg-light card-placeholder col-12"></div>
                  </Col>
                  <Col lg={3}>
                    <div className="placeholder bg-light card-placeholder col-12"></div>
                  </Col>
                  <Col lg={12} className="mt-4">
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                  </Col>
                </Row>
                :
                <Row className="timesheet-module">
                  <Col lg={3}>
                    <Card className="border-bottom border-3 card-animate border-primary">
                      <Card.Body>
                        <div className="avatar-sm float-end">
                          <div className="avatar-title bg-primary-subtle text-primary fs-3xl rounded">
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                        <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={timesheetSummary?.pending_timesheet ? timesheetSummary.pending_timesheet : "-"} separator=',' /></span></h4>

                        <p className="text-muted fw-medium text-uppercase mb-0">Pending Timesheet</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card className="border-bottom border-3 card-animate border-warning">
                      <Card.Body>
                        <div className="avatar-sm float-end">
                          <div className="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                        <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={timesheetSummary?.approved_timesheet ? timesheetSummary.approved_timesheet : "-"} separator=',' /></span></h4>

                        <p className="text-muted fw-medium text-uppercase mb-0">Approved Timesheet</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card className="border-bottom border-3 card-animate border-success">
                      <Card.Body>
                        <div className="avatar-sm float-end">
                          <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                        <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={timesheetSummary?.rejected_timesheet ? timesheetSummary.rejected_timesheet : "-"} separator=',' /></span></h4>

                        <p className="text-muted fw-medium text-uppercase mb-0">Rejected Timesheet</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card className="border-bottom border-3 card-animate border-info">
                      <Card.Body>
                        <div className="avatar-sm float-end">
                          <div className="avatar-title bg-info-subtle text-info fs-3xl rounded">
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                        <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={timesheetSummary?.total_timesheet_hrs ? timesheetSummary.total_timesheet_hrs : "-"} separator=',' /></span></h4>

                        <p className="text-muted fw-medium text-uppercase mb-0">Total Timesheet Hours</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={12}>
                    <Card className="timesheet-table-card">
                      <Card.Body>
                        <Row>
                          <Col lg={6} className="btn-section justify-content-start mb-3">
                            <div className="app-search d-none d-md-inline-flex">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  id="search-options"
                                  value={inputValue}
                                  onChange={handleChange}
                                />
                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                <span
                                  className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                  id="search-close-options"
                                ></span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} className="btn-section mb-3">
                            <Button
                              className="filter-btn"
                              onClick={handleFilterShow}
                            >
                              <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                              Filters
                            </Button>
                            <Button
                              onClick={openAddForm}
                              className="next-btn"
                            >
                              <i className="ri-add-fill"></i>  Add New
                            </Button>
                            <Dropdown>
                              {approveFormik.isSubmitting ? (
                                <Dropdown.Toggle disabled variant="primary" size='lg'>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </Dropdown.Toggle>
                              ) : (
                                <Dropdown.Toggle disabled={timesheetIds.length === 0} variant="primary" size='lg'>
                                  Approve
                                </Dropdown.Toggle>
                              )}
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleApproveByID}>Approve</Dropdown.Item>
                                <Dropdown.Item onClick={handleRejectByID}>Reject</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            {expLoader ? (
                              <Button
                                color="primary"
                                className="export-btn"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            ) : (
                              <Button onClick={downloadTimesheet} className='export-btn'>
                                <i className="bx bx-export me-1 "></i>  Export
                              </Button>
                            )}
                          </Col>
                          <Col lg={12} className="leave-table">
                            <TimesheetTable
                              timesheetdata={TimesheetDetail}
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                              handleSortChange={handleSortChange}
                              editTimesheet={editTimesheet}
                              timesheetIds={timesheetIds}
                              selectAll={selectAll}
                              handleCheckboxChange={handleCheckboxChange}
                              handleCheckAllChange={handleCheckAllChange}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
            }
          </Container>
        </div>
        <Offcanvas
          className="filter-canvas"
          show={courseFilters}
          placement="end"
          onHide={handleFilterShow}
        >
          <Offcanvas.Header
            id="courseFilters"
            className="border-bottom filter-canvas"
            closeButton
          >
            <Offcanvas.Title id="courseFiltersLabel">
              {" "}
              <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
              Filters
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Row>
                <Col lg={12}>
                  <Row className="filter-1">
                    <Col lg={12}>
                      <h5>Client</h5>
                    </Col>
                    <Col lg={12}>
                      <Select
                        options={ClientDetail}
                        isMulti
                        value={selectedClient}
                        onChange={(newValue: any) => setSelectedClient(newValue || [])}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        placeholder="Select clients"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row className="filter-2">
                    <Col lg={12}>
                      <h5>Location</h5>
                    </Col>
                    <Select
                      options={LocationFilterDropdown}
                      isMulti
                      value={selectedLocation}
                      onChange={(newValue: any) => setSelectedLocation(newValue || [])}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      placeholder="Select Locations"
                    />
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row className="filter-2">
                    <Col lg={12}>
                      <h5>Staff Name</h5>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-icon">
                        <div className={`react-select-container`}>
                          <Select
                            options={StaffFilterDropdown}
                            isMulti
                            value={selectedStaff}
                            onChange={(newValue: any) => setSelectedStaff(newValue || [])}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                            placeholder="Select Staff"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row className="filter-2">
                    <Col lg={12}>
                      <h5>Status</h5>
                    </Col>
                    <Col lg="12" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Select
                          onChange={handleStatusChange}
                          value={statusID}
                          name="status"
                          className="form-select-lg"
                        >
                          <option value="4">All</option>
                          <option value="0">Pending</option>
                          <option value="1">Approved</option>
                          <option value="2">Rejected</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row className="filter-2" style={{ paddingTop: '0' }}>
                    <Col lg={12}>
                      <h5>Date Period</h5>
                    </Col>
                    <Col lg="12" className="mb-3">
                      <Flatpickr
                        className="form-control"
                        key={dateKey}
                        placeHolder="Select a date range"
                        options={{
                          mode: 'range',
                          dateFormat: 'd-m-Y',
                          defaultDate: selectedDates.length ? selectedDates : [null, null]
                        }}
                        value={selectedDates}
                        onChange={(dates: Date[]) => handleDateRangeChange(dates)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row className="filter-2" style={{ paddingTop: '0' }}>
                    <Col lg={6}>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Invoice"
                          checked={invoiceChecked === 1}
                          onChange={handleCheckboxFilterChange}
                        />
                        <label className="form-check-label" htmlFor="Invoice">
                          Invoice Generated
                        </label>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Payroll"
                          checked={payrollChecked === 1}
                          onChange={handleCheckboxFilterChange}
                        />
                        <label className="form-check-label" htmlFor="Payroll">
                          Payroll Generated
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Offcanvas.Body>
          <Row>
            <Col lg="12">
              <div className="offcanvas-footer border-top-0 p-3 text-center">
                <Row className="px-2 approve-reject-btn">
                  <Col lg={6} className="clear-section">
                    <Button
                      color="primary"
                      className="clear-btn"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col lg={6} className="Apply-section">
                    <Button color="primary" onClick={applyFilters} className="next-btn">
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Offcanvas>
        <Offcanvas
          className="add-timesheet"
          show={addtimesheet}
          onHide={toggleAddTimesheetCanvas}
          placement="bottom"
          style={{ minHeight: "800px" }}
        >
          <Offcanvas.Header className="border-bottom" closeButton>
            <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
              {isEditing ? "Edit" : "Add"} Timesheet Details
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Form onSubmit={formik.handleSubmit}>
            <SimpleBar
              style={{ maxHeight: "625px" }}
              className="px-3"
            >
              <Offcanvas.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3 form-icon form-select-icon">
                      <Form.Label>Client</Form.Label>
                      <div className={`react-select-container ${formik.touched.client_id && formik.errors.client_id ? 'is-invalid' : ''}`}>
                        <Select
                          inputId="client_id"
                          name="client_id"
                          options={ClientDetail}
                          value={ClientDetail.find(option => option.value.toString() === formik.values.client_id.toString())}
                          onChange={handleClientChange}
                          onBlur={formik.handleBlur}
                          classNamePrefix="react-select"
                          className="react-select"
                          isDisabled={editId}
                        />
                        <i
                          style={{ fontSize: "16px" }}
                          className="bx bx-face mt-4"
                        ></i>
                        {formik.touched.client_id && formik.errors.client_id ? (
                          <div className="invalid-feedback d-block">
                            {formik.errors.client_id}
                          </div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mb-3 form-icon">
                      <Form.Label>Location</Form.Label>
                      <div className={`react-select-container ${formik.touched.location_id && formik.errors.location_id ? 'is-invalid' : ''}`}>
                        <Select
                          inputId="location_id"
                          name="location_id"
                          options={LocationDropdown}
                          value={LocationDropdown.find(option => option.value === formik.values.location_id)}
                          onChange={handleLocationChange}
                          onBlur={formik.handleBlur}
                          classNamePrefix="react-select"
                          className="react-select"
                          isDisabled={editId}
                        />
                        <i
                          style={{ fontSize: "16px" }}
                          className=" ri-map-pin-line mt-4"
                        ></i>
                        {formik.touched.location_id && formik.errors.location_id ? (
                          <div className="invalid-feedback d-block">
                            {formik.errors.location_id}
                          </div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="mb-3 form-icon">
                      <Form.Label>Staff Name</Form.Label>
                      <div className={`react-select-container ${formik.touched.staff_id && formik.errors.staff_id ? 'is-invalid' : ''}`}>
                        <Select
                          inputId="staff_id"
                          name="staff_id"
                          options={StaffDropdown}
                          value={StaffDropdown.find(option => option.value === formik.values.staff_id)}
                          onChange={handleStaffChange}
                          onBlur={formik.handleBlur}
                          classNamePrefix="react-select"
                          className="react-select"
                          isDisabled={editId}
                        />
                        <i
                          style={{ fontSize: "16px" }}
                          className="bx bx-face mt-4"
                        ></i>
                        {formik.touched.staff_id && formik.errors.staff_id ? (
                          <div className="invalid-feedback d-block">
                            {formik.errors.staff_id}
                          </div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3 d-flex flex-column form-icon">
                      <Form.Label htmlFor="JoiningdatInput">Date</Form.Label>
                      <Flatpickr
                        className={`form-control form-control-lg form-control-icon ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                        options={{
                          dateFormat: "d-m-Y",
                        }}
                        onChange={(dates: any[]) => {
                          const formattedDate = format(dates[0], 'dd-MM-yyyy');
                          formik.setFieldValue("date", formattedDate);
                        }}
                        name="date"
                        placeholder="Select date"
                        disabled={editId}
                        value={formik.values.date}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className="ri-calendar-event-line mt-4"
                      ></i>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.date}
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="mb-3">
                      <Form.Label>Start Time</Form.Label>
                      <div className="position-relative form-icon">
                        <Cleave
                          placeholder="hh:mm"
                          options={{
                            time: true,
                            timePattern: ['h', 'm']
                          }}
                          value={formik.values.start_time}
                          onChange={(e: any) => handleTimeShiftChange(e.target.rawValue, "start_time")}
                          className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                        />
                        <i
                          style={{ fontSize: "16px" }}
                          className="ri-time-line"
                        ></i>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.start_time}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="mb-3">
                      <Form.Label>End Time</Form.Label>
                      <div className="position-relative form-icon">
                        <Cleave
                          placeholder="hh:mm"
                          options={{
                            time: true,
                            timePattern: ['h', 'm']
                          }}
                          value={formik.values.end_time}
                          onChange={(e: any) => handleTimeShiftChange(e.target.rawValue, "end_time")}
                          className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                        />
                        <i
                          style={{ fontSize: "16px" }}
                          className="ri-time-line"
                        ></i>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.end_time}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="mb-3">
                      <Form.Label>Timesheet Comments</Form.Label>
                      <div className="position-relative form-icon">
                        <Form.Control
                          type="text"
                          name="notes"
                          className="form-control-lg form-control-icon name-input"
                          placeholder="Enter Comments.."
                          value={formik.values.notes}
                          onChange={formik.handleChange}
                        />
                        <i style={{ fontSize: "16px" }} className=" ri-message-3-line"></i>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Total Working Hours</Form.Label>
                      <div className="position-relative form-icon">
                        <Form.Control
                          type="text"
                          name=""
                          value={`${totalWorkHours} hours`}
                          className="form-control-lg form-control-icon name-input"
                          disabled
                        />
                        <i style={{ fontSize: "16px" }} className=" ri-timer-line"></i>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Total Paid Break (In Min)</Form.Label>
                      <div className="position-relative form-icon">
                        <Form.Control
                          type="text"
                          name="total_paid_break"
                          placeholder="Enter Total Paid Break (In Min)"
                          value={Paid ? Paid : ""}
                          onChange={handlePaidBreakChange}
                          className="form-control-lg form-control-icon name-input"
                        />
                        <i style={{ fontSize: "16px" }} className=" ri-timer-line"></i>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Total Unpaid Break (In Min)</Form.Label>
                      <div className="position-relative form-icon">
                        <Form.Control
                          type="text"
                          name="total_unpaid_break"
                          placeholder="Enter Total Unpaid Break (In Min)"
                          value={unPaid ? unPaid : ""}
                          onChange={handleBreakChange}
                          className="form-control-lg form-control-icon name-input"
                        />
                        <i style={{ fontSize: "16px" }} className=" ri-timer-line"></i>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Total Hrs</Form.Label>
                      <div className="position-relative form-icon">
                        <Form.Control
                          type="text"
                          name=""
                          value={`${totalHours} hours`}
                          className="form-control-lg form-control-icon name-input"
                          disabled
                        />
                        <i style={{ fontSize: "16px" }} className=" ri-timer-line"></i>
                      </div>
                    </Form.Group>
                  </Col>
                  {/* <Col
                    lg="12"
                    className="add-break-section d-flex gap-3 align-items-center"
                  >
                    <h5>Add Break</h5>
                    <Button onClick={handleAddBreak} className="add-break-btn">
                      <i className="ri-add-line"></i> Add Break
                    </Button>
                  </Col> */}
                  {/* <Col lg="12">
                    {breakRows.map((row, index) => (
                      <Row key={index}>
                        <Col lg="6">
                          <Row>
                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label>Type</Form.Label>
                                <Form.Select
                                  className="form-select-lg form-control-icon"
                                  value={row.break_type || ""}
                                  onChange={(e) => handleSelectChange(e, index, "break_type")}
                                >
                                  <option defaultValue="">Select Type</option>
                                  {BreakDropdown.map((breakType) => {
                                    return (
                                      <option key={breakType.id} value={breakType.id}>{breakType.break_type}</option>
                                    )
                                  })}
                                </Form.Select>
                                <i
                                  style={{ fontSize: "16px" }}
                                  className="ri-time-line mt-4"
                                ></i>
                              </Form.Group>
                            </Col>
                            <Col lg="11">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label>Duration (In Minutes)</Form.Label>
                                <Form.Select
                                  className="form-select-lg form-control-icon"
                                  value={row.break_duration}
                                  onChange={(e) => handleSelectChange(e, index, "break_duration")}
                                  disabled={row.isChecked}
                                >
                                  <option defaultValue="">Select Time</option>
                                  {Minutes.map((min) => {
                                    return (
                                      <option key={min.value} value={min.value}>{min.label}</option>
                                    )
                                  })}
                                </Form.Select>
                                <i style={{ fontSize: "16px" }} className="ri-time-line mt-4"></i>
                              </Form.Group>
                            </Col>
                            <Col lg="1" className="mt-2 d-flex align-items-center">
                              <div className="form-check form-switch form-switch-custom form-switch-md">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`break-${index}`}
                                  checked={row.isChecked}
                                  onChange={(e) => handleCheckboxChanges(e, index)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {!row.isChecked && (
                          <Col lg="2" className="mt-4">
                            <p>Set start and end time.</p>
                          </Col>
                        )}
                        {row.isChecked && (
                          <Col lg="5">
                            <Row>
                              <Col lg="6">
                                <Form.Group className="mb-3">
                                  <Form.Label>Start Time</Form.Label>
                                  <div className="position-relative form-icon">
                                    <Cleave
                                      placeholder="hh:mm"
                                      options={{
                                        time: true,
                                        timePattern: ['h', 'm']
                                      }}
                                      value={row.start_time || ""}
                                      onChange={(e: any) => handleTimeChange(e.target.rawValue, index, "start_time")}
                                      className={`form-control form-control-lg form-control-icon`}
                                    />
                                    <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="mb-3">
                                  <Form.Label>End Time</Form.Label>
                                  <div className="position-relative form-icon">
                                    <Cleave
                                      placeholder="hh:mm"
                                      options={{
                                        time: true,
                                        timePattern: ['h', 'm']
                                      }}
                                      value={row.end_time || ""}
                                      onChange={(e: any) => handleTimeChange(e.target.rawValue, index, "end_time")}
                                      className={`form-control form-control-lg form-control-icon`}
                                    />
                                    <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col lg="1" className="break-allow">
                          <Button onClick={() => handleDeleteBreak(index)} className="delete-break">
                            <i className="ri-delete-bin-line"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </Col> */}
                </Row>
              </Offcanvas.Body>
            </SimpleBar>
            <Row>
              <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                  <Row>
                    <Col lg={12} className="off-bottom-staff justify-content-end">
                      <div className="off-confirm d-flex gap-2">
                        <Button
                          onClick={toggleAddTimesheetCanvas}
                          color="primary"
                          className="clear-btn"
                        >
                          Cancel
                        </Button>
                        {!editId ?
                          <Dropdown>
                            <Dropdown as={ButtonGroup}>
                              {formik.isSubmitting ? (
                                <Button
                                  color="primary"
                                  className="clear-btn"
                                  disabled
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </Button>
                              ) : (
                                <Button style={{ background: '#ED1B24' }} type="submit" size='lg'>Add</Button>
                              )}

                              <Dropdown.Toggle split style={{ background: '#ED1B24' }} id="dropdown-split-basic" />

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleAddAndApprove}>Add And Approve</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Dropdown>
                          :
                          <Dropdown>
                            <Dropdown as={ButtonGroup}>
                              {formik.isSubmitting ? (
                                <Button
                                  color="primary"
                                  className="clear-btn"
                                  disabled
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </Button>
                              ) : (
                                <Button style={{ background: '#ED1B24' }} type="submit" size='lg'>Update</Button>
                              )}

                              <Dropdown.Toggle split style={{ background: '#ED1B24' }} id="dropdown-split-basic" />

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleAddAndApprove}>Update and Approve</Dropdown.Item>
                                <Dropdown.Item onClick={handleReject}>Update and Reject</Dropdown.Item>
                                <Dropdown.Item onClick={handlePending}>Update and Pending</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Dropdown>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </Offcanvas>
      </React.Fragment>
    </>
  );
};

export default TimesheetPage;
