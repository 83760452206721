import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "Common/TableContainer";
import {
    Offcanvas,
    Button,
    Row,
    Col,
    Card,
    Table,
    Modal,
    Form,
    Badge,
    Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { GetpayrollDataByStaff, updatePayrunList, ArchivePayrunList, getStaffDocumentDetail, getByStaffDetail } from "helpers/saibackend_helper";
import Cleave from 'cleave.js/react';
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';

interface StaffPayData {
    staff_pay_run_data_id: number;
    date: string;
    shift_time: string;
    is_petrol: number;
    earning_type: string;
    location_name: string;
    start_time: string;
    end_time: string;
    total_break_hrs: string;
    total_hrs: number;
    rate: number;
    amount: number;
    is_split: number;
}

interface StaffPayRunData {
    staff_pay_run_data_id: number;
    start_time: string;
    end_time: string;
    total_break_hrs: string;
    rate: number | string;
}

interface ApproveById {
    staff_pay_run_id: number;
    status: number;
}

type SetApproveById = (value: ApproveById) => void;


const PayrollSingleTable = ({ data, currentPage, totalPages, onPageChange, handleSortChange, handleArchiveStaffPayRun, handleStaffListUpdate, payrollStatus, approveFormik, setApproveById, approveByID }:
    {
        data: any,
        currentPage: number;
        totalPages: number;
        onPageChange: (page: number) => void;
        handleSortChange: (column: string, direction: string) => void;
        handleArchiveStaffPayRun: (id: number) => Promise<void>;
        handleStaffListUpdate: () => Promise<void>;
        payrollStatus: string,
        setApproveById: SetApproveById;
        approveFormik: FormikProps<{
            staff_pay_run_id: number;
            status: number;
        }>;
        approveByID: ApproveById;

    }) => {

    const [isBottom, setIsBottom] = useState(false);
    const [selectedStaffId, setSelectedStaffId] = useState(0);
    const [staffPayDataList, setStaffPayDataList] = useState<StaffPayData[]>([]);
    const getTimesheetDetails = (id: number) => {
        setIsBottom(true);
        setSelectedStaffId(id);
        fetchStaffPayrollDetails(id);
    };
    const [payrollStaff, setPayrollStaff] = useState("")

    /*Get Timesheet List */
    const fetchStaffPayrollDetails = async (dataId: number) => {
        try {
            const response = await GetpayrollDataByStaff(
                dataId ?? 0,
            );
            setStaffPayDataList(response.data);

        } catch (error) {
            console.log(error);
        }
    };
    /*Get Timesheet List*/

    const parseTime = (time: string): number => {
        if (!time || !time.includes(':')) return 0;
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const calculateTotalHours = (startTime: string, endTime: string, totalBreakMinutes: string): number => {
        // Parse start and end times using moment
        const start = moment(startTime, 'HH:mm');
        const end = moment(endTime, 'HH:mm');

        // If end time is before start time, assume it’s the next day
        if (end.isBefore(start)) {
            end.add(1, 'day'); // Add one day to end time
        }

        // Calculate the difference in hours
        const totalBreakHours = (parseFloat(totalBreakMinutes) || 0) / 60; // Convert minutes to hours
        const totalHours = end.diff(start, 'hours', true) - totalBreakHours;
        return totalHours > 0 ? totalHours : 0; // Ensure non-negative total hours
    };

    const handleInputChange = (index: number, field: string, value: string | number) => {
        const updatedDataList = [...staffPayDataList];
        updatedDataList[index] = { ...updatedDataList[index], [field]: value };

        if (['start_time', 'end_time', 'total_break_hrs', 'rate'].includes(field)) {
            const { start_time, end_time, total_break_hrs, rate } = updatedDataList[index];
            const total_hours = calculateTotalHours(start_time ?? '', end_time ?? '', total_break_hrs ?? '');
            const numericRate = typeof rate === 'string' ? parseFloat(rate) : rate;
            const amount = total_hours * (numericRate || 0);

            updatedDataList[index].total_hrs = isNaN(total_hours) ? 0 : total_hours;
            updatedDataList[index].amount = isNaN(amount) ? 0 : amount;
        }

        setStaffPayDataList(updatedDataList);
    };


    const [staffPayRunData, setStaffPayRunData] = useState<StaffPayRunData[]>([]);

    useEffect(() => {
        const payRunData = staffPayDataList.map((item, index) => ({
            staff_pay_run_data_id: item.staff_pay_run_data_id,
            start_time: item.start_time,
            end_time: item.end_time,
            total_break_hrs: item.total_break_hrs,
            rate: item.rate,
        }));
        setStaffPayRunData(payRunData);
    }, [staffPayDataList]);


    const formik = useFormik({
        initialValues: staffPayRunData,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await updatePayrunList(staffPayRunData);
                if (response.status === 1) {
                    toast.success(response.message);
                    handleStaffListUpdate();
                } else {
                    toast.error(response.message);
                    return;
                }
            } catch (error) {
                console.error("Error Adding/Updating Payrun:", error);
                toast.error("Error Payrun. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const handleSubmit = () => {
        formik.handleSubmit();
    }

    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
    const [archiveLoader, setArchiveLoader] = useState(false)

    const handleArchivePayrun = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await ArchivePayrunList(id);
            toast.success(response.message);
            setmodal_center(false);
            fetchStaffPayrollDetails(selectedStaffId);
            setArchiveLoader(false)
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };

    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const handleApprove = (staffPayRunId: number) => {
        setApproveById({ staff_pay_run_id: staffPayRunId, status: 1 });
        approveFormik.handleSubmit();
    };

    const handleReject = (staffPayRunId: number) => {
        setApproveById({ staff_pay_run_id: staffPayRunId, status: 2 });
        approveFormik.handleSubmit();
    };


    const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

    const editMaster = async (id: any) => {
        try {
            const response = await getByStaffDetail(id);
            const data = response.data;
            setStaffViewDetail(data);
            tog_standard()
        } catch (error) {
            return;
        }
    };


    const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
        any[]
    >([]);

    const fetchStaffDocumentDetails = async (id: any) => {
        try {
            const response = await getStaffDocumentDetail("", id);
            setStaffDocumentDetail(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const doc_url = process.env.REACT_APP_IMAGE_URL
    const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

    const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

    const getAbbreviation = (name: string) => {
        return name
            ?.split(' ')
            ?.map(word => word.charAt(0))
            ?.join('')
            ?.toUpperCase()
            ?.slice(0, 2);
    };


    const columns = useMemo(
        () => [
            {
                header: "Staff Name",
                disableFilters: true,
                accessorKey: "staff",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (
                        <div>
                            <div className="d-flex gap-2">
                                <div>
                                    <h5 className="mb-0" style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                        {cell.row.original.staff} <i onClick={() => {
                                            if (cell.row.original.staff_id) {
                                                editMaster(cell.row.original.staff_id);
                                                fetchStaffDocumentDetails(cell.row.original.staff_id);
                                            }
                                        }} className="ri-information-line link-info" style={{ cursor: 'pointer', fontSize: '18px' }} ></i>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: "Total Shift",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_shift",
            },
            {
                header: "Locations",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_locations",
            },
            {
                header: "Total Hours",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_hrs",
            },
            {
                header: "Gross Earnings",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                accessorKey: "total_earning",
                cell: (cellProps: any) => {
                    const total_earning = cellProps.row.original.total_earning;
                    return (
                        <span>{total_earning > 0 ? '$' + total_earning : 0}</span>
                    );
                }
            },
            {
                header: "Status",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "status",
                cell: (cellProps: any) => {
                    const p_status = cellProps.row.original.status;

                    let badgeContent;
                    let badgeVariant;

                    switch (p_status) {
                        case 0:
                            badgeContent = 'Pending';
                            badgeVariant = 'warning'; // Adjust according to your styling
                            break;
                        case 1:
                            badgeContent = 'Approved';
                            badgeVariant = 'success'; // Adjust according to your styling
                            break;
                        case 2:
                            badgeContent = 'Rejected';
                            badgeVariant = 'danger'; // Adjust according to your styling
                            break;
                        default:
                            badgeContent = 'Unknown';
                            badgeVariant = 'secondary'; // Default styling
                            break;
                    }

                    return (
                        <Badge bg={badgeVariant}>
                            {badgeContent}
                        </Badge>
                    );
                }
            },
            {
                header: "Action",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const itemId = cellProps.row.original.id;
                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <i
                                    onClick={() => {
                                        getTimesheetDetails(itemId);
                                        setPayrollStaff(cellProps.row.original.staff);
                                    }}
                                    className="ri-fullscreen-line link-info p-0"
                                    style={{ fontSize: "18px", cursor: "pointer" }}
                                ></i>

                            </div>
                            {payrollStatus !== "Pay Processed" &&
                                <>
                                    {cellProps.row.original.status === 0 &&
                                        <>
                                            <div className="edit">
                                                <i onClick={() => handleApprove(cellProps.row.original.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-checkbox-circle-line link-success"></i>
                                            </div>
                                            <div className="edit">
                                                <i onClick={() => handleReject(cellProps.row.original.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="r ri-close-circle-line link-danger"></i>
                                            </div>
                                        </>
                                    }
                                    <div className="edit">
                                        <i onClick={() => handleArchiveStaffPayRun(itemId)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line link-warning"></i>
                                    </div>
                                </>
                            }
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {data.length > 0 ? (
                <TableContainer
                    isPagination={true}
                    columns={columns || []}
                    data={data}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
            ) : (
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Payroll Details Available</h5>
                    </div>
                </div>
            )}
            <Offcanvas
                className="add-role-canvas"
                show={isBottom}
                onHide={() => setIsBottom(false)}
                placement="bottom"
                style={{ minHeight: "700px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">{payrollStaff}</Offcanvas.Title>
                    <div>
                        {payrollStatus !== "Pay Processed" &&
                            (formik.isSubmitting ? (
                                <Button
                                    color="primary"
                                    className="edit-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button onClick={handleSubmit} className="edit-btn">
                                    Update
                                </Button>
                            )
                            )}
                    </div>
                </Offcanvas.Header>
                <>
                    <SimpleBar
                        style={{ maxHeight: "600px" }}
                        className="px-3"
                    >
                        <Offcanvas.Body>
                            <Row>
                                <Col lg="12">
                                    <div className="table-card p-3">
                                        <table className="table align-middle table-bordered">
                                            <thead className="table-light">
                                                <tr style={{ textAlign: "center" }}>
                                                    <th>Shift date</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Pay Category</th>
                                                    <th scope="col" style={{ width: '8%' }}>Start time</th>
                                                    <th scope="col" style={{ width: '8%' }}>End Time</th>
                                                    <th scope="col" style={{ width: '8%' }}>Break Minutes</th>
                                                    <th scope="col" style={{ width: '8%' }}>Total Hours</th>
                                                    <th scope="col" style={{ width: '8%' }}>Rate($)</th>
                                                    <th scope="col" style={{ width: '8%' }}>Amount($)</th>
                                                    <th scope="col" style={{ width: "80px" }}>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {staffPayDataList.map((item, index) => (
                                                    <tr key={index} style={{ textAlign: "center" }}>
                                                        <td>
                                                            <div>
                                                                {item.is_split != 2 &&
                                                                    <>{item.date ?? "-"}<br />
                                                                        <span className="text-primary">{item.shift_time}</span>
                                                                        {item.is_petrol === 1 && <Badge>Patrol Shift</Badge>}</>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.is_split != 2 ? item.location_name : ''}
                                                        </td>
                                                        <td>{item.earning_type}</td>
                                                        <td>
                                                            <Cleave
                                                                placeholder="hh:mm"
                                                                options={{ time: true, timePattern: ['h', 'm'] }}
                                                                value={item.start_time}
                                                                className="form-control"
                                                                disabled={payrollStatus === "Pay Processed"}
                                                                onChange={(e) => handleInputChange(index, 'start_time', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Cleave
                                                                placeholder="hh:mm"
                                                                options={{ time: true, timePattern: ['h', 'm'] }}
                                                                value={item.end_time}
                                                                className="form-control"
                                                                disabled={payrollStatus === "Pay Processed"}
                                                                onChange={(e) => handleInputChange(index, 'end_time', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="number"
                                                                className="form-control-lg form-control-icon name-input"
                                                                placeholder="Enter Break Hrs"
                                                                value={item.total_break_hrs}
                                                                disabled={payrollStatus === "Pay Processed"}
                                                                onChange={(e) => handleInputChange(index, 'total_break_hrs', parseFloat(e.target.value))}
                                                            />
                                                        </td>
                                                        <td>{item.total_hrs !== undefined ? item.total_hrs.toFixed(2) : '0.00'}</td>
                                                        <td>
                                                            <Form.Control
                                                                type="number"
                                                                className="form-control-lg form-control-icon name-input"
                                                                placeholder="Enter Rate"
                                                                value={item.rate}
                                                                disabled={payrollStatus === "Pay Processed"}
                                                                onChange={(e) => handleInputChange(index, 'rate', parseFloat(e.target.value))}
                                                            />
                                                        </td>
                                                        <td>{item.amount !== undefined ? item.amount.toFixed(2) : '0.00'}</td>
                                                        <td>
                                                            {(payrollStatus !== "Pay Processed" && item.is_split != 2) &&
                                                                <i
                                                                    style={{ fontSize: '18px', cursor: 'pointer' }}
                                                                    className="ri-delete-back-2-line link-danger"
                                                                    onClick={() => {
                                                                        handleArchivePayrun(item.staff_pay_run_data_id)
                                                                    }}
                                                                ></i>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </SimpleBar>
                </>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete this Payrun.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal id="myModal" size="sm" show={modal_standard} onHide={() => { tog_standard(); }} >
                {/* <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                                {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                            </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex align-items-center" >
                        <div className="flex-shrink-0 position-relative">
                            {StaffViewDetail?.avatar ?
                                <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                :
                                <div className="avatar-sm">
                                    <div className="avatar-title rounded bg-danger-subtle text-danger">
                                        {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="flex-grow-1 ms-2">
                            <h5 className="fs-md mb-0"><Link to="/pages-profile" className="text-dark">{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</Link></h5>
                            <p className="text-muted mb-0"> {StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"} </p>
                            <p className="text-muted mb-0"> {StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"} </p>
                        </div>
                    </div>
                    <div style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '18px', cursor: 'pointer' }}>
                        <i onClick={tog_standard} className="ri-close-line"></i>
                    </div>
                    <div className="mt-4">
                        <p className="text-muted mb-2">
                            <i className="bi bi-telephone align-baseline me-1"></i>
                            {StaffViewDetail?.mobile_no ? (
                                <Link to={`tel:${StaffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                    {StaffViewDetail.mobile_no}
                                </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2">
                            <i className="bi bi-envelope align-baseline me-1"></i>
                            {StaffViewDetail?.email ? (
                                <Link to={`mailto:${StaffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{StaffViewDetail.email} </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"} </p>
                        <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"} </p>
                        <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"} </p>
                    </div>
                    {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses").length > 0 &&
                        <div className="border-bottom border-top border-dashed py-4 mt-4">
                            <h5 className="card-title mb-3">License</h5>
                            <div className="vstack gap-3">
                                {StaffDocumentDetail
                                    .filter((doc) => doc.document_type === "Licenses")
                                    .map((doc, index) => (
                                        <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-sm border border rounded">
                                                    <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                                        <i className="bi bi-file-pdf"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6>License No : {doc.ref_no}</h6>
                                                <p className="text-muted mb-0">Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <Link to={`#`} onClick={() => handleOpenDocument(doc.document_path)} className="icon-link text-reset stretched-link fs-xl" style={{ transform: "translate3d(0, -.125rem, 0)" }}>
                                                    <i className="ri-eye-line link-info"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default PayrollSingleTable;
