
import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Form, Image,Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';


interface SubComplianceMasterData {
    id: number;
    document_name: string;
    document_path: string,
    document_type: string;
    ref_no: number;
    expiry_date: string,
    reminder: string,
    is_critical: number;
}

type SortOrder = 'asc' | 'desc';

interface SubComplianceTableProps {
    data: SubComplianceMasterData[];
    archiveMaster: (id: number) => Promise<void>;
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
    handleArchiveCompliance : (id: number) => Promise<void>;
}


const StaffDocumentTable: React.FC<SubComplianceTableProps> = ({
    data,
    archiveMaster,
    editMaster,
    handlePageChange,
    handlePerRowsChange,
    paginationTotalRows,
    handleSort,
    handleArchiveCompliance
}) => {

    const doc_url = process.env.REACT_APP_IMAGE_URL

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Document Type</span>,
            selector: (row: any) => row.document_type,
            sortable: true,
            sortField: 'document_type',
            cell: (row: any) => {
                return (
                    <>
                        {row.document_type ? row.document_type : "-"}
                        {
                            row.expired_status=="Expired" &&
                                <>&nbsp;<Badge>Expired</Badge></>
                        }
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>License No</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.ref_no ? row.ref_no : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>State</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.state_id ? row.state_name : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Document Name</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row?.document_name ? row?.document_name : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Reminder</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.reminder ? row.reminder : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Expiry Date</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.expiry_date ? row.expiry_date : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Critical</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row?.is_critical === 1 ? "Yes" : "No"}
                    </>
                )
            },
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-sm'>Action</span>,
            sortable: false,

            cell: (row: any) => {
                return (
                    <>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <i onClick={() => editMaster(row.id)} className="ri-edit-2-line link-success" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            <i onClick={() => handleOpenDocument(row.document_path)} className=" ri-eye-line link-info" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            <i onClick={() => handleArchiveCompliance(row.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line link-danger"></i>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default StaffDocumentTable