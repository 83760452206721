import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import EmployeeImg from "assets/images/auth/employeeForm.png";
import SaiLogo from "assets/images/auth/sailogo.png";

// Images
import withRouter from "Common/withRouter";

const EmployeeAuth = ({ children }: any) => {
  return (
    <React.Fragment>
      <div className="auth-page-wrapper position-relative d-flex align-items-center justify-content-center min-vh-100">
        <Container fluid style={{ height: "100vh" }}>
          <Row className="justify-content-center">
            <Col lg={11} className="w-100">
              <Card className="mb-0">
                <Row className="flex-xxl-row employee-form">
                  <Col xxl={7} className="head-title">
                    <Card className="employee-form-card mb-0">
                      <Card.Body className="d-flex justify-content-between flex-column p-0">
                        <div className="">
                          <img className="logo" src={SaiLogo} />
                        </div>
                        <div className="form-title ">
                          <h3>Welcome To SAI</h3>
                          <h4>We’re excited to onboard you.</h4>
                        </div>
                        <img className="person" src={EmployeeImg}  />
                      </Card.Body>
                    </Card>
                  </Col>
                  {children}
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EmployeeAuth);
