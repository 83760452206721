import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Nav,
  ProgressBar,
  Tab,
  Image,
  Dropdown,
  Button,
  Badge,
  Spinner,
  Container
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import SaliLogo from "assets/images/auth/sailogo.png";
import { Link } from "react-router-dom";
import SearchableAddress from "helpers/SearchableAddress";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import country from "Common/country";
import { format } from 'date-fns';
import sideimage from "assets/images/auth/empOnboarding.png";
import successfullyImage from "assets/images/auth/employeeRegistered.png";
import profile from "assets/images/auth/userimg.png"
import usflag from "assets/images/flags/us.svg";
import SaiLogo from "assets/images/auth/sailogo.png";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import { VerificationTokenDetail, onBoardingStaff, getStateMasterDetail } from "helpers/saibackend_helper";

interface RowData {
  id: number;
  document_path: File | string | null,
  showUploadButton: boolean;
  document_name: string;
  expiry_date: string,
  ref_no: string,
  reminder: string,
  is_critical: number,
  document_type: string,
  state_id: string
}

interface AddressData {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface StateMasterData {
  id: number;
  state_name: string
}

interface StaffData {
  avatar: null;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: string;
  employee_status_id: number;
  access_level: string;
  notes: string,
  subcontractor_id: number;
  preferred_name: string,
  gender: string,
  tax_scale: number,
  address: string,
  dob: string;
  start_date: string
  emergency_name: string,
  emergency_number: string,
  relation: string,
  emergency_email: string,
  additional_tax: string,
  bank_name: string,
  bank_holder_name: string,
  acc_no: string,
  bsb_no: string,
  superannuation_fund_name: string,
  superannuation_account_no: string,
  tfn_no: string,
  staff_locations_count: number,
  weekly_working_hours: number,
  staff_leaves: [
    {
      leave_type_id: number,
      default_hours: number,
      used_leave_hours: number,
      available_hours: number
    }
  ],
  staff_locations: [],
  daily_working_hours: number,
  subcontractor_name: string,
  tfn_file_path: null
  is_invite: number
  documents: RowData[];
  is_tfn: number
}



const EmployeeOnboarding = () => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [dob, setDob] = useState("");
  const [seletedCountry3, setseletedCountry3] = useState<any>({
    id: 240,
    flagImg: usflag,
    countryName: "United States of America",
    countryCode: "+1",
  });

  const calculateProgress = () => {
    switch (activeTab) {
      case 0:
        return 0;
      case 1:
        return 10;
      case 2:
        return 20;
      case 3:
        return 52;
      case 4:
        return 85;
      case 5:
        return 75;
      default:
        return 0;
    }
  };

  const [activeTab, setactiveTab] = useState<number>(0);
  const [taxFileNumber, setTaxFileNumber] = useState("Yes");


  // Token Verification
  // ================================================================================================//

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const token = queryParams.get('token') || ''
  const [loading, setLoading] = useState(false);
  const [staffDetail, setStaffDetail] = useState<StaffData | null>(null);

  const [fieldShow, setFieldShow] = useState(false)

  const CheckToken = async () => {
    setLoading(true);
    try {
      const response = await VerificationTokenDetail(email, token);
      if (response.status === 1) {
        setFieldShow(true)
        setStaffDetail(response.data)
      } else {
        setFieldShow(false)
        return;
      }

    } catch (error) {
      toast.error("Error fetching details. Please try again later.");
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    if (!email || !token) {
      toast.error("Invalid or missing email/token in URL.");
    } else {
      CheckToken()
    }
  }, [email, token]);

  // ================================================================================================//

  useEffect(() => {
    if (staffDetail !== null) {
      const initialValues = {
        onboarding_token: token,
        first_name: staffDetail?.first_name || "",
        last_name: staffDetail?.last_name || "",
        email: email,
        mobile_no: staffDetail?.mobile_no || "",
        gender: staffDetail?.gender || "",
        dob: staffDetail?.dob || "",
        notes: staffDetail?.notes || "",
        address: staffDetail?.address || "",
        emergency_name: staffDetail?.emergency_name || "",
        emergency_number: staffDetail?.emergency_number || "",
        relation: staffDetail?.relation || "",
        emergency_email: staffDetail?.relation || "",
        bank_name: staffDetail?.bank_name || "",
        bank_holder_name: staffDetail?.bank_holder_name || "",
        acc_no: staffDetail?.acc_no || "",
        bsb_no: staffDetail?.bsb_no || "",
        tax_scale: staffDetail?.is_tfn || 0,
        additional_tax: staffDetail?.tfn_no || "",
        superannuation_fund_name: staffDetail?.superannuation_fund_name || "",
        superannuation_account_no: staffDetail?.superannuation_account_no || "",
        documents: [
          {
            document_name: "",
            expiry_date: "",
            is_critical: "",
            ref_no: "",
            reminder: "",
            document_path: "",
            document_type: "",
            state_id: ""
          }
        ]
      };
      formik.setValues(initialValues);
    }
  }, [staffDetail]);


  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required").matches(/^[A-Za-z0-9]+$/, "First name can only contain letters and digits"),
    last_name: Yup.string().required("Last name is required").matches(/^[A-Za-z0-9 ]+$/, "Last name can only contain letters, digits, and spaces"),
    mobile_no: Yup.number().required('Mobile Number is required').positive('Mobile Number must be a positive number').integer('Mobile Number must be an integer'),
    gender: Yup.string().nullable().required("Gender is required"),
  });


  const formik = useFormik({
    initialValues: {
      onboarding_token: token,
      first_name: "",
      last_name: "",
      email: email,
      mobile_no: "",
      gender: "",
      dob: "",
      notes: "",
      address: "",
      emergency_name: "",
      emergency_number: "",
      relation: "",
      emergency_email: "",
      bank_name: "",
      bank_holder_name: "",
      acc_no: "",
      bsb_no: "",
      tax_scale: 0,
      additional_tax: "",
      superannuation_fund_name: "",
      superannuation_account_no: "",
      documents: [
        {
          document_name: "",
          expiry_date: "",
          is_critical: "",
          ref_no: "",
          reminder: "",
          document_path: "",
          document_type: "",
          state_id: ""
        }
      ]

    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const formData = new FormData();
        formData.append('onboarding_token', values.onboarding_token);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('mobile_no', values.mobile_no);
        formData.append('gender', values.gender);
        formData.append('dob', values.dob);
        formData.append('notes', values.notes);
        formData.append('address', values.address);
        formData.append('emergency_name', values.emergency_name);
        formData.append('emergency_number', values.emergency_number);
        formData.append('relation', values.relation);
        formData.append('emergency_email', values.emergency_email);
        formData.append('bank_name', values.bank_name);
        formData.append('bank_holder_name', values.bank_holder_name);
        formData.append('acc_no', values.acc_no);
        formData.append('bsb_no', values.bsb_no);
        formData.append('tax_scale', values.tax_scale.toString());
        formData.append('additional_tax', values.additional_tax);
        formData.append('superannuation_fund_name', values.superannuation_fund_name);
        formData.append('superannuation_account_no', values.superannuation_account_no);

        values.documents.forEach((document, index) => {
          if (document.document_type) {
            formData.append(`documents[${index}][document_name]`, document.document_name);
            formData.append(`documents[${index}][expiry_date]`, document.expiry_date);
            formData.append(`documents[${index}][is_critical]`, document.is_critical);
            formData.append(`documents[${index}][ref_no]`, document.ref_no);
            formData.append(`documents[${index}][reminder]`, document.reminder ? document.reminder : '0');
            formData.append(`documents[${index}][document_path]`, document.document_path);
            formData.append(`documents[${index}][document_type]`, document.document_type);
            formData.append(`documents[${index}][state_id]`, document.state_id);
          }
        });

        const response = await onBoardingStaff(formData);
        if (response?.status === 0) {
          toast.error(response?.message);
        } else {
          toast.success(response.message);
          formik.resetForm()
          setCountdown(3);
        }
      } catch (error) {
        toast.error("Error Reset Password. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('tax_scale', taxFileNumber === "Yes" ? 1 : 0);
  }, [taxFileNumber]);

  const initialRowData: RowData[] = [{ id: 1, document_path: null, showUploadButton: true, document_name: '', expiry_date: "", ref_no: "", reminder: "", is_critical: 0, document_type: "", state_id: "" }];

  const [rows, setRows] = useState<RowData[]>(initialRowData);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: number) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setRows(rows.map(row =>
        row.id === rowId ? { ...row, document_path: selectedFile, showUploadButton: false } : row
      ));
    }
  };

  const handleRemoveFile = (rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, document_path: null, showUploadButton: true } : row
    ));
  };

  const addRow = () => {
    setRows([...rows, { id: rows.length + 1, document_path: null, showUploadButton: true, document_name: '', expiry_date: "", ref_no: "", reminder: "", is_critical: 0, document_type: "", state_id: "" }]);
  };

  const deleteRow = (id: number) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleDocumentNameChange = (value: string, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, document_name: value } : row
    ));
  };

  const handleReferenceChange = (value: string, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, ref_no: value } : row
    ));
  };

  const handleExpiryDateChange = (value: string, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, expiry_date: value } : row
    ));
  };

  const handleReminderChange = (value: string, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, reminder: value } : row
    ));
  };

  const handleIsCriticalChange = (checked: boolean, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, is_critical: checked ? 1 : 0 } : row
    ));
  };

  const handleDocumentTypeChange = (value: string, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, document_type: value } : row
    ));
  };

  const handleStateChange = (value: string, rowId: number) => {
    setRows(rows.map(row =>
      row.id === rowId ? { ...row, state_id: value } : row
    ));
  };

  useEffect(() => {
    formik.setFieldValue('documents', rows);
  }, [rows]);


  const baseurl = process.env.REACT_APP_IMAGE_URL

  const handleOpenDocument = (path: string) => {
    const fullPath = `${baseurl}/storage/${path}`;
    window.open(fullPath, '_blank');
  };

  const [address, setAddress] = useState<string>('');

  const handleAddressChange = (data: AddressData | boolean) => {
    if (typeof data === 'boolean') return;
    setAddress(data.address);
    formik.setFieldValue("address", data.address);
  };

  // State Dropdown Details 
  // ================================================================================================================================ //

  const [defaultPayloadStateValue, setDefaultPayloadStateValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort_order: "DESC",
    is_archive: 0
  })

  const [StateMasterDetail, setStateMasterDetail] = useState<StateMasterData[]>([])

  const fetchStateetails = async () => {
    try {
      const response = await getStateMasterDetail(defaultPayloadStateValue.page, defaultPayloadStateValue.page_size, defaultPayloadStateValue.search_term, defaultPayloadStateValue.sort_order, defaultPayloadStateValue.is_archive);
      setStateMasterDetail(response.data.data_list);

    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchStateetails()
  }, []);

  const [countdown, setCountdown] = useState<null | number>(null);

  useEffect(() => {
    if (countdown === null) return;

    if (countdown === 0) {
      window.location.href = '/login';
    }

    const timerId = setTimeout(() => {
      setCountdown(prevCountdown => prevCountdown! - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [countdown]);

  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) :
        (
          fieldShow ?
            <Row className="emp-onboarding">
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={12} className="logo-div text-center">
                        <img className="sai-logo" src={SaiLogo} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12} className="organization-details">
                <Tab.Container activeKey={activeTab}>
                  <Row>
                    <Col
                      lg={12}
                      id="custom-progress-bar"
                      className="progress-nav mb-4"
                    >
                      <div>
                        <ProgressBar
                          now={calculateProgress()}
                          style={{ height: "1px" }}
                        ></ProgressBar>
                      </div>

                      <Nav
                        as="ul"
                        variant="pills"
                        className="progress-bar-tab custom-nav"
                      >
                        <Nav.Item as="li" className="d-flex align-items-baseline">
                          <Nav.Link
                            as="button"
                            eventKey="0"
                            onClick={() => setactiveTab(0)}
                            className="rounded-pill"
                          >
                            1
                          </Nav.Link>
                          <Nav.Link
                            eventKey="0"
                            className="nav-name"
                            style={{ width: "fit-content", background: "none" }}
                            onClick={() => setactiveTab(0)}
                          >
                            Personal Details
                          </Nav.Link>
                        </Nav.Item>

                        {/* <Nav.Item as="li">
                      <Nav.Link
                        as="button"
                        eventKey="1"
                        onClick={() => setactiveTab(1)}
                        className="rounded-pill"
                      >
                        2
                      </Nav.Link>
                      <Nav.Link
                        eventKey="1"
                        className="nav-name"
                        style={{ width: "fit-content", background: "none" }}
                        onClick={() => setactiveTab(1)}
                      >
                        Eligibility Details
                      </Nav.Link>
                    </Nav.Item> */}

                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="2"
                            onClick={() => setactiveTab(2)}
                            className="rounded-pill"
                          >
                            2
                          </Nav.Link>
                          <Nav.Link
                            eventKey="2"
                            className="nav-name"
                            style={{ width: "fit-content", background: "none" }}
                            onClick={() => setactiveTab(2)}
                          >
                            Bank Details
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="3"
                            onClick={() => setactiveTab(3)}
                            className="rounded-pill"
                          >
                            3
                          </Nav.Link>
                          <Nav.Link
                            eventKey="3"
                            className="nav-name"
                            style={{ width: "fit-content", background: "none" }}
                            onClick={() => setactiveTab(3)}
                          >
                            Emergency Details
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="4"
                            onClick={() => setactiveTab(4)}
                            className="rounded-pill"
                          >
                            4
                          </Nav.Link>
                          <Nav.Link
                            eventKey="4"
                            className="nav-name"
                            style={{ width: "fit-content", background: "none" }}
                            onClick={() => setactiveTab(4)}
                          >
                            Licenses / Compliance
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Form onSubmit={formik.handleSubmit}>
                      <Col lg={12}>
                        <Tab.Content>
                          {activeTab === 0 && (
                            <Tab.Pane
                              eventKey="0"
                              // id="pills-gen-info"
                              role="tabpanel"
                            // className="d-flex"
                            // aria-labelledby="pills-gen-info-tab"
                            >
                              <Row>
                                <Col lg={12}>
                                  <Card className="employee-onboarding-form-card">
                                    <Card.Body className="p-0">
                                      <Row className="align-items-center">
                                        <Col lg={6} className="heading-employee-title">
                                          <h4>Personal Details</h4>
                                        </Col>
                                        <Col lg={6} align="right" className="cancel-btn-div">
                                          <Button
                                            variant="link"
                                            className="cancel-btn"
                                            href="/login"
                                          >
                                            Skip
                                          </Button>
                                        </Col>
                                        <div className="line"> </div>
                                        <div className="form-body">
                                          {/* <SimpleBar style={{ maxHeight: "525px" }} className="px-3"> */}
                                          <Row>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>First name*</Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="first_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter your first name"
                                                    value={formik.values.first_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.first_name && formik.errors.first_name)}
                                                  />
                                                  <i className="bx bx-face"></i>
                                                  <Form.Control.Feedback type="invalid">
                                                    {formik.errors.first_name}
                                                  </Form.Control.Feedback>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>Last name*</Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="last_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter your last name"
                                                    value={formik.values.last_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.last_name && formik.errors.last_name)}
                                                  />
                                                  <i className="bx bx-face"></i>
                                                  <Form.Control.Feedback type="invalid">
                                                    {formik.errors.last_name}
                                                  </Form.Control.Feedback>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Email address*
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="email"
                                                    name="email"
                                                    className="form-control-lg form-control-icon name-input"
                                                    disabled
                                                    value={formik.values.email}
                                                  />
                                                  <i className="bi bi-briefcase"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                              <Form.Label>Contact number*</Form.Label>
                                              <div className="position-relative form-icon">
                                                <Form.Control
                                                  type="number"
                                                  name="mobile_no"
                                                  className="form-control-lg form-control-icon name-input"
                                                  placeholder="Enter your contact number"
                                                  value={formik.values.mobile_no}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  isInvalid={!!(formik.touched.mobile_no && formik.errors.mobile_no)}
                                                />
                                                <i className=" ri-keyboard-line"></i>
                                                <Form.Control.Feedback type="invalid">
                                                  {formik.errors.mobile_no}
                                                </Form.Control.Feedback>
                                              </div>
                                            </Col>
                                            <Col lg={6}>
                                              <div className="mb-3 d-flex flex-column form-icon">
                                                <Form.Label htmlFor="JoiningdatInput">
                                                  Birth date
                                                </Form.Label>
                                                <Flatpickr
                                                  className="form-control-lg form-control-icon"
                                                  name="dob"
                                                  options={{
                                                    dateFormat: "d-m-Y",
                                                    maxDate: 'today',
                                                  }}
                                                  placeholder="Select date"
                                                  value={formik.values.dob}
                                                  onChange={(dates: any[]) => {
                                                    const formattedDate = format(dates[0], 'dd-MM-yyyy');
                                                    formik.setFieldValue("dob", formattedDate);
                                                  }}
                                                />
                                                <i
                                                  style={{ fontSize: "16px" }}
                                                  className="ri-calendar-event-line mt-4"
                                                ></i>
                                              </div>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4 form-icon">
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Select
                                                  className="form-select-lg form-control-icon"
                                                  id="floatingSelect"
                                                  name="gender"
                                                  value={formik.values.gender}
                                                  onChange={formik.handleChange}
                                                  isInvalid={!!formik.touched.gender && !!formik.errors.gender}
                                                >
                                                  <option defaultValue="">
                                                    Select your Gender
                                                  </option>
                                                  <option value="Male">Male</option>
                                                  <option value="Female">Female</option>
                                                </Form.Select>
                                                <i className="bx bx-face mt-4"></i>
                                                <Form.Control.Feedback type="invalid" className="position-absolute">
                                                  {formik.errors.gender}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>Address</Form.Label>
                                                <SearchableAddress
                                                  label="Address"
                                                  name="address"
                                                  placeholder="Enter Address"
                                                  onChange={handleAddressChange}
                                                  invalid={false}
                                                  defaultValue={{
                                                    latitude: "",
                                                    longitude: "",
                                                    address: address ? address : formik.values.address,
                                                  }}
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          {/* </SimpleBar> */}
                                        </div>
                                        <Col lg={12} className="next-page1">
                                          <Button
                                            color="primary"
                                            onClick={() => setactiveTab(2)}
                                            className="next-btn"
                                          >
                                            NEXT <i className="bi bi-arrow-right"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Tab.Pane>
                          )}
                          {activeTab === 2 && (
                            <Tab.Pane eventKey="2" role="tabpanel">
                              <Row>
                                <Col lg={12}>
                                  <Card className="employee-onboarding-form-card">
                                    <Card.Body className="p-0">
                                      <Row className="align-items-center">
                                        <Col lg={6} className="heading-employee-title">
                                          <h4>Bank Details</h4>
                                        </Col>
                                        <Col lg={6} className="cancel-btn-div" align="right">
                                          <Button
                                            variant="link"
                                            className="cancel-btn"
                                            href="/login"
                                          >
                                            Skip
                                          </Button>
                                        </Col>
                                        <div className="line"> </div>
                                        <div className="form-body">
                                          <Row>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>Bank Name</Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="bank_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter your bank name"
                                                    value={formik.values.bank_name}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="ri-bank-line"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Account Holder name*
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="bank_holder_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter your account holder name"
                                                    value={formik.values.bank_holder_name}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="bx bx-face"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Account number*
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="acc_no"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter your account number"
                                                    value={formik.values.acc_no}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="ri-keyboard-box-line"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>BSB number*</Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="bsb_no"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter your BSB number"
                                                    value={formik.values.bsb_no}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="ri-keyboard-box-line"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                              <div>
                                                <Form.Label>Tax File no ?</Form.Label>
                                                <div className="d-flex gap-3">
                                                  <div className="form-check mb-2">
                                                    <Form.Check
                                                      type="radio"
                                                      name="is_taxfile"
                                                      id="is_taxfile1"
                                                      onChange={() =>
                                                        setTaxFileNumber("Yes")
                                                      }
                                                      checked={
                                                        taxFileNumber === "Yes"
                                                      }
                                                    />
                                                    <Form.Label htmlFor="is_taxfile1">
                                                      Yes
                                                    </Form.Label>
                                                  </div>
                                                  <div className="form-check">
                                                    <Form.Check
                                                      type="radio"
                                                      name="is_taxfile"
                                                      id="is_taxfile2"
                                                      onChange={() =>
                                                        setTaxFileNumber("No")
                                                      }
                                                      checked={taxFileNumber === "No"}
                                                    />
                                                    <Form.Label htmlFor="is_taxfile2">
                                                      No
                                                    </Form.Label>
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                            {taxFileNumber === "Yes" && (
                                              <Col lg={12}>
                                                <Form.Group className="mb-4">
                                                  <Form.Label>
                                                    Tax File Number*
                                                  </Form.Label>
                                                  <div className="position-relative form-icon">
                                                    <Form.Control
                                                      type="text"
                                                      name="additional_tax"
                                                      className="form-control-lg form-control-icon name-input"
                                                      placeholder="Enter your tax file number"
                                                      value={formik.values.additional_tax}
                                                      onChange={formik.handleChange}
                                                    />
                                                    <i
                                                      style={{ fontSize: "16px" }}
                                                      className="ri-keyboard-box-line"
                                                    ></i>
                                                  </div>
                                                </Form.Group>
                                              </Col>
                                            )}
                                            {taxFileNumber === "No" && (
                                              <Col lg={12}>
                                                <Form.Group className="mb-4">
                                                  <Form.Label>
                                                    Reason For No*
                                                  </Form.Label>
                                                  <div className="position-relative form-icon">
                                                    <Form.Control
                                                      type="text"
                                                      name="notes"
                                                      className="form-control-lg form-control-icon name-input"
                                                      placeholder="Enter reason for no"
                                                      value={formik.values.notes}
                                                      onChange={formik.handleChange}
                                                    />
                                                    <i
                                                      style={{ fontSize: "16px" }}
                                                      className="bx bx-notepad"
                                                    ></i>
                                                  </div>
                                                </Form.Group>
                                              </Col>
                                            )}
                                            <Col lg="12">
                                              <h5 className="sup-title">Superannuation Details</h5>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Superannuation Fund name*
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="superannuation_fund_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter supperannuation fund name"
                                                    value={formik.values.superannuation_fund_name}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i
                                                    style={{ fontSize: "16px" }}
                                                    className="ri-bank-line"
                                                  ></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Superannuation Account number*
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="superannuation_account_no"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter fund account number"
                                                    value={formik.values.superannuation_account_no}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i
                                                    style={{ fontSize: "16px" }}
                                                    className="ri-keyboard-box-line"
                                                  ></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </div>
                                        <Col lg={12} className="previous-page">
                                          <Button
                                            color="primary"
                                            onClick={() => setactiveTab(0)}
                                            className="previous-btn"
                                          >
                                            <i className="bi bi-arrow-left"></i>{" "}
                                            Previous
                                          </Button>
                                          <Button
                                            color="primary"
                                            className="next-btn"
                                            onClick={() => setactiveTab(3)}
                                          >
                                            NEXT <i className="bi bi-arrow-right"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Tab.Pane>
                          )}
                          {activeTab === 3 && (
                            <Tab.Pane eventKey="3" role="tabpanel">
                              <Row>
                                <Col lg={12}>
                                  <Card className="employee-onboarding-form-card">
                                    <Card.Body className="p-0">
                                      <Row className="align-items-center">
                                        <Col lg={6} className="heading-employee-title">
                                          <h4>Emergency Information</h4>
                                        </Col>
                                        <Col lg={6} className="cancel-btn-div" align="right">
                                          <Button
                                            variant="link"
                                            className="cancel-btn"
                                            href="/login"
                                          >
                                            Skip
                                          </Button>
                                        </Col>
                                        <div className="line"> </div>
                                        <div className="form-body">
                                          <Row>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Emergency Contact Person Name
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="emergency_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter emergency contact person name"
                                                    value={formik.values.emergency_name}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="bx bx-face"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Emergency Contact Person number
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="emergency_number"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter emergency contact person number"
                                                    value={formik.values.emergency_number}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="bx bx-phone-call"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Emergency Contact Person Email
                                                </Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="email"
                                                    name="emergency_email"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter emergency contact person email"
                                                    value={formik.values.emergency_email}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="ri-mail-line"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>Relationship</Form.Label>
                                                <div className="position-relative form-icon">
                                                  <Form.Control
                                                    type="text"
                                                    name="relation"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter emergency contact person relation"
                                                    value={formik.values.relation}
                                                    onChange={formik.handleChange}
                                                  />
                                                  <i className="bx bx-face"></i>
                                                </div>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </div>
                                        <Col lg={12} className="previous-page">
                                          <Button
                                            color="primary"
                                            onClick={() => setactiveTab(2)}
                                            className="previous-btn"
                                          >
                                            <i className="bi bi-arrow-left"></i>{" "}
                                            Previous
                                          </Button>
                                          <Button
                                            color="primary"
                                            className="next-btn"
                                            onClick={() => setactiveTab(4)}
                                          >
                                            NEXT <i className="bi bi-arrow-right"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Tab.Pane>
                          )}
                          {activeTab === 4 && (
                            <Tab.Pane eventKey="4" role="tabpanel">
                              <Row>
                                <Col lg={12}>
                                  <Card className="employee-onboarding-form-card">
                                    <Card.Body className="p-0">
                                      <Row className="align-items-center">
                                        <Col lg={6} className="heading-employee-title">
                                          <h4>
                                            Licenses / Compliance
                                          </h4>
                                        </Col>
                                        <Col lg={6} className="cancel-btn-div" align="right">
                                          <Button
                                            variant="link"
                                            className="cancel-btn"
                                            href="/login"
                                          >
                                            Skip
                                          </Button>
                                        </Col>
                                        <div className="line"> </div>
                                        <Row className="doc-row" style={{ gap: "60px", height: '525px' }}>
                                          <Col lg={12}>
                                            <SimpleBar
                                              autoHide={false}
                                              // style={{ maxHeight: "61vh", overflowX: "hidden" }}
                                              style={{ maxHeight: "520px", overflowX: "hidden" }}
                                            >
                                              {rows.map((row, index) => (
                                                <Row className="add-doc" key={row.id}>
                                                  <Col lg="2">
                                                    <Form.Group className="mb-3 form-icon">
                                                      <Form.Label>Document Type</Form.Label>
                                                      <Form.Select
                                                        className="form-select-lg form-control-icon"
                                                        id="floatingSelect"
                                                        name="document_type"
                                                        value={row.document_type}
                                                        onChange={(e) => handleDocumentTypeChange(e.target.value, row.id)}
                                                      >
                                                        <option value="">Select</option>
                                                        <option value="Licenses">Licenses</option>
                                                        <option value="Compliance">Compliance</option>
                                                      </Form.Select>
                                                      <i style={{ fontSize: '16px' }} className="ri-article-line mt-4"></i>
                                                    </Form.Group>
                                                  </Col>
                                                  {(row.document_type === 'Licenses' || row.document_type === 'Compliance') && (
                                                    <Col lg="2" className="upload-section-col">
                                                      <Form.Group>
                                                        <Form.Label className="doc-label">
                                                          Document {index + 1}
                                                        </Form.Label>
                                                        <div className="uploadsection">
                                                          {row.showUploadButton ? (
                                                            <div className="d-flex align-item-center">
                                                              <Form.Label
                                                                htmlFor={`document_path-${row.id}`}
                                                                className="uploadlabel"
                                                              >
                                                                <Form.Control
                                                                  type="file"
                                                                  className="form-control-lg"
                                                                  style={{ display: "none" }}
                                                                  id={`document_path-${row.id}`}
                                                                  onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, row.id)}
                                                                />
                                                                <i className="bx bx-plus"></i> Choose File
                                                              </Form.Label>
                                                            </div>
                                                          ) : (
                                                            <Col lg="12" className="closeuser py-0">
                                                              {row.document_path && (
                                                                <p>{typeof row.document_path === "string" ? row.document_path : row.document_path.name}</p>
                                                              )}
                                                              <Button
                                                                onClick={() => handleRemoveFile(row.id)}
                                                                color="red"
                                                              >
                                                                X
                                                              </Button>
                                                            </Col>
                                                          )}
                                                        </div>
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  {row.document_type === 'Compliance' && (
                                                    <Col lg="2">
                                                      <Form.Group className="mb-3">
                                                        <Form.Label>Document name</Form.Label>
                                                        <div className="position-relative form-icon">
                                                          <Form.Control
                                                            type="text"
                                                            name="document_name"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="Enter Document Name"
                                                            value={row.document_name}
                                                            onChange={(e) => handleDocumentNameChange(e.target.value, row.id)}
                                                          />
                                                          <i
                                                            style={{ fontSize: "16px" }}
                                                            className="ri-file-text-line"
                                                          ></i>
                                                        </div>
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  {row.document_type === 'Licenses' && (
                                                    <Col lg="2">
                                                      <Form.Group className="mb-3">
                                                        <Form.Label>License</Form.Label>
                                                        <div className="position-relative form-icon">
                                                          <Form.Control
                                                            type="name"
                                                            name="ref_no"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="License#"
                                                            onChange={(e) => handleReferenceChange(e.target.value, row.id)}
                                                            value={row.ref_no}
                                                          />
                                                          <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-article-line"
                                                          ></i>
                                                        </div>
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  {(row.document_type === 'Licenses' || row.document_type === 'Compliance') && (
                                                    <Col lg="2">
                                                      <div className="mb-3 d-flex flex-column form-icon">
                                                        <Form.Label htmlFor="implement Start Date ">
                                                          Expiry date
                                                        </Form.Label>
                                                        <Flatpickr
                                                          className="form-control-lg form-control-icon"
                                                          options={{
                                                            dateFormat: "d M, Y",
                                                            minDate: "today"
                                                          }}
                                                          placeholder="Select date"
                                                          defaultValue="30 Oct, 2023"
                                                          name="expiry_date"
                                                          value={row.expiry_date}
                                                          onChange={(dates: any[]) => {
                                                            const formattedDate = format(dates[0], 'dd-MM-yyyy');
                                                            handleExpiryDateChange(formattedDate, row.id);
                                                          }}
                                                        />
                                                        <i
                                                          style={{ fontSize: "16px" }}
                                                          className="ri-calendar-event-line mt-4"
                                                        ></i>
                                                      </div>
                                                    </Col>
                                                  )}
                                                  {row.document_type === 'Licenses' && (
                                                    <Col>
                                                      <Form.Group className="mb-3 form-icon">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Select
                                                          className="form-select-lg form-control-icon"
                                                          id="state"
                                                          name="state_id"
                                                          onChange={(e) => handleStateChange(e.target.value, row.id)}
                                                          value={row.state_id}
                                                        >
                                                          <option value="">Select State</option>
                                                          {StateMasterDetail.map((stateDetail) => (
                                                            <option key={stateDetail.id} value={stateDetail.id}>
                                                              {stateDetail.state_name}
                                                            </option>
                                                          ))}
                                                        </Form.Select>
                                                        <i style={{ fontSize: '16px' }} className=" ri-map-pin-line mt-4"></i>
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  {row.document_type === 'Licenses' && (
                                                    <Col>
                                                      <Form.Group className="mb-3">
                                                        <Form.Label>Reminder</Form.Label>
                                                        <div className="position-relative form-icon">
                                                          <Form.Control
                                                            type="name"
                                                            name="reminder"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="in Days"
                                                            onChange={(e) => handleReminderChange(e.target.value, row.id)}
                                                            value={row.reminder}
                                                          />
                                                          <i
                                                            style={{ fontSize: "16px" }}
                                                            className=" ri-time-line"
                                                          ></i>
                                                        </div>
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  {row.document_type === 'Licenses' && (
                                                    <Col style={{ flex: '0.2' }} className="d-flex align-items-center">
                                                      <Form.Group className="mb-3">
                                                        <Form.Label>Critical</Form.Label>
                                                        <div className="form-check form-switch form-switch-custom form-switch-md mb-2">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="is_critical"
                                                            id={`critical-${row.id}`}
                                                            checked={row.is_critical === 1}
                                                            onChange={(e) => handleIsCriticalChange(e.target.checked, row.id)}
                                                          />
                                                        </div>
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  <Col lg="1" className="document-adjust-div d-flex justify-content-center align-items-center gap-2 mt-3 p-0">
                                                    <Button
                                                      onClick={() => deleteRow(row.id)}
                                                      className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                    >
                                                      <i
                                                        className="ph-trash"
                                                        style={{ fontSize: "15px" }}
                                                      ></i>
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              ))}
                                              <Row>
                                                <Col lg={12}>
                                                  <Button className="add-btn p-0 mb-3" onClick={addRow}>
                                                    <i className="ri-add-fill"></i> Add More
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </SimpleBar>
                                          </Col>
                                        </Row>
                                        <Col lg={12} className="previous-page">
                                          <Button
                                            color="primary"
                                            onClick={() => setactiveTab(3)}
                                            className="previous-btn"
                                          >
                                            <i className="bi bi-arrow-left"></i>{" "}
                                            Previous
                                          </Button>
                                          {formik.isSubmitting ? (
                                            <Button
                                              color="primary"
                                              className="next-btn"
                                              disabled
                                            >
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            </Button>
                                          ) : (
                                            <Button
                                              color="primary"
                                              className="next-btn"
                                              type="submit"
                                            >
                                              Submit <i className="bi bi-arrow-right"></i>
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Tab.Pane>
                          )}
                          {activeTab === 5 && (
                            <Col lg="12">
                              <Card className="add-location-form-card" style={{ minHeight: '704px' }}>
                                <Card.Body>
                                  <div className="registered">
                                    <img
                                      src={successfullyImage}
                                      alt="employee-registered"
                                    />
                                    <div className="content">
                                      <h3>Registration Successfully</h3>
                                      <p>
                                        Your Employee Has Added Successfully Please Go back
                                        to Registration Page.
                                      </p>
                                    </div>
                                    <Button
                                      color="primary"
                                      href="/dashboard"
                                      className="dashboard-btn"
                                    >
                                      <i className="bi bi-arrow-left"></i> Go to Dashboard
                                    </Button>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          )}
                        </Tab.Content>
                      </Col>
                    </Form>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
            :
            <div className="auth-page-wrapper position-relative d-flex align-items-center justify-content-center min-vh-100">
              <Container fluid style={{ height: '100vh' }}>
                <Row className="justify-content-center">
                  <Col lg={11} className='w-100'>
                    <Card className="mb-0">
                      <Row>
                        <Col lg="3" className="order-sm-1 auth-form-card my-auto">
                          <Card className="mb-0 border-0 shadow-none mb-0">
                            <Card.Body >
                              <div className='d-flex justify-content-center mb-5'>
                                <img src={SaliLogo} />
                              </div>
                              <div className="text-center mt-5">
                                <h5 className="fs-3xl">Invalid Token</h5>
                                <p className="text-muted">Please Check your Email is Correct or not</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col lg={9} className="order-sm-2 auth-side-card">
                          <Card className="auth-card bg-secondary border-0 shadow-none d-sm-block mb-0">
                            <Card.Body className=" d-flex justify-content-between flex-column">
                              <div className="text-center">
                                <h3 className="log-in-text">Start your journey with us.</h3>
                                <p className="log-in-title">
                                  It brings together your tasks, projects, timelines, files and more
                                </p>
                              </div>
                              <div className='d-flex justify-content-center align-items-center'>
                                <img className='background-side' src='/back.png' />
                              </div>
                              <div className="text-center">
                                <p className="credit-project opacity-75 mt-3">
                                  &copy; {new Date().getFullYear()} SAI. Design & Develop <i className="mdi mdi-heart text-danger"></i> by <a href="https://www.adriitsolutions.com/">Adri IT Solutions</a>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
        )
      }
    </>
  );
};

export default withRouter(EmployeeOnboarding);
