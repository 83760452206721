import React from 'react'
import ParticlesAuth from '../ParticlesAuth';
import { Card, Image, Col } from 'react-bootstrap';

import er403 from "assets/images/auth/403.jpg"
import SaliLogo from "assets/images/auth/sailogo.png"
import { Link } from 'react-router-dom';

const ForbiddenError = () => {

    document.title=" 403 Error | The Guard Duty";

    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col xxl="3" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body >
                        <div className='d-flex justify-content-center mb-5'>
                                <img src={SaliLogo} />
                            </div>
                            <div className="error-img text-center px-5">
                                <Image src={er403} className="img-fluid" alt="" />
                            </div>
                            <div className="mt-4 text-center pt-3">
                                <div className="position-relative">

                                    <h4 className="fs-2xl error-subtitle text-uppercase mb-0">Access Denied</h4>
                                    <p className="fs-lg text-muted mt-3">You do not have permission to view this page. Please contact your administrator if you believe this is an error.</p>
                                    <div className="mt-4">
                                        <Link to={process.env.PUBLIC_URL + "/dashboard"} className="btn btn-primary"><i className="mdi mdi-home me-1"></i>Back to home</Link>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </React.Fragment>
        </ParticlesAuth>
    )
}

export default ForbiddenError;