import React, { useState, useEffect } from "react";
import SaliLogo from "assets/images/auth/sailogo.png";
// import ParticlesAuth from 'pages/AuthenticationInner/ParticlesAuth';
import EmployeeAuth from "pages/AuthenticationInner/EmployeeAuth";
import { Button, Col, Form, Row, Spinner, Container, Card } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { ChangePasswordTokenDetail, updatePassword } from "helpers/saibackend_helper";

interface OnBoardingData {
  onborading_link: string;
}

const EmployeeForm = (props: any) => {

  const [showNewPassword, setShowNewPassword] = useState(false);
  const togglePasswordVisibility = (setPasswordVisibility: { (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; (arg0: (prevState: any) => boolean): void; }) => {
    setPasswordVisibility((prevState) => !prevState);
  };

  const [countdown, setCountdown] = useState<null | number>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const token = queryParams.get('token') || ''
  const navigate = useNavigate();

  const [fieldShow, setFieldShow] = useState(false)

  const CheckToken = async () => {
    setLoading(true);
    try {
      const response = await ChangePasswordTokenDetail(email, token);
      if (response.status === 1) {
        setFieldShow(true)
      } else {
        setFieldShow(false)
        return;
      }
    } catch (error) {
      toast.error("Error fetching details. Please try again later.");
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    if (!email || !token) {
      toast.error("Invalid or missing email/token in URL.");
    } else {
      CheckToken()
    }
  }, [email, token]);


  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters long"),
    password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref('password')], "Passwords must match"),
  });

  const [onBoard, setOnBoard] = useState<OnBoardingData>({ onborading_link: '' });

  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      password_confirmation: "",
      token: token
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await updatePassword(values);
        if (response.status === 0) {
          toast.error(response.message);
        } else {
          toast.success(response.message);
          setOnBoard(response.data)
          formik.resetForm()
          setCountdown(5);
        }
      } catch (error) {
        toast.error("Error Reset Password. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (countdown === null) return;

    if (countdown === 0) {
      navigate(onBoard?.onborading_link);
    }

    const timerId = setTimeout(() => {
      setCountdown(prevCountdown => prevCountdown! - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [countdown]);

  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) :
        (
          fieldShow ?
            <EmployeeAuth>
              <React.Fragment>
                <div className="col-xxl-3 employee-signup-form">
                  <Form onSubmit={formik.handleSubmit}>
                    <div>
                      <div className='mb-5 sai-logo'>
                        <img src={SaliLogo} />
                      </div>
                      <div className="text-center">
                        <h4>Set Your Password</h4>
                        <p>Reset a secured password for your account.</p>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-4">
                            <Form.Label>Your Email </Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="email"
                                name="email"
                                className="form-control-lg form-control-icon name-input"
                                placeholder="Enter your first name"
                                value={formik.values.email}
                                disabled
                              />
                              <i className="ri-mail-line"></i>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-4">
                            <Form.Label htmlFor="new-password-input">Set Password</Form.Label>
                            <div className="position-relative auth-pass-inputgroup">
                              <Form.Control
                                type={showNewPassword ? 'text' : 'password'}
                                className="pe-5 password-input"
                                name="password"
                                placeholder="Enter password"
                                id="new-password-input"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                isInvalid={!!(formik.touched.password && formik.errors.password)}
                              />
                              <Button
                                variant="link"
                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                type="button"
                                onClick={() => togglePasswordVisibility(setShowNewPassword)}
                              >
                                <i className={`ri-eye${showNewPassword ? '-off' : ''}-fill align-middle`}></i>
                              </Button>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.password}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group>
                            <Form.Label htmlFor="confirm-password-input">Re-Set Password</Form.Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Form.Control
                                type='password'
                                className="pe-5 password-input"
                                name="password_confirmation"
                                placeholder="Confirm password"
                                id="confirm-password-input"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password_confirmation}
                                isInvalid={!!(formik.touched.password_confirmation && formik.errors.password_confirmation)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.password_confirmation}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          {formik.isSubmitting ? (
                            <Button
                              color="primary"
                              className="submit-btn"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          ) : (
                            <Button type="submit" className="submit-btn">
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                  {countdown !== null && (
                    <div className="mt-3 text-center">
                      <p>You will be redirected in {countdown} seconds...</p>
                    </div>
                  )}
                </div>
              </React.Fragment>
            </EmployeeAuth>
            :
            <div className="auth-page-wrapper position-relative d-flex align-items-center justify-content-center min-vh-100">
              <Container fluid style={{ height: '100vh' }}>
                <Row className="justify-content-center">
                  <Col lg={11} className='w-100'>
                    <Card className="mb-0">
                      <Row>
                        <Col lg="3" className="order-sm-1 auth-form-card my-auto">
                          <Card className="mb-0 border-0 shadow-none mb-0">
                            <Card.Body >
                              <div className='d-flex justify-content-center mb-5'>
                                <img src={SaliLogo} />
                              </div>
                              <div className="text-center mt-5">
                                <h5 className="fs-3xl">Invalid Token</h5>
                                <p className="text-muted">Please Check your Email is Correct or not</p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col lg={9} className="order-sm-2 auth-side-card">
                          <Card className="auth-card bg-secondary border-0 shadow-none d-sm-block mb-0">
                            <Card.Body className=" d-flex justify-content-between flex-column">
                              <div className="text-center">
                                <h3 className="log-in-text">Start your journey with us.</h3>
                                <p className="log-in-title">
                                  It brings together your tasks, projects, timelines, files and more
                                </p>
                              </div>
                              <div className='d-flex justify-content-center align-items-center'>
                                <img className='background-side' src='/back.png' />
                              </div>
                              <div className="text-center">
                                <p className="credit-project opacity-75 mt-3">
                                  &copy; {new Date().getFullYear()} SAI. Design & Develop <i className="mdi mdi-heart text-danger"></i> by <a href="https://www.adriitsolutions.com/">Adri IT Solutions</a>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
        )}
    </>
  );
};

export default EmployeeForm;
