import React, { useState, useEffect } from "react";
import {
    Button,
    Col,
    Form,
    Row,
    Offcanvas,
    Modal,
    Nav,
    Tab,
    ButtonGroup,
    Spinner
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import PayGroupTable from "./PayGroupTable";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cleave from 'cleave.js/react';
import Select from 'react-select';
import { getPayCalenderDetail, getPayGroupList, addPayGroupDetail, GetByGroupList, updatePayGroupDetail, archivePayGroupList } from "helpers/saibackend_helper";

interface PayrateRow {
    id: number;
    days: string[];
    from_time: string;
    to_time: string;
    pay_category_id: string;
    rate: number;
}

interface CalenderData {
    id: number;
    name: string;
    value: number
}

interface OptionType {
    value: string;
    label: string;
}

const PayGroup = () => {

    const [isEdit, setIsEdit] = useState(false);

    const toggleEditCanvas = () => {
        setIsEdit(!isEdit);
    };

    const data = [
        {
            id: 1,
            payrate: "ABC 1",
        },
        {
            id: 2,
            payrate: "ABC 2",
        },
        {
            id: 3,
            payrate: "ABC 3",
        }
    ]

    const [rows, setRows] = useState<PayrateRow[]>([
        {
            id: Date.now(),
            days: [],
            from_time: '00:00',
            to_time: '23:59',
            pay_category_id: '',
            rate: 0,
        },
    ]);

    const addRow = () => {
        setRows([
            ...rows,
            {
                id: Date.now(),
                days: [],
                from_time: '00:00',
                to_time: '23:59',
                pay_category_id: '',
                rate: 0,
            },
        ]);
    };

    const removeRow = (id: number) => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleInputChange = (
        id: number,
        field: keyof PayrateRow,
        value: any
    ) => {
        let newValue: string | string[] | number;

        if (field === 'from_time' || field === 'to_time') {
            const formattedTime = value.replace(/^(\d{2})(\d{2})$/, '$1:$2');
            newValue = formattedTime;
        } else if (field === 'days') {
            newValue = value as string[];
        } else if (field === 'rate') {
            newValue = Number(value);
        } else {
            newValue = value;
        }

        setRows(
            rows.map((row) =>
                row.id === id ? { ...row, [field]: newValue } : row
            )
        );
    };


    const handleSelectChange = (selectedOption: OptionType | null, rowId: number) => {
        handleInputChange(rowId, 'pay_category_id', selectedOption ? selectedOption.value : 0);
    };

    const [defaultCalenderValue, setDefaultCalenderValue] = useState({
        search_term: "",
    });

    const [Calenderetail, setCalenderDetail] = useState<CalenderData[]>([]);

    const fetchCalenderDetails = async () => {
        try {
            const response = await getPayCalenderDetail(
                defaultCalenderValue.search_term
            );
            setCalenderDetail(response.data.data_list.map((term: any) => ({
                value: term.id,
                label: term.name
            })));
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        fetchCalenderDetails();
    }, [defaultCalenderValue]);


    const [PayGroupDetail, setPayGroupDetail] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);

    const [defaultValue, setDefaultValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    });


    const fetchPayGroupDetails = async () => {
        try {
            const response = await getPayGroupList(
                defaultValue.page,
                defaultValue.page_size,
                defaultValue.search_term,
                defaultValue.sort.sort_order,
                defaultValue.sort.sort_by,
                defaultValue.is_archive
            );
            setPayGroupDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPayGroupDetails();
    }, [defaultValue]);

    const handlePageChange = (page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };


    const validationSchema = Yup.object().shape({
        group_name: Yup.string().required('Pay Group Name is required'),
    });


    const formik = useFormik({
        initialValues: {
            group_name: '',
            pay_group_rates: [
                {
                    days: [],
                    from_time: '00:00',
                    to_time: '23:59',
                    pay_category_id: "",
                    rate: 0,
                }
            ],
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEditing && editId !== null) {
                    const updateValue = {
                        ...values,
                        pay_group_id: editId,
                        pay_group_rates: rows
                    }
                    const response = await updatePayGroupDetail(updateValue);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                } else {
                    const updatedvalue = {
                        ...values,
                        pay_group_rates: rows
                    }

                    console.log(updatedvalue);
                    const response = await addPayGroupDetail(updatedvalue);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }
                fetchPayGroupDetails()
                setRows([
                    {
                        id: Date.now(),
                        days: [],
                        from_time: '00:00',
                        to_time: '23:59',
                        pay_category_id: '',
                        rate: 0,
                    },
                ]);
                setIsEditing(false);
                setIsEdit(false);
                setEditId(null);
                formik.resetForm();
            } catch (error) {
                console.error("Error Adding/Updating Pay Group:", error);
                toast.error("Error Pay Group. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);

    const editMaster = async (id: number) => {
        try {
            const response = await GetByGroupList(id);
            const data = response.data;
            formik.setValues({
                group_name: data.group_name,
                pay_group_rates: data.pay_group_rates,
            });
            const updatedRows = data.pay_group_rates.map((rate: any) => ({
                id: rate.pay_group_rate_id,
                days: rate.days,
                from_time: rate.from_time,
                to_time: rate.to_time,
                pay_category_id: rate.pay_category_id,
                rate: rate.rate,
            }));
            setIsEditing(true);
            setRows(updatedRows)
            setIsEdit(true)
            setEditId(id);
        } catch (error) {
            console.error("Error fetching Pay Group details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveGroup = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archivePayGroupList(id);
            fetchPayGroupDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            console.error("Error Archive Pay Group:", error);
            toast.error("Error Archive Please try again later.");
        }
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setRows([
            {
                id: Date.now(),
                days: [],
                from_time: '00:00',
                to_time: '23:59',
                pay_category_id: '',
                rate: 0,
            },
        ]);
        setIsEdit(true);
    };


    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg={12}>
                            <div className="d-flex justify-content-end">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openAddForm}
                                >
                                    <i className=" ri-add-fill"></i> Add
                                </Button>
                            </div>
                        </Col>
                        <Col lg="12" className="mt-3">
                            <PayGroupTable editMaster={editMaster} handleArchiveGroup={handleArchiveGroup} data={PayGroupDetail} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} handleSort={handleSort} />
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={isEdit}
                onHide={toggleEditCanvas}
                placement="bottom"
                style={{ minHeight: "800px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Pay Rate
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="mt-4 px-4">
                        <Row>
                            <Col md="4" className="px-4">
                                <Form.Group className="mb-3">
                                    <Form.Label>Pay Group Name</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Form.Control
                                            type="text"
                                            name="group_name"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Pay Group Name"
                                            value={formik.values.group_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.group_name && formik.errors.group_name)}
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.group_name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <SimpleBar
                                    style={{ maxHeight: "500px" }}
                                    className="px-3"
                                >
                                    {rows.map((row, index) => (
                                        <Row key={row.id}>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Day*</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <ButtonGroup aria-label="Basic checkbox toggle button group" className="week_chk_group">
                                                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'PH'].map((day) => (
                                                                <React.Fragment key={day}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check week_chk"
                                                                        id={`${day}-${row.id}`}
                                                                        checked={row.days.includes(day)}
                                                                        onChange={() =>
                                                                            handleInputChange(
                                                                                row.id,
                                                                                'days',
                                                                                row.days.includes(day)
                                                                                    ? row.days.filter((d) => d !== day)
                                                                                    : [...row.days, day]
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="primary"
                                                                        className="mb-0"
                                                                        htmlFor={`${day}-${row.id}`}
                                                                    >
                                                                        {day != "PH" ? day.charAt(0) : "PH"}
                                                                    </Button>
                                                                </React.Fragment>
                                                            ))}
                                                        </ButtonGroup>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>From Time*</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Cleave
                                                            placeholder="hh:mm"
                                                            options={{
                                                                time: true,
                                                                timePattern: ['h', 'm']
                                                            }}
                                                            value={row.from_time}
                                                            onChange={(e: any) => handleInputChange(row.id, "from_time", e.target.rawValue,)}
                                                            className={`form-control form-control-lg form-control-icon`}
                                                        />
                                                        <i
                                                            style={{ fontSize: '16px' }}
                                                            className="ri-calendar-event-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>To Time*</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Cleave
                                                            placeholder="hh:mm"
                                                            options={{
                                                                time: true,
                                                                timePattern: ['h', 'm']
                                                            }}
                                                            value={row.to_time}
                                                            className={`form-control form-control-lg form-control-icon`}
                                                            onChange={(e: any) => handleInputChange(row.id, "to_time", e.target.rawValue,)}
                                                        />
                                                        <i
                                                            style={{ fontSize: '16px' }}
                                                            className="ri-calendar-event-line"
                                                        ></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3 form-icon">
                                                    <Form.Label>Pay Category*</Form.Label>
                                                    <Select
                                                        options={Calenderetail}
                                                        defaultValue={Calenderetail.find(option => option.value === Number(row.pay_category_id))}
                                                        onChange={(selectedOption: OptionType | null) => handleSelectChange(selectedOption, row.id)}
                                                        placeholder="Select Category"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                    />
                                                    <i
                                                        style={{ fontSize: '16px' }}
                                                        className=" ri-list-check mt-4"
                                                    ></i>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Rate</Form.Label>
                                                    <div className="position-relative form-icon">
                                                        <Form.Control
                                                            type="number"
                                                            name="rate"
                                                            className="form-control-lg form-control-icon name-input"
                                                            placeholder="Enter Rate"
                                                            value={row.rate ? row.rate : ""}
                                                            onChange={(e) =>
                                                                handleInputChange(row.id, 'rate', Number(e.target.value))
                                                            }
                                                        />
                                                        <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="d-flex align-items-center mt-3">
                                                {index > 0 && (
                                                    <Button
                                                        className="p-0"
                                                        style={{ background: 'none', border: 'none' }}
                                                        onClick={() => removeRow(row.id)}
                                                    >
                                                        <i
                                                            className="bx bx-trash link-danger"
                                                            style={{ fontSize: "24px" }}
                                                        ></i>
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    ))}
                                    <Button className="add-btn bg-none d-flex align-items-center gap-2 p-0" style={{ background: 'none', border: 'none' }} onClick={addRow}>
                                        <i className="ri-add-fill"></i>   Add Payrate
                                    </Button>
                                </SimpleBar>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg="12" style={{
                            position: "fixed",
                            bottom: "0",
                            width: "100%",
                            left: "0",
                        }}>
                            <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                <Row>
                                    <Col
                                        lg={12}
                                        className="off-bottom-staff justify-content-end"
                                    >
                                        <div className="off-confirm">
                                            <Button
                                                onClick={toggleEditCanvas}
                                                color="secondary"
                                                className="clear-btn me-2"
                                            >
                                                Cancel
                                            </Button>
                                            {formik.isSubmitting ? (
                                                <Button
                                                    color="primary"
                                                    className="next-btn"
                                                    disabled
                                                >
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    className="next-btn"
                                                >
                                                    {isEditing ? "Update" : "Add"}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete this Pay Group.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )

}

export default PayGroup;