import React, { useState, useEffect } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Dropdown,
    Offcanvas,
    Form,
    Tab,
    Nav,
    Modal,
    Badge,
    Spinner
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { GetByPayrollStaffList, ArchiveStaffPayRateList, updatePayrollList, PayrunApproveList, exportPayRunDetail, Reprocess } from "helpers/saibackend_helper";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PayrollSingleTable from "./PayrollSingleTable";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import CountUp from 'react-countup';

const PayrollSingleUser = () => {
    const params = useParams() as { id: string };
    const { id } = params;
    const extractOriginalId = (formattedId: string): number | null => {
        const match = formattedId.match(/^payrun_(\d+)$/);
        return match ? parseInt(match[1], 10) : 0;
    };

    const payload_run_id = id ? extractOriginalId(id) : 0;

    const [payrollDetail, setPayrollDetail] = useState<any>();
    const [PayrollStaffList, setPayrollStaffList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [payloadValue, setPayloadValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0,
    });

    const fetchPayrollDetails = async () => {
        try {
            const response = await GetByPayrollStaffList(
                payload_run_id ?? 0,
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by
            );
            setPayrollDetail(response.data?.master_data);
            setPayrollStatus(response.data?.master_data?.status);
            const staffPayroll = response.data?.staff_payroll;
            if (staffPayroll) {
                setPayrollStaffList(staffPayroll.data_list);
                setTotalPages(staffPayroll.total_pages);
                setCurrentPage(payloadValue.page);
                setTotalRecords(staffPayroll.total_records);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const areAllStatusesOne = () => {
        return PayrollStaffList.every((item: any) => item.status === 1);
    };

    useEffect(() => {
        fetchPayrollDetails();
    }, [payloadValue]);

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayloadValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };


    const [payrollStatus, setPayrollStatus] = useState("")

    const handleSortChange = (column: string) => {
        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
                sort_by: column
            },
            page: 1
        }));
    };


    const handlePageChange = (page: number) => {
        setPayloadValue({ ...payloadValue, page: page });
    };


    const statusColors: Record<string, string> = {
        'Pay Approved': 'warning',
        'Draft': 'secondary',
        'Pay Processed': 'success',
    };

    const badgeColor = statusColors[payrollDetail?.status || ''] || 'primary';

    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
    const [archiveLoader, setArchiveLoader] = useState(false)

    const handleArchiveStaffPayRun = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
        fetchPayrollDetails();
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await ArchiveStaffPayRateList(id);
            fetchPayrollDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };


    const formik = useFormik({
        initialValues: {
            pay_run_id: payload_run_id,
            pay_date: "",
            status: 0,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {

                const updatedValues = {
                    ...values,
                    status: isByIdApprove,
                    pay_date: payrollDetail?.pay_date
                }
                const response = await updatePayrollList(updatedValues);
                if (response.status === 1) {
                    toast.success(response.message);
                    fetchPayrollDetails()
                } else {
                    toast.error(response.message);
                    return;
                }
            } catch (error) {
                console.error("Error Adding/Updating Payroll:", error);
                toast.error("Error Payroll. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const [isByIdApprove, setIsByIdApprove] = useState(0);

    const handleApproveByID = () => {
        setIsByIdApprove(1);
        formik.handleSubmit();
    };

    const handlePayProceedByID = () => {
        setIsByIdApprove(2);
        formik.handleSubmit();
    };

    const [approveByID, setApproveById] = useState({
        staff_pay_run_id: 0,
        status: 0
    })

    const approveFormik = useFormik({
        initialValues: {
            staff_pay_run_id: 0,
            status: 0
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const updatedValues = {
                    ...values,
                    staff_pay_run_id: approveByID.staff_pay_run_id,
                    status: approveByID.status
                }

                const response = await PayrunApproveList(updatedValues)

                if (response.status === 1) {
                    toast.success(response.message);
                    fetchPayrollDetails()
                } else {
                    toast.error(response.message);
                    return;
                }
            } catch (error) {
                console.error("Error Approve/Reject Payrun:", error);
                toast.error("Error Payrun. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });


    // Reprocess //

    const [reloading, setreloading] = useState(false)
    const rerpocess = async () => {
        setreloading(true)
        try {
            const response = await Reprocess(payload_run_id ? payload_run_id : 0);
            if (response.status === 1) {
                toast.success(response.message);
                fetchPayrollDetails();
                setreloading(false)
            } else {
                toast.error(response.message);
                setreloading(false)
                return;
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Export Payrun //

    const [expLoader, setExpLoader] = useState(false)

    const downloadPayrun = async () => {
        setExpLoader(true)
        try {
            await exportPayRunDetail(
                payload_run_id ? payload_run_id : 0
            );
            setExpLoader(false)
        } catch (error) {
            console.error("Error downloading Timesheet:", error);
            toast.error("Error downloading Timesheet. Please try again later.");
        }
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={payrollDetail?.pay_run_name ?? "Payroll Details"} pageTitle="Payroll" pageTitleLink="/payroll" />
                    {loading ?
                        <Row className="placeholder-glow">
                            <Col lg={4}>
                                <div className="placeholder bg-light card-placeholder col-12"></div>
                            </Col>
                            <Col lg={4}>
                                <div className="placeholder bg-light card-placeholder col-12"></div>
                            </Col>
                            <Col lg={4}>
                                <div className="placeholder bg-light card-placeholder col-12"></div>
                            </Col>
                            <Col lg={12} className="mt-4">
                                <div className="placeholder bg-light table-placeholder col-12"></div>
                            </Col>
                        </Row>
                        :
                        <Row className="payroll">
                            <Col lg={12}>
                                {payload_run_id === null ?
                                    <Card className="staff-tabel-card">
                                        <Card.Body>
                                            <div className="text-center"><h4 className="text-danger">Invalid Payroll Id</h4></div>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <>
                                        <Row>
                                            <Col lg={3}>
                                                <Card className="border-bottom border-3 card-animate border-info">
                                                    <Card.Body>
                                                        <div className="avatar-sm float-end">
                                                            <div className="avatar-title bg-info-subtle text-info fs-3xl rounded">
                                                                <i className="bi bi-calendar-week"></i>
                                                            </div>
                                                        </div>
                                                        <h4 className="mb-4"><span>{payrollDetail?.pay_date ? payrollDetail.pay_date : "-"}</span></h4>

                                                        <p className="text-muted fw-medium text-uppercase mb-0">Pay Date</p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg={3}>
                                                <Card className="border-bottom border-3 card-animate border-info">
                                                    <Card.Body>
                                                        <div className="avatar-sm float-end">
                                                            <div className="avatar-title bg-info-subtle text-info fs-3xl rounded">
                                                                <i className="bi bi-people"></i>
                                                            </div>
                                                        </div>
                                                        <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={payrollDetail?.total_staff ? payrollDetail.total_staff : "-"} separator=',' /></span></h4>

                                                        <p className="text-muted fw-medium text-uppercase mb-0">Total Staff</p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg={3}>
                                                <Card className="border-bottom border-3 card-animate border-success">
                                                    <Card.Body>
                                                        <div className="avatar-sm float-end">
                                                            <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                                                                <i className="bi bi-clock"></i>
                                                            </div>
                                                        </div>
                                                        <h4 className="mb-4"><span className="counter-value" data-target="40"><CountUp end={payrollDetail?.total_hrs ? payrollDetail.total_hrs : "-"} separator=',' /></span></h4>

                                                        <p className="text-muted fw-medium text-uppercase mb-0">Total Hours</p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg={3}>
                                                <Card className="border-bottom border-3 card-animate border-primary">
                                                    <Card.Body>
                                                        <div className="avatar-sm float-end">
                                                            <div className="avatar-title bg-primary-subtle text-primary fs-3xl rounded">
                                                                <i className="bi bi-currency-dollar"></i>
                                                            </div>
                                                        </div>
                                                        <h4 className="mb-4">$<span className="counter-value" data-target="40"><CountUp end={payrollDetail?.total_pay_amount ? payrollDetail.total_pay_amount : "-"} separator=',' /></span></h4>

                                                        <p className="text-muted fw-medium text-uppercase mb-0">Total Payable Amount</p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Card className="staff-tabel-card">
                                            <Card.Body>
                                                <Row className="align-items-center g-2 mt-0">
                                                    <Col lg={4} className="mb-3 text-left">
                                                        <h4 className="mb-0">
                                                            <span>{payrollDetail?.pay_run_name}</span> &nbsp;
                                                            <Badge bg={badgeColor}>{payrollDetail?.status}</Badge>
                                                        </h4>
                                                    </Col>
                                                    <Col lg={8} className="btn-section mb-3 text-end">
                                                        <div className="app-search d-none d-md-inline-flex">
                                                            <div className="position-relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    id="search-options"
                                                                    style={{ background: '#F5F6FA' }}
                                                                    value={inputValue}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                                                <span
                                                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                    id="search-close-options"
                                                                ></span>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            color="primary"
                                                            className="clear-btn"
                                                            href="/payroll"
                                                        >
                                                            Back
                                                        </Button>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="sub-btn" size='lg'>
                                                                Action
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {payrollDetail?.status === "Draft" &&
                                                                    <>
                                                                        <Dropdown.Item onClick={rerpocess}>Re-Generate</Dropdown.Item>
                                                                        <Dropdown.Item disabled={!areAllStatusesOne()} onClick={handlePayProceedByID}>Pay Process</Dropdown.Item>
                                                                    </>
                                                                }
                                                                <Dropdown.Item onClick={downloadPayrun}>Export</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        {/* {payrollDetail?.status === "Draft" && */}
                                                        <>
                                                            {/* {reloading ? (
                                                                    <Button
                                                                        variant="danger"
                                                                        className="sub-btn"
                                                                        disabled
                                                                    >
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Button>
                                                                ) : (
                                                                    <Button onClick={rerpocess} className="next-btn"><i className="ri-refresh-line"></i> Re-Generate</Button>
                                                                )} */}
                                                            {/* {formik.isSubmitting ? (
                                                                    <Button
                                                                        color="danger"
                                                                        className="sub-btn"
                                                                        disabled
                                                                    >
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={!areAllStatusesOne()} onClick={handlePayProceedByID} className="next-btn">Pay Process</Button>
                                                                )} */}
                                                        </>
                                                        {/* } */}
                                                        {/* {expLoader ? (
                                                            <Button
                                                                color="primary"
                                                                className="export-btn"
                                                                disabled
                                                            >
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={downloadPayrun} className='export-btn'>
                                                                <i className="bx bx-export me-1 "></i>  Export
                                                            </Button>
                                                        )} */}
                                                    </Col>
                                                    <Col lg={12} className="payroll-table mt-0">
                                                        <PayrollSingleTable
                                                            data={PayrollStaffList}
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                            handleSortChange={handleSortChange}
                                                            handleArchiveStaffPayRun={handleArchiveStaffPayRun}
                                                            handleStaffListUpdate={() => fetchPayrollDetails()}
                                                            payrollStatus={payrollStatus}
                                                            approveFormik={approveFormik}
                                                            setApproveById={setApproveById}
                                                            approveByID={approveByID}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </>
                                }
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >
                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete this Staff Payrate.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PayrollSingleUser;
