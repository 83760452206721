import React, { useEffect, useState } from 'react';
import { Card, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from 'Common/withRouter';
import SaliLogo from "assets/images/auth/sailogo.png"
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, resetLoginFlag } from "../../slices/thunk";
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { checkTokenDetail, resetPassword } from 'helpers/saibackend_helper';

const ResetPassWord = (props: any) => {
    const [showNewPassword, setShowNewPassword] = useState(false);
    const togglePasswordVisibility = (setPasswordVisibility: { (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; (arg0: (prevState: any) => boolean): void; }) => {
        setPasswordVisibility((prevState) => !prevState);
    };
    const [countdown, setCountdown] = useState<null | number>(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email') || '';
    const token = queryParams.get('token') || '';
    const is_invite = queryParams.get('is_invite') || 0;

    document.title = is_invite ? "Set Password | The Guard Duty" : "Reset Password | The Guard Duty";

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("New Password is required")
            .min(8, "Password must be at least 8 characters long"),
        password_confirmation: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref('password')], "Passwords must match"),
    });

    const formik = useFormik({
        initialValues: {
            email: email,
            password: "",
            password_confirmation: "",
            token: token
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                console.log(values);
                const response = await resetPassword(values);
                if (response.status === 0) {
                    toast.error(response.message);
                } else {
                    toast.success(response.message);
                    formik.resetForm()
                    setCountdown(3);
                }
            } catch (error) {
                toast.error(`Error ${is_invite ? 'Set' : 'Reset'} Password. Please try again later.`);
            } finally {
                setSubmitting(false);
            }
        }
    });

    useEffect(() => {
        if (countdown === null) return;

        if (countdown === 0) {
            window.location.href = '/login';
        }

        const timerId = setTimeout(() => {
            setCountdown(prevCountdown => prevCountdown! - 1);
        }, 1000);

        return () => clearTimeout(timerId);
    }, [countdown]);

    const [fieldShow, setFieldShow] = useState(false)

    const CheckToken = async () => {
        setLoading(true);
        try {
            const response = await checkTokenDetail(email, token);
            if (response.status === 1) {
                setFieldShow(true);
            } else {
                setFieldShow(false);
                toast.error(response.message)
            }

        } catch (error) {
            console.error("Error fetching Token details:", error);
            toast.error("Error fetching details. Please try again later.");
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        if (!email || !token) {
            toast.error("Invalid or missing email/token in URL.");
        } else {
            CheckToken()
        }
    }, [email, token]);


    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col xxl="3" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body >
                            <div className='d-flex justify-content-center mb-5'>
                                <img src={SaliLogo} />
                            </div>
                            {fieldShow &&
                                <div className="text-center mt-5">
                                    <h5 className="fs-3xl">{is_invite ? 'Set' : 'Reset'} Password</h5>
                                    {/* <p className="text-muted">Log in To Continue</p> */}
                                </div>
                            }
                            {loading ? (
                                <div className="text-center mt-5">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) :
                                fieldShow ?
                                    <div className="p-2 mt-5">

                                        <Form onSubmit={formik.handleSubmit}>

                                            <Form.Group className="mb-4" controlId="formUsername">
                                                <Form.Label>Email Address<span className="text-danger"></span></Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control type="email" name="email" className="form-control password-input" disabled
                                                        value={formik.values.email}
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-4">
                                                <Form.Label htmlFor="new-password-input">New Password</Form.Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Form.Control
                                                        type={showNewPassword ? 'text' : 'password'}
                                                        className="pe-5 password-input"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        id="new-password-input"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password}
                                                        isInvalid={!!(formik.touched.password && formik.errors.password)}
                                                    />
                                                    <Button
                                                        variant="link"
                                                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        onClick={() => togglePasswordVisibility(setShowNewPassword)}
                                                    >
                                                        <i className={`ri-eye${showNewPassword ? '-off' : ''}-fill align-middle`}></i>
                                                    </Button>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label htmlFor="confirm-password-input">Confirm Password</Form.Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Form.Control
                                                        type='password'
                                                        className="pe-5 password-input"
                                                        name="password_confirmation"
                                                        placeholder="Confirm password"
                                                        id="confirm-password-input"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password_confirmation}
                                                        isInvalid={!!(formik.touched.password_confirmation && formik.errors.password_confirmation)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password_confirmation}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <div className="mt-4">
                                                {formik.isSubmitting ? (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary w-100"
                                                        disabled
                                                    >
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="btn btn-primary w-100" type="submit">{is_invite ? 'Set' : 'Reset'} Password</Button>
                                                )}
                                            </div>
                                        </Form>
                                        {countdown !== null && (
                                            <div className="mt-3 text-center">
                                                <p>You will be redirected in {countdown} seconds...</p>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <h5 className="fs-3xl">Invalid Token</h5>
                                        <p className="text-muted">Please Check your Email is Correct or not</p>
                                    </div>
                            }
                        </Card.Body>
                    </Card>
                    {/* <ToastContainer/> */}
                </Col>
            </React.Fragment>
        </ParticlesAuth>

    );
};

export default withRouter(ResetPassWord);