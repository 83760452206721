import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Nav,
    Row,
    Tab,
    Dropdown,
    Image,
    Offcanvas,
    Table,
    Modal,
    Spinner
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { getInvoiceDetail, addInvoiceDetails, archiveInvoiceMasterDetail, getByInvoiceMasterDetail, updateInvoiceMasterDetail } from "helpers/saibackend_helper";
import InvoiceTemplateTable from "./InvoiceTemplateTable";
import InvoiceTemplateData from "./InvoiceTemplateData";
import blank from "assets/images/users/blanklogo.png";

interface RowData {
    id: number;
    title: string;
    required: string;
}

interface InvoiceMasterData {
    id: number;
    company_name: string;
    bank_name: string;
    account_holder_name: number,
    bsb_no: number,
}


const InvoiceTemplateMaster = (() => {

    const [loading, setLoading] = useState(true);
    const [isBottom, setIsBottom] = useState(false);
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };

    const [totalRows, setTotalRows] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);
    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveCompliance = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const [invoiceMasterDetail, setInvoiceMasterDetail] = useState<InvoiceMasterData[]>([])

    const [defaultValue, setDefaultValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    })

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };


    const handlePageChange = (page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };


    const fetchInvoiceDetails = async () => {
        try {
            const response = await getInvoiceDetail(defaultValue.page, defaultValue.page_size, defaultValue.search_term, defaultValue.sort.sort_order, defaultValue.sort.sort_by, defaultValue.is_archive);
            setInvoiceMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            return;

        }
    };


    useEffect(() => {
        fetchInvoiceDetails();
    }, [defaultValue]);


    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required('Company name is required'),
        company_logo: Yup.mixed().required('Company Logo is required')
            .test('fileType', 'Logo field must be a type of jpeg, png.', (value: any) => {
                if (typeof value === 'string') return true;
                return value && value instanceof File && ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type);
            })
            .test('fileSize', 'Ensure the file size is not greater than 2MB', (value: any) => {
                if (typeof value === 'string') return true;
                return value && value instanceof File && value.size <= 2 * 1024 * 1024;
            }),
        bank_name: Yup.string().required('Bank name is required'),
        bank_account_number: Yup.string().required('Bank Account Number is required'),
        bsb_no: Yup.string().required('Bsb number is required'),
        template_name: Yup.string().required('Template name is required'),
    });

    const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            formik.setFieldValue('company_logo', file);
        }
    };

    const removeLogo = () => {
        setSelectedImage(null);
        formik.setFieldValue('company_logo', null);
    };

    const formik = useFormik({
        initialValues: {
            company_name: '',
            company_logo: null,
            bank_name: '',
            bank_account_number: '',
            bsb_no: '',
            template_name: '',
            notes_1: '',
            notes_2: '',
            notes_3: '',
            notes_4: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {

                const updatedValues = {
                    ...values,
                    notes_1: values.notes_1 || '',
                    notes_2: values.notes_2 || '',
                    notes_3: values.notes_3 || '',
                    notes_4: values.notes_4 || '',
                };

                let response;
                if (isEditing && editId !== null) {
                    response = await updateInvoiceMasterDetail(
                        editId,
                        updatedValues.company_name,
                        updatedValues.company_logo,
                        updatedValues.bank_name,
                        updatedValues.bank_account_number,
                        updatedValues.bsb_no,
                        updatedValues.template_name,
                        updatedValues.notes_1,
                        updatedValues.notes_2,
                        updatedValues.notes_3,
                        updatedValues.notes_4
                    );
                    if (response.status === 0) {
                        toast.error(response.message);
                        setSubmitting(false);
                        return;
                    } else {
                        toast.success(response.message);
                    }
                } else {
                    const response = await addInvoiceDetails(values);
                    if (response.status === 0) {
                        toast.error(response.message);
                        setSubmitting(false);
                        return;
                    } else {
                        toast.success(response.message);
                    }
                }
                toggleBottomCanvas();
                fetchInvoiceDetails();
                setSelectedImage(null)
                formik.resetForm();
            } catch (error) {
                toast.error("Error Invoice Template. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveInvoiceMasterDetail(id);
            fetchInvoiceDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };

    const editMaster = async (id: number) => {
        try {
            const response = await getByInvoiceMasterDetail(id);
            const data = response.data;
            formik.setValues({
                company_name: data.company_name,
                company_logo: data.company_logo,
                bank_name: data.bank_name,
                bank_account_number: data.bank_account_number,
                bsb_no: data.bsb_no,
                template_name: data.template_name,
                notes_1: data.notes_1,
                notes_2: data.notes_2,
                notes_3: data.notes_3,
                notes_4: data.notes_4,
            });
            setIsEditing(true);
            setEditId(id);
            toggleBottomCanvas();

        } catch (error) {
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setIsBottom(true);
        setSelectedImage(null)
    };

    const baseurl = process.env.REACT_APP_IMAGE_URL

    const imageSrc = typeof selectedImage === 'string'
        ? selectedImage
        : formik.values.company_logo
            ? `${baseurl}/${formik.values.company_logo}`
            : blank;


    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                            <h4 className="card-title mb-2">Invoice Template Master</h4>
                            <div className="d-flex gap-2">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openAddForm}
                                >
                                    <i className=" ri-add-fill"></i> Add Template
                                </Button>
                            </div>
                        </Col>
                        <Col lg="12">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <InvoiceTemplateTable data={invoiceMasterDetail} handleSort={handleSort} handleArchiveCompliance={handleArchiveCompliance} editMaster={editMaster} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} />
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={isBottom}
                onHide={toggleBottomCanvas}
                placement="bottom"
                style={{ minHeight: "496px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Invoice Template
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Offcanvas.Body>
                        <Row>
                            <Col lg="2" className="mb-2 text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto">
                                    <label htmlFor="profile-img-file-input">
                                        <img
                                            src={imageSrc ? imageSrc : blank}
                                            alt="Profile"
                                            style={{ width: '250px', height: '150px' }}
                                            className="avatar-lg square object-fit-contain border-0 img-thumbnail user-profile-image"
                                        />
                                    </label>
                                    <div className="avatar-xs p-0 square position-absolute end-0 bottom-0">
                                        <input
                                            id="profile-img-file-input"
                                            type="file"
                                            name="company_logo"
                                            className="profile-img-file-input d-none"
                                            onChange={handleImageChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                {selectedImage ? <Button className="mt-2" onClick={removeLogo}>Remove</Button> : <Button className="mt-2"><label htmlFor="profile-img-file-input">Choose</label></Button>}
                                {formik.touched.company_logo && formik.errors.company_logo && (
                                    <div className="invalid-feedback d-block">
                                        {formik.errors.company_logo}
                                    </div>
                                )}
                            </Col>
                            <Col lg="10">
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Template name
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="template_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Template name"
                                                    value={formik.values.template_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.template_name && formik.errors.template_name)}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.template_name}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Child Company Name
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="company_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Child Company Name"
                                                    value={formik.values.company_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.company_name && formik.errors.company_name)}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.company_name}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Bank Name
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="bank_name"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Bank Name"
                                                    value={formik.values.bank_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.bank_name && formik.errors.bank_name)}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.bank_name}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Bank Account Number
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="number"
                                                    name="bank_account_number"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Bank Account Number"
                                                    value={formik.values.bank_account_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.bank_account_number && formik.errors.bank_account_number)}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.bank_account_number}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                BSB Number
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="number"
                                                    name="bsb_no"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter BSB Number"
                                                    value={formik.values.bsb_no}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.bsb_no && formik.errors.bsb_no)}
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.bsb_no}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Note 1
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="notes_1"
                                                    value={formik.values.notes_1}
                                                    onChange={formik.handleChange}
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Note 1"
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Note 2
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="notes_2"
                                                    value={formik.values.notes_2}
                                                    onChange={formik.handleChange}
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Note 2"
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Note 3
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="notes_3"
                                                    value={formik.values.notes_3}
                                                    onChange={formik.handleChange}
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Note 3"
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Note 4
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="name"
                                                    name="notes_4"
                                                    value={formik.values.notes_4}
                                                    onChange={formik.handleChange}
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Note 4"
                                                />
                                                <i
                                                    style={{ fontSize: "16px" }}
                                                    className=" ri-file-list-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Offcanvas.Body>
                    <Row>
                        <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                            <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                <Row>
                                    <Col lg={12} className="off-bottom-staff justify-content-end">
                                        <div className="off-confirm">
                                            <Button
                                                onClick={toggleBottomCanvas}
                                                color="primary"
                                                className="clear-btn"
                                            >
                                                Cancel
                                            </Button>
                                            {formik.isSubmitting ? (
                                                <Button
                                                    color="primary"
                                                    className="next-btn"
                                                    disabled
                                                >
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            ) : (
                                                <Button type="submit" color="primary" className="next-btn">
                                                    {isEditing ? "Update" : "Add"}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas >
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete Invoice.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default InvoiceTemplateMaster;