import React, { useState , useEffect} from 'react';
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

const mapStyles = {
    width: '100%',
    // height: 'calc(100vh - 45vh)',
    borderRadius: '12px'
};

const LoadingContainer = () => <div>Loading...</div>;

const ClientListMap = (props: any) => {
    const [selectedPlace, setSelectedPlace] = useState<any>(null);
    const [activeMarker, setActiveMarker] = useState<any>(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);

    const onMarkerClick = (props: any, marker: any, e: any) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };

    const onMapClick = () => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    };

    return (
        <div id="gmaps-markers" className="gmaps" style={{ position: "relative" }}>
            <Map
                google={props?.google}
                zoom={8}
                style={mapStyles}
                // initialCenter={{ lat: 22.30389, lng: 70.80216 }}
                onClick={onMapClick}
            >
                {props?.locations?.map((location: any, index: number) => (
                    <Marker
                        key={index}
                        position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
                        name={location.name}
                        onClick={onMarkerClick}
                    />
                ))}
            </Map>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyA35lst1bnWNgD14Eyx3ZFqhXe4qU44xts",
    LoadingContainer: LoadingContainer,
    v: "3",
})(ClientListMap);
