import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Nav,
  Tab,
  Table,
  Modal,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import { format } from 'date-fns';
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import { getBySubcontractorDetail, updateSubcontractorDetail, addSubcontractorComplianceDetail, getSubcontractorComplianceDetail, getStateMasterDetail, archiveSubcontractorComplianceDetail, getBySubcontractorComplianceDetail, updateSubcontractorComplianceDetail } from "helpers/saibackend_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import SearchableAddress from "helpers/SearchableAddress";
import * as Yup from "yup";
import SubcontractorComplianceTable from "./SubcontractorComplianceTable";
import moment from 'moment';


interface SubcontractorData {
  id: number;
  name: string;
  abbreviation: string;
  abn: string;
  contact_person: string;
  contact_number: string;
  email: string;
  address: string;
  total_staff: number;
  assigned_locations: number;
  staff_list: [],
  location_list: [],
  prefere_location_list: []
  is_reset_password: number
  is_invite: number
}

interface AddressData {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}



interface SubComplianceMasterData {
  id: number;
  compliance_name: string;
  document_number: string,
  description: string;
  reminder_days: number;
  date: string,
  is_critical: number;
}

interface SubLocationData {
  id: number;
  address: string;
  city: string,
  state: string;
  postal_code: string;
  country: string,
}

interface StateMasterData {
  id: number;
  state_name: string
}

interface StaffMember {
  first_name: string;
  last_name: string;
}

interface Location {
  location_id: number;
  name: string;
}



const EditSubcontractor = () => {
  document.title = "Edit SubContractor | The Guard Duty";

  const [key, setKey] = useState('first');

  const [modal_standard, setmodal_standard] = useState<boolean>(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  // Subcontractor Location Details 
  // ================================================================================================================================ //

  const [SubcontractorDetail, setSubcontractorDetail] = useState<SubcontractorData | null>(null);

  const getsubcontractordetail = async () => {
    try {
      const subid = localStorage.getItem("subcontractorID");
      if (subid !== null) {
        const response = await getBySubcontractorDetail(parseInt(subid));
        const data = response.data;
        setSubcontractorDetail(data);
      } else {
        toast.error("Error fetching details. Please try again later.");
      }
    } catch (error) {
      toast.error("Error fetching details. Please try again later.");
    }
  };

  useEffect(() => {
    getsubcontractordetail()
  }, [])


  const [address, setAddress] = useState<string>('');

  const handleAddressChange = (data: AddressData | boolean) => {
    if (typeof data === 'boolean') return;
    setAddress(data.address);
    formik.setFieldValue("address", data.address);
  };

  useEffect(() => {
    if (SubcontractorDetail !== null) {
      const initialValues = {
        name: SubcontractorDetail.name || "",
        abbreviation: SubcontractorDetail.abbreviation || "",
        abn: SubcontractorDetail.abn || "",
        contact_person: SubcontractorDetail.contact_person || "",
        contact_number: SubcontractorDetail.contact_number || "",
        email: SubcontractorDetail.email || "",
        address: SubcontractorDetail.address || "",
        staff_list: SubcontractorDetail.staff_list || "",
        is_invite: SubcontractorDetail.is_invite || 0
      };
      formik.setValues(initialValues);
    }
  }, [SubcontractorDetail]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email('Invalid email format').required('Email is required'),
  });

  const [isInvite, setIsInvite] = useState(0);
  const staffList = SubcontractorDetail?.staff_list || [];

  const formik = useFormik({
    initialValues: {
      name: "",
      abbreviation: "",
      abn: "",
      contact_person: "",
      contact_number: "",
      email: "",
      address: "",
      is_invite: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const subid = localStorage.getItem("subcontractorID");
        if (subid !== null) {
          const payload = { ...values, is_invite: isInvite };
          const response = await updateSubcontractorDetail(
            parseInt(subid),
            payload.name,
            payload.abbreviation,
            payload.abn,
            payload.contact_person,
            payload.contact_number,
            payload.email,
            payload.address,
            payload.is_invite
          );
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
          setIsInvite(0)
        } else {
          toast.error("Error fetching details. Please try again later.");
        }
      } catch (error) {
        toast.error("Error Subcontactor. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleUpdateAndInvite = () => {
    setIsInvite(1);
    formik.handleSubmit();
  };


  // Subcontractor Compliance Document Details 
  // ================================================================================================================================ //

  const [totalRows, setTotalRows] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);

  const [subComplianceMasterDetail, setSubComplianceMasterDetail] = useState<SubComplianceMasterData[]>([])

  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: ""
    },
    is_archive: 0
  })

  const handlePageChange = (page: number) => {
    setDefaultValue(prevPayload => ({
      ...prevPayload,
      page: page
    }));
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setDefaultValue(prevPayload => ({
      ...prevPayload,
      page_size: String(newPerPage),
      page: page
    }));
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const subcontractor_id = Number(localStorage.getItem("subcontractorID"))

  const fetchSubComplianceDetails = async () => {
    try {
      const response = await getSubcontractorComplianceDetail(defaultValue.page, defaultValue.page_size, defaultValue.search_term, defaultValue.sort.sort_order, defaultValue.sort.sort_by, defaultValue.is_archive, subcontractor_id);
      setSubComplianceMasterDetail(response.data.data_list);
      setTotalRows(response.data.total_records);
      console.log(response);
    } catch (error) {
      console.log(error);

    }
  };

  const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
    const columnSelector = typeof column === 'function' ? column.name : column;

    setDefaultValue(prevState => ({
      ...prevState,
      sort: {
        sort_by: columnSelector,
        sort_order: sortDirection.toUpperCase(),
      }
    }));
  };


  useEffect(() => {
    if (key === "third") {
      fetchSubComplianceDetails();
    }
  }, [defaultValue, key === "third"]);

  // State Dropdown Details 
  // ================================================================================================================================ //

  const [defaultPayloadStateValue, setDefaultPayloadStateValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort_order: "DESC",
    is_archive: 0
  })

  const [StateMasterDetail, setStateMasterDetail] = useState<StateMasterData[]>([])

  const fetchStateetails = async () => {
    try {
      const response = await getStateMasterDetail(defaultPayloadStateValue.page, defaultPayloadStateValue.page_size, defaultPayloadStateValue.search_term, defaultPayloadStateValue.sort_order, defaultPayloadStateValue.is_archive);
      setStateMasterDetail(response.data.data_list);

    } catch (error) {
      console.log(error);

    }
  };

  useEffect(() => {
    if (key === "third") {
      fetchStateetails()
    }
  }, [key === "third"]);

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false)

  const handleArchiveCompliance = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };


  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }


  const archiveSubcontractorComplianceMaster = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await archiveSubcontractorComplianceDetail(id);
      fetchSubComplianceDetails();
      toast.success(response.message);
      setmodal_center(false)
      setArchiveLoader(false)
    } catch (error) {
      console.error("Error Archive Subcontractor Compliance:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const validComplianceNames = [
    'Workers Comp',
    'Public Liability',
    'Master License',
    'Subcontractor Agreement',
    'Renewal',
    'Documents'
  ];

  // Subcontractor Compliance Document Edit Get by Details 
  // ================================================================================================================================ //

  const editSubcontractorComplianceMaster = async (id: number) => {
    try {
      const response = await getBySubcontractorComplianceDetail(id);
      const data = response.data;
      const complianceName = validComplianceNames.includes(data.compliance_name) ? data.compliance_name : 'Documents';
      Complianceformik.setValues({
        compliance_name: data.compliance_name,
        description: data.description,
        document_number: data.document_number,
        reminder_days: data.reminder_days,
        date: data.date,
        is_critical: data.is_critical,
        state_id: data.state_id,
        compliance_path: data.compliance_path,
        documentName: data.compliance_name
      });
      setIsEditing(true);
      setDocumentType(complianceName)
      setDocumentName(data.compliance_name)
      setShowUploadButton(!data.compliance_path && !file);
      setEditId(id);
      setFile(null)
      setmodal_standard(true)
      fetchSubComplianceDetails();
    } catch (error) {
      console.error("Error fetching Subcontractor Compliance details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  // Subcontractor Compliance Document Handle Function Details 
  // ================================================================================================================================ //

  const baseurl = process.env.REACT_APP_IMAGE_URL

  const handleOpenDocument = (path: string) => {
    const fullPath = `${baseurl}/${path}`;
    window.open(fullPath, '_blank');
  };

  const handleFilePreview = (file: File | null) => {

    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } else {
      return;
    }
  };

  const [file, setFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState(true);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFile(selectedFiles[0]);
      setShowUploadButton(false);
      Complianceformik.setFieldValue('compliance_path', selectedFiles[0])
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setShowUploadButton(true);
  };

  // Subcontractor Compliance Name Handle Function Details 
  // ================================================================================================================================ //

  const [documentType, setDocumentType] = useState('Workers Comp');
  const [documentName, setDocumentName] = useState('');

  const handleDocumentTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDocumentType(e.target.value);
    Complianceformik.resetForm()
    setFile(null)
    setShowUploadButton(true)
    setIsEditing(false)
    setEditId(null)
    Complianceformik.setFieldValue('compliance_name', e.target.value === 'Documents' ? documentName : e.target.value);

    if (e.target.value !== 'Documents') {
      setDocumentName('');
    }
  };

  const handleDocumentNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
    if (documentType === 'Documents') {
      Complianceformik.setFieldValue('compliance_name', e.target.value);
    }
  };

  const handleCheckboxChange = (e: { target: { checked: any; }; }) => {
    Complianceformik.setFieldValue('is_critical', e.target.checked ? 1 : 0);
  };

  // Subcontractor Compliance Add and Edit Function Details 
  // ================================================================================================================================ //

  const Complianceformik = useFormik({
    initialValues: {
      compliance_name: 'Workers Comp',
      description: '',
      document_number: '',
      reminder_days: '',
      date: '',
      is_critical: 0,
      state_id: '',
      compliance_path: null,
      documentName: ''
    },
    validationSchema: Yup.object({
      compliance_name: Yup.string().required('Compliance Name is required'),
      compliance_path: Yup.mixed().required('Document is required'),
      // date: Yup.date().required('Expiry date is required'),
      // state: Yup.string().required('State is required'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const subcontractor_id = localStorage.getItem("subcontractorID");
      const dataToSend = { ...values, subcontractor_id };

      try {
        if (isEditing && editId !== null) {
          const response = await updateSubcontractorComplianceDetail(editId, values.compliance_name, values.compliance_path, values.description, values.document_number, values.reminder_days, values.state_id, values.date, values.is_critical);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          console.log(dataToSend);
          const response = await addSubcontractorComplianceDetail(dataToSend);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        Complianceformik.resetForm();
        fetchSubComplianceDetails();
        setDocumentName('')
        setFile(null)
        setShowUploadButton(true)
        setmodal_standard(false)
        setIsEditing(false);
        setEditId(null);
      } catch (error) {
        console.error("Error Adding/Updating Subcontractor Master:", error);
        toast.error("Error Subcontractor Master. Please try again later.");
      } finally {
        setSubmitting(false);
      }

    },
  });

  const openDocForm = () => {
    setIsEditing(false);
    setEditId(null);
    Complianceformik.resetForm()
    setmodal_standard(true);
    setFile(null)
    setDocumentType('Workers Comp')
    setShowUploadButton(true)
    // setdocname("")
  }

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Edit Subcontractor" pageTitle="Subcontractor" pageTitleLink="/subcontractor" />
            <Row className="edit-staff">
              <Col xl={12} className="user-section">
                <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => setKey(k as string)}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row className="d-flex align-items-center flex-wrap gap-2 mb-4">
                      <div className="col-md order-1 d-flex">
                        <Nav
                          variant="pills"
                          className="arrow-navtabs nav-secondary gap-2 flex-grow-1"
                          style={{ width: 'fit-content' }}
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="first" href="#subcontractordetails">
                              Subcontractor Details
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second" href="#stafflist">
                              Staff List
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third" href="#subcontractorcompliance">
                              Subcontractor Compliance{" "}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <span className="d-flex gap-3">
                          <Button
                            className="sub-cancel-btn"
                            href="/subcontractor"
                          >
                            Back
                          </Button>
                          {formik.isSubmitting ? (
                            <Button
                              color="primary"
                              className="sub-update-btn"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          ) : (
                            <Button type="submit" className="sub-update-btn">
                              Update
                            </Button>
                          )}
                          {SubcontractorDetail?.is_reset_password === 0 &&

                            (formik.isSubmitting ? (
                              <Button
                                color="primary"
                                className="sub-update-btn"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            ) : (
                              < Button onClick={handleUpdateAndInvite} className="sub-update-btn">
                                Update and Invite
                              </Button>
                            )
                            )
                          }
                        </span>
                      </div>
                    </Row>
                    {key === "first" &&
                      <Tab.Content>
                        <Tab.Pane eventKey="first" id="personalDetails">
                          <Card className="section-1">
                            <Card.Header>
                              <h5 className="card-title personal-details-title mb-0">
                                General Details
                              </h5>
                            </Card.Header>
                            <Card.Body>
                              {/* <SimpleBar
                              style={{ maxHeight: "576px" }}
                              className="px-3"
                            > */}
                              <Row>
                                <Col lg={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Subcontractor Name</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder="Enter Subcontractor Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={!!(formik.touched.name && formik.errors.name)}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-building-2-line"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.name}
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      Subcontractor Abbreviation
                                    </Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="abbreviation"
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder="Enter Subcontractor Abbreviation"
                                        value={formik.values.abbreviation}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-edit-circle-line"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Company ABN</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="abn"
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder="Enter Company ABN"
                                        value={formik.values.abn}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-bank-card-2-line"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="12">
                                  <h4 className="card-title mb-2 mt-3">
                                    Contact Details
                                  </h4>
                                </Col>
                                <Col lg={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Contact Person Name</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="contact_person"
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder="Enter Contact Person Name"
                                        value={formik.values.contact_person}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-user-line"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="4" className="mb-4">
                                  <Form.Label>Contact Person Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="contact_number"
                                    className="form-control-lg rounded-end flag-input"
                                    placeholder="Enter Contact Number"
                                    value={formik.values.contact_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Contact Person Email</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="email"
                                        name="email"
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder="Enter Contact Person Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={!!(formik.touched.email && formik.errors.email)}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-mail-open-line"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="12">
                                  <h4 className="card-title mb-2 mt-3">
                                    Address Details
                                  </h4>
                                </Col>
                                {/* <Col lg={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type="text"
                                        name="address"
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder="Enter Address"
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-map-pin-line"
                                      ></i>
                                    </div>
                                  </Form.Group>
                                </Col> */}
                                <Col lg="4">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <SearchableAddress
                                      label="Location Address"
                                      name="address"
                                      placeholder="Enter Address"
                                      onChange={handleAddressChange}
                                      invalid={false}
                                      defaultValue={{
                                        latitude: "",
                                        longitude: "",
                                        address: formik.values.address,
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              {/* </SimpleBar> */}
                            </Card.Body>
                            {/* <Card.Footer>
                            <Row>
                              <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                  <Button
                                    className="cancel-btn"
                                    href="/subcontractor"
                                  >
                                    Cancel
                                  </Button>
                                  <Button onClick={() => setKey('second')} className="next-btn">
                                    Next
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Card.Footer> */}
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    }
                    {key === "second" &&
                      <Tab.Content>
                        <Tab.Pane eventKey="second" id="changePassword">
                          <Card className="section-1">
                            <Card.Header>
                              <h5 className="card-title personal-details-title mb-0">
                                Staff List
                              </h5>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col lg="12">
                                  <SimpleBar
                                    className="py-0"
                                  // style={{ height: "290px" }}
                                  >
                                    <div className="table-responsive mt-0">
                                      <Table className="m-0">
                                        <tbody>
                                          {staffList.length > 0 ? (
                                            staffList.map((item: StaffMember, key: number) => {
                                              const initials = item.last_name
                                                ? `${item.first_name.charAt(0)}${item.last_name.charAt(0)}`
                                                : `${item.first_name.slice(0, 2)}`;

                                              return (
                                                <tr key={key}>
                                                  <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                      <div className="avatar-sm">
                                                        <div className="avatar-title rounded bg-danger-subtle text-danger">
                                                          {initials}
                                                        </div>
                                                      </div>
                                                      <div className="flex-grow-1">
                                                        <h6 className="fs-md mb-1">
                                                          <Link to="#" className="text-reset">
                                                            {item.first_name} {item.last_name}
                                                          </Link>
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          ) : (
                                            <tr>
                                              <td style={{ border: 'none' }}>
                                                <div className="d-flex align-items-center justify-content-center gap-1">
                                                  <div className="flex-grow-1 d-flex justify-content-center">
                                                    <h6 className="fs-md mb-1">No Staff Available</h6>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </SimpleBar>
                                </Col>
                              </Row>
                            </Card.Body>
                            {/* <Card.Footer>
                            <Row>
                              <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                  <Button
                                    className="cancel-btn"
                                    href="/subcontractor"
                                  >
                                    Cancel
                                  </Button>
                                  <Button onClick={() => setKey('four')} className="next-btn">
                                    Next
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Card.Footer> */}
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    }
                  </Form>
                  {key === "third" &&
                    <Tab.Content>
                      <Tab.Pane eventKey="third" id="education">
                        <Card className="section-1">
                          <Card.Header>
                            <h5 className="card-title personal-details-title mb-0">
                              Subcontractor Compliance
                            </h5>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col lg={12}>
                                <div className="d-flex justify-content-end gap-2">
                                  <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        style={{ height: '47px' }}
                                        id="search-options"
                                        value={inputValue}
                                        onChange={handleChange}
                                      />
                                      <span style={{ top: '5px' }} className="mdi mdi-magnify search-widget-icon"></span>
                                      <span
                                        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                        id="search-close-options"
                                      ></span>
                                    </div>
                                  </div>
                                  <Button
                                    variant="primary"
                                    className="sub-update-btn"
                                    onClick={openDocForm}
                                  >
                                    <i className="ri-add-fill"></i> Add
                                  </Button>
                                </div>
                              </Col>
                              <Col lg="12" className="mt-3">
                                <SimpleBar
                                  style={{ maxHeight: "589px" }}
                                >
                                  <SubcontractorComplianceTable data={subComplianceMasterDetail} handleArchiveCompliance={handleArchiveCompliance} handleSort={handleSort} archiveMaster={archiveSubcontractorComplianceMaster} editMaster={editSubcontractorComplianceMaster} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} />
                                </SimpleBar>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                    </Tab.Content>
                  }
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
      <Modal size="xl" id="myModal" show={modal_standard} onHide={() => { tog_standard(); }} >
        <Modal.Header className="modal-title fs-xl" id="myModalLabel">
          {isEditing ? "Edit" : "Add"} Subcontractor Compliance
        </Modal.Header>
        <Form onSubmit={Complianceformik.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col lg="12">
                <Row className="add-doc">
                  <Col lg="3">
                    <Form.Group className="mb-3 form-icon">
                      <Form.Label>Compliance Type</Form.Label>
                      <Form.Select
                        className="form-select-lg form-control-icon"
                        id="floatingSelect"
                        name="compliance_name"
                        onChange={handleDocumentTypeChange}
                        value={documentType}
                      >
                        <option value="Workers Comp">Workers Comp</option>
                        <option value="Public Liability">Public Liability</option>
                        <option value="Master License">Master License</option>
                        <option value="Subcontractor Agreement">Subcontractor Agreement</option>
                        <option value="Renewal">Renewal</option>
                        <option value="Documents">Documents</option>
                      </Form.Select>
                      <i style={{ fontSize: '16px' }} className="ri-article-line mt-4"></i>
                    </Form.Group>
                  </Col>
                  {(documentType === 'Workers Comp' || documentType === 'Public Liability' || documentType === 'Master License' || documentType === 'Renewal') && (
                    <Col lg="3">
                      <Form.Group className="mb-3">
                        <Form.Label>Document Number</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="number"
                            name="document_number"
                            className="form-control-lg form-control-icon"
                            placeholder="Enter Document Number"
                            onChange={Complianceformik.handleChange}
                            value={Complianceformik.values.document_number}
                          />
                          <i style={{ fontSize: '16px' }} className="ri-hashtag"></i>
                        </div>
                        {Complianceformik.errors.document_number ? <div>{Complianceformik.errors.document_number}</div> : null}
                      </Form.Group>
                    </Col>
                  )}
                  {documentType === 'Documents' && (
                    <Col lg="3">
                      <Form.Group className="mb-3">
                        <Form.Label>Document Name</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="text"
                            name="documentName"
                            className="form-control-lg form-control-icon"
                            placeholder="Enter Document Name"
                            onChange={handleDocumentNameChange}
                            value={documentName}
                          />
                          <i style={{ fontSize: '16px' }} className="ri-file-list-line"></i>
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  {(documentType === 'Workers Comp' || documentType === 'Public Liability' || documentType === 'Master License' || documentType === 'Subcontractor Agreement' || documentType === 'Renewal' || documentType === 'Documents') && (
                    <Col lg="3" className="upload-section-col">
                      <Form.Group>
                        <Form.Label className="doc-label">File Upload</Form.Label>
                        <div className="uploadsection">
                          {showUploadButton ? (
                            <div className="d-flex align-item-center">
                              <Form.Label htmlFor="file" className="uploadlabel">
                                <Form.Control
                                  type="file"
                                  className="form-control-lg"
                                  style={{ display: 'none' }}
                                  id="file"
                                  name="compliance_path"
                                  onChange={handleFileChange}
                                  isInvalid={!!(Complianceformik.touched.compliance_path && Complianceformik.errors.compliance_path)}
                                />
                                <i className="bx bx-plus"></i> Choose File
                              </Form.Label>
                            </div>
                          ) : (
                            <Col lg="12" className="closeuser py-0">
                              <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="file-name">{file ? file.name : Complianceformik.values.compliance_path}</p>
                              <Button onClick={handleRemoveFile} color="red">
                                X
                              </Button>
                            </Col>
                          )}
                        </div>
                        {Complianceformik.touched.compliance_path && Complianceformik.errors.compliance_path ? (
                          <div className="invalid-feedback d-block">{Complianceformik.errors.compliance_path}</div>
                        ) : null}
                        {(Complianceformik.values.compliance_path || file) &&
                          <div className="closeuser d-flex gap-2 align-items-center justify-content-center">
                            <i
                              onClick={() => {
                                if (file) {
                                  handleFilePreview(file);
                                } else if (Complianceformik.values.compliance_path) {
                                  handleOpenDocument(Complianceformik.values.compliance_path);
                                }
                              }}
                              className="ri-eye-line link-info"
                              style={{ fontSize: '18px', cursor: 'pointer' }}
                            ></i>
                            {/* <p className="mb-0">{file?.name ? file?.name : Complianceformik.values.document_path}</p> */}
                          </div>
                        }
                      </Form.Group>
                    </Col>
                  )}
                  {documentType === 'Master License' && (
                    <Col lg="3">
                      <Form.Group className="mb-3 form-icon">
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          className="form-select-lg form-control-icon"
                          id="state"
                          name="state_id"
                          onChange={Complianceformik.handleChange}
                          value={Complianceformik.values.state_id}
                        >
                          <option value="">Select State</option>
                          {StateMasterDetail.map((stateDetail) => (
                            <option key={stateDetail.id} value={stateDetail.id.toString()}>
                              {stateDetail.state_name}
                            </option>
                          ))}
                        </Form.Select>
                        <i style={{ fontSize: '16px' }} className="ri-user-2-line mt-4"></i>
                        {Complianceformik.errors.state_id ? <div>{Complianceformik.errors.state_id}</div> : null}
                      </Form.Group>
                    </Col>
                  )}
                  {(documentType === 'Workers Comp' || documentType === 'Public Liability' || documentType === 'Master License' || documentType === 'Renewal') && (
                    <Col lg="3">
                      <div className="mb-3 d-flex flex-column form-icon">
                        <Form.Label htmlFor="JoiningdatInput">Expiry Date</Form.Label>
                        <Flatpickr
                          className="form-control-lg form-control-icon"
                          options={{
                            dateFormat: "d M, Y",
                            minDate: "today"
                          }}
                          placeholder="Select date"
                          style={{ border: '1px solid #dde1ef' }}
                          name="date"
                          value={Complianceformik.values.date}
                          onChange={(dates: any[]) => {
                            const formattedDate = format(dates[0], 'dd-MM-yyyy');
                            Complianceformik.setFieldValue("date", formattedDate);
                          }}
                        />
                        <i style={{ fontSize: '16px' }} className="ri-calendar-event-line mt-4"></i>
                        {Complianceformik.errors.date ? <div>{Complianceformik.errors.date}</div> : null}
                      </div>
                    </Col>
                  )}
                  {(documentType === 'Workers Comp' || documentType === 'Public Liability' || documentType === 'Master License' || documentType === 'Renewal') && (
                    <Col lg="3">
                      <Form.Group className="mb-3">
                        <Form.Label>Reminder</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="number"
                            name="reminder_days"
                            className="form-control-lg form-control-icon"
                            placeholder="No. of Days"
                            onChange={Complianceformik.handleChange}
                            value={Complianceformik.values.reminder_days}
                          />
                          <i style={{ fontSize: '16px' }} className="ri-calendar-line"></i>
                          {Complianceformik.errors.reminder_days ? <div>{Complianceformik.errors.reminder_days}</div> : null}
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  {documentType === 'Renewal' && (
                    <Col lg="3">
                      <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="text"
                            name="description"
                            className="form-control-lg form-control-icon"
                            placeholder="Enter Description"
                            onChange={Complianceformik.handleChange}
                            value={Complianceformik.values.description}
                          />
                          <i style={{ fontSize: '16px' }} className="ri-font-color"></i>
                          {Complianceformik.errors.description ? <div>{Complianceformik.errors.description}</div> : null}
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  {documentType === 'Master License' && (
                    <Col lg="3" className="d-flex align-items-center">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Critical
                        </Form.Label>
                        <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                          <Form.Check
                            type="checkbox"
                            role="switch"
                            name="is_critical"
                            id="CriticalCheck"
                            onChange={handleCheckboxChange}
                            checked={Complianceformik.values.is_critical === 1}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button variant="light" onClick={() => tog_standard()}>Close</Button>
            {Complianceformik.isSubmitting ? (
              <Button
                color="primary"
                className="sub-update-btn"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button
                color="primary"
                type="submit"
              >
                {isEditing ? "Update" : "Add"}
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >

        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to delete this document.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
              {archiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveSubcontractorComplianceMaster(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditSubcontractor;
