import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { getByStaffDetail, getStaffDocumentDetail } from 'helpers/saibackend_helper';
import { Offcanvas, Button, Row, Col, Card, Table } from "react-bootstrap";
import profile from 'assets/images/auth/profile.png'
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

interface payrollStaff {
    value: string
}

type setPayrollStaff = (value: payrollStaff) => void;

const PendingTimesheetTable = ({ data, currentPage, totalPages, onPageChange, handleSortChange, fetchPendingSingleTimesheetDetails, setPayrollStaff }:
    {
        data: any,
        currentPage: number;
        totalPages: number;
        onPageChange: (page: number) => void;
        handleSortChange: (column: string, direction: string) => void;
        fetchPendingSingleTimesheetDetails: (id: number) => Promise<void>;
        setPayrollStaff: setPayrollStaff
    }) => {

    const columns = useMemo(
        () => [
            {
                header: "Staff",
                disableFilters: true,
                accessorKey: "staff",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "From Date",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "date",
            },
            {
                header: "Pay Schedule",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "pay_schedule",
            },
            {
                header: "Total Pending Timesheet",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "total_pending_timesheet",
            },
            {
                header: "Action",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <i
                                    onClick={() => {
                                        fetchPendingSingleTimesheetDetails(cellProps.row.original.staff_id);
                                        setPayrollStaff({
                                            value : cellProps.row.original.staff
                                        })
                                    }}
                                    className="ri-fullscreen-line link-info p-0"
                                    style={{ fontSize: "18px", cursor: "pointer" }}
                                ></i>

                            </div>
                        </div >
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {data.length > 0 ?
                <TableContainer
                    isPagination={true}
                    columns={(columns || [])}
                    data={data}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
                :
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Pending Timesheet Available</h5>

                    </div>
                </div>
            }
        </React.Fragment >
    );
}

export default PendingTimesheetTable;