import React from 'react';
import DataTable from 'react-data-table-component';

interface InvoiceMasterData {
    id: number;
    company_name: string;
    bank_name: string;
    account_holder_name: number,
    bsb_no: number,
}

type SortOrder = 'asc' | 'desc';

interface ComplianceTableProps {
    data: InvoiceMasterData[];
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
}

const InvoiceTemplateTable: React.FC<ComplianceTableProps> = ({ data, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows,handleArchiveCompliance,handleSort }) => {

    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Invoice Template</span>,
            selector: (row: any) => row.name,
            sortField: 'name',
            sortable: true,
            left: true,
            cell: (row: any) => <div style={{ textAlign: 'left' }}>{row.template_name}</div>
        },
        {
            name: <span className='font-weight-bold fs-sm text-right'>Action</span>,
            // sortable: true,
            right: true,
            cell: (row: any) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                        <i onClick={() => editMaster(row.id)} className="ri-edit-2-line link-success"  style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                        <i onClick={() => handleArchiveCompliance(row.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line link-danger"></i>
                    </div>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default InvoiceTemplateTable;
