import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { createSelector } from 'reselect';
import { bottom } from '@popperjs/core';

interface ScheduleChartProps {
    dataColors: string;
    totalFinishedShift: number;
    totalPublishedShift: number;
    totalUnpublishedShift: number;
    totalUnassignedShift: number;
}

const ScheduleChart: React.FC<ScheduleChartProps> = ({
    dataColors,
    totalFinishedShift,
    totalPublishedShift,
    totalUnpublishedShift,
    totalUnassignedShift,
}) => {
    const selectLayoutThemeType = createSelector(
        (state: any) => state.Layout,
        (layoutThemeType) => layoutThemeType.layoutThemeType
    );

    const layoutThemeType = useSelector(selectLayoutThemeType);

    useEffect(() => {
        document.getElementsByClassName('apex-charts');
    }, [layoutThemeType]);

    const totalShifts = totalFinishedShift + totalPublishedShift + totalUnpublishedShift + totalUnassignedShift;

    const finishedShiftPercentage = totalShifts ? (totalFinishedShift / totalShifts) * 100 : 0;
    const publishedShiftPercentage = totalShifts ? (totalPublishedShift / totalShifts) * 100 : 0;
    const unpublishedShiftPercentage = totalShifts ? (totalUnpublishedShift / totalShifts) * 100 : 0;
    const unassignedShiftPercentage = totalShifts ? (totalUnassignedShift / totalShifts) * 100 : 0;

    const customColors = ['#F0484F', '#95847A', '#F0B414', '#3981EC'];

    const series = [{
        data: [
            unassignedShiftPercentage,
            publishedShiftPercentage,
            unpublishedShiftPercentage,
            finishedShiftPercentage,
        ],
    }];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            height: 600, 
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '70%',
                distributed: false,
            },
            
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: number) {
                return `${val.toFixed(2)}%`;
            },
        },
        colors: customColors,
        grid: {
            show: false,
            borderColor: "#fff",
        },
        xaxis: {
            categories: [
                "Unassigned Shifts",
                "Published Shifts",
                "Unpublished Shifts",
                "Finished Shifts",
            ],
            labels: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
    };

    return (
        <React.Fragment>
            <Chart
                dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={247}
            />
        </React.Fragment>
    );
};

export default ScheduleChart;
