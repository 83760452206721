import React, { useState } from "react";
import { Container, Row, Col, Card, Offcanvas, Button } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import background from "assets/images/auth/background.png";
import profile from "assets/images/auth/userimg.png";
import logo1 from "assets/images/auth/logo1.png";
import logo2 from "assets/images/auth/logo2.png";
import logo3 from "assets/images/auth/logo3.png";
import logo4 from "assets/images/auth/logo4.png";
import FB from "assets/images/dashboard/facebook-icon.png";
import LI from "assets/images/dashboard/linkedin-icon.png";
import IG from "assets/images/dashboard/instagram-icon.png";
import Twitter from "assets/images/dashboard/twitter-icon.png";
import WorkingCalendar from "./WorkingCalendar";
import userpf from "assets/images/auth/userpf.png";

const CalendarPage = () => {
  document.title = "Dashboard | The Guard Duty";

  const [isRight, setIsRight] = useState(false);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Adams Mitchell" pageTitle="Staff" />
            <Row className="edit-staff">
              {/* <Col xxl={3}>
                <Card className="overflow-hidden">
                  <div>
                    <img
                      src={background}
                      alt=""
                      className="card-img-top profile-wid-img object-fit-cover"
                      style={{ height: "200px" }}
                    />
                    <div>
                      <input
                        id="profile-foreground-img-file-input"
                        type="file"
                        className="profile-foreground-img-file-input d-none"
                      />
                      <label
                        htmlFor="profile-foreground-img-file-input"
                        className="profile-photo-edit btn btn-light btn-sm position-absolute end-0 top-0 m-3"
                      >
                        <i className="ri-image-edit-line align-bottom me-1"></i>{" "}
                        Edit Cover Images
                      </label>
                    </div>
                  </div>
                  <Card.Body className="pt-0 mt-n5">
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto">
                        <img
                          src={profile}
                          alt=""
                          className="avatar-lg rounded-circle object-fit-cover border-0 img-thumbnail user-profile-image"
                        />
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit position-absolute end-0 bottom-0">
                          <input
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input d-none"
                          />
                          <label
                            htmlFor="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <span className="avatar-title rounded-circle bg-light text-body">
                              <i className="bi bi-camera"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="mt-3">
                        <h5 className="user-name">Adams Mitchell</h5>
                        <p className="text-muted user-role">Web Developer</p>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body className="border-top">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="card-title mb-0">
                          Complete Your Profile
                        </h5>
                      </div>
                    </div>
                    <h5 className="profile-status">Profile Status </h5>
                    <div className="progress animated-progress custom-progress progress-label">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${70}%` }}
                        aria-valuenow={30}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div className="label">70%</div>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body className="border-top">
                    <div className="mb-4">
                      <p className='detail-people'><i className='ri-phone-line icon-profile'></i> +61 12345 67890</p>
                      <p className='detail-people'><i className='ri-mail-line icon-profile'></i> adams.mitchell@gmail.com</p>
                      <p className='detail-people'><i className='ri-map-pin-line icon-profile'></i> 19 Fiona Avenue, Brisbane, 2076, Australia</p>
                      <p className='detail-people'><i className='ri-cake-line icon-profile'></i> 12-12-2000</p>
                      <p className='detail-people'><i className='mdi mdi-gender-transgender icon-profile'></i> Male</p>
                      <p className='detail-people'><i className='ri-calendar-event-line icon-profile'></i> 12-03-2023</p>
                    </div>
                    <div className="calc-social-div">
                      <img alt="LI-logo" src={LI} className="m-2" />
                      <img alt="Twitter-logo" src={Twitter} className="m-2" />
                      <img alt="FB-logo" src={FB} className="m-2" />
                      <img alt="IG-logo" src={IG} className="m-2" />
                    </div>
                  </Card.Body>
                  <Card.Footer className="border-top-0">
                    <Row>
                      <Col lg={12} className="archive-employee-btn">
                        <h5>
                          <span onClick={toggleRightCanvas}>Archive</span> This
                          Staff
                        </h5>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col> */}
              <Col xl={12} className="user-section">
                <WorkingCalendar />
              </Col>
            </Row>
          </Container>
        </div>
        <Offcanvas
          className="archive-canvas"
          show={isRight}
          onHide={toggleRightCanvas}
          placement="end"
        >
          <Offcanvas.Header className="border-bottom" closeButton>
            <Offcanvas.Title id="offcanvasRightLabel">
              Archive Staff
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0 overflow-hidden">
            <Row>
              <Col lg="12">
                <div className="d-flex align-items-center user-sec">
                  <div className="chat-user-img flex-shrink-0 me-2">
                    <div className="chat-user-img flex-shrink-0">
                      <div>
                        <img
                          src={userpf}
                          className="rounded-circle img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="user-name text-truncate mb-0">Adams Smith</p>
                    <div className="text-muted user-role text-truncate">
                      Doctor
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="12">
                <div className="archive-info-note">
                  <i className="ri-information-line"></i>
                  <p>
                    Archiving will revoke any access granted to this person. Are
                    you sure you want to archive this Person?
                  </p>
                </div>
              </Col>
            </Row>
          </Offcanvas.Body>
          <Row>
            <Col lg="12">
              <div className="offcanvas-footer border-top-0 p-4 text-center">
                <Row className="approve-reject-btn">
                  <Col lg={12} className="Apply-section px-0">
                    <Button color="primary" className="next-btn">
                      Archive Staff
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Offcanvas>
      </React.Fragment>
    </>
  );
};

export default CalendarPage;
