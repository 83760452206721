import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';

const mapStyles = {
    width: '100%',
    borderRadius: '12px'
};

const LoadingContainer = () => <div>Loading...</div>;

const LocationMap = (props: any) => {
    const { onAddMarker, marker, setLatitude, setLongitude, setAddress, latitude, longitude } = props;

    const [selectedPlace, setSelectedPlace] = useState<any>(null);
    const [activeMarker, setActiveMarker] = useState<any>(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [mapCenter, setMapCenter] = useState<{ lat: number, lng: number }>({ lat: -34.9284989, lng:138.6007456 });

    useEffect(() => {
        if (latitude && longitude) {
            setMapCenter({ lat: parseFloat(latitude.toString()), lng: parseFloat(longitude.toString()) });
        }
    }, [latitude, longitude]);

    const onMarkerClick = (props: any, marker: any, e: any) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };

    const onMapClick = (mapProps: any, map: any, clickEvent: any) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        onAddMarker(lat, lng);
        setShowingInfoWindow(false);
        setActiveMarker(null);
    };

    const onMarkerDragEnd = (coord: any) => {
        const lat = coord.latLng.lat();
        const lng = coord.latLng.lng();
        onAddMarker(lat, lng);
        setLatitude(lat);
        setLongitude(lng);
        updateAddressFromCoordinates(lat, lng);
    };

    const updateAddressFromCoordinates = async (lat: number, lng: number) => {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat, lng };
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results && results[0]) {
                const result = results[0];
                setAddress(result.formatted_address);
            } else {
                console.error("Geocoding failed: " + status);
            }
        });
    };

    return (
        <div id="gmaps-markers" className="gmaps" style={{ position: 'relative',height:'350px' }}>
            <Map
                google={props.google}
                zoom={8}
                style={mapStyles}
                center={mapCenter}
                onClick={onMapClick}
            >
                {marker && (
                    <Marker
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={onMarkerClick}
                        draggable={true}
                        onDragend={(t: any, map: any, coord: any) => onMarkerDragEnd(coord)}
                    />
                )}
                {/* <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow}
                >
                    <div>
                        <h1>{selectedPlace && selectedPlace.name}</h1>
                    </div>
                </InfoWindow> */}
            </Map>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyA35lst1bnWNgD14Eyx3ZFqhXe4qU44xts",
    LoadingContainer: LoadingContainer,
})(LocationMap);
