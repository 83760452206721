// @ts-ignore
import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import Select from 'react-select';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Spinner,
  Dropdown,
  Image,
  Tab,
  Nav,
  Modal
} from "react-bootstrap";
import { GerIncidentTemplateDropdown, getByIncidentTemplateMasterDetail, getLocationDropdownIncidentReportDetail, getIncidentReportDetail, getClientDropdownIncidentReportDetail, archiveIncidentReportDetail, addIncidentReportDetail, updateIncidentReportDetail, getByIncidentReportDetail } from "helpers/saibackend_helper";
import BreadCrumb from "Common/BreadCrumb";
import IncidentTable from "./IncidentTable";
import NewSearchableAddress from "helpers/NewSearchableAddress";
import SignatureCanvas from 'react-signature-canvas';
import IncidentData from "./IncidentData";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Flatpickr from "react-flatpickr";

interface IncientTemplateMasterData {
  id: number;
  name: string;
  value: number
}
interface IncientTemplateFieldData {
  field_name: string;
  field_type: string;
  field_options: { options?: string[] } | null;
  field_order: number;
  is_required: number;
}

interface AddressData {
  address: string;
}

interface ClientData {
  id: number,
  name: string,
  no_of_locations: number,
  value: number
}

interface LocationData {
  id: number,
  name: string,
  value: number
}

interface IncidentReportData {
  id: number;
  date: string;
  client_id: number;
  location_id: number,
  submitted_by: number,
  updated_by: null,
  status: string,
  share_with_client: number,
  incident_type: string,
  location_name: null,
  submitted_by_name: string,
  updated_by_name: string,
  client_name: null
}

interface ReportData {
  [key: string]: string | number | File | undefined;
}

interface FormValues {
  date: string;
  client_id: string;
  location_id: string;
  incident_type_id: string;
  report_data: {
    [key: string]: string | number | null;
  };
}
const IncidentMasterfield: IncientTemplateFieldData[] = [
];

const IncidentMaster = () => {

  document.title = "Report List | The Guard Duty";

  const [leave, setleave] = useState(false);
  const toggleleaveCanvas = () => {
    setleave(!leave);
  };

  const [totalRows, setTotalRows] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);

  const [IncidentReportDetail, setIncidentreportDetail] = useState<IncidentReportData[]>([])

  const [payloadValue, setPayloadValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: ""
    },
    is_archive: 0
  })

  const handlePageChange = (page: number) => {
    setPayloadValue(prevPayload => ({
      ...prevPayload,
      page: page
    }));
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPayloadValue(prevPayload => ({
      ...prevPayload,
      page_size: String(newPerPage),
      page: page
    }));
  };

  const [loadingscreen, setLoadingscreen] = useState(true);

  const fetchIncidentReportDetails = async () => {
    try {
      const response = await getIncidentReportDetail(payloadValue.page, payloadValue.page_size, payloadValue.search_term, payloadValue.sort.sort_order, payloadValue.sort.sort_by, payloadValue.is_archive);
      setIncidentreportDetail(response.data.data_list);
      setTotalRows(response.data.total_records);
      setLoadingscreen(false)
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchIncidentReportDetails();
  }, [payloadValue]);

  const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
    const columnSelector = typeof column === 'function' ? column.name : column;

    setPayloadValue(prevState => ({
      ...prevState,
      sort: {
        sort_by: columnSelector,
        sort_order: sortDirection.toUpperCase(),
      }
    }));
  };


  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setPayloadValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const [IncidentMasterDetail, setIncidentMasterDetail] = useState<IncientTemplateMasterData[]>([])
  const [IncidentMasterfield, setIncidentMasterfield] = useState<IncientTemplateFieldData[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchIncidentDetails = async () => {
    setLoading(true);
    try {
      const searchTerm = "";
      const response = await GerIncidentTemplateDropdown(searchTerm);
      setIncidentMasterDetail(response.data.data_list.map((temp: any) => ({
        value: temp.id,
        label: temp.name
      })));
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  };


  // Client Dropdown and Location Dropdown API Integration // 
  // =========================================================================================================== //

  const [ClientDropdown, setClientDropdown] = useState<ClientData[]>([])
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);

  const [defaultClientDropdownValue, setDefaultClientDropdownValue] = useState({
    search_term: "",
    is_archive: 0
  })


  const fetchClientDropdownListDetails = async () => {
    try {
      const searchTerm = "";
      const is_archive = 0
      const response = await getClientDropdownIncidentReportDetail(searchTerm, is_archive);
      setClientDropdown(response.data.data_list.map((client: any) => ({
        value: client.id,
        label: client.name
      })));
    } catch (error) {
    }
  };

  const handleClientChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      setSelectedClientId(selectedOption.value);
      setDefaultLocationDropdownValue(prevState => ({
        ...prevState,
        client_id: selectedOption.value,
      }));
      formik.setFieldValue('client_id', selectedOption.value)
    }
  };

  const [defaultLocationDropdownValue, setDefaultLocationDropdownValue] = useState({
    client_id: 0,
    is_archive: 0
  })

  const [LocationDropdown, setLocationDropdown] = useState<LocationData[]>([])

  const fetchLocationDropdownListDetails = async () => {
    try {
      const response = await getLocationDropdownIncidentReportDetail(defaultLocationDropdownValue.client_id, defaultLocationDropdownValue.is_archive);
      setLocationDropdown(response.data.data_list.map((location: any) => ({
        value: location.id,
        label: location.name
      })));
    } catch (error) {
    }
  };

  useEffect(() => {
    if (selectedClientId) {
      fetchLocationDropdownListDetails();
    }
  }, [defaultLocationDropdownValue, selectedClientId]);

  const handleLocationChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      formik.setFieldValue('location_id', selectedOption.value);
    }
  };

  useEffect(() => {
    if (leave) {
      fetchIncidentDetails();
      fetchClientDropdownListDetails();
      fetchLocationDropdownListDetails()
    }
  }, [leave]);
  // =========================================================================================================== //

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


  const handleArchiveReport = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };


  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const [archiveLoader, setArchiveLoader] = useState(false)

  const archiveMaster = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await archiveIncidentReportDetail(id);
      fetchIncidentReportDetails();
      toast.success(response.message);
      setmodal_center(false)
      setArchiveLoader(false)
    } catch (error) {
      toast.error("Error Archive Please try again later.");
    }
  };

  const editMaster = async (id: number) => {
    setLoading(true);
    try {
      const response = await getByIncidentTemplateMasterDetail(id);
      const data = response.data;
      setIncidentMasterfield(data.fields);

    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      editMaster(selectedOption.value);
      formik.setFieldValue('incident_type_id', selectedOption.value);
      formik.setFieldValue('report_data', {})
      setIsEditing(false);
      setEditId(null);
      setFileInputs({})
      setSignatureFile(null)
      setSignatureFileURL(null)
      setAddressInputs({})
      setShowUploadButtons({})
    }
  };

  //   const handleEmployeeChange = (selectedOption: { value: number; label: string } | null) => {
  //     if (selectedOption) {
  //         getLeave(selectedOption.value);
  //         formik.setFieldValue('staff_id', selectedOption.value);
  //     }
  // };

  const [fileInputs, setFileInputs] = useState<{ [key: string]: File | null }>({});
  const [showUploadButtons, setShowUploadButtons] = useState<{ [key: string]: boolean }>({});
  const signatureRef = useRef<SignatureCanvas>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFileInputs((prevState) => ({ ...prevState, [fieldName]: selectedFile }));
      setShowUploadButtons((prevState) => ({ ...prevState, [fieldName]: false }));
      // formik.setFieldValue(`report_data.${fieldName}`, selectedFile);
    }
  };

  const handleRemoveFile = (fieldName: string) => {
    setFileInputs((prevState) => ({ ...prevState, [fieldName]: null }));
    setShowUploadButtons((prevState) => ({ ...prevState, [fieldName]: true }));
  };

  const [addressInputs, setAddressInputs] = useState<{ [key: string]: string }>({});
  const handleAddressChange = (fieldName: string, data: AddressData | boolean) => {
    if (typeof data === 'boolean') return;

    const newAddress = data.address || '';
    setAddressInputs(prevState => ({
      ...prevState,
      [fieldName]: newAddress,
    }));

    formik.setFieldValue(`report_data.${fieldName}`, newAddress);
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      date: '',
      client_id: '',
      location_id: '',
      incident_type_id: '',
      report_data: {},
    },
    validationSchema: Yup.object({
      incident_type_id: Yup.number().positive('Incient Type is Required').required('Incient Type is Required'),
      date: Yup.string().required("Date is required"),
      client_id: Yup.number().positive('Client is Required').required('Client is Required'),
      location_id: Yup.number().positive('Location is Required').required('Location is Required'),
    }),
    onSubmit: async (values) => {
      try {
        const reportData: { [key: string]: string | number | null } = {};

        for (const field of IncidentMasterfield) {
          if (field.field_type === 'file' && fileInputs[field.field_name]) {
            const base64File = await convertFileToBase64(fileInputs[field.field_name] as File);
            reportData[field.field_name] = base64File;
          } else if (field.field_type === 'location') {
            reportData[field.field_name] = addressInputs[field.field_name] || formik.values.report_data[field.field_name] || "";
          } else if (field.field_type === 'signature') {
            reportData[field.field_name] = signatureRef.current?.isEmpty() ? '' : (signatureRef.current?.toDataURL() || '');
          } else {
            reportData[field.field_name] = values.report_data[field.field_name] || '';
          }
        }

        const formattedDate = format(new Date(values.date), 'yyyy-MM-dd');
        const payload = {
          ...values,
          date: formattedDate,
          report_data: JSON.stringify(reportData),
        };
        if (isEditing && editId !== null) {
          const response = await updateIncidentReportDetail(editId, values.date, values.client_id, values.location_id, values.incident_type_id, payload.report_data);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          const response = await addIncidentReportDetail(payload);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        toggleleaveCanvas();
        formik.resetForm();
        setIncidentMasterfield([])
        setAddressInputs({})
        setShowUploadButtons({})
        fetchIncidentReportDetails();
      } catch (error) {
        toast.error('Failed to add Incident Report');
      }
    },
  });


  function base64ToFileConvertDoc(base64String: string, fileName: string): File {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }


  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const [signatureFileURL, setSignatureFileURL] = useState<string | null>(null);

  const editIncidentReportMaster = async (id: number) => {
    try {
      const response = await getByIncidentReportDetail(id);
      const data = response.data;
      const template = await getByIncidentTemplateMasterDetail(data.incident_type_id);
      const templatedata = template.data;
      setIncidentMasterfield(templatedata.fields);
      setDefaultLocationDropdownValue(prevState => ({
        ...prevState,
        client_id: data.client_id,
      }));

      const fileInputsData = typeof data.report_data === 'string' ? JSON.parse(data.report_data) : data.report_data || {};
      const updatedFileInputs: { [key: string]: File | null } = {};
      const updatedShowUploadButtons: { [key: string]: boolean } = {};

      for (const key in fileInputsData) {
        if (fileInputsData[key] && typeof fileInputsData[key] === 'string' && fileInputsData[key].startsWith('data:')) {
          updatedFileInputs[key] = base64ToFileConvertDoc(fileInputsData[key], key);
          updatedShowUploadButtons[key] = false;
        }
      }

      let signatureFile: File | null = null;
      if (data.report_data.Signature && typeof data.report_data.Signature === 'string' && data.report_data.Signature.startsWith('data:')) {
        signatureFile = base64ToFileConvertDoc(data.report_data.Signature, 'signature.png');
      }
      if (signatureFile) {
        setSignatureFile(signatureFile);
        const url = URL.createObjectURL(signatureFile);
        setSignatureFileURL(url);
      }

      setFileInputs(updatedFileInputs);
      setShowUploadButtons(updatedShowUploadButtons);
      formik.setValues({
        date: data.date,
        client_id: data.client_id,
        location_id: data.location_id,
        incident_type_id: data.incident_type_id,
        report_data: fileInputsData
      });
      setIsEditing(true);
      setEditId(id);
      toggleleaveCanvas();
    } catch (error) {
      toast.error('Error fetching details. Please try again later.');
    }
  }

  const handleFilePreview = (file: File | null) => {

    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } else {
      return;
    }
  };

  const handleSignaturePreview = (file: File | null) => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      if (signatureRef.current) {
        signatureRef.current.fromDataURL(fileURL);
      }
    } else {
      return
        ;
    }
  };

  const handleClearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setSignatureFileURL(null);
      setSignatureFile(null)
    }
  };

  useEffect(() => {
    if (signatureFileURL && signatureRef.current) {
      signatureRef.current.fromDataURL(signatureFileURL);
    }
  }, [signatureFileURL]);

  useEffect(() => {
    handleSignaturePreview(signatureFile)
  }, [editIncidentReportMaster])

  const openAddForm = () => {
    setIsEditing(false);
    setEditId(null);
    setFileInputs({})
    setSignatureFile(null)
    setSignatureFileURL(null)
    formik.resetForm()
    setleave(true);
    setIncidentMasterfield([])
    setAddressInputs({})
    setShowUploadButtons({})
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content organization-setting-page">
          <Container fluid>
            {
              loadingscreen ?
                <Row className="placeholder-glow">
                  <Col lg={12} className="mt-4">
                    <div className="placeholder bg-light table-placeholder col-12"></div>
                  </Col>
                </Row>
                :
                <Card className="organization-setting-card">
                  <Card.Body className="organization-setting-cardbody">
                    <Row>
                      <Col
                        lg={12}
                        className="role-list-page"
                        style={{ height: "100%" }}
                      >
                        <Row>
                          <Col
                            lg="12"
                            className="d-flex align-items-center justify-content-between role-navbar"
                          >
                            <h4 className="card-title mb-2">Report List</h4>
                            <div className="d-flex">
                              <div className="app-search d-none d-md-inline-flex">
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    id="search-options"
                                    value={inputValue}
                                    onChange={handleSearchChange}
                                  />
                                  <span className="mdi mdi-magnify search-widget-icon"></span>
                                  <span
                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                    id="search-close-options"
                                  ></span>
                                </div>
                              </div>
                              <Button
                                variant="primary"
                                className="add-company"
                                onClick={openAddForm}
                              >
                                <i className=" ri-add-fill"></i> Add
                              </Button>
                            </div>
                          </Col>
                          <Col lg="12">
                            <IncidentTable data={IncidentReportDetail} handleArchiveReport={handleArchiveReport} handleSort={handleSort} archiveMaster={archiveMaster} editIncidentReportMaster={editIncidentReportMaster} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
            }
          </Container>
        </div>
      </React.Fragment>
      <Offcanvas className="add-role-canvas" show={leave} onHide={toggleleaveCanvas} placement="bottom" style={{ minHeight: "800px" }}>
        <Offcanvas.Header className="add-header border-bottom" closeButton>
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">{isEditing ? "Edit" : "Add"} Incident Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3 form-icon">
                  <div className={`react-select-container ${formik.touched.incident_type_id && formik.errors.incident_type_id ? 'is-invalid' : ''}`}>
                    <Form.Label className="text-muted">Select Template</Form.Label>
                    <Select
                      inputId="incident_type_id"
                      name="incident_type_id"
                      options={IncidentMasterDetail}
                      value={IncidentMasterDetail.find(option => option.value === Number(formik.values.incident_type_id))}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      classNamePrefix="react-select"
                      className="react-select"
                    />
                    <i style={{ fontSize: "16px" }} className="ri-file-line mt-4"></i>
                    {formik.touched.incident_type_id && formik.errors.incident_type_id ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.incident_type_id}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="3">
                <div className="mb-3 d-flex flex-column form-icon">
                  <Form.Label htmlFor="implement Start Date">Report Date</Form.Label>
                  <Flatpickr
                    className={`form-control form-control-lg form-control-icon ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                    name="date"
                    value={formik.values.date}
                    onChange={(date: any[]) => formik.setFieldValue('date', date[0])}
                    placeholder="Select date"
                    required
                  />
                  <i style={{ fontSize: "16px" }} className="ri-calendar-event-line mt-4"></i>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.date}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3 form-icon">
                  <Form.Label className="text-muted">Client Name</Form.Label>
                  <div className={`react-select-container ${formik.touched.client_id && formik.errors.client_id ? 'is-invalid' : ''}`}>
                    <Select
                      inputId="client_id"
                      name="client_id"
                      options={ClientDropdown}
                      value={ClientDropdown.find(option => option.value === Number(formik.values.client_id))}
                      onChange={handleClientChange}
                      onBlur={formik.handleBlur}
                      classNamePrefix="react-select"
                      className="react-select"
                    />
                    <i style={{ fontSize: "16px" }} className=" ri-user-2-line mt-4"></i>
                    {formik.touched.client_id && formik.errors.client_id ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.client_id}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3 form-icon">
                  <Form.Label className="text-muted">Location Name</Form.Label>
                  <div className={`react-select-container ${formik.touched.location_id && formik.errors.location_id ? 'is-invalid' : ''}`}>
                    <Select
                      inputId="location_id"
                      name="location_id"
                      options={LocationDropdown}
                      value={LocationDropdown.find(option => option.value === Number(formik.values.location_id))}
                      onChange={handleLocationChange}
                      onBlur={formik.handleBlur}
                      classNamePrefix="react-select"
                      className="react-select"
                    />
                    <i style={{ fontSize: "16px" }} className=" ri-map-pin-line mt-4"></i>
                    {formik.touched.location_id && formik.errors.location_id ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.location_id}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>

            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <Spinner animation="border" />
              </div>
            ) : (
              IncidentMasterfield.length > 0 &&
              <Row className="mt-4">
                <Card className="alert alert-light alert-border alert-dismissible fade show">
                  <Card.Body className="p-0">
                    <Row>
                      {IncidentMasterfield.map((field) => (
                        <Col key={field.field_order} lg={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{field.field_name}</Form.Label>
                            {(() => {
                              switch (field.field_type) {
                                case 'radio':
                                  return (
                                    <div className="d-flex gap-3">
                                      {field.field_options && field.field_options.options ? (
                                        field.field_options.options.map((option: string, index: number) => (
                                          <div key={index} className="form-check mb-2">
                                            <Form.Check
                                              type="radio"
                                              name={`report_data.${field.field_name}`}
                                              id={`${field.field_name}-${index}`}
                                              value={option}
                                              onChange={formik.handleChange}
                                              checked={formik.values.report_data[field.field_name] === option}
                                            />
                                            <Form.Label
                                              className="update-pay-rate-title"
                                              htmlFor={`${field.field_name}-${index}`}
                                            >
                                              {option}
                                            </Form.Label>
                                          </div>
                                        ))
                                      ) : (
                                        <p>No options available</p>
                                      )}
                                    </div>
                                  );
                                case 'textarea':
                                  return (
                                    <Form.Control
                                      as="textarea"
                                      name={`report_data.${field.field_name}`}
                                      className="form-control-lg form-control-icon name-input"
                                      placeholder={`Enter ${field.field_name}`}
                                      onChange={formik.handleChange}
                                      value={formik.values.report_data[field.field_name] || ''}
                                    />
                                  );
                                case 'signature':
                                  return (
                                    <>
                                      <div className="signature-pad" style={{ border: '1px solid #ddd', borderRadius: '0.5rem' }}>
                                        <SignatureCanvas
                                          ref={signatureRef}
                                          penColor="red"
                                          canvasProps={{ width: 369, height: 150, className: 'sigCanvas' }}
                                        />
                                      </div>
                                      <div className="closeuser d-flex gap-2 align-items-center justify-content-center">
                                        {/* {signatureFile &&
                                          <i onClick={() => handleSignaturePreview(signatureFile)} className=" ri-eye-line link-info" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                        } */}
                                        <i onClick={handleClearSignature} className=" ri-delete-bin-line link-danger" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                      </div>
                                    </>
                                  );
                                case 'file':
                                  return (
                                    <>
                                      <div className="uploadsection">
                                        {showUploadButtons[field.field_name] !== false ? (
                                          <div className="d-flex align-items-center">
                                            <Form.Label htmlFor={`document_path_${field.field_name}`} className="uploadlabel">
                                              <Form.Control
                                                type="file"
                                                name="document_path"
                                                className="form-control-lg form-control-icon name-input"
                                                style={{ display: "none" }}
                                                id={`document_path_${field.field_name}`}
                                                onChange={(e) => handleFileChange(e as ChangeEvent<HTMLInputElement>, field.field_name)}
                                              />
                                              <i className="bx bx-plus"></i> Choose File
                                            </Form.Label>
                                          </div>
                                        ) : (
                                          <Col lg="12" className="closeuser py-0">
                                            <p>{fileInputs[field.field_name]?.name}</p>
                                            <Button onClick={() => handleRemoveFile(field.field_name)} variant="danger">X</Button>
                                          </Col>
                                        )}
                                      </div>
                                      {fileInputs[field.field_name] &&
                                        <div className="closeuser d-flex gap-2 align-items-center justify-content-center">
                                          <i onClick={() => handleFilePreview(fileInputs[field.field_name])} className=" ri-eye-line link-info" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                          <p className="mb-0">File Selected: {fileInputs[field.field_name]?.name}</p>
                                        </div>
                                      }
                                    </>
                                  );
                                case 'location':
                                  return (
                                    <NewSearchableAddress
                                      label={field.field_name}
                                      name={`location-name-${field.field_name}`}
                                      placeholder={`Enter ${field.field_name}`}
                                      value={String(formik.values.report_data[field.field_name]) === "undefined" ? "" : String(formik.values.report_data[field.field_name])}
                                      onChange={(data: boolean | AddressData) => handleAddressChange(field.field_name, data)}
                                      invalid={false}
                                      defaultValue={{
                                        latitude: '',
                                        longitude: '',
                                        address: addressInputs[field.field_name] || '',
                                      }}
                                    />
                                  );
                                default:
                                  return (
                                    <div className="position-relative form-icon">
                                      <Form.Control
                                        type={field.field_type}
                                        name={`report_data.${field.field_name}`}
                                        className="form-control-lg form-control-icon name-input"
                                        placeholder={`Enter ${field.field_name}`}
                                        onChange={formik.handleChange}
                                        value={formik.values.report_data[field.field_name] || ''}
                                      />
                                      <i style={{ fontSize: "16px" }} className="ri-file-list-line"></i>
                                    </div>
                                  );
                              }
                            })()}
                          </Form.Group>
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            )}
            <Row>
              <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                  <Row>
                    <Col lg={12} className="off-bottom-staff justify-content-end">
                      <div className="off-confirm">
                        <Button
                          onClick={toggleleaveCanvas}
                          color="primary"
                          className="clear-btn"
                        >
                          Cancel
                        </Button>
                        {formik.isSubmitting ? (
                          <Button
                            color="primary"
                            className="next-btn"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </Button>
                        ) : (
                          <Button color="primary" className="next-btn" type="submit">
                            {isEditing ? "Update" : "Add"}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >

        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to delete this Report.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
              {archiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveMaster(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IncidentMaster;

