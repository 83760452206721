import React, { ChangeEvent, useState } from "react";
import {
  Card,
  Col,
  Container,
  Modal,
  Row,
  Form,
  Offcanvas,
  Button,
} from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import Flatpickr from "react-flatpickr";

// import UpcomingEvents from './UpcomingEvents';
import calenderdata from "./data/calenderdata"; // Sample events JSON

const Calendar = () => {
  document.title = "Calendar | SAI";

  const [eventData, setEventData] = useState<any>({});

  const [addEventModal, setAddEventModal] = useState<boolean>(false);
  const addEventToggle = () => setAddEventModal(!addEventModal);

  const [personalmodal, setpersonalmodal] = useState<boolean>(false);
  const personaltoggle = () => setpersonalmodal(!personalmodal);

  const [timeOffCanvas, setTimeOffCanvas] = useState<boolean>(false);
  const timeOffToggle = () => setTimeOffCanvas(!timeOffCanvas);

  const [unavailibilityCanvas, setUnavailibilityCanvas] =
    useState<boolean>(false);
  const unavailibilityToggle = () =>
    setUnavailibilityCanvas(!unavailibilityCanvas);

  const [allDayOptions, setAllDayOptions] = useState<boolean>(true);
  const [repeatOptions, setRepeatOptions] = useState<boolean>(false);
  const [endRepeatOptions, setEndRepeatOptions] = useState<boolean>(true);

  const handleDateSelect = (/* selectInfo */) => {
    setAddEventModal(true);
  };

  const handleEventClick = (clickInfo: any): void => {
    personaltoggle();
    setEventData(clickInfo.event);
    console.log("data", eventData);
  };

  const handleTypeSelect = (selectedType: string) => {
    if (selectedType === "1") {
      timeOffToggle();
      addEventToggle();
    } else if (selectedType === "2") {
      unavailibilityToggle();
      addEventToggle();
    }
  };
  const handleAllDayChange = (value: any) => {
    setAllDayOptions(value === 1);
  };

  const handleRepeatChange = (value: any) => {
    setRepeatOptions(value === 1);
  };

  const handleEndRepeatChange = (value: any) => {
    setEndRepeatOptions(value === 1);
  };


  const [file, setFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [selectedLeaveType, setSelectedLeaveType] = useState<string>('');

  const handleLeaveTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLeaveType(event.target.value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setShowUploadButton(false);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setShowUploadButton(true);
  };


  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid={true}>
          <div className="calendar-wrapper d-xl-flex gap-4">
            <div className="w-100">
              <Card className="card-h-100" style={{ borderRadius: "1rem" }}>
                <div id="calendar">
                  <FullCalendar
                    height="auto"
                    plugins={[
                      BootstrapTheme,
                      dayGridPlugin,
                      interactionPlugin,
                      listPlugin,
                    ]}
                    initialView="dayGridMonth"
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                    }}
                    events={calenderdata}
                    editable={true}
                    droppable={true}
                    selectable={true}
                    dayMaxEvents={true}
                    select={handleDateSelect}
                    eventClick={handleEventClick}
                    // dayCellContent={(arg) => handleDayContent(arg, fullcalendardata)}
                    customButtons={{
                      customButton: {
                        text: "+ Add",
                        click: addEventToggle,
                      },
                    }}
                    views={{
                      dayGridMonth: {
                        dayMaxEvents: 1, // adjust as needed
                      },
                    }}
                  />
                  <div className="calendar-footer">
                    <Button variant="primary" onClick={handleDateSelect} className="add-event"><i className="ri-add-line"></i> Add</Button>
                    <div className="legends">
                      <span className="p-2 rounded">
                        <i className="ri-checkbox-blank-circle-fill text-warning align-bottom me-1"></i>In-progress shifts
                      </span>
                      <span className="p-2 rounded">
                        <i className="ri-checkbox-blank-circle-fill text-success align-bottom me-1"></i>Complete Shifts
                      </span>
                      <span className="p-2 rounded">
                        <i style={{color:"#2272EA"}} className="ri-checkbox-blank-circle-fill align-bottom me-1"></i>Upcoming Shifts
                      </span>
                      <span className="p-2 rounded">
                        <i className="ri-checkbox-blank-circle-fill text-secondary align-bottom me-1"></i>Time off / Unavailability
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </div>
      <Modal
        show={addEventModal}
        onHide={() => {
          addEventToggle();
        }}
        centered
        size="lg"
        className="shiftmodal"
      >
        <Modal.Header className="personalheader">Add Event</Modal.Header>
        <div className="line"> </div>
        <Modal.Body className="personalbody" style={{ height: "200px" }}>
          <Form action="#">
            <Row>
              <Col lg="12">
                <Form.Group className="mb-3 form-icon">
                  <Form.Label className="text-muted">Select type</Form.Label>
                  <Form.Select
                    className="form-select-lg form-control-icon"
                    id="Break Time"
                    onChange={(e) => handleTypeSelect(e.target.value)}
                  >
                    <option defaultValue="">Select</option>
                    <option value="1">Add Leave</option>
                    <option value="2">Add Unavailability</option>
                  </Form.Select>
                  <i
                    style={{ fontSize: "16px", marginTop: "1.6rem" }}
                    className=" ri-calendar-line"
                  ></i>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Offcanvas
        className="time-off-canvas"
        show={timeOffCanvas}
        onHide={timeOffToggle}
        placement="bottom"
      >
        <Offcanvas.Header className="add-header border-bottom p-2" closeButton>
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            Apply Time off
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 mt-4" style={{ overflowX: "hidden" }}>
          <Row>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon name-input"
                    style={{ height: "45px" }}
                    placeholder="Select Start Date"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                  />
                  <i
                    style={{ fontSize: "16px" }}
                    className="ri-calendar-event-line"
                  ></i>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>Start Time</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon"
                    style={{ height: "45px" }}
                    placeholder="Select Start Time"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                  />
                  <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon name-input"
                    style={{ height: "45px" }}
                    placeholder="Select End Date"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                  />
                  <i
                    style={{ fontSize: "16px" }}
                    className="ri-calendar-event-line"
                  ></i>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>End Time</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon"
                    style={{ height: "45px" }}
                    placeholder="Select End Time"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                  />
                  <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3 form-icon">
                <Form.Label>Leave Type</Form.Label>
                <Form.Select
                  className="form-select-lg form-control-icon"
                  id="floatingSelect"
                  onChange={handleLeaveTypeChange}
                  value={selectedLeaveType}
                >
                  <option value="">Select Leave Type</option>
                  <option value="1">Sick Leave</option>
                  <option value="2">Unpaid Leave</option>
                </Form.Select>
                <i
                  style={{ fontSize: "16px", marginTop: "1.5rem" }}
                  className="ri-calendar-check-line"
                ></i>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>Leave Duration</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon"
                    style={{ height: "45px" }}
                    value="00:00"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    disabled
                  />
                  <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>Current Leave Balance (Hr) </Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon"
                    style={{ height: "45px" }}
                    value="00:00"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    disabled
                  />
                  <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>New Leave Balance (Hr) </Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon"
                    style={{ height: "45px" }}
                    value="00:00"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    disabled
                  />
                  <i style={{ fontSize: "16px" }} className="ri-time-line"></i>
                </div>
              </Form.Group>
            </Col>
            {selectedLeaveType === '1' && (
              <Col lg="3">
                <Form.Group>
                  <Form.Label className="doc-label">
                    Leave Attachments
                  </Form.Label>
                  <div className="uploadsection">
                    {showUploadButton ? (
                      <div className="d-flex align-item-center">
                        <Form.Label
                          htmlFor="file"
                          className="uploadlabel"
                        >
                          <Form.Control
                            type="file"
                            className="form-control-lg"
                            style={{ display: "none" }}
                            id="file"
                            onChange={handleFileChange}
                          />
                          <i className="bx bx-plus"></i>{" "}
                          Choose File
                        </Form.Label>
                      </div>
                    ) : (
                      <Col lg="12" className="closeuser">
                        <p>{file?.name}</p>
                        <Button
                          onClick={handleRemoveFile}
                          color="red"
                        >
                          X
                        </Button>
                      </Col>
                    )}
                  </div>
                </Form.Group>
              </Col>
            )}
            <Col lg={selectedLeaveType === '1' ? "9" : '12'}>
              <Form.Group className="mb-3">
                <Form.Label>Note</Form.Label>
                <div className="position-relative form-icon">
                  <Form.Control
                    type="name"
                    name="name"
                    className="form-control-lg form-control-icon name-input"
                    placeholder="Enter Time off Note"
                  />
                  <i
                    style={{ fontSize: "16px" }}
                    className="ri-book-open-line"
                  ></i>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="off-canvas-footer off-bottom-staff custom-padding justify-content-end">
          <div className="off-confirm">
            <Button color="primary" className="clear-btn">
              Cancel
            </Button>
            <Button color="primary" className="next-btn">
              Apply
            </Button>
          </div>
        </div>
      </Offcanvas>
      <Offcanvas
        className="unavailibility-canvas"
        show={unavailibilityCanvas}
        onHide={unavailibilityToggle}
        placement="bottom"
      >
        <Offcanvas.Header className="add-header border-bottom p-2" closeButton>
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            Apply Unavailability
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 mt-4" style={{ overflowX: "hidden" }}>
          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label className="radio-label d-block">
                  Do You Unavailable For All Day .?
                </Form.Label>
                <div className="form-check form-check-inline">
                  <Form.Check
                    type="radio"
                    name="allDayOptions"
                    id="allDayRadio1"
                    value={1}
                    checked={allDayOptions}
                    onChange={() => handleAllDayChange(1)}
                  />
                  <Form.Label htmlFor="allDayRadio1">Yes</Form.Label>
                </div>
                <div className="form-check form-check-inline">
                  <Form.Check
                    type="radio"
                    name="allDayOptions"
                    id="allDayRadio2"
                    value={0}
                    checked={!allDayOptions}
                    onChange={() => handleAllDayChange(0)}
                  />
                  <Form.Label htmlFor="allDayRadio2">No</Form.Label>
                </div>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon name-input"
                    style={{ height: "45px" }}
                    placeholder="Select Start Date"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                  />
                  <i
                    style={{ fontSize: "16px" }}
                    className="ri-calendar-event-line"
                  ></i>
                </div>
              </Form.Group>
            </Col>
            {allDayOptions === false && (
              <>
                <Col lg="3">
                  <Form.Group className="mb-3">
                    <Form.Label>Start Time</Form.Label>
                    <div className="position-relative form-icon">
                      <Flatpickr
                        className="form-control form-control-icon"
                        style={{ height: "45px" }}
                        placeholder="Select Start Time"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                        }}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className="ri-time-line"
                      ></i>
                    </div>
                  </Form.Group>
                </Col>
              </>
            )}
            <Col lg="3">
              <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <div className="position-relative form-icon">
                  <Flatpickr
                    className="form-control form-control-icon name-input"
                    style={{ height: "45px" }}
                    placeholder="Select End Date"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                  />
                  <i
                    style={{ fontSize: "16px" }}
                    className="ri-calendar-event-line"
                  ></i>
                </div>
              </Form.Group>
            </Col>
            {allDayOptions === false && (
              <>
                <Col lg="3">
                  <Form.Group className="mb-3">
                    <Form.Label>End Time</Form.Label>
                    <div className="position-relative form-icon">
                      <Flatpickr
                        className="form-control form-control-icon"
                        style={{ height: "45px" }}
                        placeholder="Select End Time"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                        }}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className="ri-time-line"
                      ></i>
                    </div>
                  </Form.Group>
                </Col>
              </>
            )}
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label className="radio-label d-block">
                  Repeat This Unavailibility .?{" "}
                </Form.Label>
                <div className="form-check form-check-inline">
                  <Form.Check
                    type="radio"
                    name="repeatOptions"
                    id="repeatRadio1"
                    value={1}
                    checked={repeatOptions}
                    onChange={() => handleRepeatChange(1)}
                  />
                  <Form.Label htmlFor="repeatRadio1">Yes</Form.Label>
                </div>
                <div className="form-check form-check-inline">
                  <Form.Check
                    type="radio"
                    name="repeatOptions"
                    id="repeatRadio2"
                    value={0}
                    checked={!repeatOptions}
                    onChange={() => handleRepeatChange(0)}
                  />
                  <Form.Label htmlFor="repeatRadio2">No</Form.Label>
                </div>
              </Form.Group>
            </Col>
            {repeatOptions === true && (
              <>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="radio-label d-block">
                      Select Week
                    </Form.Label>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="radio"
                        name="weekOptions"
                        id="weekRadio1"
                        value="option1"
                      />
                      <Form.Label htmlFor="weekRadio1">Every Week</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="radio"
                        name="weekOptions"
                        id="weekRadio2"
                        value="option2"
                      />
                      <Form.Label htmlFor="weekRadio2">Every 2 Week</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="radio"
                        name="weekOptions"
                        id="weekRadio3"
                        value="option3"
                      />
                      <Form.Label htmlFor="weekRadio3">Every 4 Week</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="radio"
                        name="weekOptions"
                        id="weekRadio4"
                        value="option4"
                      />
                      <Form.Label htmlFor="weekRadio4">Every Month</Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="radio-label d-block">
                      Select Day
                    </Form.Label>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox1"
                        value="option1"
                      />
                      <Form.Label htmlFor="dayCheckbox1">Mon</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox2"
                        value="option2"
                      />
                      <Form.Label htmlFor="dayCheckbox2">Tue</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox3"
                        value="option3"
                      />
                      <Form.Label htmlFor="dayCheckbox3">Wed</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox4"
                        value="option4"
                      />
                      <Form.Label htmlFor="dayCheckbox4">Thu</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox5"
                        value="option5"
                      />
                      <Form.Label htmlFor="dayCheckbox5">Fri</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox6"
                        value="option6"
                      />
                      <Form.Label htmlFor="dayCheckbox6">Sat</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="checkbox"
                        name="dayOptions"
                        id="dayCheckbox7"
                        value="option7"
                      />
                      <Form.Label htmlFor="dayCheckbox7">Sun</Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="radio-label d-block">
                      End Repeated Unavailability .?{" "}
                    </Form.Label>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="radio"
                        name="endRepeatOptions"
                        id="endRepeatRadio1"
                        value={1}
                        checked={endRepeatOptions}
                        onChange={() => handleEndRepeatChange(1)}
                      />
                      <Form.Label htmlFor="endRepeatRadio1">Never</Form.Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Form.Check
                        type="radio"
                        name="endRepeatOptions"
                        id="endRepeatRadio2"
                        value={0}
                        checked={!endRepeatOptions}
                        onChange={() => handleEndRepeatChange(0)}
                      />
                      <Form.Label htmlFor="endRepeatRadio2">On Date</Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                {endRepeatOptions === false && (
                  <>
                    <Col lg="3">
                      <Form.Group className="mb-3">
                        <Form.Label>End Date</Form.Label>
                        <div className="position-relative form-icon">
                          <Flatpickr
                            className="form-control form-control-icon name-input"
                            style={{ height: "45px" }}
                            placeholder="Select End Date"
                            options={{
                              dateFormat: "d M, Y",
                            }}
                          />
                          <i
                            style={{ fontSize: "16px" }}
                            className="ri-calendar-event-line"
                          ></i>
                        </div>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </>
            )}
            <Col lg="12">
              <Form.Group className="mb-3">
                <Form.Label>Note</Form.Label>
                <div className="position-relative form-icon">
                  <Form.Control
                    type="text"
                    name="note"
                    className="form-control-lg form-control-icon name-input"
                    placeholder="Enter Time off Note"
                  />
                  <i
                    style={{ fontSize: "16px" }}
                    className="ri-book-open-line"
                  ></i>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="off-canvas-footer off-bottom-staff custom-padding justify-content-end ">
          <div className="off-confirm">
            <Button color="primary" className="clear-btn">
              Cancel
            </Button>
            <Button color="primary" className="next-btn">
              Apply
            </Button>
          </div>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
};

export default Calendar;
