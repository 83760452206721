import React, { useState, useEffect } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Offcanvas,
    Form,
    Dropdown,
    Image,
    Tab,
    Nav,
    Badge,
    Table,
    Modal
} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import BreadCrumb from "Common/BreadCrumb";
import logo from "assets/images/auth/cropprofile.png";
import { getDashboardDetails, RejectShiftList, getByStaffDetail, getStaffDocumentDetail } from "helpers/saibackend_helper";
import profile from "assets/images/auth/userprofile.png";
import takebreaks from "assets/images/auth/break.png";
import background from "assets/images/auth/Rectangle 7118.png";
import user from "assets/images/auth/blank.png";
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import ScheduleChart from "./ScheduleChart";
import TimesheetChart from "./TimesheeetChart";
import admincalendardata from "./data/admincalendardata";
import ColumnBarChart from "Common/ColumnBarChart";
import InvoiceChart from "./InvoiceChart";
import Clientadminchart from "./Clientadminchart";
import { useProfile } from "Common/Hooks/UserHooks";

const AdminDashboard = (() => {

    document.title = "Dashboard | The Guard Duty";

    const { userProfile } = useProfile();
    const userRoles = userProfile.roles;
    const allowedRoles = ['DEVELOPER', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
    const hasRoles = (roles: string[]) => roles.some(role => userRoles.includes(role));
    const canAccess = hasRoles(allowedRoles);

    const [upcomingshift, setUpcomingshift] = useState<boolean>(false);
    const handleupcomingshift = () => setUpcomingshift(!upcomingshift);

    const [availableshift, setAvailableshift] = useState<boolean>(false);
    const handleavailableshift = () => setAvailableshift(!availableshift);

    const [shiftrequst, setshiftrequst] = useState<boolean>(false);
    const handleshiftrequst = () => setshiftrequst(!shiftrequst);

    const [timeoffrequst, settimeoffrequst] = useState<boolean>(false);
    const handletimeoffrequst = () => settimeoffrequst(!timeoffrequst);

    const [takebreak, settakebreak] = useState<boolean>(false);
    const handletakebreak = () => settakebreak(!takebreak);

    const [viewshift, setviewshift] = useState<boolean>(false);
    const handleviewshift = () => setviewshift(!viewshift);

    const [isBottom, setIsBottom] = useState(false);
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };

    const [startTime, setStartTime] = useState<string | null>(null);
    const [endTime, setEndTime] = useState<string | null>(null);
    const [shiftStarted, setShiftStarted] = useState(false);

    const handleStartShift = () => {
        setShiftStarted(true);
        const currentStartTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        setStartTime(currentStartTime);
    };

    const handleEndShift = () => {
        const currentEndTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        setEndTime(currentEndTime);
        setIsBottom(false)
        setShiftStarted(false)
        setBreakStarted(false)
    };

    const [selectedCheckbox, setSelectedCheckbox] = useState<string | null>(null);

    const handleCheckboxChange = (id: string) => {
        setSelectedCheckbox(id === selectedCheckbox ? null : id);
    };
    const [breakStarted, setBreakStarted] = useState(false);
    const [breakTime, setBreakTime] = useState(0);
    const [totalBreakDuration, setTotalBreakDuration] = useState(0);

    const startBreak = () => {
        settakebreak(false)
        setBreakStarted(true);
        const intervalId = setInterval(() => {
            setBreakTime((prevTime) => prevTime + 1);
        }, 1000);
        // Save the interval ID to clear it later
        localStorage.setItem('breakIntervalId', intervalId.toString());
    };

    const finishBreak = () => {
        setBreakStarted(false);
        clearInterval(Number(localStorage.getItem('breakIntervalId')));
        setTotalBreakDuration(breakTime);
    };

    useEffect(() => {
        return () => {
            // Clear the interval when the component unmounts
            clearInterval(Number(localStorage.getItem('breakIntervalId')));
        };
    }, []);



    // Dashboard API Integration //
    // ===============================================================================================================//

    const [DashboardDetail, setDashboardDetail] = useState<any>({});
    const [RejectShiftDetail, setRejectShiftDetail] = useState<any>({});
    const [totalReject, setTotalReject] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchDashbaordDetails = async () => {
        try {
            const response = await getDashboardDetails();
            setDashboardDetail(response.data.data_list);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchRejectShiftDetails = async () => {
        try {
            const response = await RejectShiftList();
            setRejectShiftDetail(response.data.data_list);
            setTotalReject(response.data.total_records)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchRejectShiftDetails()
        fetchDashbaordDetails();
    }, []);

    const getStatusColor = (status: string): string => {
        switch (status) {
            case 'AWAITING CLOCK IN':
                return 'awaited';
            case 'CLOCKED OUT':
                return 'clockout';
            case 'MISSED CLOCK IN':
                return 'missed';
            case 'CLOCKED IN':
                return 'clockin';
            case 'MISSED CLOCKED IN':
                return 'missedclockin'
            default:
                return 'black';
        }
    };

    const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

    const editMaster = async (id: any) => {
        try {
            const response = await getByStaffDetail(id);
            const data = response.data;
            setStaffViewDetail(data);
            tog_standard()
        } catch (error) {
            return;
        }
    };


    const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
        any[]
    >([]);

    const fetchStaffDocumentDetails = async (id: any) => {
        try {
            const response = await getStaffDocumentDetail("", id);
            setStaffDocumentDetail(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const doc_url = process.env.REACT_APP_IMAGE_URL
    const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

    const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

    const getAbbreviation = (name: string) => {
        return name
            ?.split(' ')
            ?.map(word => word.charAt(0))
            ?.join('')
            ?.toUpperCase()
            ?.slice(0, 2);
    };

    return (
        <>        
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Welcome To SAI" pageTitle="Dashboard" />
                        {
                            loading ?
                                <Row className="placeholder-glow mt-5">
                                    <Col md={12}>
                                        <div className="placeholder bg-light card-placeholder col-12"></div>
                                    </Col>
                                    <Col md={12} className="mt-5">
                                        <div className="placeholder bg-light card-placeholder col-12"></div>
                                    </Col>
                                    <Col md={6} className="mt-5">
                                        <div className="placeholder bg-light table-placeholder col-12"></div>
                                    </Col>
                                    <Col md={6} className="mt-5">
                                        <div className="placeholder bg-light table-placeholder col-12"></div>
                                    </Col>
                                </Row>
                            :
                            <>
                                <Row className="admin-dashboard">
                                    <Col lg="12">
                                        <Row>
                                            {/* <Col lg="3">
                                                <Card className="overflow-hidden">
                                                    <div>
                                                        <img
                                                            src={background}
                                                            alt=""
                                                            className="card-img-top profile-wid-img object-fit-cover"
                                                            style={{ height: "120px" }}
                                                        />
                                                        <div>
                                                            <input
                                                                id="profile-foreground-img-file-input"
                                                                type="file"
                                                                className="profile-foreground-img-file-input d-none"
                                                            />
                                                            <label
                                                                htmlFor="profile-foreground-img-file-input"
                                                                className="profile-photo-edit btn btn-light btn-sm position-absolute end-0 top-0 m-3"
                                                            >
                                                                <i className="ri-image-edit-line align-bottom me-1"></i>{" "}
                                                                Edit Cover Images
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <Card.Body className="pt-0 p-3 mt-n5">
                                                        <div className="text-center">
                                                            <div className="profile-user position-relative d-inline-block mx-auto">
                                                                <img
                                                                    src={logo}
                                                                    alt=""
                                                                    className={`avatar-lg rounded-circle object-fit-cover ${breakStarted ? 'breakitemtime' : ''} img-thumbnail user-profile-image`}
                                                                />
                                                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit position-absolute end-0 bottom-0">
                                                                    <input
                                                                        id="profile-img-file-input"
                                                                        type="file"
                                                                        className="profile-img-file-input d-none"
                                                                    />
                                                                    <label
                                                                        htmlFor="profile-img-file-input"
                                                                        className="profile-photo-edit avatar-xs"
                                                                    >
                                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                                            <i className="bi bi-camera"></i>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3">
                                                                <h5 className="user-name">Richard Marshall</h5>
                                                                <p className="text-muted user-role">Access Level</p>
                                                                <p className="user-role-title">Role</p>
                                                                {endTime ?
                                                                    <p className="user-shift-title">Shift Ended</p>
                                                                    :
                                                                    breakStarted ? (
                                                                        <>
                                                                            <p className="breakstart">
                                                                                Break Started
                                                                            </p>
                                                                            <p className="breakstart mb-3">
                                                                                {Math.floor(breakTime / 3600).toString().padStart(2, '0')}:
                                                                                {Math.floor((breakTime % 3600) / 60).toString().padStart(2, '0')}hr
                                                                            </p>
                                                                        </>
                                                                    ) : startTime ? (
                                                                        <>
                                                                            <p className="shift-started">Shift Started {startTime}</p>
                                                                            <p className="shift-started mb-3">Location (Reception)</p>
                                                                        </>
                                                                    ) : (
                                                                        <p className="user-shift-title">Shift Not Started</p>
                                                                    )

                                                                }
                                                            </div>
                                                            {!shiftStarted ? (
                                                                <Button className="shift-start-btn" onClick={handleStartShift}>
                                                                    Start Shift
                                                                </Button>
                                                            ) : (
                                                                <div className="start-shift">
                                                                    {breakStarted ? (
                                                                        <Button onClick={finishBreak} className="takebreak-btn">
                                                                            Finish Break
                                                                        </Button>
                                                                    ) : (
                                                                        <Button onClick={handletakebreak} className="takebreak-btn">
                                                                            Take Break
                                                                        </Button>
                                                                    )}
                                                                    <Button onClick={toggleBottomCanvas} className="shift-end-btn">End Shift</Button>
                                                                </div>
                                                            )}
                                                            <Row className="shift-details text-start">
                                                                <Col lg="6">
                                                                    <p className="shift-time-title">Location</p>
                                                                    <p className="shift-time">Hospital</p>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <p className="shift-time-title">Department</p>
                                                                    <p className="shift-time">Department 1</p>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <p className="shift-time-title">Start Time</p>
                                                                    <p className="shift-time">{startTime || 'N/A'}</p>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <p className="shift-time-title">End Time</p>
                                                                    <p className="shift-time">{endTime || 'N/A'}</p>
                                                                </Col>
                                                            </Row>
                                                            <div className="text-start">
                                                                <p className="shift-time-title">Break Details</p>
                                                                {totalBreakDuration > 0 ?
                                                                    <p className="shift-timemeal mb-0">Meal Break: {Math.floor(totalBreakDuration / 60)}min (Paid)</p>
                                                                    :
                                                                    <p className="shift-timemeal mb-0">Meal Break: N/A</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col> */}
                                            {/* <Col lg="9">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Card onClick={handleupcomingshift} className="overflow-hidden">
                                                            <Card.Body>
                                                                <div className="avatar-sm float-end">
                                                                    <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                                                                        <i className="ri-calendar-event-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <h4>
                                                                    <span className="counter-value" data-target="368.24">
                                                                        10
                                                                    </span>
                                                                </h4>
                                                                <p className="text-muted mb-4">Upcoming Shift</p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Card onClick={handleavailableshift} className="overflow-hidden">
                                                            <Card.Body>
                                                                <div className="avatar-sm  float-end">
                                                                    <div className="avatar-title bg-danger-subtle text-danger  fs-3xl rounded">
                                                                        <i className="ri-calendar-event-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <h4>
                                                                    <span className="counter-value" data-target="368.24">
                                                                        20
                                                                    </span>
                                                                </h4>
                                                                <p className="text-muted mb-4">Available Shift</p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Card onClick={handleshiftrequst} className="overflow-hidden">
                                                            <Card.Body>
                                                                <div className="avatar-sm float-end">
                                                                    <div
                                                                        className="avatar-title fs-3xl rounded"
                                                                        style={{ background: "#DFEBFC", color: "#2272EA" }}
                                                                    >
                                                                        <i className="ri-calendar-event-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <h4>
                                                                    <span className="counter-value" data-target="368.24">
                                                                        15
                                                                    </span>
                                                                </h4>
                                                                <p className="text-muted mb-4">Shift Request</p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Card onClick={handletimeoffrequst} className="overflow-hidden">
                                                            <Card.Body>
                                                                <div className="avatar-sm float-end">
                                                                    <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                                                                        <i className="ri-calendar-event-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <h4>
                                                                    <span className="counter-value" data-target="368.24">
                                                                        10
                                                                    </span>
                                                                </h4>
                                                                <p className="text-muted mb-4">Time Off Request</p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Card className="location-table">
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col lg="12" className="d-flex justify-content-between">
                                                                        <h5 className="mb-4">Location <Badge className="total-location"><i className="ri-map-pin-line"></i> 25</Badge></h5>
                                                                        <p className="view-details">View Details</p>
                                                                    </Col>
                                                                    <Col lg="12">
                                                                        <div className="table-responsive">
                                                                            <Table className="align-middle table-nowrap mb-0">
                                                                                <thead className="table-light">
                                                                                    <tr>
                                                                                        <th scope="col">Location Name</th>
                                                                                        <th scope="col">Client Name</th>
                                                                                        <th scope="col">Departments</th>
                                                                                        <th scope="col">No of Employees</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Location 1</td>
                                                                                        <td>Charlie Dawson</td>
                                                                                        <td>10</td>
                                                                                        <td>100</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Location 2</td>
                                                                                        <td>Elijah Gilbert</td>
                                                                                        <td>12</td>
                                                                                        <td>200</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Location 3</td>
                                                                                        <td>Ashton Collins</td>
                                                                                        <td>15</td>
                                                                                        <td>80</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            <Col lg="12">
                                                <Card className="location-table">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col lg="12" className="d-flex justify-content-between">
                                                                <h5>Live Operation</h5>
                                                            </Col>
                                                            <Col lg="12" className="mt-4">
                                                                <SimpleBar
                                                                    className="py-0"
                                                                    style={{ height: "290px" }}
                                                                >
                                                                    <div className="table-responsive">
                                                                        <Table className="align-middle table-nowrap mb-0">
                                                                            <thead className="table-light">
                                                                                <tr>
                                                                                    <th scope="col">Location Name</th>
                                                                                    <th scope="col">Staff Name</th>
                                                                                    <th scope="col">Shift Time</th>
                                                                                    <th scope="col">Clock In Time</th>
                                                                                    <th scope="col">Clock Out Time</th>
                                                                                    <th scope="col">Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {DashboardDetail?.live_operation?.length > 0 ? (
                                                                                    DashboardDetail.live_operation.map((operation: any) => {
                                                                                        const statusColor = getStatusColor(operation?.status || '');
                                                                                        return (
                                                                                            <tr key={operation.id}> {/* Add a unique key for each row */}
                                                                                                <td>
                                                                                                    {operation?.location_name ? operation?.location_name : "-"} <br />
                                                                                                    <span className="badge rounded-pill bg-info-subtle text-info">{operation?.client}</span>
                                                                                                </td>
                                                                                                <td style={{display:'flex', gap:'4px' , alignItems:'center'}}
                                                                                                >
                                                                                                    {operation?.staff_name ? operation.staff_name : "-"} <i onClick={() => {
                                                                                                        if (operation?.staff_id) {
                                                                                                            editMaster(operation.staff_id);
                                                                                                            fetchStaffDocumentDetails(operation.staff_id);
                                                                                                        }
                                                                                                    }} className="ri-information-line link-info"  style={{ cursor: 'pointer', fontSize:'18px' }} ></i>
                                                                                                </td>
                                                                                                <td>{operation?.shift_time ? operation?.shift_time : "-"}</td>
                                                                                                <td>
                                                                                                    {operation?.clock_in_time ?
                                                                                                        `${operation?.clock_in_time} ${operation?.check_in_status !== null ? `(${operation?.check_in_status})` : ""}`
                                                                                                        :
                                                                                                        "-"
                                                                                                    }
                                                                                                </td>
                                                                                                <td>{operation?.clock_out_time ? `${operation?.clock_out_time}` : "-"}</td>
                                                                                                <td>
                                                                                                    <span className={`badge ${statusColor}`}>{operation?.status}</span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td colSpan={6} className="text-center">No Live Operation Available</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </SimpleBar>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col lg="3">
                                        <Card className="location-table">
                                            <Card.Header>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="calendar-overall-navbar d-flex align-items-center">
                                                        <h5 className="calendar-admin-title">Calendar <Badge className="total-location"><i className="ri-calendar-event-line"></i></Badge></h5>
                                                    </div>
                                                    <div className="calendar-overall-navbar">
                                                        <div className="calendar-navbar">
                                                            <Badge className="calendar-left-icon"><i className="ri-arrow-left-line"></i></Badge>
                                                            <div className="calendar-title-name">Weekly</div>
                                                            <Badge className="calendar-right-icon"><i className="ri-arrow-right-line"></i></Badge>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className="pt-0">
                                                <Row>
                                                    <Col lg="12" className="calendar-col">
                                                        {admincalendardata.map((data) => {
                                                            const statusClass = data.status
                                                            return (
                                                                <>
                                                                    <div key={data.id}>
                                                                        <p className="date-calc">{data.date}</p>
                                                                        {data.place === "Leave" ?
                                                                            <div className={`div-calendar ${statusClass}`}>
                                                                                <p className="time-calc">All Day <i className="ri-moon-clear-line"></i></p>
                                                                                <div className="depart-calc">
                                                                                    <p>Leave</p>
                                                                                    <i onClick={handleviewshift} className="ri-file-list-2-line"></i>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className={`div-calendar ${statusClass}`}>
                                                                                <p className="time-calc">{data.time}</p>
                                                                                <p className="place-calc">{data.place}</p>
                                                                                <div className="depart-calc">
                                                                                    <p>{data.department}</p>
                                                                                    {data.time !== "Unavailable" && data.time !== "Available" && (
                                                                                        <i onClick={handleviewshift} className="ri-file-list-2-line"></i>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </Col>
                                                    <Col lg="6">
                                                        <p className="calendar-status mt-3 mb-3"><i className="icon-1 ri-checkbox-blank-circle-fill"></i> Unavailable/Leave</p>
                                                        <p className="calendar-status"><i className="icon-2 ri-checkbox-blank-circle-fill"></i> Unavailable/Leave</p>
                                                    </Col>
                                                    <Col lg="6">
                                                        <p className="calendar-status mt-3 mb-3"><i className="icon-3 ri-checkbox-blank-circle-fill"></i> Unavailable/Leave</p>
                                                        <p className="calendar-status"><i className="icon-4 ri-checkbox-blank-circle-fill"></i> Unavailable/Leave</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col> */}
                                    <Col lg="12">
                                        <Card className="location-table">
                                            <Card.Body>
                                                <Row>
                                                    <Col lg="12" className="d-flex justify-content-between">
                                                        <h5 className="mb-4">Rejected Shift<Badge className="total-location"><i className="ri-layout-masonry-line"></i> {totalReject || 0}</Badge></h5>
                                                    </Col>
                                                    <Col lg="12">
                                                        <SimpleBar
                                                            className="py-0"
                                                            style={{ height: "290px" }}
                                                        >
                                                            <div className="table-responsive">
                                                                <Table className="align-middle table-nowrap mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col">Staff</th>
                                                                            <th scope="col">Location</th>
                                                                            <th scope="col">Client</th>
                                                                            <th scope="col">Shift Time</th>
                                                                            <th scope="col">Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {RejectShiftDetail.length > 0 ?

                                                                            (RejectShiftDetail.map((shift: any) => {
                                                                                return (
                                                                                    <tr key={shift.id}>
                                                                                        <td>{shift.staff ? shift.staff : "-"}</td>
                                                                                        <td>{shift.location_name ? shift.location_name : "-"}</td>
                                                                                        <td>{shift.client_name ? shift.client_name : "-"}</td>
                                                                                        <td>{shift.time ? shift.time : "-"}</td>
                                                                                        <td>{shift.date ? shift.date : "-"}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan={5} className="text-center">No Reject Shift Available</td>
                                                                                </tr>
                                                                            )}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </SimpleBar>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg="7">
                                        <Card className="location-table" style={{ height: '94%' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg="12" className="d-flex justify-content-between flex-wrap gap-2">
                                                        <h5>Schedule <Badge className="total-location"><i className="ri-layout-masonry-line"></i> {DashboardDetail?.schedule?.total_shift || 0}</Badge></h5>
                                                    </Col>
                                                    <Col lg="12">
                                                        <ScheduleChart
                                                            dataColors='["--tb-primary", "--tb-success", "--tb-warning", "--tb-danger", "--tb-info"]'
                                                            totalFinishedShift={DashboardDetail?.schedule?.total_finished_shift || 0}
                                                            totalPublishedShift={DashboardDetail?.schedule?.total_published_shift || 0}
                                                            totalUnpublishedShift={DashboardDetail?.schedule?.total_unpublished_shift || 0}
                                                            totalUnassignedShift={DashboardDetail?.schedule?.total_unassigned_shift || 0}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {canAccess && (
                                        <Col lg="5">
                                            <Card className="location-table" style={{ height: '94%' }}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col lg="12" className="d-flex justify-content-between">
                                                            <h5>
                                                                Timesheet
                                                                <Badge className="total-location">
                                                                    <i className="ri-calendar-line"></i>
                                                                    {DashboardDetail?.timesheet?.total_timesheet || 0}
                                                                </Badge>
                                                            </h5>
                                                        </Col>
                                                        <Col lg="12">
                                                            <TimesheetChart
                                                                dataColors='["--tb-primary", "--tb-success", "--tb-warning", "--tb-danger"]'
                                                                totalApproved={DashboardDetail?.timesheet?.total_approved_timesheet || 0}
                                                                totalPending={DashboardDetail?.timesheet?.total_pending_timesheet || 0}
                                                                totalRejected={DashboardDetail?.timesheet?.total_rejected_timesheet || 0}
                                                                totalTimesheet={DashboardDetail?.timesheet?.total_timesheet || 0}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}
                                    {/* <Col lg="8">
                                        <Card className="location-table" style={{ height: '94%' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg="12" className="d-flex justify-content-between">
                                                        <h5>Client <Badge className="total-location"><i className="ri-user-line"></i> 500</Badge></h5>
                                                        <p className="view-details">View Details</p>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Clientadminchart />
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className="location-table" style={{ height: '94%' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg="12" className="d-flex justify-content-between">
                                                        <h5>Invoice <Badge className="total-location"><i className="ri-file-text-line"></i> 100</Badge></h5>
                                                        <p className="view-details">View Details</p>
                                                    </Col>
                                                    <Col lg="12">
                                                        <InvoiceChart dataColors='["--tb-primary", "--tb-success", "--tb-warning", "--tb-danger", "--tb-info"]' />
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col> */}
                                </Row>
                                <Modal id="myModal" size="sm" show={modal_standard} onHide={() => { tog_standard(); }} >
                                    {/* <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                                        {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                                    </Modal.Header> */}
                                    <Modal.Body>
                                        <div className="d-flex align-items-center" >
                                            <div className="flex-shrink-0 position-relative">
                                                {StaffViewDetail?.avatar ?
                                                    <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                                    :
                                                    <div className="avatar-sm">
                                                        <div className="avatar-title rounded bg-danger-subtle text-danger">
                                                            {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <h5 className="fs-md mb-0"><Link to="/pages-profile" className="text-dark">{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</Link></h5>
                                                <p className="text-muted mb-0"> {StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"} </p>
                                                <p className="text-muted mb-0"> {StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"} </p>
                                            </div>
                                        </div>
                                        <div style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '18px', cursor: 'pointer' }}>
                                            <i onClick={tog_standard} className="ri-close-line"></i>
                                        </div>
                                        <div className="mt-4">
                                            <p className="text-muted mb-2">
                                                <i className="bi bi-telephone align-baseline me-1"></i>
                                                {StaffViewDetail?.mobile_no ? (
                                                    <Link to={`tel:${StaffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                                        {StaffViewDetail.mobile_no}
                                                    </Link>
                                                ) : (
                                                    "-"
                                                )}
                                            </p>
                                            <p className="text-muted mb-2">
                                                <i className="bi bi-envelope align-baseline me-1"></i>
                                                {StaffViewDetail?.email ? (
                                                    <Link to={`mailto:${StaffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{StaffViewDetail.email} </Link>
                                                ) : (
                                                    "-"
                                                )}
                                            </p>
                                            <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"} </p>
                                            <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"} </p>
                                            <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"} </p>
                                        </div>
                                        {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses").length > 0 &&
                                            <div className="border-bottom border-top border-dashed py-4 mt-4">
                                                <h5 className="card-title mb-3">License</h5>
                                                <div className="vstack gap-3">
                                                    {StaffDocumentDetail
                                                        .filter((doc) => doc.document_type === "Licenses")
                                                        .map((doc, index) => (
                                                            <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                                                <div className="flex-shrink-0">
                                                                    <div className="avatar-sm border border rounded">
                                                                        <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                                                            <i className="bi bi-file-pdf"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h6>License No : {doc.ref_no}</h6>
                                                                    <p className="text-muted mb-0">Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}</p>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <Link to={`#`} onClick={() => handleOpenDocument(doc.document_path)} className="icon-link text-reset stretched-link fs-xl" style={{ transform: "translate3d(0, -.125rem, 0)" }}>
                                                                        <i className="ri-eye-line link-info"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        }
                                    </Modal.Body>
                                </Modal>
                            </>
                        }

                    </Container>
                </div >
            </React.Fragment >
        </>
    )
})

export default AdminDashboard;