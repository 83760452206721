//Include Both Helper File with needed methods
import { userLogin } from "../../../helpers/saibackend_helper";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
  try {
    let response;

    response = userLogin({
      email: user.email,
      password: user.password,
    });

    var data = await response;

    if (data) {
      localStorage.setItem("authUser", JSON.stringify(data));
      var finallogin: any = JSON.stringify(data);
      finallogin = JSON.parse(finallogin);
      data = finallogin.data;

      if (finallogin.status === 1) {
        dispatch(loginSuccess(data));
        history("/dashboard");
        toast.success(finallogin.message);
      } else {
        history("/login");
        dispatch(apiError(finallogin));
        toast.error(finallogin.message);
      }
    }
  } catch (error) {
    dispatch(apiError(error));
    toast.error("An error occurred. Please try again later.");
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
