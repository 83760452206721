
import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Form, Image } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

interface SubContractorMasterData {
    id: number;
    name: string;
    abbreviation: string;
    abn: number,
    contact_person: string,
    contact_number: string,
    email: string,
    address: string,
    city: string,
    state: string,
    postal_code: number,
    country: string
}

interface SubContractorTableProps {
    data: SubContractorMasterData[];
    archiveMaster: (id: number) => Promise<void>;
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
}


const SubContractorTable: React.FC<SubContractorTableProps> = ({ data, archiveMaster, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows }) => {


    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Name</span>,
            selector: (row: any) => row.name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Abbreviation</span>,
            sortable: true,
            cell: (row: any) => {
                return (
                    <>
                        {/* <div style={{width:'fit-content',background:'#DDDDDE',padding:'12px',borderRadius:'50%'}}>
                        </div> */}
                        <span className="badge bg-body-secondary rounded-pill border border-info text-info"> {row.abbreviation.slice(0, 4)}</span>
                    </>
                );
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>ABN</span>,
            selector: (row: any) => row.abn,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Contact Person</span>,
            selector: (row: any) => row.contact_person,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Contact Number</span>,
            selector: (row: any) => row.contact_number,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Email</span>,
            selector: (row: any) => row.email,
            sortable: true
        },
        // {
        //     name: <span className='font-weight-bold fs-sm'>Address</span>,
        //     selector: (row: any) => row.address,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-sm'>City</span>,
        //     selector: (row: any) => row.city,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-sm'>State</span>,
        //     selector: (row: any) => row.state,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-sm'>Postal Code</span>,
        //     selector: (row: any) => row.postal_code,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-sm'>Country</span>,
        //     selector: (row: any) => row.country,
        //     sortable: true
        // },
        {
            name: <span className='font-weight-bold fs-sm'>Action</span>,
            sortable: true,

            cell: (row: any) => {
                return (
                    <>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <i onClick={() => editMaster(row.id)} className="ri-pencil-line" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            <i onClick={() => archiveMaster(row.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line"></i>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
        />
    );
};

export default SubContractorTable