import React, { useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Nav,
    Row,
    Tab,
} from "react-bootstrap";
import company1 from "assets/images/auth/sailogo.png"
import ComplianceMaster from "../ComplianceMaster/ComplianceMaster";
import HolidayMaster from "../HolidayMaster/HolidayMaster";
import SubContractorMaster from "../SubContractorMaster/SubContractorMaster";
import TemplateMaster from "../TemplateMaster/TemplateMaster";
import usflag from "assets/images/flags/us.svg";
import CompanyDocumentMaster from "../CompanyDocumentMaster/CompanyDocumentMaster";
import InvoiceTemplateMaster from "../InvoiceTemplateMaster/InvoiceTemplateMaster";
import PaymentTermsMaster from "../PaymentTermsMaster/PaymentTermsMaster";
import LeaveMaster from "../LeaveMaster/LeaveMaster";
import Paysetting from "../PaySetting/Paysetting";
import BillingSetting from "../BillingSetting/BillingSetting";

enum AccessLevel {
    SuperAdmin = "SuperAdmin",
    Admin = "Admin",
    Manager = "Manager",
    Employee = "Employee",
}

enum Module {
    Workforce = "Workforce",
    Location = "Location",
    Rostering = "Rostering",
    Timesheet = "Timesheet",
    Client = "Client",
}

type Permissions = {
    [key in AccessLevel]: {
        [key in Module]: boolean;
    };
};

type SelectCallback = (eventKey: string | null) => void;


const initialPermissions: Permissions = {
    [AccessLevel.SuperAdmin]: {
        [Module.Workforce]: false,
        [Module.Location]: false,
        [Module.Rostering]: false,
        [Module.Timesheet]: false,
        [Module.Client]: false,
    },
    [AccessLevel.Admin]: {
        [Module.Workforce]: false,
        [Module.Location]: false,
        [Module.Rostering]: false,
        [Module.Timesheet]: false,
        [Module.Client]: false,
    },
    [AccessLevel.Manager]: {
        [Module.Workforce]: false,
        [Module.Location]: false,
        [Module.Rostering]: false,
        [Module.Timesheet]: false,
        [Module.Client]: false,
    },
    [AccessLevel.Employee]: {
        [Module.Workforce]: false,
        [Module.Location]: false,
        [Module.Rostering]: false,
        [Module.Timesheet]: false,
        [Module.Client]: false,
    },
};

const OrganizationSetting = (() => {

    document.title = "SAI Master | The Guard Duty";

    const [activeTab, setActiveTab] = useState<string>("v-pills-home-tab2");
    const [leave, setleave] = useState(false);
    const toggleleaveCanvas = () => {
        setleave(!leave);
    };

    const [subcon, setsubcon] = useState(false);
    const togglesubconCanvas = () => {
        setsubcon(!subcon);
    };

    const [permissions, setPermissions] = useState<Permissions>(initialPermissions);

    const handlePermissionChange = (accessLevel: AccessLevel, module: Module) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [accessLevel]: {
                ...prevPermissions[accessLevel],
                [module]: !prevPermissions[accessLevel][module],
            },
        }));
    };

    const handleTabSelect: SelectCallback = (eventKey) => {
        if (eventKey) {
            setActiveTab(eventKey);
        }
    };
    return (
        <>
            <React.Fragment>
                <div className="page-content organization-setting-page">
                    <Container fluid>
                        <Card className="organization-setting-card">
                            <Card.Body className="organization-setting-cardbody">
                                <Row>
                                    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
                                        <Col lg={3} className="organization-company-navtab-view">
                                            <Row>
                                                <Col lg="12">
                                                    <div className="company-overview-name">
                                                        <img src={company1} width="50" />
                                                        <h5>SAI Master</h5>
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab2" className="mb-2" id="v-pills-home-tab2" >
                                                            <i style={{ fontSize: '20px' }} className="ri-book-2-fill"></i> Compliance Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab3" className="mb-2" id="v-pills-home-tab3" >
                                                            <i style={{ fontSize: '20px' }} className="ri-suitcase-2-line"></i> Holiday Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab4" className="mb-2" id="v-pills-home-tab4" >
                                                            <i style={{ fontSize: '20px' }} className="ri-logout-box-line"></i> Leave Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    {/* <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab5" className="mb-2" id="v-pills-home-tab5" >
                                                            <i className="ri-award-fill"></i> Subcontractor Master
                                                        </Nav.Link>
                                                    </Nav> */}
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab7" className="mb-2" id="v-pills-home-tab7" >
                                                            <i style={{ fontSize: '20px' }} className="ri-spam-line"></i> Incident Template Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    {/* <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab6" className="mb-2" id="v-pills-home-tab6" >
                                                            <i style={{ fontSize: '20px' }} className="ri-key-fill"></i> Access Permission
                                                        </Nav.Link>
                                                    </Nav> */}
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab8" className="mb-2" id="v-pills-home-tab8" >
                                                            <i style={{ fontSize: '20px' }} className=" ri-building-line"></i> Company Document Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab9" className="mb-2" id="v-pills-home-tab9" >
                                                            <i style={{ fontSize: '20px' }} className=" ri-file-text-line"></i> Invoice Template Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab10" className="mb-2" id="v-pills-home-tab10" >
                                                            <i style={{ fontSize: '20px' }} className=" ri-file-list-2-line"></i> Payment Terms Master
                                                        </Nav.Link>
                                                    </Nav>
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab11" className="mb-2" id="v-pills-home-tab11" >
                                                            <i style={{ fontSize: '20px' }} className="  ri-wallet-line"></i> Pay Settings
                                                        </Nav.Link>
                                                    </Nav>
                                                    <Nav variant="pills" className="flex-column text-start" id="v-pills-tab" aria-orientation="vertical" >
                                                        <Nav.Link eventKey="v-pills-home-tab12" className="mb-2" id="v-pills-home-tab12" >
                                                            <i style={{ fontSize: '20px' }} className="  ri-file-list-3-line"></i> Billing Settings
                                                        </Nav.Link>
                                                    </Nav>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col lg={9} className="role-list-page" style={{ height: '100%' }}>
                                            <Tab.Content className="text-muted mt-4 mt-md-0">
                                                {activeTab === "v-pills-home-tab2" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab2">
                                                        <ComplianceMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab3" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab3">
                                                        <HolidayMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab4" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab4">
                                                        <LeaveMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab5" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab5">
                                                        <SubContractorMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab7" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab7">
                                                        <TemplateMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab8" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab8">
                                                        <CompanyDocumentMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab9" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab9">
                                                        <InvoiceTemplateMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab10" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab10">
                                                        <PaymentTermsMaster />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab11" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab11">
                                                        <Paysetting />
                                                    </Tab.Pane>
                                                )}
                                                {activeTab === "v-pills-home-tab12" && (
                                                    <Tab.Pane eventKey="v-pills-home-tab12">
                                                        <BillingSetting />
                                                    </Tab.Pane>
                                                )}
                                            </Tab.Content>
                                        </Col>
                                    </Tab.Container>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
})

export default OrganizationSetting;