import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

interface TimesheetChartProps {
    dataColors: string;
    totalApproved: number;
    totalPending: number;
    totalRejected: number;
    totalTimesheet: number;
}

const TimesheetChart: React.FC<TimesheetChartProps> = ({
    dataColors,
    totalApproved,
    totalPending,
    totalRejected,
    totalTimesheet
}) => {
    const selectLayoutThemeType = createSelector(
        (state: any) => state.Layout,
        (layoutThemeType) => layoutThemeType.layoutThemeType
    );

    const layoutThemeType = useSelector(selectLayoutThemeType);

    useEffect(() => {
        document.getElementsByClassName('apex-charts');
    }, [layoutThemeType]);

    // Calculate percentages
    const approvedPercentage = totalTimesheet ? (totalApproved / totalTimesheet) * 100 : 0;
    const pendingPercentage = totalTimesheet ? (totalPending / totalTimesheet) * 100 : 0;
    const rejectedPercentage = totalTimesheet ? (totalRejected / totalTimesheet) * 100 : 0;

    const series = [rejectedPercentage, approvedPercentage, pendingPercentage];

    const options: any = {
        chart: {
            height: 240,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                        formatter: function (val: number) {
                            // Convert value to percentage and ensure it is a number
                            const num = Number(val);
                            if (isNaN(num)) {
                                return ''; // Return empty if not a number
                            }
                            return `${num.toFixed(0)}%`;
                        }
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function () {
                            return `${(totalTimesheet).toFixed(0)} Total`; 
                        }
                    }
                }
            }
        },
        labels: ['Rejected', 'Approved', 'Pending'],
        colors: ['#F0B414', '#50B478', '#F0484F'],
        legend: {
            show: true,
            position: 'right',
            right: '10%'
        },
    };

    return (
        <Chart
            dir="ltr"
            key={totalTimesheet}
            className="apex-charts"
            series={series}
            options={options}
            type="radialBar"
            height={247}
        />
    );
};

export default TimesheetChart;
