import { Navigate } from "react-router-dom";

import Fourzerofour from "pages/AuthenticationInner/Errors/404Error";
import Fivezerozero from "pages/AuthenticationInner/Errors/500Error";
import Fivezerothree from "pages/AuthenticationInner/Errors/503Error";
import OfflinePage from "pages/AuthenticationInner/Errors/Offline";
import ForbiddenError from "pages/AuthenticationInner/Errors/403Error";


import Ecommerce from "pages/Dashboard/Ecommerce";
import PasswordCreate from "pages/Authentication/PasswordCreate";

// Authentication
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import ResetPassword from "pages/Authentication/Reset-Password";
import SignUpOrganization from "pages/Authentication/SignUpOrganization";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import UserProfile from "pages/Authentication/user-profile";
import OrganizationPage from "pages/organization/OrganizationPage";
import Existingorganization from "pages/Authentication/Existingorganization";
import Companyonboarding from "pages/Dashboard/Companyonboarding";
import StaffPage from "pages/WorkForce/StaffPage";
import EditStaff from "pages/WorkForce/EditStaff";
import Location from "pages/Location/Location";
import EmployeeForm from "pages/Employee/EmployeeForm";
import EmployeeOnboarding from "pages/Employee/EmployeeOnboarding";
import TimeOff from "pages/WorkForce/LeavePage";
import AddLocation from "pages/Location/AddLocation";
import CalendarPage from "pages/WorkForce/CalendarPage";
import TimesheetPage from "pages/Timesheet/TimesheetPage";
import SchedulePage from "pages/Schedule/SchedulePage";
import ScheduleNew from "pages/Schedule/ScheduleNew";
import ClientList from "pages/ClientModule/ClientList";
import InvoiceList from "pages/ClientModule/InvoiceList";
import OrganizationSetting from "pages/Dashboard/Ecommerce/OrganizationSetting";
import AdminDashboard from "pages/Dashboard/AdminDashboard";
import IncidentMaster from "pages/Dashboard/IncidentMaster/IncidentMaster"
import SubContractorPage from "pages/SubContractor/SubContractorPage";
import EditSubcontractor from "pages/SubContractor/EditSubcontractor";
import PayrollPage from "pages/Payroll/PayrollPage";
import PayrollSingleUser from "pages/Payroll/PayrollSingleUser";
import PatrolLocation from "pages/PatrolLocation/PatrolLocation";
import PendingTimesheet from "pages/Payroll/PendingTimesheet";
import CheckPointLogTable from "pages/Schedule/CheckPointLogTable";

const authProtectedRoutes = [
    // Dashboard
    // { path: "/dashboard", component: <Ecommerce />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/organization-setting", component: <OrganizationSetting />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/dashboard", component: <AdminDashboard />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/", exact: true, component: <Navigate to="/dashboard" />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/company-setting", name: "companyonboarding", component: <Companyonboarding />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },

    //user prpfile
    { path: "/user-profile", name: "UserProfile", component: <UserProfile />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    // this route should be at the end of all other routes
    { path: "/auth-pass-change", name: "PasswordCreate", component: <PasswordCreate />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    // eslint-disable-next-line react/display-name
    { path: "/staff-page", name: "Staffpage", component: <StaffPage />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/edit-staff", name: "EditStaff", component: <EditStaff />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/staff-calendar", name: "StaffCalendar", component: <CalendarPage />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/location", name: "Location", component: <Location />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/add-location", name: "AddLocation", component: <AddLocation />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/time-off", name: "TimeOff", component: <TimeOff />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/timesheet", name: "TimesheetPage", component: <TimesheetPage />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/schedule-old", name: "SchedulePage", component: <SchedulePage />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/schedule", name: "ScheduleNew", component: <ScheduleNew />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER','SUB_CONTRACTOR'] },
    { path: "/client-list", name: "ClientList", component: <ClientList />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/invoice-list", name: "InvoiceList", component: <InvoiceList />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/incident-master", name: "IncidentMaster", component: <IncidentMaster />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/subcontractor", name: "SubContractorPage", component: <SubContractorPage />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/edit-subcontractor", name: "EditSubcontractor", component: <EditSubcontractor />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/payroll", name: "PayrollPage", component: <PayrollPage />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/payroll/details/:id", name: "PayrollSingleUser", component: <PayrollSingleUser />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/patrol-location", name: "PatrolLocation", component: <PatrolLocation />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/pending-timesheet", name: "PendingTimesheet", component: <PendingTimesheet />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },
    { path: "/checkpoint-log", name: "CheckPointLogTable", component: <CheckPointLogTable />, roles: ['SUPER_ADMIN','DEVELOPER','ADMIN','MANAGER'] },

];

const publicRoutes = [
    // Authentication
    { path: "/login", name: "Login", component: <Login /> },
    { path: "/logout", name: "Logout", component: <Logout /> },
    { path: "/reset-password", name: "ResetPassword", component: <ResetPassword /> },
    { path: "/forgot-password", name: "ForgotPassword", component: <ForgotPassword /> },
    { path: "/signup-organization", name: "SignUpOrganization", component: <SignUpOrganization /> },
    // { path: "/organization-page", name: "OrganizationPage", component: <OrganizationPage /> },
    // { path: "/existing-organization", name: "Existingorganization", component: <Existingorganization /> },
    { path: "/employee-auth", name: "EmployeeAuth", component: <EmployeeForm /> },
    { path: "/emp-onboarding", name: "EmployeeOnboarding", component: <EmployeeOnboarding /> },
    // Authentication Inner

    // Error
    // Error 404
    { path: "/404", name: "Fourzerofour", component: <Fourzerofour /> },

    // Error 500
    { path: "/auth-500", name: "Fivezerozero", component: <Fivezerozero /> },

    // Error 503
    { path: "/auth-503", name: "Fivezerothree", component: <Fivezerothree /> },

    // Offline Page
    { path: "/auth-offline", name: "OfflinePage", component: <OfflinePage /> },

    // Error 403
    { path: "/403", name: "ForbiddenError", component: <ForbiddenError /> },
    { path: "*", component: <Navigate to="/404" /> },
];

export { authProtectedRoutes, publicRoutes };