import React, { useState, useEffect, useRef } from 'react';
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";

const mapStyles = {
    width: '100%',
    // height: 'calc(100vh - 45vh)',
    borderRadius: '12px'
};

const LoadingContainer = () => <div>Loading...</div>;

const LocationMap = ({ google, latitude, longitude, name, address }: any) => {
    const [selectedPlace, setSelectedPlace] = useState<any>(null);
    const [activeMarker, setActiveMarker] = useState<any>(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [map, setMap] = useState<any>(null);
    const mapRef = useRef<any>(null);

    useEffect(() => {
        if (mapRef.current && latitude && longitude) {
            const map = mapRef.current.map;
            if (map) {
                const newLatLng = new google.maps.LatLng(latitude, longitude);
                map.panTo(newLatLng);
            }
        }
    }, [google, latitude, longitude]);

    const onMarkerClick = (props: any, marker: any, e: any) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };

    const onMapClick = () => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    };

    const onMapReady = (mapProps: any, map: any) => {
        mapRef.current = map;
    };

    return (
        <React.Fragment>
            <div id="gmaps-markers" className="gmaps" style={{ position: "relative" }}>
                <Map
                    google={google}
                    zoom={12} 
                    style={mapStyles}
                    initialCenter={{ lat: latitude || -34.9284989, lng: longitude || 138.6007456 }}
                    center={{ lat: latitude || -34.9284989, lng: longitude || 138.6007456 }}
                    onClick={onMapClick}
                    onReady={onMapReady}
                >
                    {(latitude && longitude) && (
                        <Marker
                            position={{ lat: latitude, lng: longitude }}
                            name={name || "Location"}
                            address={address || "Address"}
                            onClick={onMarkerClick}
                        />
                    )}
                    <InfoWindow
                        marker={activeMarker}
                        visible={showingInfoWindow}
                    >
                        <div>
                            <h2>{selectedPlace?.name}</h2>
                            <p>{selectedPlace?.address}</p>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        </React.Fragment>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyA35lst1bnWNgD14Eyx3ZFqhXe4qU44xts",
    LoadingContainer: LoadingContainer,
    v: "3",
})(LocationMap);