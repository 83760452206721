import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import SaliLogo from "assets/images/auth/sailogo.png"
// import ParticlesAuth from 'pages/AuthenticationInner/ParticlesAuth';
import SignupAuth from 'pages/AuthenticationInner/SignupAuth';

const SignUpOrganization = (props: any) => {


    return (
        <>
            <SignupAuth>
                <React.Fragment>
                    <div className="col-xxl-3 mx-auto signup-organization">
                        <Card className="card mb-0 border-0 shadow-none mb-0">
                            <Card.Body>
                                <div className='d-flex justify-content-center'>
                                    <img src={SaliLogo} />
                                </div>
                                <div className='logo-sign nav-link'>
                                    <h5 className="fs-3xl">Let’s Get Started</h5>
                                    <p className="text-muted">Are You Creating a New Organization or  are you joining an Existing Company Account? </p>
                                </div>
                                <a href='/organization-page' className='all-add'>
                                <div className='add-organization'>
                                    <Badge><i className="bi bi-plus"></i></Badge>
                                    <div>
                                        <h5>I’m a organization Owner</h5>
                                        <p>Create a New Organization</p>
                                    </div>
                                </div>
                                <i className="bi bi-chevron-right"></i>
                                </a>
                                <a href='/existing-organization' className='all-add'>
                                <div className='add-organization'>
                                    <Badge><i className="bi bi-arrow-right"></i></Badge>
                                    <div>
                                        <h5>I’m a Team Member</h5>
                                        <p>Create a New Organization</p>
                                    </div>
                                </div>
                                <i className="bi bi-chevron-right"></i>
                                </a>
                            </Card.Body>
                        </Card>
                    </div>
                </React.Fragment>
            </SignupAuth>
        </>
    )
}

export default SignUpOrganization;