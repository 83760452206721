import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Nav,
    Row,
    Tab,
    Dropdown,
    Image,
    Offcanvas,
    Table
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { addSubContractorMasterDetail, getSubContractorMasterDetail, archiveSubContractorMasterDetail, getBySubContractorMasterDetail, updateSubContractorMasterDetail } from "helpers/saibackend_helper";
import SubContractorTable from "./SubContractorTable";
import Flatpickr from "react-flatpickr";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usflag from "assets/images/flags/us.svg";
import SimpleBar from "simplebar-react";
import country from "Common/country";
import * as Yup from "yup";
import { useFormik } from "formik";

import avatar1 from 'assets/images/users/48/avatar-1.jpg'
import avatar2 from 'assets/images/users/48/avatar-2.jpg'
import avatar4 from 'assets/images/users/48/avatar-4.jpg'
import avatar5 from 'assets/images/users/48/avatar-5.jpg'
import avatar6 from 'assets/images/users/48/avatar-6.jpg'
import avatar7 from 'assets/images/users/48/avatar-7.jpg'
import avatar8 from 'assets/images/users/48/avatar-8.jpg'


interface RowData {
    id: number;
    file: File | null;
    showUploadButton: boolean;
}

interface DocumentData {
    id: number;
    file: File | null;
    showUploadButton: boolean;
}

interface FileEntry {
    id: number;
    file: File | null;
    showUploadButton: boolean;
}

interface SubContractorMasterData {
    id: number;
    name: string;
    abbreviation: string;
    abn: number,
    contact_person: string,
    contact_number: string,
    email: string,
    address: string,
    city: string,
    state: string,
    postal_code: number,
    country: string
}

// interface FormValues {
//     name: string;
//     abbreviation: string;
//     abn: number,
//     contact_person: string,
//     contact_number: string,
//     email: string,
//     address: string,
//     city: string,
//     state: string,
//     postal_code: number,
//     country: string
// }

const SubContractorMaster = (() => {

    const [SubContractorMasterDetail, setSubContractorMasterDetail] = useState<SubContractorMasterData[]>([])
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);
    const [totalRows, setTotalRows] = useState(0);
    const [payloadValue, setPayloadValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort_order: "DESC",
        is_archive: 0
    })

    const fetchSubContractorDetails = async () => {
        try {
            const response = await getSubContractorMasterDetail(payloadValue.page, payloadValue.page_size, payloadValue.search_term, payloadValue.sort_order, payloadValue.is_archive);
            setSubContractorMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        fetchSubContractorDetails();
    }, [payloadValue]);


    const handlePageChange = (page: number) => {
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };


    const validationSchema = Yup.object({
        name: Yup.string().required('Subcontractor Name is required'),
        // abbreviation: Yup.string().required('Abbreviation is required'),
        // abn: Yup.number().required('ABN is required').positive('ABN must be a positive number').integer('ABN must be an integer'),
        // contact_person: Yup.string().required('Contact Person Name is required'),
        // contact_number: Yup.number().required('Contact Number is required').positive('Contact Number must be a positive number').integer('Contact Number must be an integer'),
        // email: Yup.string().email('Invalid email format').required('Email is required'),
        // address: Yup.string().required('Address is required'),
        // city: Yup.string().required('City is required'),
        // state: Yup.string().required('State/Province is required'),
        // postal_code: Yup.number().required('Postal Code is required').positive('Postal Code must be a positive number').integer('Postal Code must be an integer'),
        // country: Yup.string().required('Country is required')
    });


    const formik = useFormik({
        initialValues: {
            name: "",
            abbreviation: "",
            abn: "",
            contact_person: "",
            contact_number: "",
            email: "",
            address: "",
            city: "",
            state: "",
            postal_code: "",
            country: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (isEditing && editId !== null) {
                    console.log(values);
                    await updateSubContractorMasterDetail(
                        editId,
                        values.name,
                        values.abbreviation,
                        values.abn,
                        values.contact_person,
                        values.contact_number,
                        values.email,
                        values.address,
                        values.city,
                        values.state,
                        values.postal_code,
                        values.country
                    );
                    toast.success("Sub Contractor Master Updated successfully!");
                }
                else {
                    console.log(values);
                    await addSubContractorMasterDetail(values);
                    toast.success("Sub Contractor Master Added successfully!");
                }
                fetchSubContractorDetails()
                togglesubconCanvas();
                resetForm();
            } catch (error) {
                console.error("Error Adding/Updating Compliance Master:", error);
                toast.error("Error Compliance Master. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });


    const archiveMaster = async (id: number) => {
        try {
            await archiveSubContractorMasterDetail(id);
            fetchSubContractorDetails()
            toast.success("Sub Contractor Master Archive successfully!");
        } catch (error) {
            console.error("Error Archive Sub Contractor Master:", error);
            toast.error("Error Archive Please try again later.");
        }
    };

    const editMaster = async (id: number) => {
        try {
            const response = await getBySubContractorMasterDetail(id);
            const data = response.data;
            formik.setValues({
                ...formik.values,
                name: data.name,
                abbreviation: data.abbreviation,
                abn: data.abn,
                contact_person: data.contact_person,
                contact_number: data.contact_number,
                email: data.email,
                address: data.address,
                city: data.city,
                state: data.state,
                postal_code: data.postal_code,
                country: data.country
            });
            setIsEditing(true);
            setEditId(id);
            togglesubconCanvas();
        } catch (error) {
            console.error("Error fetching Compliance Master details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };


    const [subcon, setsubcon] = useState(false);
    const togglesubconCanvas = () => {
        setsubcon(!subcon);
    };

    const [seletedCountry3, setseletedCountry3] = useState<any>({
        id: 240,
        flagImg: usflag,
        countryName: "United States of America",
        countryCode: "+1",
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: number) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setRows(rows.map(row =>
                row.id === rowId ? { ...row, file: selectedFile, showUploadButton: false } : row
            ));
        }
    };
    const handleRemoveFile = (rowId: number) => {
        setRows(rows.map(row =>
            row.id === rowId ? { ...row, file: null, showUploadButton: true } : row
        ));
    };


    const [rows, setRows] = useState<RowData[]>([{ id: 1, file: null, showUploadButton: true }]);

    const addRow = () => {
        setRows([...rows, { id: rows.length + 1, file: null, showUploadButton: true }]);
    };


    const deleteRow = (id: number) => {
        if (id !== 1) {
            const updatedRows = rows.filter((row) => row.id !== id);
            setRows(updatedRows);
        }
    };

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>, documentId: number) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setDocuments(documents.map(doc =>
                doc.id === documentId ? { ...doc, file: selectedFile, showUploadButton: false } : doc
            ));
        }
    };

    const handleRemoveDocument = (documentId: number) => {
        setDocuments(documents.map(doc =>
            doc.id === documentId ? { ...doc, file: null, showUploadButton: true } : doc
        ));
    };

    const [documents, setDocuments] = useState<DocumentData[]>([{ id: 1, file: null, showUploadButton: true }]);

    const addDocument = () => {
        setDocuments([...documents, { id: documents.length + 1, file: null, showUploadButton: true }]);
    };

    const deleteDocument = (id: number) => {
        if (id !== 1) {
            const updatedDocuments = documents.filter((doc) => doc.id !== id);
            setDocuments(updatedDocuments);
        }
    };

    const handleFileSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, entryId: number) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFileEntries(fileEntries.map(entry =>
                entry.id === entryId ? { ...entry, file: selectedFile, showUploadButton: false } : entry
            ));
        }
    };

    const removeSelectedFile = (entryId: number) => {
        setFileEntries(fileEntries.map(entry =>
            entry.id === entryId ? { ...entry, file: null, showUploadButton: true } : entry
        ));
    };

    const [fileEntries, setFileEntries] = useState<FileEntry[]>([{ id: 1, file: null, showUploadButton: true }]);

    const appendNewFileEntry = () => {
        setFileEntries([...fileEntries, { id: fileEntries.length + 1, file: null, showUploadButton: true }]);
    };

    const removeFileEntry = (id: number) => {
        if (id !== 1) {
            const updatedFileEntries = fileEntries.filter((entry) => entry.id !== id);
            setFileEntries(updatedFileEntries);
        }
    };

    const preferred = [
        {
            id: 21,
            first_name: "Liam",
            last_name: "Walker",
            avatar: avatar1,
        },
        {
            id: 22,
            first_name: "Noah",
            last_name: "Hall",
            avatar: avatar2,
        },
        {
            id: 23,
            first_name: "Elijah",
            last_name: "Allen",
            avatar: avatar4,
        },
        {
            id: 24,
            first_name: "Logan",
            last_name: "Young",
            avatar: avatar5,
        },
        {
            id: 25,
            first_name: "Lucas",
            last_name: "Hernandez",
            avatar: avatar6
        },
        {
            id: 26,
            first_name: "Mason",
            last_name: "King",
            avatar: avatar7
        },
        {
            id: 27,
            first_name: "Ethan",
            last_name: "Wright",
            avatar: avatar8
        },
        {
            id: 28,
            first_name: "Alexander",
            last_name: "Scott",
            avatar: avatar1,
        },
        {
            id: 29,
            first_name: "Henry",
            last_name: "Green",
            avatar: avatar2,
        },
        {
            id: 30,
            first_name: "Sebastian",
            last_name: "Baker",
            avatar: avatar4,
        }
    ]

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.setValues({
            name: '',
            abbreviation: '',
            abn: '',
            contact_person: '',
            contact_number: '',
            email: '',
            address: '',
            city: '',
            state: '',
            postal_code: '',
            country: ''
        });
        setsubcon(true);
    };


    return (
        <>
            <Row>
                <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                    <h4 className="card-title mb-2">Subcontractor Master</h4>
                    <div className="d-flex">
                        <Button
                            variant="primary"
                            className="add-company"
                            onClick={openAddForm}
                        >
                            <i className=" ri-add-fill"></i> Add
                        </Button>
                    </div>
                </Col>
                <Col lg="12">
                    <SubContractorTable data={SubContractorMasterDetail} archiveMaster={archiveMaster} editMaster={editMaster} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} />
                </Col>
            </Row>
            <Offcanvas
                className="add-role-canvas"
                show={subcon}
                onHide={togglesubconCanvas}
                placement="bottom"
                style={{ minHeight: "700px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Subcontractor Master
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <SimpleBar
                        style={{ maxHeight: "500px" }}
                        className="px-3"
                    >
                        <Offcanvas.Body>
                            <Row>
                                <Col lg="12">
                                    <Tab.Container defaultActiveKey="home1">
                                        <Nav as="ul" variant="tabs" className="nav-justified mb-3 ">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="home1">
                                                    Subcontractor Details
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="profile1">
                                                    {isEditing ? `Staff from ${formik.values.name}` : "Staff from"}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="messages1">
                                                    Subcontractor Compliance
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="text-muted">
                                            <Tab.Pane eventKey="home1">
                                                <Row>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Subcontractor Name</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Subcontractor Name"
                                                                    value={formik.values.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.touched.name && !!formik.errors.name}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-building-2-line"></i>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.name}
                                                                </Form.Control.Feedback>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Subcontractor Abbreviation</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="abbreviation"
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Subcontractor Abbreviation"
                                                                    value={formik.values.abbreviation}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                // isInvalid={!!formik.touched.abbreviation && !!formik.errors.abbreviation}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-edit-circle-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.abbreviation}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Company ABN</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="abn"
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Company ABN"
                                                                    value={formik.values.abn}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                // isInvalid={!!formik.touched.abn && !!formik.errors.abn}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-bank-card-2-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.abn}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="12">
                                                        <h4 className="card-title mb-2 mt-3">Contact Details</h4>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Contact Person Name</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="contact_person"
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Contact Person Name"
                                                                    value={formik.values.contact_person}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                // isInvalid={!!formik.touched.contact_person && !!formik.errors.contact_person}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-user-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.contact_person}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" className="mb-4">
                                                        <Form.Label>Contact Person Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="contact_number"
                                                            className="form-control-lg rounded-end flag-input"
                                                            placeholder="Enter Contact Number"
                                                            value={formik.values.contact_number}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        // isInvalid={!!formik.touched.contact_number && !!formik.errors.contact_number}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">
                                                            {formik.errors.contact_number}
                                                        </Form.Control.Feedback> */}

                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Contact Person Email</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="email"
                                                                    name="email"
                                                                    value={formik.values.email}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Contact Person Email"
                                                                // isInvalid={!!formik.touched.email && !!formik.errors.email}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-mail-open-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.email}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="12">
                                                        <h4 className="card-title mb-2 mt-3">Address Details</h4>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="address"
                                                                    value={formik.values.address}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Address"
                                                                // isInvalid={!!formik.touched.address && !!formik.errors.address}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-map-pin-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.address}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>City</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="city"
                                                                    value={formik.values.city}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter City"
                                                                // isInvalid={!!formik.touched.city && !!formik.errors.city}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-map-pin-user-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.city}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3 position-relative">
                                                            <Form.Label>State/Province</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Select
                                                                    name="state"
                                                                    value={formik.values.state}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    className="form-control-lg form-control-icon name-input"
                                                                // isInvalid={!!formik.touched.state && !!formik.errors.state}
                                                                >
                                                                    <option value="">Select State/Province</option>
                                                                    <option value="South Australia">South Australia</option>
                                                                    <option value="Victoria">Victoria</option>
                                                                </Form.Select>
                                                                <i style={{ fontSize: "16px" }} className="ri-map-pin-2-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid" className="position-absolute">
                                                                    {formik.errors.state}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Postal Code</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="postal_code"
                                                                    value={formik.values.postal_code}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    className="form-control-lg form-control-icon name-input"
                                                                    placeholder="Enter Postal Code"
                                                                // isInvalid={!!formik.touched.postal_code && !!formik.errors.postal_code}
                                                                />
                                                                <i style={{ fontSize: "16px" }} className="ri-mail-send-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.postal_code}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3 position-relative">
                                                            <Form.Label>Country</Form.Label>
                                                            <div className="position-relative form-icon">
                                                                <Form.Select
                                                                    name="country"
                                                                    value={formik.values.country}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    className="form-control-lg form-control-icon name-input"
                                                                // isInvalid={!!formik.touched.country && !!formik.errors.country}
                                                                >
                                                                    <option value="">Select Country</option>
                                                                    <option value="Australia">Australia</option>
                                                                    <option value="India">India</option>
                                                                </Form.Select>
                                                                <i style={{ fontSize: "16px" }} className="ri-earth-line"></i>
                                                                {/* <Form.Control.Feedback type="invalid" className="position-absolute">
                                                                    {formik.errors.country}
                                                                </Form.Control.Feedback> */}
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="profile1">
                                                <Row>
                                                    <Col lg="12">
                                                        <h5 className="mb-0 text-center">Total Staff : {preferred.length}</h5>
                                                    </Col>
                                                    <Col lg="12">
                                                        {/* <SimpleBar className="py-0" style={{ height: "290px" }}> */}
                                                        <div className="table-responsive mt-0">
                                                            <Table className="m-0">
                                                                <tbody>
                                                                    {(preferred || []).map((item: any, key: number) => (
                                                                        <tr key={key}>
                                                                            <td>
                                                                                <div className="d-flex align-items-center gap-1">
                                                                                    <div className="flex-shrink-0">
                                                                                        <img src={item.avatar} alt="" className="avatar-sm rounded-circle p-1" />
                                                                                    </div>
                                                                                    <div className="flex-grow-1">
                                                                                        <h6 className="fs-md mb-1"><Link to={"/apps-instructors-overview"} className="text-reset">{item.first_name} {item.last_name}</Link></h6>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        {/* </SimpleBar> */}
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="messages1">
                                                <Row>
                                                    <Col lg="12">
                                                        <h4 className="card-title mb-3">Master License</h4>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Form action="#">
                                                            {rows.map((row, index) => (
                                                                <Row className="add-doc" key={row.id}>
                                                                    <Col lg="2">
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>
                                                                                {"License #" + row.id}
                                                                            </Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="name"
                                                                                    className="form-control-lg form-control-icon name-input"
                                                                                    placeholder="Enter License Number"
                                                                                />
                                                                                <i
                                                                                    style={{ fontSize: "16px" }}
                                                                                    className=" ri-hashtag"
                                                                                ></i>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="3" className="upload-section-col">
                                                                        <Form.Group>
                                                                            <Form.Label className="doc-label">
                                                                                Upload License {index + 1}
                                                                            </Form.Label>
                                                                            <div className="uploadsection">
                                                                                {row.showUploadButton ? (
                                                                                    <div className="d-flex align-item-center">
                                                                                        <Form.Label
                                                                                            htmlFor={`file-${row.id}`}
                                                                                            className="uploadlabel"
                                                                                        >
                                                                                            <Form.Control
                                                                                                type="file"
                                                                                                className="form-control-lg"
                                                                                                style={{ display: "none" }}
                                                                                                id={`file-${row.id}`}
                                                                                                onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, row.id)}
                                                                                            />
                                                                                            <i className="bx bx-plus"></i>{" "}
                                                                                            Choose File
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                ) : (
                                                                                    <Col lg="12" className="closeuser py-0">
                                                                                        <p>{row.file?.name}</p>
                                                                                        <Button
                                                                                            onClick={() => handleRemoveFile(row.id)}
                                                                                            color="red"
                                                                                        >
                                                                                            X
                                                                                        </Button>
                                                                                    </Col>
                                                                                )}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <div className="mb-3 d-flex flex-column form-icon">
                                                                            <Form.Label htmlFor="JoiningdatInput">
                                                                                Date
                                                                            </Form.Label>
                                                                            <Flatpickr
                                                                                className="form-control-lg form-control-icon"
                                                                                options={{
                                                                                    dateFormat: "d M, Y",
                                                                                }}
                                                                                placeholder="Select date"
                                                                                defaultValue="30 Oct, 2023"
                                                                            />
                                                                            <i
                                                                                style={{ fontSize: "16px" }}
                                                                                className="ri-calendar-event-line mt-4"
                                                                            ></i>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>
                                                                                Reminder
                                                                            </Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="name"
                                                                                    className="form-control-lg form-control-icon name-input"
                                                                                    placeholder="No. of Days"
                                                                                />
                                                                                <i
                                                                                    style={{ fontSize: "16px" }}
                                                                                    className=" ri-calendar-line"
                                                                                ></i>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="1"
                                                                        className="document-adjust-div d-flex align-items-center gap-4 p-0"
                                                                    >
                                                                        <Form.Group>
                                                                            <div className="form-check mx-3">
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    name="criticalcheck"
                                                                                    className="mb-0"
                                                                                    id={`Criticalcheck${row.id}`}
                                                                                />
                                                                                <Form.Label
                                                                                    className="update-pay-rate-title mb-0"
                                                                                    htmlFor={`Criticalcheck${row.id}`}
                                                                                >
                                                                                    Critical
                                                                                </Form.Label>
                                                                            </div>
                                                                        </Form.Group>
                                                                        {rows.length > 1 &&
                                                                            <Button
                                                                                onClick={() => deleteRow(row.id)}
                                                                                className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                                            >
                                                                                <i
                                                                                    className="ph-trash"
                                                                                    style={{ fontSize: "15px" }}
                                                                                ></i>
                                                                            </Button>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row>
                                                                <Col lg={12} className="mb-3">
                                                                    <Button className="add-btn p-0" onClick={addRow}>
                                                                        <i className="ri-add-fill"></i> Add More
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg="12" className="mt-3">
                                                        <h4 className="card-title mb-2">Workers Comp and Public Liability</h4>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Form action="#">
                                                            {documents.map((doc, index) => (
                                                                <Row className="add-doc" key={doc.id}>
                                                                    <Col lg="2">
                                                                        <Form.Group className="mb-3 form-icon">
                                                                            <Form.Label>Document Type</Form.Label>
                                                                            <Form.Select
                                                                                className="form-select-lg form-control-icon"
                                                                                id="floatingSelect"
                                                                            >
                                                                                <option defaultValue="">Select</option>
                                                                                <option value="1">Workers Comp</option>
                                                                                <option value="2">Public Liability</option>
                                                                            </Form.Select>
                                                                            <i
                                                                                style={{ fontSize: "16px" }}
                                                                                className="ri-article-line mt-4"
                                                                            ></i>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="3" className="upload-section-col">
                                                                        <Form.Group>
                                                                            <Form.Label className="doc-label">Document {index + 1}</Form.Label>
                                                                            <div className="uploadsection">
                                                                                {doc.showUploadButton ? (
                                                                                    <div className="d-flex align-item-center">
                                                                                        <Form.Label htmlFor={`workerfile-${doc.id}`} className="uploadlabel">
                                                                                            <Form.Control
                                                                                                type="file"
                                                                                                className="form-control-lg"
                                                                                                style={{ display: "none" }}
                                                                                                id={`workerfile-${doc.id}`}
                                                                                                onChange={(e) => handleDocumentChange(e as React.ChangeEvent<HTMLInputElement>, doc.id)}
                                                                                            />
                                                                                            <i className="bx bx-plus"></i> Choose File
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                ) : (
                                                                                    <Col lg="12" className="closeuser py-0">
                                                                                        <p>{doc.file?.name}</p>
                                                                                        <Button onClick={() => handleRemoveDocument(doc.id)} color="red">
                                                                                            X
                                                                                        </Button>
                                                                                    </Col>
                                                                                )}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>Policy/Certificate Number</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="licenseNumber"
                                                                                    className="form-control-lg form-control-icon"
                                                                                    placeholder="Enter Policy/Certificate Number"
                                                                                />
                                                                                <i style={{ fontSize: "16px" }} className="ri-hashtag"></i>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <div className="mb-3 d-flex flex-column form-icon">
                                                                            <Form.Label htmlFor="JoiningdatInput">Expiry Date</Form.Label>
                                                                            <Flatpickr
                                                                                className="form-control-lg form-control-icon"
                                                                                options={{ dateFormat: "d M, Y" }}
                                                                                placeholder="Select date"
                                                                                defaultValue="30 Oct, 2023"
                                                                            />
                                                                            <i style={{ fontSize: "16px" }} className="ri-calendar-event-line mt-4"></i>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>Reminder</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="reminderDays"
                                                                                    className="form-control-lg form-control-icon"
                                                                                    placeholder="No. of Days"
                                                                                />
                                                                                <i style={{ fontSize: "16px" }} className="ri-calendar-line"></i>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="1" className="document-adjust-div d-flex align-items-center gap-4 p-0">
                                                                        <Form.Group>
                                                                            <div className="form-check mx-3">
                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    name="criticalCheck"
                                                                                    className="mb-0"
                                                                                    id={`CriticalCheck${doc.id}`}
                                                                                />
                                                                                <Form.Label className="update-pay-rate-title mb-0" htmlFor={`CriticalCheck${doc.id}`}>
                                                                                    Critical
                                                                                </Form.Label>
                                                                            </div>
                                                                        </Form.Group>
                                                                        {documents.length > 1 &&
                                                                            <Button onClick={() => deleteDocument(doc.id)} className="btn btn-subtle-danger btn-icon btn-md remove-item-btn">
                                                                                <i className="ph-trash" style={{ fontSize: "15px" }}></i>
                                                                            </Button>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row>
                                                                <Col lg={12} className="mb-3">
                                                                    <Button className="add-btn p-0" onClick={addDocument}>
                                                                        <i className="ri-add-fill"></i> Add More
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg="12" className="mt-3">
                                                        <h4 className="card-title mb-2">Documents</h4>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form action="#">
                                                            {fileEntries.map((entry, index) => (
                                                                <Row className="file-entry-row" key={entry.id}>
                                                                    <Col lg="2">
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{"Document" + entry.id}</Form.Label>
                                                                            <div className="position-relative form-icon">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="licenseNumber"
                                                                                    className="form-control-lg form-control-icon"
                                                                                    placeholder="Enter Document Number"
                                                                                />
                                                                                <i style={{ fontSize: "16px" }} className="ri-hashtag"></i>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="3" className="upload-section-col">
                                                                        <Form.Group>
                                                                            <Form.Label className="doc-label">Document {index + 1}</Form.Label>
                                                                            <div className="uploadsection">
                                                                                {entry.showUploadButton ? (
                                                                                    <div className="d-flex align-item-center">
                                                                                        <Form.Label htmlFor={`entryfile-${entry.id}`} className="uploadlabel">
                                                                                            <Form.Control
                                                                                                type="file"
                                                                                                className="form-control-lg"
                                                                                                style={{ display: "none" }}
                                                                                                id={`entryfile-${entry.id}`}
                                                                                                onChange={(e) => handleFileSelectionChange(e as React.ChangeEvent<HTMLInputElement>, entry.id)}
                                                                                            />
                                                                                            <i className="bx bx-plus"></i> Choose File
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                ) : (
                                                                                    <Col lg="12" className="closeuser py-0">
                                                                                        <p>{entry.file?.name}</p>
                                                                                        <Button onClick={() => removeSelectedFile(entry.id)} color="red">
                                                                                            X
                                                                                        </Button>
                                                                                    </Col>
                                                                                )}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg="1" className="document-adjust-div d-flex align-items-center mt-3 p-0">
                                                                        <Button onClick={() => removeFileEntry(entry.id)} className="btn btn-subtle-danger btn-icon btn-md remove-item-btn">
                                                                            <i className="ph-trash" style={{ fontSize: "15px" }}></i>
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row>
                                                                <Col lg={12} className="mb-3">
                                                                    <Button className="add-btn p-0" onClick={appendNewFileEntry}>
                                                                        <i className="ri-add-fill"></i> Add More
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg="12" className="mt-3">
                                                        <h4 className="card-title mb-2">Notes</h4>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Group className="mb-4">
                                                            <div className="position-relative form-icon">
                                                                <textarea
                                                                    className="form-control form-control-icon"
                                                                    rows={5}
                                                                    name="address"
                                                                    placeholder="Enter Notes..."
                                                                />
                                                                <i
                                                                    className="ri-file-copy-2-line d-flex align-items-start"
                                                                    style={{ marginTop: "0.5rem" }}
                                                                ></i>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </SimpleBar>
                    <Row>
                        <Col lg="12">
                            <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                <Row>
                                    <Col lg={12} className="off-bottom-staff justify-content-end">
                                        <div className="off-confirm">
                                            <Button color="primary" type="submit" disabled={formik.isSubmitting} className="next-btn">
                                                {isEditing ? "Update" : "Add"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas>
        </>
    )
})

export default SubContractorMaster;