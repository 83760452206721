import React, { useEffect, useState } from 'react';
import { Card, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from 'Common/withRouter';
import SaliLogo from "assets/images/auth/sailogo.png"
import googlestore from "assets/images/auth/playstore1.png"
import appstore from "assets/images/auth/appstore1.png"
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, resetLoginFlag } from "../../slices/thunk";
import { createSelector } from 'reselect';

const Signin = (props: any) => {
    const dispatch: any = useDispatch();

    const [userLogin, setUserLogin] = useState<any>([]);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });

    document.title = "Login | The Guard Duty";
    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col lg="3" className="order-sm-1 auth-form-card my-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body >
                            <div className='d-flex justify-content-center login-logo mb-5'>
                                <img src={SaliLogo} />
                            </div>
                            <div className="text-center mt-5">
                                <h5 className="fs-3xl">Welcome Back</h5>
                                <p className="text-muted">Log in To Continue</p>
                            </div>
                            <div className="p-2 mt-5">
                                {/* {error && error ? (<Alert variant="danger"> {error} </Alert>) : null} */}

                                <Form action="#" onSubmit={(e) => {
                                    e.preventDefault(); validation.handleSubmit();
                                    return false;
                                }}>

                                    <Form.Group className="mb-4" controlId="formUsername">
                                        <Form.Label>Email Address<span className="text-danger">*</span></Form.Label>
                                        <div className="position-relative">
                                            <Form.Control type="email" name="email" className="form-control password-input" placeholder="Enter your Email Address"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.email || ""}
                                                isInvalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                }
                                            />
                                        </div>
                                        {validation.touched.email && validation.errors.email ? (
                                            <Form.Control.Feedback type="invalid">{validation.errors.email}</Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formPassword">
                                        <div className="float-end">
                                            <Link to={process.env.PUBLIC_URL + "/forgot-password"} className="text-muted">Forgot password?</Link>
                                        </div>
                                        <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <Form.Control type={passwordShow ? "text" : "password"} className="form-control pe-5 password-input " placeholder="Enter password"
                                                name="password"
                                                value={validation.values.password || ""}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                isInvalid={
                                                    validation.touched.password && validation.errors.password ? true : false
                                                }
                                            />
                                            {validation.touched.password && validation.errors.password ? (
                                                <Form.Control.Feedback type="invalid">{validation.errors.password}</Form.Control.Feedback>
                                            ) : null}
                                            <Button variant="link" className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group controlId="formRememberMe">
                                        <Form.Check type="checkbox" label="Remember me" id="auth-remember-check" />
                                    </Form.Group> */}

                                    <div className="mt-4">
                                        {/* <Button className="btn btn-primary w-100" type="submit" disabled={loading}> {loading && <Spinner size='sm' />} {" "}Sign In</Button> */}
                                        <Button className="btn btn-primary w-100" type="submit"> Sign In</Button>
                                    </div>
                                    <div className="mt-4 pt-2 text-center">
                                        <div className="signin-other-title position-relative">
                                            <h5 className="fs-sm mb-4 title">For Employee Login</h5>
                                        </div>
                                        <div className='d-flex justify-content-center gap-3'>
                                            <Link target='_blank' to="https://play.google.com/store/apps/details?id=com.sai.adri">
                                                <img style={{ width: '150px' }} src={googlestore} />
                                            </Link>
                                            <Link target='_blank' to="https://apps.apple.com/app/sai-group/id6618147920">
                                                <img style={{ width: '150px' }} src={appstore} />
                                            </Link>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                    {/* <ToastContainer/> */}
                </Col>
            </React.Fragment>
        </ParticlesAuth>

    );
};

export default withRouter(Signin);