import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Nav,
    Row,
    Tab,
    Dropdown,
    Image,
    Offcanvas,
    Table,
    Modal,
    Spinner
} from "react-bootstrap";
import TemplateTable from "./TemplateTable";
import { useFormik, FormikProvider, FieldArray, FormikErrors } from 'formik';
import * as Yup from 'yup';
import SimpleBar from "simplebar-react";
import { addIncidentTemplateMasterDetail, getIncidentTemplateMasterDetail, getByIncidentTemplateMasterDetail, archiveInsidentTemplateMasterDetail, updateIncidentTemplateMasterDetail } from "helpers/saibackend_helper";
import TemplateData from "./TemplateData";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type RowData = {
    id: number;
    title: string;
    answerType: string;
    required: boolean;
    options: string[];
};

interface IncientTemplateMasterData {
    id: number;
    name: string;
}

const TemplateMaster = (() => {

    const [loading, setLoading] = useState(true);
    const [isBottom, setIsBottom] = useState(false);
    const toggleBottomCanvas = () => {
        setIsBottom(!isBottom);
    };

    const [totalRows, setTotalRows] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);
    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveCompliance = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const [IncidentMasterDetail, setIncidentMasterDetail] = useState<IncientTemplateMasterData[]>([])

    const [defaultValue, setDefaultValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    })

    const handlePageChange = (page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const fetchIncidentDetails = async () => {
        try {
            const response = await getIncidentTemplateMasterDetail(defaultValue.page, defaultValue.page_size, defaultValue.search_term, defaultValue.sort.sort_order, defaultValue.sort.sort_by, defaultValue.is_archive);
            setIncidentMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            return;

        }
    };


    useEffect(() => {
        fetchIncidentDetails();
    }, [defaultValue]);

    const addOption = (index: number) => {
        const updatedRows = formik.values.rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                return { ...row, options: [...row.options, ''] };
            }
            return row;
        });
        formik.setFieldValue('rows', updatedRows);
    };

    const formik = useFormik({
        initialValues: {
            formName: '',
            rows: [{ id: 1, title: '', answerType: '', required: false, options: [] }]
        },
        validationSchema: Yup.object({
            formName: Yup.string().required('Form name is required'),
            // .min(1, 'Form name must be at least 1 characters').max(20, 'Form name must be at most 20 characters'),
            rows: Yup.array().of(
                Yup.object().shape({
                    title: Yup.string()
                        .required('Title is required'),
                    // .min(1, 'Title must be at least 1 characters')
                    // .max(20, 'Title must be at most 20 characters'),
                    answerType: Yup.string().required('Answer type is required'),
                    required: Yup.boolean(),
                })
            )
        }),
        onSubmit: async (values) => {
            const formTemplateFields = values.rows.map((row, index) => ({
                field_name: row.title,
                field_type: row.answerType,
                is_required: row.required ? 1 : 0,
                field_order: index + 1,
                ...(row.answerType === "radio" && { field_options: { options: row.options } })
            }));

            const payload = {
                name: values.formName,
                form_template_fields: formTemplateFields
            };

            try {
                if (isEditing && editId !== null) {
                    const response = await updateIncidentTemplateMasterDetail(editId, payload.name, payload.form_template_fields);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                } else {
                    const response = await addIncidentTemplateMasterDetail(payload);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }
                fetchIncidentDetails();
                toggleBottomCanvas();
                formik.resetForm();
            } catch (error) {
                toast.error("Error adding template, please try again later.");
            }
        }
    });

    const editMaster = async (id: number) => {
        try {
            const response = await getByIncidentTemplateMasterDetail(id);
            const data = response.data;

            const mappedFields = data.fields.map((field: { field_name: any; field_type: any; is_required: number; field_options: { options: any; }; }, index: number) => ({
                id: index + 1,
                title: field.field_name,
                answerType: field.field_type,
                required: field.is_required === 1,
                options: field.field_options ? field.field_options.options : []
            }));
            formik.setValues({
                formName: data.name,
                rows: mappedFields
            });
            setIsEditing(true);
            setEditId(id);
            toggleBottomCanvas();
        } catch (error) {
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveInsidentTemplateMasterDetail(id);
            fetchIncidentDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setIsBottom(true);
    };

    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                            <h4 className="card-title mb-2">Incident Template Master</h4>
                            <div className="d-flex gap-2">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openAddForm}
                                >
                                    <i className=" ri-add-fill"></i> Add Template
                                </Button>
                            </div>
                        </Col>
                        <Col lg="12">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <TemplateTable data={IncidentMasterDetail} archiveMaster={archiveMaster} editMaster={editMaster} handlePageChange={handlePageChange} handleSort={handleSort} handlePerRowsChange={handlePerRowsChange} handleArchiveCompliance={handleArchiveCompliance} paginationTotalRows={totalRows} />
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={isBottom}
                onHide={toggleBottomCanvas}
                placement="bottom"
                style={{ minHeight: "800px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Template
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <SimpleBar
                            style={{ maxHeight: "625px" }}
                            className="px-3"
                        >
                            <Offcanvas.Body>
                                <Row>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Form name
                                            </Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    name="formName"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Form name"
                                                    value={formik.values.formName}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!(formik.touched.formName && formik.errors.formName)}
                                                />
                                                <i style={{ fontSize: "16px" }} className="ri-file-list-line"></i>
                                                {formik.touched.formName && formik.errors.formName ? (
                                                    <div className="text-danger">{formik.errors.formName}</div>
                                                ) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <FieldArray
                                        name="rows"
                                        render={arrayHelpers => (
                                            <>
                                                {formik.values.rows.map((row, index) => (
                                                    <Row key={row.id}>
                                                        <Col lg={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Title/Questions</Form.Label>
                                                                <div className="position-relative form-icon">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`rows[${index}].title`}
                                                                        value={row.title}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        className={`form-control-lg form-control-icon name-input ${formik.touched.rows && formik.errors.rows && typeof formik.errors.rows[index] === 'object' && 'title' in (formik.errors.rows[index] as FormikErrors<RowData>) ? 'is-invalid' : ''}`}
                                                                        placeholder="Enter Title/Questions"
                                                                    />
                                                                    <i style={{ fontSize: "16px" }} className="ri-clipboard-line"></i>
                                                                    {formik.touched.rows && formik.errors.rows && typeof formik.errors.rows[index] === 'object' && 'title' in (formik.errors.rows[index] as FormikErrors<RowData>) ? (
                                                                        <div className="text-danger">{(formik.errors.rows[index] as FormikErrors<RowData>).title}</div>
                                                                    ) : null}
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <Form.Group className="mb-3 form-icon form-select-icon">
                                                                        <Form.Label className="text-muted">
                                                                            Answer type
                                                                        </Form.Label>
                                                                        <Form.Select
                                                                            name={`rows[${index}].answerType`}
                                                                            value={row.answerType}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            // className="form-select-lg form-control-icon"
                                                                            className={`form-select-lg form-control-icon ${formik.touched.rows && formik.errors.rows && typeof formik.errors.rows[index] === 'object' && 'answerType' in (formik.errors.rows[index] as FormikErrors<RowData>) ? 'is-invalid' : ''}`}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            <option value="date">Date picker</option>
                                                                            <option value="location">Location</option>
                                                                            <option value="textarea">Description</option>
                                                                            <option value="number">Number</option>
                                                                            <option value="file">Photo/documents</option>
                                                                            <option value="signature">Signature</option>
                                                                            <option value="radio">Single Choice</option>
                                                                            <option value="text">Text</option>
                                                                            <option value="time">Time Picker</option>
                                                                        </Form.Select>
                                                                        <i className="ri-calendar-line"></i>
                                                                        {formik.touched.rows && formik.errors.rows && typeof formik.errors.rows[index] === 'object' && 'answerType' in (formik.errors.rows[index] as FormikErrors<RowData>) ? (
                                                                            <div className="text-danger">{(formik.errors.rows[index] as FormikErrors<RowData>).answerType}</div>
                                                                        ) : null}

                                                                    </Form.Group>
                                                                </Col>
                                                                {row.answerType === "radio" && (
                                                                    <Col lg={12}>
                                                                        {row.options.map((option, optionIndex) => (
                                                                            <Row key={optionIndex}>
                                                                                <Col lg={12}>
                                                                                    <Form.Group className="mb-3">
                                                                                        <Form.Label>Option {optionIndex + 1}</Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            name={`rows[${index}].options[${optionIndex}]`}
                                                                                            value={option}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                            className="form-control-lg form-control-icon name-input"
                                                                                            placeholder={`Enter option ${optionIndex + 1}`}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </Row>
                                                                        ))}
                                                                        <Button className="mb-3" variant="secondary" onClick={() => addOption(index)}>Add Option</Button>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                        <Col lg="1" className="d-flex justify-content-center">
                                                            <Form.Group className="mb-3 form-icon">
                                                                <Form.Label className="text-muted">
                                                                    Required
                                                                </Form.Label>
                                                                <div className="form-check form-switch form-switch-lg mt-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        name={`rows[${index}].required`}
                                                                        checked={row.required}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="1" className="mt-4">
                                                            <Button
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                                            >
                                                                <i
                                                                    className="ph-trash"
                                                                    style={{ fontSize: "15px" }}
                                                                ></i>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Col lg={12}>
                                                    <Button variant="primary" className="add-btn d-flex  p-0" onClick={() => arrayHelpers.push({ id: formik.values.rows.length + 1, title: '', answerType: '', required: false, options: [] })}><i className="ri-add-fill"></i> Add Fields</Button>
                                                </Col>
                                            </>
                                        )}
                                    />
                                </Row>
                            </Offcanvas.Body>
                        </SimpleBar>
                        <Row>
                            <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                    <Row>
                                        <Col lg={12} className="off-bottom-staff justify-content-end">
                                            <div className="off-confirm">
                                                <Button
                                                    onClick={toggleBottomCanvas}
                                                    color="primary"
                                                    className="clear-btn"
                                                >
                                                    Cancel
                                                </Button>
                                                {formik.isSubmitting ? (
                                                    <Button
                                                        color="primary"
                                                        className="next-btn"
                                                        disabled
                                                    >
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button color="primary" className="next-btn" type="submit" disabled={formik.isSubmitting}>
                                                        {isEditing ? "Update" : "Add"}
                                                    </Button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </FormikProvider>
            </Offcanvas >
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete Template.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default TemplateMaster;
