import React, { ChangeEvent, useMemo, useState } from "react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import { Form, Badge, Offcanvas, Button, Row, Col } from "react-bootstrap";
import ellipse from "assets/images/auth/blank.png";
import { getByTimeOffDetail } from "helpers/saibackend_helper";
import SimpleBar from "simplebar-react";
import profile from "assets/images/auth/profile.png";
import Flatpickr from "react-flatpickr";

const PatrolLocationTable = ({
    data,
    handleSortChange,
    currentPage,
    totalPages,
    onPageChange,
    editMaster,
    handleArchivePetrolLocation
}: {
    data: any;
    handleSortChange: (column: string, direction: string) => void;
    currentPage: number,
    totalPages: number,
    onPageChange: (page: number) => void,
    handleArchivePetrolLocation: (id: number) => Promise<void>;
    editMaster: (id: number) => Promise<void>;
}) => {

    const columns = useMemo(
        () => [
            {
                header: "Region Name",
                accessorKey: "region_name",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Total Locations",
                accessorKey: "no_of_locations",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Action",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const id = cellProps.row.original.id;

                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <i onClick={() => editMaster(id)} className="ri-edit-2-line link-success" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            </div>
                            <div className="edit">
                                <i onClick={() => handleArchivePetrolLocation(id)} className="ri-delete-bin-line link-danger" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {data.length > 0 ? (
                <TableContainer
                    isPagination={true}
                    columns={columns || []}
                    data={data}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
            ) : (
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Patrol Location Available</h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default PatrolLocationTable;
