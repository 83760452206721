import React, { useState, useEffect } from "react";
import {
    Button,
    Col,
    Form,
    Row,
    Offcanvas,
    Modal,
    Nav,
    Tab,
    Spinner
} from "react-bootstrap";
import { useFormik } from 'formik';
import SimpleBar from "simplebar-react";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BillingCategoriesTable from "./BillingCategoriesTable";
import { getBillingCategoriesList, addBillingCategoriesDetail, GetByBillingCategoriesList, updateBillingCategoriesDetail, archiveBillingCategoriesList } from "helpers/saibackend_helper";

const BillingCategories = () => {

    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const [PayCategoriesDetail, setPayCategoriesDetail] = useState([])
    const [totalRows, setTotalRows] = useState(0);

    const [defaultValue, setDefaultValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    });

    const [loading, setLoading] = useState(true);

    const fetchPayCategoriesDetails = async () => {
        try {
            const response = await getBillingCategoriesList(
                defaultValue.page,
                defaultValue.page_size,
                defaultValue.search_term,
                defaultValue.sort.sort_order,
                defaultValue.sort.sort_by,
                defaultValue.is_archive
            );
            setPayCategoriesDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPayCategoriesDetails();
    }, [defaultValue]);

    const handlePageChange = (page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setDefaultValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setDefaultValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setDefaultValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    });


    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEditing && editId !== null) {
                    const updateValue = {
                        ...values,
                        billing_category_id: editId
                    }
                    const response = await updateBillingCategoriesDetail(updateValue);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                } else {
                    const response = await addBillingCategoriesDetail(values);
                    if (response.status === 1) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                        return;
                    }
                }
                fetchPayCategoriesDetails()
                setIsEditing(false);
                setmodal_standard(false);
                setEditId(null);
                formik.resetForm();
            } catch (error) {
                console.error("Error Adding/Updating Pay Schedule:", error);
                toast.error("Error Pay Schedule. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const editMaster = async (id: number) => {
        try {
            const response = await GetByBillingCategoriesList(id);
            const data = response.data;
            formik.setValues({
                name: data.name,
            });
            setIsEditing(true);
            setmodal_standard(true)
            setEditId(id);
        } catch (error) {
            console.error("Error fetching Pay Categories details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveCategories = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveBillingCategoriesList(id);
            fetchPayCategoriesDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            console.error("Error Archive Pay Categories:", error);
            toast.error("Error Archive Please try again later.");
        }
    };


    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        formik.resetForm()
        setmodal_standard(true);
    };


    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg={12}>
                            <div className="d-flex justify-content-end">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openAddForm}
                                >
                                    <i className=" ri-add-fill"></i> Add
                                </Button>
                            </div>
                        </Col>
                        <Col lg="12" className="mt-3">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <BillingCategoriesTable editMaster={editMaster} data={PayCategoriesDetail} handleArchiveCategories={handleArchiveCategories} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} handleSort={handleSort} />
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Modal id="myModal" show={modal_standard} onHide={() => { tog_standard(); }} >
                <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                    {isEditing ? "Edit" : "Add"} Billing Categories
                </Modal.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.name && formik.errors.name)}
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-edit-2-line"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.name}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button variant="light" onClick={() => tog_standard()}>Close</Button>
                        {formik.isSubmitting ? (
                            <Button
                                color="primary"
                                className="next-btn"
                                disabled
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                className="add-company"
                                color="primary"
                            >
                                {isEditing ? "Update" : "Add"}
                            </Button>
                        )}
                    </div>
                </Form>
            </Modal>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete this Billing Categories.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default BillingCategories;