import React, { Fragment, useEffect, useState } from "react";
import { Row, Table, Button, Pagination, Form } from "react-bootstrap";
import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

// Column Filter
const Filter = ({ column, table }: { column: Column<any, unknown>; table: ReactTable<any>; }) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
};

interface TableContainerProps {
  columns?: any;
  data?: any;
  divClassName?: any;
  tableClass?: any;
  theadClass?: any;
  isBordered?: boolean;
  customPageSize?: number;
  isGlobalFilter?: boolean;
  isPagination: boolean;
  PaginationClassName?: string;
  SearchPlaceholder?: string;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  onSortChange: (column: string, direction: string) => void;
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  customPageSize,
  isGlobalFilter,
  PaginationClassName,
  SearchPlaceholder,
  currentPage,
  totalPages,
  onPageChange,
  isPagination,
  onSortChange,
}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({ itemRank });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: { fuzzy: fuzzyFilter },
    state: { columnFilters, globalFilter },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { getHeaderGroups, getRowModel, setPageSize, getState } = table;

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);

  const getPaginationRange = () => {
    const totalDisplayedPages = 5;
    const halfDisplayedPages = Math.floor(totalDisplayedPages / 2);
    let startPage = Math.max(currentPage - halfDisplayedPages, 1);
    let endPage = Math.min(startPage + totalDisplayedPages - 1, totalPages);

    if (endPage - startPage + 1 < totalDisplayedPages) {
      startPage = Math.max(endPage - totalDisplayedPages + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  return (
    <Fragment>
      {isGlobalFilter && (
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder={SearchPlaceholder}
        />
      )}
      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const handleHeaderClick = (event: React.MouseEvent) => {
                    if (header.column.getCanSort()) {
                      const direction = header.column.getIsSorted() === "asc" ? "desc" : "asc";
                      onSortChange(header.column.id, direction);
                    } else {
                      event.stopPropagation();
                    }
                  };

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.getCanSort() ? "cursor-pointer select-none sort" : ""}
                      onClick={handleHeaderClick}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() &&
                            ({ asc: " ▲", desc: " ▼" }[header.column.getIsSorted() as string] ?? null)}
                          {header.column.getCanFilter() && (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {isPagination && (
        <Row className={PaginationClassName}>
          <div className="col-sm">
            <div className="text-muted">
              Showing <span className="fw-semibold">{currentPage}</span> of <span className="fw-semibold">{totalPages}</span> Pages
            </div>
          </div>
          <div className="col-sm-auto mt-3 mt-sm-0">
            <div className="pagination-wrap hstack gap-2 justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => onPageChange(1)} />
                <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
                {getPaginationRange().map((page) => (
                  <Pagination.Item key={page} active={page === currentPage} onClick={() => onPageChange(page)}>
                    {page}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => onPageChange(totalPages)} />
              </Pagination>
            </div>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

export default TableContainer;
