import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  ButtonGroup,
  Modal,
  ListGroup,
  Badge,
  Nav,
  Tab,
  Table,
  Spinner,
  Accordion
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { DayCellContentArg, EventContentArg } from '@fullcalendar/common';
import Select from "react-select";
import { getScheduleClientList, getScheduleLocationList, ShiftTemplateArchiveById, getByStaffDetail, getStaffDocumentDetail, ShiftHistoryList, saveShiftTemplate, loadShiftTemplate, submitShiftTemplate, getScheduleStaffList, exportPatrolLocationInfoDetail, getScheduleList, createScheduleShift, updateScheduleShift, getShiftDetail, ExportShift, getSchedulePublishDetail, publishShifts, archiveShiftDetail } from "helpers/saibackend_helper";
import moment from 'moment';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.in";
import noDataImg from "assets/images/no-data.png"

interface DropdownData {
  value: number;
  label: string;
}
interface PublishDateItem {
  date: string;
  dayName: string;
}
interface PublishStaffItem {
  staff_id: string;
  staff_name: string;
}
const SchedulePage = () => {
  document.title = "Scheduling | The Guard Duty";

  const getInitials = (name: string): string => {
    const names = name.split(" ");
    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial =
      names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : "";
    return `${firstNameInitial}${lastNameInitial}`;
  };

  const renderDayCellContent = (info: DayCellContentArg) => {
    return (
      <div className="fc-daygrid-day-events">
        <div className="fc-daygrid-day-plus-icon">
          <button
            onClick={() => createShiftShow(moment(info.date).format('DD-MM-YYYY'))}
          >
            <i className="ri-add-circle-line"></i>
          </button>
        </div>
      </div>
    );
  };

  const [isShiftCanvasOpen, setIsShiftCanvasOpen] = useState<boolean>(false);
  const createShiftShow = (date: any) => {
    if (selectedLocation) {
      formik.resetForm();
      setSelectedStaffName("");
      setEditId(null);
      setActiveTab('home1')
      setIsFinishShift(false);
      setShiftHours(9);
      formik.setFieldValue("date", date);
      formik.setFieldValue("client_id", selectedClient?.value);
      formik.setFieldValue("location_id", selectedLocation?.value);
      setIsShiftCanvasOpen(true);
      setShiftClockIn('')
      setShiftClockOut('')
    } else {
      alert('Please select a location before creating a shift.');
    }
  };
  /*---------------GET FILTER DATA START---------------------------------*/
  const calendarRef = useRef<FullCalendar | null>(null);
  const [editId, setEditId] = useState<number | null>(null);
  const [isFinishShift, setIsFinishShift] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<DropdownData | null>(null);
  const [selectedStaffName, setSelectedStaffName] = useState<string>("");
  const [shiftHours, setShiftHours] = useState<number>(9);
  const [clientSearch, setClientSearch] = useState<string>("");
  const [clientList, setClientList] = useState<DropdownData[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<DropdownData | null>(null);
  const [locationSearch, setLocationSearch] = useState<string>("");
  const [locationList, setLocationList] = useState<DropdownData[]>([]);
  const [staffList, setStaffList] = useState<DropdownData[]>([]);
  const [eventList, setEventList] = useState<any[]>([]);
  const [totalShifts, setTotalShifts] = useState<number>(0);
  const [totalPublishShifts, setTotalPublishShifts] = useState<number>(0);
  const [totalUnpublishShifts, setTotalUnpublishShifts] = useState<number>(0);
  const [totalUnassignShifts, setTotalUnassignShifts] = useState<number>(0);
  const [totalArchivedShifts, setTotalArchivedShifts] = useState<number>(0);
  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [Is_petrol_site, setIs_petrol_site] = useState<number>(0);
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [Created_by, setCreatedBy] = useState<string>("")

  const handleArchiveShift = async (id: number): Promise<void> => {
    setArchiveId(id);
    setArchiveModal(true);
  };

  const archiveShift = async (id: number) => {
    try {
      const response = await archiveShiftDetail(id);
      fetchEventsFromAPI();
      toast.success(response.message);
      setArchiveModal(false);
      setIsShiftCanvasOpen(false);
    } catch (error) {
      console.error("Error Archive Holday:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const fetchClientList = async (searchValue: string) => {
    try {
      const response = await getScheduleClientList(searchValue);
      const clients = response.data.map((client: any) => ({
        value: client.value,
        label: client.label,
      }));
      setClientList(clients);

      const storedClient = localStorage.getItem('selectedClient');

      if (clients.length > 0) {
        if (storedClient) {
          setSelectedClient(JSON.parse(storedClient));
        } else {
          setSelectedClient(clients[0]);
          localStorage.setItem('selectedClient', JSON.stringify(clients[0]));
        }
      } else {
        setSelectedClient(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLocationList = async (client: any, searchValue: string) => {
    try {
      const response = await getScheduleLocationList(client.value, searchValue);
      const locations = response.data;
      setLocationList(locations);

      const storedLocation = localStorage.getItem('selectedLocation');

      if (locations.length > 0) {
        if (storedLocation) {
          setSelectedLocation(JSON.parse(storedLocation));
        } else {
          setSelectedLocation(locations[0]);
          localStorage.setItem('selectedLocation', JSON.stringify(locations[0]));
        }
      } else {
        setSelectedLocation(null);
      }
    } catch (error) {
      console.log(error);

    }
  };
  const fetchStaffList = async (location: any, searchValue: string) => {
    try {
      const response = await getScheduleStaffList(location.value, searchValue);
      setStaffList(response.data);
    } catch (error) {
      console.log(error);

    }
  };
  const replaceEvents = (newEvents: any) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.getEvents().forEach(event => event.remove()); // Remove all existing events
      calendarApi.addEventSource(newEvents); // Add new events
      setEventList(newEvents); // Update the state with new events
    }
  };

  const handleDatesSet = () => {
    fetchEventsFromAPI();
  };

  const handleEventDrop = async (arg: any) => {
    if (arg.event.extendedProps.class_name === "finished" || arg.event.extendedProps.class_name === "archived" || arg.event.extendedProps.status != 1 || arg.event.extendedProps.status_name === "CLOCKED IN") {
      // If the event is not draggable, revert to the original position
      arg.revert();
    } else {
      const event: any = arg.event;
      const newStart = arg.event.start;
      // const newEnd = arg.event.end;

      try {
        const postdata = {
          id: event.id,
          date: moment(newStart).format("YYYY-MM-DD"),
          // start_time: moment(newStart).format("HH:mm"),
          // end_time: moment(newEnd).format("HH:mm"),
        };
        const response = await updateScheduleShift(postdata);
        if (response.status === 1) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
          arg.revert();
          return;
        }
      } catch (error) {
        console.error("Error updating event:", error);
      }
    }
  };

  const [ShiftHistoryDetails, setShiftHistoryDetails] = useState<any[]>([]);
  const [location_id, setLocation_id] = useState<number>(0)
  const [shiftClockIn, setShiftClockIn] = useState<any>("")
  const [shiftClockOut, setShiftClockOut] = useState<any>("")
  const [isAccept, setIsAccept] = useState<any>("")

  const handleEventClick = async (arg: any) => {
    const event = arg.event;
    try {
      const response = await getShiftDetail(event.id);
      const history = await ShiftHistoryList(event.id)
      const historyData = history?.data ? history.data : null
      setShiftHistoryDetails(historyData)
      if (response.status === 1) {
        const event_data = response.data;
        setIs_petrol_site(event_data?.is_petrol_site)
        setSelectedStaffName(event.extendedProps.staff_name);
        setCreatedBy(event_data?.created_by)
        setEditId(event.id);
        setLocation_id(event_data.location_id)
        editMaster(event_data.staff_id)
        setShiftClockIn(event_data.clock_in_time)
        setShiftClockOut(event_data.clock_out_time)
        fetchStaffDocumentDetails(event_data.staff_id)
        setIsAccept(event_data.is_accept)
        setIsShiftCanvasOpen(true);
        const formattedStartTime = moment(event_data.start_time).format("HH:mm");
        const formattedEndTime = moment(event_data.end_time).format("HH:mm");
        const formattedDate = moment(event_data.date).format("DD-MM-YYYY");
        const formattedClockinTime = event_data.clock_in_time ? moment(event_data.clock_in_time).format("HH:mm") : "";
        const formattedClockoutTime = event_data.clock_out_time ? moment(event_data.clock_out_time).format("HH:mm") : "";

        // Calculate shift hours
        const startTime = moment(event_data.start_time, "YYYY-MM-DDTHH:mm:ss"); // Adjust format as needed
        const endTime = moment(event_data.end_time, "YYYY-MM-DDTHH:mm:ss"); // Adjust format as needed
        const duration = moment.duration(endTime.diff(startTime));
        const hours = parseFloat(duration.asHours().toFixed(2)); // Get total hours as decimal
        setShiftHours(hours);
        formik.setValues({
          ...formik.values,
          date: formattedDate,
          staff_id: event_data.staff_id,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          clock_in_time: formattedClockinTime,
          clock_out_time: formattedClockoutTime,
          total_unpaid_break: event_data.total_unpaid_break,
          total_paid_break: event_data.total_paid_break,
          shift_notes: event_data.shift_notes,
          admin_note: event_data.admin_note,
          is_finished: event_data.is_finished,
          client_id: selectedClient?.value,
          location_id: selectedLocation?.value,
          is_published: event_data.is_published
        });
        setIsFinishShift((event_data.is_finished || event_data.is_archive) ? true : false);
      } else {
        toast.error(response.message);
        return;
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };

  const fetchEventsFromAPI = async () => {
    try {
      const calendarApi = calendarRef.current?.getApi();
      const client: any = selectedClient?.value;
      const location: any = selectedLocation?.value;
      if (calendarApi) {
        const view = calendarApi.view;
        const start = view.activeStart;
        const end = view.activeEnd;

        const formattedStart = moment(start).format("YYYY-MM-DD");
        const formattedEnd = moment(end).subtract(1, 'day').format("YYYY-MM-DD");

        const response = await getScheduleList(client, location, formattedStart, formattedEnd);
        replaceEvents(response.data?.data_list);
        setTotalShifts(response.data?.total_shift);
        setTotalPublishShifts(response.data?.total_published_shift);
        setTotalUnpublishShifts(response.data?.total_unpublished_shift);
        setTotalUnassignShifts(response.data?.total_unassigned_shift);
        setTotalArchivedShifts(response.data?.total_archived_shift)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportExcel = async () => {
    try {
      const calendarApi = calendarRef.current?.getApi();
      const client: any = selectedClient?.value;
      const location: any = selectedLocation?.value;
      if (calendarApi) {
        const view = calendarApi.view;
        const start = view.activeStart;
        const end = view.activeEnd;

        const formattedStart = moment(start).format("YYYY-MM-DD");
        const formattedEnd = moment(end).subtract(1, 'day').format("YYYY-MM-DD");

        await ExportShift(client, location, formattedStart, formattedEnd);
      } else {
        console.error("Calendar API is not available.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClientList(clientSearch);
  }, []);
  useEffect(() => {
    fetchLocationList(selectedClient, locationSearch);
  }, [selectedClient]);
  useEffect(() => {
    fetchStaffList(selectedLocation, locationSearch);
  }, [selectedLocation]);
  useEffect(() => {
    fetchEventsFromAPI();
  }, [selectedClient, selectedLocation]);

  /*---------------GET FILTER DATA END-----------------------------------*/

  /*---------------------------MODAL HANDLER START---------------------------------*/
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const [publishData, setPublishData] = useState<{ dates: PublishDateItem[], staff_list: PublishStaffItem[] }>({ dates: [], staff_list: [] });

  const [isDateAllChecked, setIsDateAllChecked] = useState(false);
  const [checkedPublishDates, setCheckedPublishDates] = useState<string[]>([]);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsDateAllChecked(checkedPublishDates.length === publishData.dates.length);
  }, [checkedPublishDates, publishData.dates.length]);

  // Handle "Select All" and "Deselect All"
  const handleDateCheckAll = () => {
    if (!isDateAllChecked) {
      setCheckedPublishDates(publishData.dates.map(dateItem => dateItem.date));
    } else {
      setCheckedPublishDates([]);
    }
    setIsDateAllChecked(!isDateAllChecked);
  };

  // Handle individual checkbox changes
  const handleDateCheckboxChange = (date: string) => {
    setCheckedPublishDates(prev =>
      prev.includes(date)
        ? prev.filter(item => item !== date)
        : [...prev, date]
    );
  };

  const [isStaffAllChecked, setIsStaffAllChecked] = useState(false);
  const [checkedPublishStaff, setCheckedPublishStaff] = useState<string[]>([]);

  // Update isChecked state based on the checkedItems array
  useEffect(() => {
    setIsStaffAllChecked(checkedPublishStaff.length === publishData.staff_list.length);
  }, [checkedPublishStaff, publishData.staff_list.length]);

  // Handle "Select All" and "Deselect All"
  const handleStaffCheckAll = () => {
    if (!isStaffAllChecked) {
      setCheckedPublishStaff(publishData.staff_list.map(staffItem => staffItem.staff_id));
    } else {
      setCheckedPublishStaff([]);
    }
    setIsStaffAllChecked(!isStaffAllChecked);
  };

  // Handle individual checkbox changes
  const handleStaffCheckboxChange = (staff_id: string) => {
    setCheckedPublishStaff(prev =>
      prev.includes(staff_id)
        ? prev.filter(item => item !== staff_id)
        : [...prev, staff_id]
    );
  };

  const [expLoader, setExpLoader] = useState(false)

  const getPublishDetail = async () => {
    setExpLoader(true)
    try {
      const calendarApi = calendarRef.current?.getApi();
      const client: any = selectedClient?.value;
      const location: any = selectedLocation?.value;
      if (calendarApi) {
        const view = calendarApi.view;
        const start = view.activeStart;
        const end = view.activeEnd;

        const formattedStart = moment(start).format("YYYY-MM-DD");
        const formattedEnd = moment(end).subtract(1, 'day').format("YYYY-MM-DD");

        const payload = {
          client_id: client,
          location_id: location,
          start_date: formattedStart,
          end_date: formattedEnd,
        }
        const response = await getSchedulePublishDetail(payload);
        if (response.status === 1) {
          setPublishData(response.data);
          const res_date: PublishDateItem[] = response.data.dates;
          const res_staff: PublishStaffItem[] = response.data.staff_list;
          setCheckedPublishDates(res_date.map(dateItem => dateItem.date));
          setCheckedPublishStaff(res_staff.map(staff => staff.staff_id));
          setPublishModal(true);
        } else {
          toast.error(response.message);
        }
      } else {
        console.error("Calendar API is not available.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setExpLoader(false)
    }
  };

  const [publishLoader, setPublishLoader] = useState(false)

  const handlePublishShifts = async () => {
    setPublishLoader(true)
    try {
      const client: any = selectedClient?.value;
      const location: any = selectedLocation?.value;

      const payload = {
        client_id: client,
        location_id: location,
        dates: checkedPublishDates,
        staff_ids: checkedPublishStaff,
      }
      const response = await publishShifts(payload);
      if (response.status === 1) {
        toast.success(response.message);
        setPublishModal(false);
        fetchEventsFromAPI();
        setPublishLoader(false)
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  /*---------------------------MODAL HANDLER END-------------------------------- */

  /*Shift Template Start */
  const templateValidationSchema = Yup.object().shape({
    template_name: Yup.string().required("Please enter a template name"),
  });

  const [template_id, setTemplateId] = useState<number>(0);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openLoadTemplateModal, setOpenLoadTemplateModal] = useState<boolean>(false);
  const [savingTemplateShifts, setSavingTemplateShifts] = useState<boolean>(false);
  const [submitTemplateResponse, setSubmitTemplateResponse] = useState<any>(null);
  const [templateData, setTemplateData] = useState<any>([]);
  const templateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      'template_name': '',
      'description': '',
      'is_shift_notes': 0,
      'is_admin_notes': 0,
      'is_remove_staff': 0
    },
    validationSchema: templateValidationSchema,
    onSubmit: async (values) => {
      try {
        const calendarApi = calendarRef.current?.getApi();
        const client: any = selectedClient?.value;
        if (calendarApi) {
          const view = calendarApi.view;
          const start = view.activeStart;
          const end = view.activeEnd;

          const formattedStart = moment(start).format("YYYY-MM-DD");
          const formattedEnd = moment(end).subtract(1, 'day').format("YYYY-MM-DD");

          const payload = {
            ...values,
            client_id: client,
            start_date: formattedStart,
            end_date: formattedEnd,
          }
          const response = await saveShiftTemplate(payload);
          if (response.status === 1) {
            toast.success(response.message);
            setOpenTemplateModal(false);
          } else {
            toast.error(response.message);
          }
        } else {
          console.error("Calendar API is not available.");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  });

  const loadShiftTemplateFunc = async () => {
    try {
      const client: any = selectedClient?.value;
      const payload = {
        client_id: client
      }
      const response = await loadShiftTemplate(payload);
      if (response.status === 1) {
        setTemplateData(response.data.data_list)
        if (response.data.data_list.length > 0 && response.data.data_list[0].id) {
          setTemplateId(response.data.data_list[0].id);
        }
        setOpenLoadTemplateModal(true);
        setSubmitTemplateResponse(null);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  }

  const submitShiftTemplateFunc = async () => {
    try {
      setSavingTemplateShifts(true);
      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        const view = calendarApi.view;
        const start = view.activeStart;
        const end = view.activeEnd;

        const formattedStart = moment(start).format("YYYY-MM-DD");
        const formattedEnd = moment(end).subtract(1, 'day').format("YYYY-MM-DD");

        const payload = {
          template_id,
          start_date: formattedStart,
          end_date: formattedEnd,
        }
        const response = await submitShiftTemplate(payload);
        if (response.status === 1) {
          toast.success(response.message);
          fetchEventsFromAPI();
          setSubmitTemplateResponse(response.data);
          // setOpenLoadTemplateModal(false);
          setSavingTemplateShifts(false);
        } else {
          setSavingTemplateShifts(false);
          toast.error(response.message);
        }
      } else {
        setSavingTemplateShifts(false);
        console.error("Calendar API is not available.");
      }
    } catch (error) {
      console.log(error);
      setSavingTemplateShifts(false);
      toast.error("Something went wrong");
    }
  }
  /*Shift Template End */

  /*------------------------------Formik Start--------------------------------*/
  const [initialValues, setInitialValues] = useState({
    date: "",
    staff_id: 0,
    start_time: "09:00",
    end_time: "18:00",
    clock_in_time: "",
    clock_out_time: "",
    total_unpaid_break: "",
    total_paid_break: "",
    shift_notes: "",
    admin_note: "",
    is_finished: 0,
    client_id: selectedClient?.value,
    location_id: selectedLocation?.value,
    shift_id: 0,
    is_published: 0
  });

  const shiftValidationSchema = Yup.object().shape({
    date: Yup.string().required("Please select a date"),
    start_time: Yup.string()
      .required("Start time is required"),
    end_time: Yup.string()
      .required("Finish time is required"),
    total_unpaid_break: Yup.number()
      .positive("Unpaid break must be a positive number")
      .integer("Unpaid break must be an integer").nullable(),
    total_paid_break: Yup.number()
      .positive("Paid break must be a positive number")
      .integer("Paid break must be an integer").nullable(),
    shift_notes: Yup.string().nullable(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: shiftValidationSchema,
    onSubmit: async (values) => {
      const formattedStartTime = moment(values.start_time, "HH:mm").format("HH:mm");
      const formattedEndTime = moment(values.end_time, "HH:mm").format("HH:mm");
      const formattedClockinTime = values.clock_in_time ? moment(values.clock_in_time, "HH:mm").format("HH:mm") : "";
      const formattedClockoutTime = values.clock_out_time ? moment(values.clock_out_time, "HH:mm").format("HH:mm") : "";

      const postdata = {
        ...values,
        shift_id: editId,
        staff_id: values.staff_id ?? 0,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        clock_in_time: formattedClockinTime,
        clock_out_time: formattedClockoutTime,
        is_accept: editId ? isAccept : undefined
      };
      const response = await createScheduleShift(postdata);
      if (response.status === 1) {
        toast.success(response.message);
        fetchEventsFromAPI();
        formik.resetForm();
        setIsShiftCanvasOpen(false);
      } else {
        toast.error(response.message);
        return;
      }
    }
  });

  const calculateShiftHours = (startTime: string, endTime: string, unpaidBreak: number): number => {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    const duration = moment.duration(end.diff(start));
    const totalHours = parseFloat(duration.asHours().toFixed(2));

    // Deduct unpaid break time (convert minutes to hours)
    const unpaidBreakHours = unpaidBreak / 60;
    const netHours = totalHours - unpaidBreakHours;

    return parseFloat(netHours.toFixed(2));
  };

  const handleTimeChange = (time: string, field: string) => {
    formik.setFieldValue(field, time);
    if (field === "start_time" || field === "end_time") {
      const formattedTime = moment(time, "HH:mm").format("HH:mm");
      const startTime = (field === "start_time") ? formattedTime : formik.values.start_time;
      const endTime = (field === "end_time") ? formattedTime : formik.values.end_time;

      if (startTime && endTime) {
        const shiftHours = calculateShiftHours(startTime, endTime, Number(formik.values.total_unpaid_break));
        setShiftHours(shiftHours);
      }
    }
  };
  /*------------------------------Formik End----------------------------------*/

  const customStyles = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: "#F0484F",
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: "#F0484F",
      color: "white",
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#F0484F",
      ":hover": {
        backgroundColor: "#F0484F",
        color: "white",
      },
    }),
  };
  const renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <div className="schedule w-100">
        <div className="week-table p-0">
          <div className={eventInfo.event.extendedProps.class_name}>
            <p className="event-title mb-1">{eventInfo.event.title}</p>
            <p className="event-title mb-1">{eventInfo.event.extendedProps.staff_name}</p>
            {eventInfo.event.extendedProps.status_name === "CLOCKED IN" &&
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div className="shift-note-tooltip">
                      <p className="mb-0">Clocked In</p>
                    </div>
                  </Tooltip>
                }
              >
                <i className="ri-timer-flash-line clock_in"></i>
              </OverlayTrigger>
            }{eventInfo.event.extendedProps.status_name === "MISSED CLOCK IN" &&
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div className="shift-note-tooltip">
                      <p className="mb-0">Missed Clock In</p>
                    </div>
                  </Tooltip>
                }
              >
                <i className="ri-alarm-warning-line clock_in text-primary"></i>
              </OverlayTrigger>
            }
            {eventInfo.event.extendedProps.status_name === "Reject" &&
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div className="shift-note-tooltip">
                      <p className="mb-0">Rejected</p>
                    </div>
                  </Tooltip>
                }
              >
                <i className="ri-thumb-down-line clock_in"></i>
              </OverlayTrigger>
            }
            {eventInfo.event.extendedProps.status_name === "Pending" &&
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div className="shift-note-tooltip">
                      <p className="mb-0">Pending</p>
                    </div>
                  </Tooltip>
                }
              >
                <i className="ri-scan-line clock_in"></i>
              </OverlayTrigger>
            }
            {eventInfo.event.extendedProps.is_night_shift ?
              <i className="ri-moon-fill night_shift" />
              : <i className="ri-sun-fill night_shift" />
            }
            {eventInfo.event.extendedProps.hasConflict ?
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div className="shift-note-tooltip">
                      <p className="mb-0 text-primary">Shift Overlapping</p>
                    </div>
                  </Tooltip>
                }
              >
                <i className="ri-error-warning-line conflict_shift text-primary" />
              </OverlayTrigger>
              : ""
            }
            {eventInfo.event.extendedProps.shift_notes && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div className="shift-note-tooltip">
                      <p>Shift Note</p>
                      {eventInfo.event.extendedProps.shift_notes}
                    </div>
                  </Tooltip>
                }
              >
                <i className="ri-file-list-2-line notes" />
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    );
  };
  const [Chat_Box_Username, setChat_Box_Username] = useState("Lisa Parker");

  const [activeTab, setActiveTab] = useState<string>("home1");

  const handleTabChange = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  const downloadPatrolInfo = async () => {
    try {
      await exportPatrolLocationInfoDetail(
        editId ? editId : 0
      );
    } catch (error) {
      console.error("Error downloading Patrol Info:", error);
      toast.error("Error downloading Patrol Info. Please try again later.");
    }
  }


  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'AWAITING CLOCK IN':
        return 'warning';
      case 'CLOCKED OUT':
        return 'info';
      case 'MISSED CLOCK IN':
        return 'primary';
      case 'CLOCKED IN':
        return 'success';
      case 'Pending':
        return 'warning'
      case 'Reject':
        return 'primary'
      default:
        return 'black';
    }
  };

  const checkpointlog = () => {
    window.location.href = '/checkpoint-log';
  };

  const [archiveTemplateId, setArchiveTemplateId] = useState<number | undefined>(undefined);


  const handleArchiveShiftTemplate = async (id: number): Promise<void> => {
    setArchiveTemplateId(id);
    setmodal_center(true);
  };


  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }


  const [archiveLoader, setArchiveLoader] = useState(false)

  const archiveShiftTemplate = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await ShiftTemplateArchiveById(id);
      toast.success(response.message);
      setArchiveLoader(false)
      setmodal_center(false)
      loadShiftTemplateFunc()
    } catch (error) {
      toast.error("Error Archive Please try again later.");
    }
  };

  const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

  const editMaster = async (id: any) => {
    try {
      const response = await getByStaffDetail(id);
      const data = response.data;
      setStaffViewDetail(data);
      tog_standard()
    } catch (error) {
      return;
    }
  };


  const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
    any[]
  >([]);

  const fetchStaffDocumentDetails = async (id: any) => {
    try {
      const response = await getStaffDocumentDetail("", id);
      setStaffDocumentDetail(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  const [modal_standard, setmodal_standard] = useState<boolean>(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  const handleOpenDocument = (path: string) => {
    const fullPath = `${doc_url}/${path}`;
    window.open(fullPath, '_blank');
  };

  const doc_url = process.env.REACT_APP_IMAGE_URL
  const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

  const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

  const getAbbreviation = (name: string) => {
    return name
      ?.split(' ')
      ?.map(word => word.charAt(0))
      ?.join('')
      ?.toUpperCase()
      ?.slice(0, 2);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Schedule" pageTitle="Dashboard" />
            <Row className="schedule">
              <Col md={2}>
                <Card className="schedule-card">
                  <Card.Body>
                    <Row>
                      <Col lg={12}>
                        <Dropdown className="mb-3 w-100">
                          <Dropdown.Toggle variant="light" className="w-100">
                            Actions
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {totalShifts > 0 ?
                              <Dropdown.Item href="#" onClick={() => setOpenTemplateModal(true)}> Save Template</Dropdown.Item> : null
                            }
                            <Dropdown.Item href="#" onClick={loadShiftTemplateFunc}>Load Template</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="mb-4">
                          <Dropdown as={ButtonGroup} size="lg" className="w-100">
                            {expLoader ? (
                              <Button
                                color="primary"
                                // className="clear-btn"
                                style={{ height: '71.78px', width: '197px' }}
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            ) : (
                              <Button variant="primary" onClick={() => getPublishDetail()}>
                                <h5 className="text-white">Publish</h5>
                                <small>{totalUnpublishShifts ?? 0} Shifts Unpublished</small>
                              </Button>
                            )}
                            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                            <Dropdown.Menu>
                              <Dropdown.Item href="#" onClick={() => getPublishDetail()}>Publish & Notify</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Resend Notifications</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Dropdown>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Form.Label
                            htmlFor="choices-multiple-remove-button"
                            className="text-muted"
                          >
                            Client
                          </Form.Label>
                          <Select
                            closeMenuOnSelect={true}
                            value={selectedClient}
                            options={clientList}
                            styles={customStyles}
                            onChange={(newValue: any) => {
                              setSelectedClient(newValue);
                              setSelectedLocation(null)
                              localStorage.setItem('selectedLocation', JSON.stringify(null));
                              localStorage.setItem('selectedClient', JSON.stringify(newValue));
                            }}
                            onInputChange={(newValue: string) => setClientSearch(newValue)}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        {/* <SimpleBar className="location-bar chat-room-list simplebar-scrollable-y simplebar-mouse-entered">
                    <div className="chat-message-list">
                      <ul className="list-unstyled chat-list chat-user-list" id="userList">
                        {locationList.map((value: any, key: number) => (
                          <>
                          <li id="contact-id-1" key={key} data-name="direct-message" className={selectedLocation === value.value ? 'active' : ''}>
                            <Link to="#"
                              onClick={() => setSelectedLocation(value.value)}>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img away align-self-center me-2 ms-0">
                                  <div className="avatar-xxs">
                                    <span className="avatar-title rounded-circle bg-primary text-white fs-xxs">
                                      {getInitials(value.label)}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-truncate mb-0">{value.label}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </SimpleBar> */}
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Form.Label
                            htmlFor="choices-multiple-remove-button"
                            className="text-muted"
                          >
                            Location
                          </Form.Label>
                          <Select
                            closeMenuOnSelect={true}
                            value={selectedLocation}
                            options={locationList}
                            styles={customStyles}
                            onChange={(newValue: any) => { setSelectedLocation(newValue); localStorage.setItem('selectedLocation', JSON.stringify(newValue)); }}
                            onInputChange={(newValue: string) => setLocationSearch(newValue)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 mb-3">
                      <Card.Title as="h6" className="mb-0">Weekly Report</Card.Title>
                    </div>
                    <ul className="vstack gap-2 list-unstyled">
                      <li>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                <i className="bi bi-pin"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Total Shifts</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              {totalShifts}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                <i className="bi bi-pin"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Un-Assigned Shifts</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              {totalUnassignShifts}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                <i className="bi bi-pin"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">UnPublished Shifts</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              {totalUnpublishShifts ?? 0}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                <i className="bi bi-pin"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Published Shifts</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              {totalPublishShifts}
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* <li>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                <i className="bi bi-pin"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Published Shift Cost</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              0
                            </div>
                          </div>
                        </div>
                      </li> */}
                      <li>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                <i className="bi bi-pin"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Archived Shifts</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              {totalArchivedShifts ?? 0}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={10}>
                <Card className="schedule-card">
                  <Card.Body>
                    <FullCalendar
                      ref={calendarRef}
                      height="auto"
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin,
                      ]}
                      timeZone="Australia/Sydney"
                      initialView="dayGridWeek"
                      views={{
                        dayGridWeek: {
                          dayHeaderFormat: { weekday: 'short', day: '2-digit', month: 'long' }
                        }
                      }}
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev title next today",
                        center: "",
                        right: "myCustomButton checkpointButton",
                        // right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                      }}
                      events={eventList}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dayMaxEvents={true}
                      firstDay={1}
                      // select={handleDateSelect}
                      // eventClick={handleEventClick}
                      // dayCellContent={(arg) => handleDayContent(arg, fullcalendardata)}
                      // customButtons={{
                      customButtons={{
                        myCustomButton: {
                          text: 'Export',
                          click: function () {
                            exportExcel();
                          },
                        },
                        checkpointButton: {
                          text: 'Checkpoint Log',
                          click: function () {
                            checkpointlog()
                            // Add your logic here for the checkpoint button
                          },
                        },
                      }}
                      dayCellContent={renderDayCellContent}
                      eventContent={renderEventContent}
                      datesSet={handleDatesSet} // Callback for when the view changes
                      eventDrop={handleEventDrop}
                      eventClick={handleEventClick} // Callback for when an event is clicked
                    />
                    <Row>
                      <Col lg={7} className="legends d-flex flex-wrap">
                        <span className="p-2 rounded">
                          <i className="ri-checkbox-blank-circle-fill text-secondary align-bottom me-1"></i>
                          Un-assigned Shifts
                        </span>
                        <span className="p-2 rounded">
                          <i
                            className="ri-checkbox-blank-circle-fill align-bottom me-1"
                            style={{ color: "#F0B414" }}
                          ></i>
                          Unpublished
                        </span>
                        <span className="p-2 rounded">
                          <i className="ri-checkbox-blank-circle-fill text-success align-bottom me-1"></i>
                          Finished Shifts
                        </span>
                        <span className="p-2 rounded">
                          <i
                            className="ri-checkbox-blank-circle-fill align-bottom me-1"
                            style={{ color: "#3981EC" }}
                          ></i>
                          Published Shifts
                        </span>
                        <span className="p-2 rounded">
                          <i
                            className="ri-checkbox-blank-circle-fill align-bottom me-1"
                            style={{ color: "#FF6C6C" }}
                          ></i>
                          Archived Shifts
                        </span>
                      </Col>
                      <Col lg={5} className="legends d-flex flex-wrap justify-content-end">
                        <span className="p-2 rounded">
                          <i
                            className="ri-timer-flash-line align-bottom me-1 text-success"
                          ></i>
                          Clocked In
                        </span>
                        <span className="p-2 rounded">
                          <i
                            className="ri-alarm-warning-line align-bottom me-1 text-primary"
                          ></i>
                          Missed Clock In
                        </span>
                        <span className="p-2 rounded">
                          <i
                            className="ri-thumb-down-line align-bottom me-1 text-primary"
                          ></i>
                          Rejected
                        </span>
                        <span className="p-2 rounded">
                          <i
                            className="ri-scan-line align-bottom me-1 text-warning"
                          ></i>
                          Pending
                        </span>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Offcanvas
          className="shift-option-canvas"
          show={isShiftCanvasOpen}
          placement="end"
          onHide={() => setIsShiftCanvasOpen(false)}
        >

          <Row className="m-0">
            <Col lg={3} className="shift-column">
              {selectedStaffName &&
                <>
                  <span>{getInitials(selectedStaffName)}</span>
                  <h4 className="title">{selectedStaffName}</h4>
                </>
              }
              <Row className="g-3 mt-3">
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Client</p>
                        <h6 className="fs-lg">{selectedClient?.label}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Location</p>
                        <h6 className="fs-lg">{selectedLocation?.label}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {editId &&
                  <Col md={12}>
                    <Card className="border shadow-none mb-0">
                      <Card.Body className="p-2">
                        <div className="text-center">
                          <p className="text-muted text-truncate mb-2">Created By</p>
                          <h6 className="fs-lg">{Created_by}</h6>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                }
                <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Shift Length</p>
                        <h6 className="fs-lg">{shiftHours} Hrs</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {/* <Col md={12}>
                  <Card className="border shadow-none mb-0">
                    <Card.Body className="p-2">
                      <div className="text-center">
                        <p className="text-muted text-truncate mb-2">Shift Cost</p>
                        <h6 className="fs-lg">$0</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
                {(editId != null && editId > 0 && !isFinishShift) && (
                  <Col md={12}>
                    <Button
                      variant="outline-primary"
                      className="w-100"
                      onClick={() => handleArchiveShift(editId)}
                    >
                      <i className="ri-delete-bin-line"></i> Delete
                    </Button>
                  </Col>
                )}
                {(Is_petrol_site === 1 && editId) &&
                  <Col md={12}>
                    <Button onClick={downloadPatrolInfo} variant="primary" className="sub-update-btn d-flex align-items-center gap-2">
                      <i className=" ri-download-line" style={{ fontSize: '18px' }}></i> Patrol Info
                    </Button>
                  </Col>
                }
              </Row>
            </Col>
            <Col lg={9} className="p-0 shift-form">
              <Form onSubmit={formik.handleSubmit}>
                <Offcanvas.Header
                  id="createShift"
                  className="border-bottom"
                  closeButton
                >
                  <Offcanvas.Title id="createShiftLabel">
                    {" "}
                    <i
                      className="bx bx-calendar-plus align-middle me-1"
                      style={{ color: "#999" }}
                    ></i>{" "}
                    {editId ? (isFinishShift) ? "Shift Detail" : "Edit Shift" : "New Shift"}   {(Is_petrol_site === 1 && editId) && <span className="badge bg-info-subtle text-info">Patrol Shift</span>}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0 px-4">
                  <Tab.Container
                    defaultActiveKey="home1"
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                  >
                    {
                      (editId != null && editId > 0) &&
                      <Nav as="ul" variant='pills' className="nav-justified mt-3 mb-3">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="home1">
                            Details
                          </Nav.Link>
                        </Nav.Item>
                        {editId &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="profile1">
                              History
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {editId &&
                          <Nav.Item as="li">
                            <Nav.Link eventKey="profile2">
                              Staff Details
                            </Nav.Link>
                          </Nav.Item>
                        }
                      </Nav>
                    }

                    <Tab.Content className="text-muted">
                      <Tab.Pane eventKey="home1">
                        <SimpleBar
                          style={{ maxHeight: "80vh" }}
                          className="px-3"
                        >
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3 mt-3">
                                <Form.Label>Date</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="text" className="form-control form-control-icon" placeholder="Enter Date" name="shift_date" value={formik.values.date} readOnly />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-event-line"
                                  ></i>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Select Staff
                                </Form.Label>
                                <Select
                                  inputId="staffSelect"
                                  name="staff_id"
                                  options={staffList}
                                  value={staffList.find(option => option.value === formik.values.staff_id)}
                                  onChange={(selectedOption: any) => {
                                    formik.setFieldValue('staff_id', selectedOption ? selectedOption.value : '');
                                    setSelectedStaffName(selectedOption ? selectedOption.label : '');
                                  }}
                                  onBlur={formik.handleBlur}
                                  isDisabled={shiftClockIn}
                                  classNamePrefix="react-select"
                                  className={formik.touched.staff_id && formik.errors.staff_id ? 'is-invalid' : ''}
                                />
                                <i
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "1.6rem",
                                  }}
                                  className="ri-user-line"
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.staff_id}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3">
                                <Form.Label>Start Time</Form.Label>
                                <div className="position-relative form-icon">
                                  <Cleave
                                    placeholder="hh:mm"
                                    options={{
                                      time: true,
                                      timePattern: ['h', 'm']
                                    }}
                                    value={formik.values.start_time}
                                    onChange={(e: any) => handleTimeChange(e.target.rawValue, "start_time")}
                                    className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                    disabled={shiftClockIn}
                                  />
                                  {/* <Flatpickr
                                className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                name="start_time"
                                value={formik.values.start_time}
                                onChange={(time: Date[]) => handleTimeChange(time, "start_time")}
                                style={{ height: "45px" }}
                                placeholder="Select Start Time"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true
                                }}
                              /> */}
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-time-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.start_time}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3">
                                <Form.Label>End Time</Form.Label>
                                <div className="position-relative form-icon">
                                  <Cleave
                                    placeholder="hh:mm"
                                    options={{
                                      time: true,
                                      timePattern: ['h', 'm']
                                    }}
                                    value={formik.values.end_time}
                                    disabled={shiftClockIn}
                                    onChange={(e: any) => handleTimeChange(e.target.rawValue, "end_time")}
                                    className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                  />
                                  {/* <Flatpickr
                                className={`form-control form-control-lg form-control-icon ${formik.touched.end_time && formik.errors.end_time ? 'is-invalid' : ''}`}
                                name="end_time"
                                value={formik.values.end_time}
                                onChange={(time: Date[]) => handleTimeChange(time, "end_time")}
                                style={{ height: "45px" }}
                                placeholder="Select End Time"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  time_24hr: true
                                }}
                              /> */}
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-time-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.end_time}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Unpaid Break (in min)
                                </Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="number" className="form-control form-control-icon" placeholder="Enter Time"
                                    name="total_unpaid_break"
                                    onChange={e => {
                                      formik.handleChange(e);
                                      const shiftHours = calculateShiftHours(formik.values.start_time, formik.values.end_time, Number(e.target.value))
                                      setShiftHours(shiftHours);
                                    }}
                                    disabled={shiftClockIn}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.total_unpaid_break}
                                    isInvalid={!!(formik.touched.total_unpaid_break && formik.errors.total_unpaid_break)}
                                  />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-2-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.total_unpaid_break}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="mb-3 form-icon">
                                <Form.Label className="text-muted">
                                  Paid Break (in min)
                                </Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control type="number" className="form-control form-control-icon" placeholder="Enter Time"
                                    name="total_paid_break"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={shiftClockIn}
                                    value={formik.values.total_paid_break}
                                    isInvalid={!!(formik.touched.total_paid_break && formik.errors.total_paid_break)}
                                  />
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="ri-calendar-2-line"
                                  ></i>
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.total_paid_break}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>

                            {
                              (editId != null && editId > 0) &&
                              <>
                                <Col lg="6">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Clocked In Time</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Cleave
                                        placeholder="hh:mm"
                                        options={{
                                          time: true,
                                          timePattern: ['h', 'm']
                                        }}
                                        value={formik.values.clock_in_time}
                                        onChange={(e: any) => handleTimeChange(e.target.rawValue, "clock_in_time")}
                                        disabled={shiftClockIn}
                                        className={`form-control form-control-lg form-control-icon ${formik.touched.clock_in_time && formik.errors.clock_in_time ? 'is-invalid' : ''}`}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-time-line"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.clock_in_time}
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Clocked Out Time</Form.Label>
                                    <div className="position-relative form-icon">
                                      <Cleave
                                        placeholder="hh:mm"
                                        options={{
                                          time: true,
                                          timePattern: ['h', 'm']
                                        }}
                                        value={formik.values.clock_out_time}
                                        disabled={shiftClockOut}
                                        onChange={(e: any) => handleTimeChange(e.target.rawValue, "clock_out_time")}
                                        className={`form-control form-control-lg form-control-icon ${formik.touched.clock_out_time && formik.errors.clock_out_time ? 'is-invalid' : ''}`}
                                      />
                                      <i
                                        style={{ fontSize: "16px" }}
                                        className="ri-time-line"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.clock_out_time}
                                      </Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="12">
                                  <Form.Group className="mb-3 form-icon">
                                    <Form.Label className="text-muted">
                                      Is Finished
                                    </Form.Label>
                                    <Form.Select
                                      className="form-select-lg form-control-icon"
                                      id="is_finished"
                                      onChange={(e) => formik.handleChange(e)}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.is_finished}
                                    >
                                      <option value="0">No</option>
                                      <option value="1">Yes</option>
                                    </Form.Select>
                                    <i
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "1.6rem",
                                      }}
                                      className="ri-user-line"
                                    ></i>
                                  </Form.Group>
                                </Col>
                              </>
                            }
                            <Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Note</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control-lg form-control-icon name-input"
                                    placeholder="Enter Notes"
                                    rows={2}
                                    name="shift_notes"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.shift_notes}
                                    isInvalid={!!(formik.touched.shift_notes && formik.errors.shift_notes)}
                                  />
                                  <i
                                    style={{
                                      fontSize: "16px",
                                      alignItems: "baseline",
                                      marginTop: "10px",
                                    }}
                                    className="ri-book-open-line"
                                  ></i>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.shift_notes}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>Admin Note</Form.Label>
                                <div className="position-relative form-icon">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control-lg form-control-icon name-input"
                                    placeholder="Enter Admin Notes"
                                    rows={2}
                                    name="admin_note"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.admin_note}
                                    isInvalid={!!(formik.touched.admin_note && formik.errors.admin_note)}
                                  />
                                  <i
                                    style={{
                                      fontSize: "16px",
                                      alignItems: "baseline",
                                      marginTop: "10px",
                                    }}
                                    className="ri-book-open-line"
                                  ></i>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.admin_note}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          {!isFinishShift &&
                            <Row>
                              <div className="line"></div>
                              <Col lg={12} className="d-flex justify-content-between">
                                {/* <div className="col-sm-auto">
                                        <Form.Label
                                          className="visually-hidden"
                                          htmlFor="autoSizingSelect"
                                        >
                                          Preference
                                        </Form.Label>
                                        <Form.Select
                                          data-choices
                                          data-choices-sorting="true"
                                          id="autoSizingSelect"
                                        >
                                          <option value="1">Repeat Tomorrow</option>
                                          <option value="2">Repeat for next week</option>
                                          <option value="3">Repeat for Specific days</option>
                                          <option value="3">Publish shift</option>
                                          {editId && (
                                            <option value="5">Terminate Shift</option>
                                          )}
                                        </Form.Select>
                                      </div> */}
                                {formik.isSubmitting ? (
                                  <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="next-btn"
                                    disabled={formik.isSubmitting}
                                  >
                                    {editId ? "Update" : "Create"}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          }
                        </SimpleBar>
                      </Tab.Pane>
                      {editId &&
                        <Tab.Pane eventKey="profile1">
                          <SimpleBar
                            style={{ maxHeight: "85vh" }}
                            className="px-3"
                          >
                            <Row>
                              {ShiftHistoryDetails === null ?
                                <Col lg={12} className="text-center mt-5 pt-5">
                                  <img src={noDataImg} style={{ width: '100px' }} />
                                  <div className='d-flex justify-content-center'>No history available</div>
                                </Col>
                                :
                                <Col lg="12" className="status-table">
                                  <Table className="align-middle table-wrap mb-0" responsive>
                                    <thead>
                                      <tr>
                                        <th scope="col User">User</th>
                                        <th scope="col User">Field</th>
                                        <th scope="col Previous">Previous Value</th>
                                        <th scope="col Previous">Updated Value</th>
                                        <th scope="col Previous">Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ShiftHistoryDetails?.map((time: any) => {
                                        return (
                                          <tr>
                                            <td>{time?.user_name}</td>
                                            <td>{time?.field}</td>
                                            <td>{time?.prev_val}</td>
                                            <td>{time?.updated_val}</td>
                                            <td>{time?.created_date}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </Col>
                              }
                            </Row>
                          </SimpleBar>
                        </Tab.Pane>
                      }
                      {editId &&
                        <Tab.Pane eventKey="profile2">
                          <div className="d-flex align-items-center" >
                            <div className="flex-shrink-0 position-relative">
                              {StaffViewDetail?.avatar ?
                                <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                :
                                <div className="avatar-sm">
                                  <div className="avatar-title rounded bg-danger-subtle text-danger">
                                    {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                  </div>
                                </div>

                              }
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h5 className="fs-md mb-0"><Link to="/pages-profile" className="text-dark">{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</Link></h5>
                              <p className="text-muted mb-0"> {StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"} </p>
                              <p className="text-muted mb-0"> {StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"} </p>
                            </div>
                          </div>
                          <div className="mt-4">
                            <p className="text-muted mb-2">
                              <i className="bi bi-telephone align-baseline me-1"></i>
                              {StaffViewDetail?.mobile_no ? (
                                <Link to={`tel:${StaffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                  {StaffViewDetail.mobile_no}
                                </Link>
                              ) : (
                                "-"
                              )}
                            </p>
                            <p className="text-muted mb-2">
                              <i className="bi bi-envelope align-baseline me-1"></i>
                              {StaffViewDetail?.email ? (
                                <Link to={`mailto:${StaffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{StaffViewDetail.email} </Link>
                              ) : (
                                "-"
                              )}
                            </p>
                            <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"} </p>
                            <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"} </p>
                            <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"} </p>
                          </div>
                          {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses").length > 0 &&
                            <div className="border-bottom border-top border-dashed py-4 mt-4">
                              <h5 className="card-title mb-3">License</h5>
                              <div className="vstack gap-3">
                                {StaffDocumentDetail
                                  .filter((doc) => doc.document_type === "Licenses")
                                  .map((doc, index) => (
                                    <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                      <div className="flex-shrink-0">
                                        <div className="avatar-sm border border rounded">
                                          <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                            <i className="bi bi-file-pdf"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h6>License No : {doc.ref_no}</h6>
                                        <p className="text-muted mb-0">Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}</p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <Link to={`#`} onClick={() => handleOpenDocument(doc.document_path)} className="icon-link text-reset stretched-link fs-xl" style={{ transform: "translate3d(0, -.125rem, 0)" }}>
                                          <i className="ri-eye-line link-info"></i>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          }
                        </Tab.Pane>
                      }
                    </Tab.Content>
                  </Tab.Container>
                </Offcanvas.Body>
              </Form>
            </Col>
          </Row>
        </Offcanvas>
        <Modal
          show={openTemplateModal}
          onHide={() => {
            setOpenTemplateModal(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>Save Template for {selectedClient?.label}</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form onSubmit={templateFormik.handleSubmit}>
              <Row>
                <Col md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Template Name</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="template_name"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Template Name"
                        type="text"
                        onChange={templateFormik.handleChange}
                        onBlur={templateFormik.handleBlur}
                        value={templateFormik.values.template_name}
                        isInvalid={!!(templateFormik.touched.template_name && templateFormik.errors.template_name)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {templateFormik.errors.template_name}
                      </Form.Control.Feedback>
                      <i style={{ fontSize: "16px" }} className="bx bx-note"></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        name="description"
                        as="textarea"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Description"
                        type="text"
                        onChange={templateFormik.handleChange}
                        onBlur={templateFormik.handleBlur}
                        value={templateFormik.values.description}
                        rows={5}
                      />
                      <i style={{ fontSize: "16px" }} className="bx bx-file"></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_remove_staff"
                        id="is_remove_staff"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_remove_staff", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_remove_staff)}
                      />
                      <Form.Label>
                        Remove Staff From Shifts
                      </Form.Label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_shift_notes"
                        id="is_shift_notes"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_shift_notes", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_shift_notes)}
                      />
                      <Form.Label>
                        Include Shift Notes
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <div className="form-check form-switch form-switch-custom form-switch-lg mb-2">
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        name="is_admin_notes"
                        id="is_admin_notes"
                        onChange={(e) => {
                          templateFormik.setFieldValue("is_admin_notes", e.target.checked ? 1 : 0)
                        }}
                        checked={Boolean(templateFormik.values.is_admin_notes)}
                      />
                      <Form.Label>
                        Include Admin Notes
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setOpenTemplateModal(false)}>Cancel</Button>
              <Button variant="danger" onClick={() => templateFormik.handleSubmit()} disabled={templateFormik.isSubmitting}>
                {templateFormik.isSubmitting ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ><span className="visually-hidden">Submitting...</span></Spinner> :
                  <><i className="ri-upload-2-fill"></i> Submit</>
                }
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={openLoadTemplateModal}
          onHide={() => {
            setOpenLoadTemplateModal(false);
          }}
          size="lg"
          centered
        >
          {
            submitTemplateResponse ?
              <>
                <Modal.Body className="p-4">
                  <Row className="g-4">
                    <Col md={12}>
                      <h3 className="mb-4">{submitTemplateResponse?.total_shifts} Shifts in Template</h3>
                      <span className="flex-grow-1">
                        <h6 className="text-muted mb-0 mt-2">
                          <i className="ri-check-line text-success align-middle me-1" style={{ fontSize: "1.2rem" }}></i> {submitTemplateResponse?.successful_shifts}
                        </h6>
                      </span>
                      {
                        (submitTemplateResponse?.issues != "") &&
                        <>
                          <span className="flex-grow-1">
                            <h6 className="text-muted mb-0 mt-2">
                              <i className="ri-alert-line text-danger align-middle me-1" style={{ fontSize: "1.2rem" }}></i> {submitTemplateResponse?.issues}
                            </h6>
                          </span>
                          {
                            (submitTemplateResponse.overlaps.length > 0) &&
                            <Accordion defaultActiveKey='0' className="custom-accordion-border accordion-border-box accordion-danger mt-3">
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                  {submitTemplateResponse.overlaps.length} Shift has overlapped with existing shift.
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul>
                                    {
                                      submitTemplateResponse.overlaps.map((overlapItem: any, index: number) => (
                                        <li key={index}>
                                          {overlapItem}
                                        </li>
                                      ))
                                    }
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          }
                        </>
                      }
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <div className="hstack gap-2 justify-content-center">
                    <Button variant="primary" onClick={() => setOpenLoadTemplateModal(false)}>Okay, Done</Button>
                  </div>
                </Modal.Footer>
              </>
              : templateData.length > 0 ?
                <>
                  <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
                    <h4>Load Template for {selectedClient?.label}</h4>
                  </Modal.Header>
                  <Modal.Body className="p-4">
                    <SimpleBar
                      style={{ maxHeight: "65vh" }}
                      className="px-3"
                    >
                      <Row className="g-4">
                        {templateData.map((dataItem: any, index: number) => (
                          <Col lg={12} key={index}>
                            <Form.Check className="card-radio">
                              <Form.Check.Input id={`template_chk${index}`} name="shippingMethod" type="radio" checked={dataItem.id === template_id} onChange={() => setTemplateId(dataItem.id)} />
                              <Form.Check.Label className="d-flex gap-2 align-items-center" htmlFor={`template_chk${index}`}>
                                <span className="flex-grow-1">
                                  <span className="fs-md fw-medium mb-1 text-wrap d-block">{dataItem.template_name}</span>
                                  <p className="text-muted mb-0 mt-2">
                                    <i className="bi bi-calendar-event align-baseline me-1"></i> {dataItem.total_shift}
                                    <i className="bi bi-people-fill align-baseline ms-4 me-1"></i> {dataItem.total_assign_staff}
                                    <i className="bi bi-calendar-range align-baseline ms-4 me-1"></i> {dataItem.start_date + "-" + dataItem.end_date}
                                  </p>
                                  <span className="text-muted fw-normal text-wrap d-block mt-3">{dataItem.description}</span>
                                </span>
                                <Link to="#" onClick={() => handleArchiveShiftTemplate(dataItem.id)} className="btn btn-primary btn-icon btn-sm float-end"><i className="bi bi-trash-fill"></i></Link>
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        ))}
                      </Row>
                    </SimpleBar>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="hstack gap-2 justify-content-center">
                      <Button variant="light" onClick={() => setOpenLoadTemplateModal(false)}>Cancel</Button>
                      <Button variant="danger" onClick={() => submitShiftTemplateFunc()} disabled={savingTemplateShifts}>
                        {savingTemplateShifts ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          ><span className="visually-hidden">Submitting...</span></Spinner> :
                          <><i className="ri-upload-2-fill"></i> Submit</>
                        }
                      </Button>
                    </div>
                  </Modal.Footer>
                </>
                :
                <Modal.Body className="p-4 m-4">
                  <Row>
                    <Col lg={12}>
                      <p className="text-center text-muted">No templates available.</p>
                    </Col>
                  </Row>
                </Modal.Body>
          }
        </Modal>
        <Modal
          show={publishModal}
          onHide={() => {
            setPublishModal(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header className="modal-title bg-danger-subtle text-center" id="myModalLabel" style={{ display: 'block' }}>
            <h4>Publish For {selectedClient?.label}</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="mt-4">
              <Row>
                <Col>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Days</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isDateAllChecked} onChange={handleDateCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <ListGroup>
                    {publishData.dates.map((dateItem: any, index) => (
                      <ListGroup.Item key={index}>
                        <input className="form-check-input me-2" type="checkbox" checked={checkedPublishDates.includes(dateItem.date)}
                          onChange={() => handleDateCheckboxChange(dateItem.date)} />
                        {dateItem.dayName}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
                <Col>
                  <Row>
                    <Col className="align-self-center">
                      <h5>Select Staff</h5>
                    </Col>
                    <Col className="align-self-end mb-1">
                      <div className="form-check" style={{ float: 'right' }}>
                        <input className="form-check-input me-2" type="checkbox" checked={isStaffAllChecked} onChange={handleStaffCheckAll} />
                        Select All
                      </div>
                    </Col>
                  </Row>
                  <SimpleBar style={{ maxHeight: "300px" }} data-simplebar-direction="right" data-simplebar-track="primary">
                    <ListGroup>
                      {publishData.staff_list.map((staff: any, index) => (
                        <ListGroup.Item key={index}>
                          <input className="form-check-input me-2" type="checkbox" checked={checkedPublishStaff.includes(staff.staff_id)}
                            onChange={() => handleStaffCheckboxChange(staff.staff_id)} />
                          {staff.staff_name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </SimpleBar>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setPublishModal(false)}>Cancel</Button>
              {publishLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={handlePublishShifts}><i className="ri-upload-2-fill"></i> Publish</Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={archiveModal}
          onHide={() => setArchiveModal(false)}
          size="sm"
          centered
        >

          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">You want to pemenantly delete this Shift.</p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setArchiveModal(false)}>Close</Button>
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveShift(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal_center}
          onHide={() => {
            tog_center();
          }}
          size="sm"
          centered
        >

          <Modal.Body className="text-center p-4">
            <i className="ri-delete-bin-line text-danger display-5"></i>
            <div className="mt-4">
              <h4 className="mb-3">Are you sure?</h4>
              <p className="text-muted mb-4">You want to delete this template.</p>
              <div className="hstack gap-2 justify-content-center">
                <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                {archiveLoader ? (
                  <Button
                    color="primary"
                    className="next-btn"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button variant="danger" onClick={() => {
                    if (archiveTemplateId !== undefined) {
                      archiveShiftTemplate(archiveTemplateId);
                    } else {
                      toast.error("No Archive ID provided.");
                    }
                  }}>Delete</Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default SchedulePage;
