import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Badge,
  Tab,
  Nav,
  Modal,
  Spinner
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ClientListTable from "./ClientTables/ClientListTable";
import SearchableAddress from "helpers/SearchableAddress";
import Select from 'react-select';
import ClientDocumentTable from "./ClientTables/ClientDocumentTable";
import { getClientDetail, addClientDetail, getByClientDetail, getTemplateInoviceDetail, updateClientDetail, archiveClientDetail, exportClientDetail, restoreClientDetail, getClientDocumentDetail, addClientDocumentDetail, getByClientDocumentDetail, updateClientDocumentDetail, archiveClientDocumentDetail } from "helpers/saibackend_helper";
import { number } from "prop-types";

interface AddressData {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface TemplateData {
  id: number;
  template_name: string;
  value: number;
}


interface ClientData {
  id: number;
  name: string;
  address: string;
  abn: string,
}

interface ClientDocumentData {
  id: number;
  document_name: string;
  document_path: string;
}

const ClientList = () => {
  document.title = "Clients | The Guard Duty";

  const [clientCanvas, setClientCanvas] = useState<boolean>(false);
  const handleAddClient = () => setClientCanvas(!clientCanvas);


  const [modal_standard, setmodal_standard] = useState<boolean>(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  // Client List API Integration //
  // ============================================================================================================================//
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);
  const [ClientDetail, setClientDetail] = useState<ClientData[]>([])

  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "DESC",
      sort_by: ""
    },
    is_archive: 0
  })

  const handleSortChange = (column: string) => {
    setDefaultValue(prevState => ({
      ...prevState,
      sort: {
        sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
        sort_by: column
      },
      page: 1
    }));
  };

  const handlePageChange = (page: number) => {
    setDefaultValue({ ...defaultValue, page: page });
  };

  const [expLoader, setExpLoader] = useState(false)

  const exportClient = async () => {
    setExpLoader(true)
    try {
      await exportClientDetail(
        defaultValue.page,
        defaultValue.page_size,
        defaultValue.search_term,
        defaultValue.sort.sort_order,
        defaultValue.sort.sort_by,
        Number(isArchived),
      );
      setExpLoader(false)
    } catch (error) {
      console.error('There was an error exporting the data!', error);
    }
  };

  const fetchClientDetails = async () => {
    try {
      const response = await getClientDetail(defaultValue.page, defaultValue.page_size, defaultValue.search_term, defaultValue.sort.sort_order, defaultValue.sort.sort_by, Number(isArchived));
      setClientDetail(response.data.data_list);
      setTotalPages(response.data.total_pages);
      setCurrentPage(defaultValue.page);
      setTotalRecords(response.data.total_records);
      setLoading(false);
    } catch (error) {
      console.log(error);

    }
  };

  useEffect(() => {
    fetchClientDetails();
  }, [defaultValue, isArchived]);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultValue(prevPayload => ({
        ...prevPayload,
        search_term: inputValue,
      }));
      console.log(defaultValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const [alladdress, setAllAddress] = useState<AddressData | null>(null);
  const [address, setAddress] = useState<string>('');

  const handleAddressChange = (data: AddressData | boolean) => {
    if (typeof data === 'boolean') return;
    setAddress(data.address);
    formik.setFieldValue("address", data.address);
    formik.setFieldValue("city", data.city);
    formik.setFieldValue("state", data.state);
    formik.setFieldValue("postal_code", data.postalCode);
    formik.setFieldValue("country", data.country);
    setAllAddress(data);
    console.log(data);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Client name is required'),
    contact_email: Yup.string()
      .email('Invalid email format')
      .notRequired(),
    abn_no: Yup.string()
      .nullable()
      .matches(/^\d{11}$/, 'ABN must be an 11-digit number'),
    contact_phone: Yup.string()
      .nullable()
      .matches(/^(?:\+?61|0)[2-9]\d{8}|\+?61[1-9]\d{8}$/, 'Invalid phone number')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      invoice_note: '',
      abn_no: '',
      contact_person: "",
      contact_phone: "",
      contact_email: "",
      invoice_template_id: 0
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEditing && editId !== null) {
          const response = await updateClientDetail(editId, values.name, values.address, values.city, values.state, values.postal_code, values.country, values.contact_person, values.contact_phone, values.contact_email, values.abn_no, values.invoice_note, values.invoice_template_id);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        } else {
          const response = await addClientDetail(values);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        }
        handleAddClient();
        setDefaultValue(prevState => ({
          ...prevState,
          sort: {
            sort_order: "DESC",
            sort_by: ""
          },
          page: 1
        }));
        fetchClientDetails();
        formik.resetForm();
        setAllAddress(null)
        setAddress('')
      } catch (error) {
        console.error("Error Adding/Updating Client Master:", error);
        toast.error("Error Client Master. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const editMaster = async (id: number) => {
    try {
      const response = await getByClientDetail(id);
      const data = response.data;
      formik.setValues({
        name: data.name,
        address: data.address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
        invoice_note: data.invoice_note,
        abn_no: data.abn_no,
        contact_person: data.contact_person,
        contact_phone: data.contact_phone,
        contact_email: data.contact_email,
        invoice_template_id: data.invoice_template_id
      });
      setAddress(data.address)
      setIsEditing(true);
      setEditId(id);
      handleAddClient();
    } catch (error) {
      console.error("Error fetching Compliance Master details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  const openAddForm = () => {
    setIsEditing(false);
    setEditId(null);
    formik.resetForm()
    setClientCanvas(true);
    setAllAddress(null)
    setAddress('')
  };

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false)

  const handleArchiveClient = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const archiveClient = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await archiveClientDetail(id);
      fetchClientDetails();
      toast.success(response.message);
      setmodal_center(false)
      setArchiveLoader(false)
    } catch (error) {
      console.error("Error Archive Subcontractor:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const [restoreId, setRestoreId] = useState<number | undefined>(undefined);
  const [restoreLoader, setRestoreLoader] = useState(false)

  const handleRestoreClient = async (id: number): Promise<void> => {
    setRestoreId(id);
    setmodal_restore_center(true);
  };


  const [modal_restore_center, setmodal_restore_center] = useState<boolean>(false);
  function tog_restore_center() {
    setmodal_restore_center(!modal_restore_center);
  }

  const restoreClient = async (id: number) => {
    setRestoreLoader(true)
    try {
      const response = await restoreClientDetail(id);
      fetchClientDetails();
      toast.success(response.message);
      setmodal_restore_center(false);
      setRestoreLoader(false)
    } catch (error) {
      console.error("Error Restore Subcontractor:", error);
      toast.error("Error Restore Please try again later.");
    }
  };

  //Client Document API Integration
  // ==================================================================================================================== //

  const [file, setFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState(true);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setShowUploadButton(false);
      docformik.setFieldValue('document_path', selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setShowUploadButton(true);
    docformik.setFieldValue("document_path", null)
  };

  const [isEditingDoc, setIsEditingDoc] = useState(false);
  const [editIdDoc, setEditIdDoc] = useState<number | null>(null);
  const [totalRows, setTotalRows] = useState(0);
  const [ClientDocumentDetail, setClientDocumentDetail] = useState<ClientDocumentData[]>([])

  const [defaultDocValue, setDefaultDocValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "ASC",
      sort_by: ""
    },
    is_archive: 0,
  })


  const [inputDocValue, setInputDocValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultDocValue(prevPayload => ({
        ...prevPayload,
        search_term: inputDocValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputDocValue]);

  const handleDocChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDocValue(e.target.value);
  };


  const fetchClientDocumentDetails = async () => {
    try {
      const validEditId = editId !== null ? editId : 0;
      const response = await getClientDocumentDetail(
        defaultDocValue.page,
        defaultDocValue.page_size,
        defaultDocValue.search_term,
        defaultDocValue.sort.sort_order,
        defaultDocValue.sort.sort_by,
        Number(isArchived),
        validEditId
      );
      setClientDocumentDetail(response.data.data_list);
      setTotalRows(response.data.total_records);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   fetchClientDocumentDetails();
  // }, [editId, defaultDocValue]);

  useEffect(() => {
    if (clientCanvas) {
      fetchClientDocumentDetails();
      fetchTemplateDetails()
    }
  }, [clientCanvas, defaultDocValue, editId]);


  const handlePageDocChange = (page: number) => {
    setDefaultDocValue(prevPayload => ({
      ...prevPayload,
      page: page
    }));
  };

  const handlePerRowsDocChange = (newPerPage: number, page: number) => {
    setDefaultDocValue(prevPayload => ({
      ...prevPayload,
      page_size: String(newPerPage),
      page: page
    }));
  };


  const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
    const columnSelector = typeof column === 'function' ? column.name : column;

    setDefaultDocValue(prevState => ({
      ...prevState,
      sort: {
        sort_by: columnSelector,
        sort_order: sortDirection.toUpperCase(),
      }
    }));
  };


  const validationDocSchema = Yup.object().shape({
    document_name: Yup.string().required('Document name is required'),
    document_path: Yup.mixed().required('Document is required'),
  });

  const [archiveClientDocId, setArchiveClientDocId] = useState<number | undefined>(undefined);
  const [docArchiveLoader, setDocArchiveLoader] = useState(false)

  const handleArchiveClientDoc = async (id: number): Promise<void> => {
    setArchiveClientDocId(id);
    console.log(id);
    setmodal_doc_center(true);
  };


  const [modal_doc_center, setmodal_doc_center] = useState<boolean>(false);
  function tog_doc_center() {
    setmodal_doc_center(!modal_doc_center);
  }


  const docformik = useFormik({
    initialValues: {
      document_name: '',
      document_path: null,
    },
    validationSchema: validationDocSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let response;
        if (isEditingDoc && editIdDoc !== null) {
          response = await updateClientDocumentDetail(
            editIdDoc,
            values.document_name,
            values.document_path,
          )
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        else {
          const payload = {
            ...values,
            client_id: editId,
          };
          response = await addClientDocumentDetail(payload);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        docformik.resetForm();
        fetchClientDocumentDetails()
        setShowUploadButton(true)
        setmodal_standard(false)
        setFile(null)
        setIsEditingDoc(false);
        setEditIdDoc(null);
      } catch (error) {
        console.error("Error Adding/Updating Client Document:", error);
        toast.error("Error Client Document. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  const editClientDocument = async (id: number) => {
    try {
      const response = await getByClientDocumentDetail(id);
      const data = response.data;
      docformik.setValues({
        document_name: data.document_name,
        document_path: data.document_path,
      });
      setIsEditingDoc(true);
      setFile(null)
      setEditIdDoc(id);
      setmodal_standard(true)
      setShowUploadButton(!data.document_path && !file);
    } catch (error) {
      console.error("Error fetching Compliance Master details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };


  const archiveMaster = async (id: number) => {
    setDocArchiveLoader(true)
    try {
      const response = await archiveClientDocumentDetail(id);
      fetchClientDocumentDetails()
      toast.success(response.message);
      setmodal_doc_center(false)
      setDocArchiveLoader(false)
    } catch (error) {
      console.error("Error Archive Client Document :", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const openAddDocForm = () => {
    setIsEditingDoc(false);
    setEditIdDoc(null);
    docformik.resetForm()
    setmodal_standard(true);
    setShowUploadButton(true)
    setFile(null)
  };

  const baseurl = process.env.REACT_APP_IMAGE_URL

  const handleOpenDocument = (path: string) => {
    const fullPath = `${baseurl}/${path}`;
    window.open(fullPath, '_blank');
  };

  const handleFilePreview = (file: File | null) => {

    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } else {
      return;
    }
  };

  // Template Dropdown //
  // =============================================================================================================//

  const [TemplateDetail, setTemplateDetail] = useState<TemplateData[]>([]);

  const fetchTemplateDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getTemplateInoviceDetail(searchTerm);
      setTemplateDetail(response.data.map((temp: any) => ({
        value: temp.id,
        label: temp.template_name
      })));
    } catch (error) {
      console.log(error);
    }
  };


  const handleInvoiceTemplateChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      formik.setFieldValue('invoice_template_id', selectedOption.value);
    }
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Client List" pageTitle="Dashboard" />
          {
            loading ?
              <Row className="placeholder-glow">
                <Col lg={12} className="mt-4">
                  <div className="placeholder bg-light table-placeholder col-12"></div>
                </Col>
              </Row>
              :
              <Row className="workforce-staff">
                <Col lg={12}>
                  <Tab.Container defaultActiveKey="home1">
                    <Card className="staff-tabel-card">
                      <Card.Body>
                        <Row className="align-items-center g-2">
                          <Col lg={12}>
                            <div className="form-check mb-2" style={{ float: 'right' }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Allstatus"
                                checked={isArchived}
                                onChange={() => setIsArchived(!isArchived)}
                              />
                              <label className="form-check-label font-weight-bold" htmlFor="Allstatus">
                                <b>Show Archive List</b>
                              </label>
                            </div>
                          </Col>
                          <Col lg={5} className="btn-employee-section mb-3">
                            <h5>
                              Client List  <Badge>{totalRecords}</Badge>
                            </h5>
                          </Col>
                          <Col lg={7} className="btn-section mb-3">
                            <div className="app-search d-none d-md-inline-flex">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  id="search-options"
                                  value={inputValue}
                                  onChange={handleChange}
                                />
                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                <span
                                  className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                  id="search-close-options"
                                ></span>
                              </div>
                            </div>
                            <Button
                              onClick={openAddForm}
                              className="next-btn"
                            >
                              <i className="ri-add-fill"></i> Add New
                            </Button>
                            {expLoader ? (
                              <Button
                                color="primary"
                                className="export-btn"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            ) : (
                              <Button onClick={exportClient} className='export-btn'>
                                <i className="bx bx-export me-1 "></i>  Export
                              </Button>
                            )}
                          </Col>
                          <Col lg={12} className="leave-table mt-0 px-4">
                            <ClientListTable data={ClientDetail} currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange} editClientMaster={editMaster} archiveClient={archiveClient} handleRestoreClient={handleRestoreClient} handleArchiveClient={handleArchiveClient} handleSortChange={handleSortChange} />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Container>
                </Col>
              </Row>
          }
        </Container>
      </div>
      <Offcanvas
        className="add-client-offcanvas"
        show={clientCanvas}
        onHide={handleAddClient}
        placement="bottom"
        style={{ minHeight: "90vh" }}
      >
        <Offcanvas.Header className="add-header border-bottom" closeButton>
          <Offcanvas.Title id="offcanvasBottomLabel">
            {isEditing ? "Edit" : "Add"} Client
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tab.Container defaultActiveKey="General">
            <Nav as="ul" variant="tabs" className="mb-3">
              <Nav.Item as="li">
                {" "}
                <Nav.Link
                  style={{ fontSize: "15px", fontWeight: "600" }}
                  eventKey="General"
                >
                  {" "}
                  General Details{" "}
                </Nav.Link>{" "}
              </Nav.Item>
              {isEditing &&
                <Nav.Item as="li">
                  {" "}
                  <Nav.Link
                    style={{ fontSize: "15px", fontWeight: "600" }}
                    eventKey="Document"
                  >
                    {" "}
                    Client Document{" "}
                  </Nav.Link>{" "}
                </Nav.Item>
              }
            </Nav>

            <Tab.Content className="text-muted">
              <Tab.Pane eventKey="General" id="General">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="12" className="mb-2">
                          <h5 style={{ color: '#ccc' }}>General Details</h5>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>Client Name</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="name"
                                className="form-control-lg form-control-icon name-input"
                                placeholder="Enter Client Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!(formik.touched.name && formik.errors.name)}
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className="bx bx-face"
                              ></i>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <SearchableAddress
                              label="Location Address"
                              name="address"
                              placeholder="Enter Address"
                              onChange={handleAddressChange}
                              invalid={false}
                              defaultValue={{
                                latitude: "",
                                longitude: "",
                                address: address,
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>Invoice Note</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="invoice_note"
                                className="form-control-lg form-control-icon name-input"
                                placeholder="Enter Invoice Note"
                                value={formik.values.invoice_note}
                                onChange={formik.handleChange}
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className=" ri-file-list-2-line"
                              ></i>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>ABN Number</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="abn_no"
                                className="form-control-lg form-control-icon name-input"
                                placeholder="Enter ABN Number"
                                value={formik.values.abn_no}
                                onChange={formik.handleChange}
                                isInvalid={!!(formik.touched.abn_no && formik.errors.abn_no)}
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className=" ri-file-list-2-line"
                              ></i>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.abn_no}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>City</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="city"
                                value={alladdress?.city ? alladdress?.city : (formik.values.city || "-")}
                                className="form-control-lg form-control-icon name-input"
                                disabled
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className="  ri-map-pin-line"
                              ></i>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>State</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="state"
                                value={alladdress?.state ? alladdress?.state : (formik.values.state || "-")}
                                className="form-control-lg form-control-icon name-input"
                                disabled
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className=" ri-map-pin-line"
                              ></i>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>Postal Code</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="postal_code"
                                value={alladdress?.postalCode ? alladdress?.postalCode : (formik.values.postal_code || "-")}
                                className="form-control-lg form-control-icon name-input"
                                disabled
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className=" ri-map-pin-line"
                              ></i>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          <Form.Group className="mb-4">
                            <Form.Label>Country</Form.Label>
                            <div className="position-relative form-icon">
                              <Form.Control
                                type="name"
                                name="country"
                                value={alladdress?.country ? alladdress?.country : (formik.values.country || "-")}
                                className="form-control-lg form-control-icon name-input"
                                disabled
                              />
                              <i
                                style={{ fontSize: "16px" }}
                                className=" ri-map-pin-line"
                              ></i>
                            </div>
                          </Form.Group>
                        </Col>

                      </Row>
                    </Col>
                    <Col lg="12" className="mb-2">
                      <h5 style={{ color: '#ccc' }}>Contact Details</h5>
                    </Col>
                    <Col lg="3">
                      <Form.Group className="mb-4">
                        <Form.Label>Contact Person Name</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="name"
                            name="contact_person"
                            className="form-control-lg form-control-icon name-input"
                            placeholder="Enter Contact Person Name"
                            value={formik.values.contact_person}
                            onChange={formik.handleChange}
                          />
                          <i
                            style={{ fontSize: "16px" }}
                            className="bx bx-face"
                          ></i>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group className="mb-4">
                        <Form.Label>Contact Person Number</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="name"
                            name="contact_phone"
                            className="form-control-lg form-control-icon name-input"
                            placeholder="Enter Contact Person Number"
                            value={formik.values.contact_phone}
                            onChange={formik.handleChange}
                            isInvalid={!!(formik.touched.contact_phone && formik.errors.contact_phone)}
                          />
                          <i
                            style={{ fontSize: "16px" }}
                            className=" ri-keyboard-line"
                          ></i>
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.contact_phone}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group className="mb-4">
                        <Form.Label>Contact Person Email</Form.Label>
                        <div className="position-relative form-icon">
                          <Form.Control
                            type="name"
                            name="contact_email"
                            className="form-control-lg form-control-icon name-input"
                            placeholder="Enter Contact Person Email"
                            value={formik.values.contact_email}
                            onChange={formik.handleChange}
                            isInvalid={!!(formik.touched.contact_email && formik.errors.contact_email)}
                          />
                          <i
                            style={{ fontSize: "16px" }}
                            className="  ri-mail-open-line"
                          ></i>
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.contact_email}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group className="mb-3 form-icon">
                        <Form.Label>Invoice Template</Form.Label>
                        <div className={`react-select-container ${formik.touched.invoice_template_id && formik.errors.invoice_template_id ? 'is-invalid' : ''}`}>
                          <Select
                            inputId="invoice_template_id"
                            name="invoice_template_id"
                            options={TemplateDetail}
                            value={TemplateDetail.find(option => option.value === formik.values.invoice_template_id)}
                            onChange={handleInvoiceTemplateChange}
                            onBlur={formik.handleBlur}
                            classNamePrefix="react-select"
                            className="react-select"
                          />
                          <i
                            style={{ fontSize: "18px" }}
                            className="ri-file-text-line mt-4"
                          ></i>
                          {formik.touched.invoice_template_id && formik.errors.invoice_template_id ? (
                            <div className="invalid-feedback d-block">
                              {formik.errors.invoice_template_id}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      lg="12"
                      className="offcanvas-footer pb-0 text-center"
                      style={{
                        position: "fixed",
                        bottom: "0",
                        width: "100%",
                        left: "0",
                      }}
                    >
                      <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                        <Row>
                          <Col lg={12} className="off-bottom-staff justify-content-end">
                            <div className="off-confirm">
                              <Button
                                onClick={handleAddClient}
                                color="primary"
                                className="clear-btn"
                              >
                                Cancel
                              </Button>
                              {formik.isSubmitting ? (
                                <Button
                                  color="primary"
                                  className="next-btn"
                                  disabled
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </Button>
                              ) : (
                                <Button color="primary" className="next-btn" type="submit" disabled={formik.isSubmitting}>
                                  {isEditing ? "Update" : "Add"}
                                </Button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey="Document" id="Document">
                <Row>
                  <Col lg="12">
                    <div className="d-flex justify-content-end gap-2">
                      <div className="app-search d-none d-md-inline-flex">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            id="search-options"
                            style={{ height: '47px' }}
                            value={inputDocValue}
                            onChange={handleDocChange}
                          />
                          <span style={{ top: '5px' }} className="mdi mdi-magnify search-widget-icon"></span>
                          <span
                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                            id="search-close-options"
                          ></span>
                        </div>
                      </div>
                      <Button onClick={openAddDocForm} className="next-btn" style={{ padding: "12px", width: '100px' }}>
                        Add
                      </Button>
                    </div>
                  </Col>
                  <Col lg="12" className="mt-3">
                    <ClientDocumentTable handlePageChange={handlePageDocChange} handleSort={handleSort} handlePerRowsChange={handlePerRowsDocChange} paginationTotalRows={totalRows} data={ClientDocumentDetail} handleArchiveClientDoc={handleArchiveClientDoc} editClientDocument={editClientDocument} />
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Offcanvas.Body>
      </Offcanvas >
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to archive this Client.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
              {archiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveClient(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modal_doc_center}
        onHide={() => {
          tog_doc_center();
        }}
        size="sm"
        centered
      >

        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to delete this Client Document.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_doc_center(false)}>Close</Button>
              {docArchiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => {
                  if (archiveClientDocId !== undefined) {
                    archiveMaster(archiveClientDocId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modal_restore_center}
        onHide={() => {
          tog_restore_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className=" ri-restart-line text-success display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to restore this Client.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_restore_center(false)}>Close</Button>
              {restoreLoader ? (
                <Button
                  variant="success"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="success" onClick={() => {
                  if (restoreId !== undefined) {
                    restoreClient(restoreId);
                  } else {
                    toast.error("No Restore ID provided.");
                  }
                }}>Restore</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal id="myModal" show={modal_standard} onHide={() => { tog_standard(); }} >
        <Modal.Header className="modal-title fs-xl" id="myModalLabel">
          {isEditingDoc ? "Edit" : "Add"} Client Document
        </Modal.Header>
        <Form onSubmit={docformik.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col lg="12">
                <Form.Group className="mb-3">
                  <Form.Label>Document Name</Form.Label>
                  <div className="position-relative form-icon">
                    <Form.Control
                      type="text"
                      name="document_name"
                      className="form-control-lg form-control-icon"
                      placeholder="Enter Document Name"
                      value={docformik.values.document_name}
                      onChange={docformik.handleChange}
                      onBlur={docformik.handleBlur}
                      isInvalid={!!(docformik.touched.document_name && docformik.errors.document_name)}
                    />
                    <i style={{ fontSize: '16px' }} className="ri-file-list-line"></i>
                    <Form.Control.Feedback type="invalid">
                      {docformik.errors.document_name}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12" className="upload-section-col">
                <Form.Group>
                  <Form.Label className="doc-label">Document Upload</Form.Label>
                  <div className={`uploadsection bg-white ${!!(docformik.touched.document_path && docformik.errors.document_path) ? 'invalid-border' : ''}`}>
                    {showUploadButton ? (
                      <div className="d-flex align-item-center">
                        <Form.Label htmlFor="file" className="uploadlabel">
                          <Form.Control
                            type="file"
                            className="form-control-lg"
                            style={{ display: 'none' }}
                            id="file"
                            name="document_path"
                            onChange={handleFileChange}
                            isInvalid={!!(docformik.touched.document_path && docformik.errors.document_path)}
                          />
                          <i className="bx bx-plus"></i> Choose File
                        </Form.Label>
                      </div>
                    ) : (
                      <Col lg="12" className="closeuser py-0">
                        <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="file-name">{file ? file.name : docformik.values.document_path ? docformik.values.document_path : ''}</p>
                        <Button onClick={handleRemoveFile} color="red">
                          X
                        </Button>
                      </Col>
                    )}
                  </div>
                  {docformik.touched.document_path && docformik.errors.document_path ? (
                    <div className="invalid-feedback d-block">{docformik.errors.document_path}</div>
                  ) : null}
                  {(docformik.values.document_path || file) && (
                    <div className="closeuser d-flex gap-2 align-items-center justify-content-start">
                      <i
                        onClick={() => {
                          if (file) {
                            handleFilePreview(file);
                          } else if (docformik.values.document_path) {
                            handleOpenDocument(docformik.values.document_path);
                          }
                        }}
                        className="ri-eye-line link-info"
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                      ></i>
                      <p className="mb-0">File Selected: {file?.name ? file?.name : docformik.values.document_path}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button variant="light" onClick={() => tog_standard()}>Close</Button>
            {docformik.isSubmitting ? (
              <Button
                color="primary"
                className="next-btn"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button
                color="primary"
                type="submit"
              >
                {isEditingDoc ? "Update" : "Add"}
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    </React.Fragment >
  );
};

export default ClientList;
