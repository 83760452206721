import React, { useState, useEffect } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Offcanvas,
    Form,
    Modal,
    Badge,
    Dropdown
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { GetPendingTimesheetList, PendingPayrollByIdList } from "helpers/saibackend_helper";
import PendingTimesheetTable from "./PendingTimesheetTable";
import SimpleBar from "simplebar-react";


const PendingTimesheet = (() => {

    const [PendingTimesheetDetail, setPendingTimesheetDetail] = useState([]);
    const [isBottom, setIsBottom] = useState(false);
    const getPendingTimesheetDetails = () => {
        setIsBottom(true);
    };
    const [payrollStaff, setPayrollStaff] = useState({
        value: ""
    })
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [payloadValue, setPayloadValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0,
    });

    const fetchPendingTimesheetDetails = async () => {
        try {
            const response = await GetPendingTimesheetList(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
            );
            setPendingTimesheetDetail(response.data.data_list);
            setTotalPages(response.data.total_pages);
            setCurrentPage(payloadValue.page);
            setTotalRecords(response.data.total_records);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPendingTimesheetDetails();
    }, [payloadValue]);


    const handleSortChange = (column: string) => {
        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
                sort_by: column
            },
            page: 1
        }));
    };


    const handlePageChange = (page: number) => {
        setPayloadValue({ ...payloadValue, page: page });
    };

    const [PendingPayrollByIdDetail, setPendingPayrollByIdDetail] = useState([]);

    const fetchPendingSingleTimesheetDetails = async (id: number) => {
        try {
            const response = await PendingPayrollByIdList(id);
            getPendingTimesheetDetails()
            setPendingPayrollByIdDetail(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Pending - Timesheet" pageTitle="Payroll" pageTitleLink="/payroll" />
                    {
                        loading ?
                            <Row className="placeholder-glow">
                                <Col lg={12} className="mt-4">
                                    <div className="placeholder bg-light table-placeholder col-12"></div>
                                </Col>
                            </Row>
                            :
                            <Row className="payroll">
                                <Col lg={12}>
                                    <Card className="staff-tabel-card">
                                        <Card.Body>
                                            <Row className="align-items-center g-2 mt-0">
                                                <Col lg={12} className="payroll-table mt-0">
                                                    <PendingTimesheetTable
                                                        data={PendingTimesheetDetail}
                                                        currentPage={currentPage}
                                                        totalPages={totalPages}
                                                        onPageChange={handlePageChange}
                                                        handleSortChange={handleSortChange}
                                                        fetchPendingSingleTimesheetDetails={fetchPendingSingleTimesheetDetails}
                                                        setPayrollStaff={setPayrollStaff}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
            <Offcanvas
                className="add-role-canvas"
                show={isBottom}
                onHide={() => setIsBottom(false)}
                placement="bottom"
                style={{ minHeight: "700px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">{payrollStaff.value}</Offcanvas.Title>
                </Offcanvas.Header>
                <>
                    <SimpleBar
                        style={{ maxHeight: "600px" }}
                        className="px-3"
                    >
                        <Offcanvas.Body>
                            <Row>
                                <Col lg="12">
                                    <div className="table-card p-3">
                                        <table className="table align-middle table-bordered">
                                            <thead className="table-light">
                                                <tr style={{ textAlign: "center" }}>
                                                    <th style={{ width: '8%' }}>Sr No.</th>
                                                    <th scope="col" style={{ width: '8%' }}>Date</th>
                                                    <th scope="col" style={{ width: '8%' }}>Client Name</th>
                                                    <th scope="col" style={{ width: '8%' }}>Location</th>
                                                    <th scope="col" style={{ width: '8%' }}>Staff</th>
                                                    <th scope="col" style={{ width: '8%' }}>Time</th>
                                                    <th scope="col" style={{ width: '8%' }}>Total Break Hrs</th>
                                                    <th scope="col" style={{ width: '8%' }}>Total Hrs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {PendingPayrollByIdDetail.map((timesheet: any) => {
                                                    return (
                                                        <tr key={timesheet.id} style={{ textAlign: "center" }}>
                                                            <td>{timesheet.id}</td>
                                                            <td>{timesheet.date}</td>
                                                            <td>{timesheet.client_name}</td>
                                                            <td>{timesheet.location}</td>
                                                            <td>{timesheet.staff}</td>
                                                            <td>{timesheet.time}</td>
                                                            <td>{timesheet.total_break_hrs}</td>
                                                            <td>{timesheet.total_hrs}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </SimpleBar>
                </>
            </Offcanvas>
        </>
    )
})

export default PendingTimesheet;