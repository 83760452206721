import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { getByStaffDetail, getStaffDocumentDetail } from 'helpers/saibackend_helper';
import { Offcanvas, Button, Row, Col, Card, Table, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import profile from 'assets/images/auth/profile.png'
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

interface StaffData {
    avatar: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile_no: string;
    employement_type: string;
    access_level: string;
    has_subcontractor: number;
    subcontractor_id: number;
    employee_pin: string;
    invited: number;
    employee_name: string;
    employee_status: string
    address: string,
    dob: string;
    start_date: string
    gender: string,
    emergency_name: string,
    emergency_number: string,
    relation: string,
    emergency_email: string,
    bank_name: string,
    bank_holder_name: string,
    acc_no: string,
    bsb_no: string,
    superannuation_fund_name: string,
    superannuation_account_no: string,
    tfn_no: string,
    staff_locations_count: number,
    weekly_working_hours: number,
    staff_leaves: [],
    staff_locations: [],
    pay_schedule_name:string
}

interface StaffDocumentData {
    id: number;
    document_name: string;
    document_path: string;
    expiry_date: string;
    document_type: string
    ref_no: string
    expired_status: string
}


const StaffTable = ({ StaffDetail, handleSortChange, handleArchiveStaff, handleRestoreStaff, currentPage, totalPages, onPageChange, canAccess }: { StaffDetail: any, handleSortChange: (column: string, direction: string) => void; handleArchiveStaff: (id: number) => Promise<void>; handleRestoreStaff: (id: number) => Promise<void>; currentPage: number, totalPages: number, onPageChange: (page: number) => void, canAccess: boolean }) => {

    const [isBottom, setIsBottom] = useState(false);
    const [clickedItemId, setClickedItemId] = useState<string | null>(null);

    const toggleBottomCanvas = (id: string | null) => {
        setIsBottom(!isBottom);
        if (id) {
            setClickedItemId(id);
            localStorage.setItem('clickedItemId', id);
        } else {
            setClickedItemId(null);
            localStorage.removeItem('clickedItemId');
        }
    };
    const [StaffViewDetail, setStaffViewDetail] = useState<StaffData | null>(null);

    const editMaster = async () => {
        try {
            if (clickedItemId !== null) {
                const response = await getByStaffDetail(parseInt(clickedItemId));
                const data = response.data;
                setStaffViewDetail(data);
            } else {

            }
        } catch (error) {
            console.error("Error fetching Compliance Master details:", error);
            toast.error("Error fetching details. Please try again later.");
        }
    };

    useEffect(() => {
        editMaster()
    }, [clickedItemId])


    const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
        StaffDocumentData[]
    >([]);

    const fetchStaffDocumentDetails = async () => {
        try {
            if (Number(clickedItemId) > 0) {
                const response = await getStaffDocumentDetail("", Number(clickedItemId));
                setStaffDocumentDetail(response.data.data_list);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchStaffDocumentDetails();
    }, [clickedItemId]);

    const doc_url = process.env.REACT_APP_IMAGE_URL

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

    const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

    let displayText = '';
    if (leaveNames.length > 2) {
        displayText = `${leaveNames.slice(0, 2).join(', ')}`;
    } else {
        displayText = leaveNames.join(', ');
    }

    const getAbbreviation = (name: string) => {
        return name
            ?.split(' ')  // Split the name by spaces
            ?.map(word => word.charAt(0))  // Take the first character of each word
            ?.join('')  // Join them together
            ?.toUpperCase()  // Convert to uppercase
            ?.slice(0, 2);  // Ensure only the first two characters are returned
    };




    const columns = useMemo(
        () => [
            // {
            //     header: (<Form.Check> <Form.Check.Input type="checkbox" id="checkAll" value="option" /> </Form.Check>),
            //     cell: (cellProps: any) => {
            //         return (<Form.Check> <Form.Check.Input type="checkbox" name="chk_child" defaultValue="option1" /> </Form.Check>);
            //     },
            //     id: '#',
            //     enableColumnFilter: false,
            //     enableSorting: false,
            // },
            {
                header: "Staff Name",
                accessorKey: "name",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    const itemId = cell.row.original.id;
                    return (<div>
                        <div className='d-flex align-items-center gap-3' style={{ cursor: 'pointer' }} onClick={() => toggleBottomCanvas(itemId)}>
                            {cell.row.original.avatar
                                ?
                                <div className="profile-user position-relative d-inline-block">
                                    <img
                                        src={`${doc_url}/${cell.row.original.avatar}`}
                                        alt=""
                                        style={{ width: '2.5rem', height: '2.5rem' }}
                                        className={`rounded-circle object-fit-cover user-profile-image`}
                                    />
                                </div>
                                :
                                <div style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <div className="avatar-title rounded-circle bg-danger-subtle text-danger">
                                        {getAbbreviation(cell.row.original.employee_name)}
                                    </div>
                                </div>
                            }
                            <div>
                                <h5 className='mb-0'>{cell.row.original.employee_name}</h5>
                                <p className='mb-0'>{cell.row.original.email}</p>
                            </div>
                        </div>
                    </div>);
                },
            },
            {
                header: "Mobile Number",
                accessorKey: "mobile_no",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (<div>
                        <div>
                            <h5 className='mb-0'>{cell.row.original.mobile_no ? cell.row.original.mobile_no : "-"}</h5>
                        </div>
                    </div>);
                },
            },
            {
                header: "Access Level",
                accessorKey: "access_level",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (<div>
                        <div>
                            <h5 className='mb-0'>{cell.row.original.access_level ? cell.row.original.access_level : "EMPLOYEE"}</h5>
                        </div>
                    </div>);
                },
            },
            {
                header: "Employment Type",
                accessorKey: "employee_status",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (<div>
                        <div>
                            <h5 className='mb-0'>{cell.row.original.employee_status ? cell.row.original.employee_status : "-"}</h5>
                        </div>
                    </div>);
                },
            },
            ...(canAccess ? [{
                header: "Subcontractor Name",
                accessorKey: "subcontractor_name",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (<div>
                        <div>
                            <h5 className='mb-0'>{cell.row.original.subcontractor_name ? cell.row.original.subcontractor_name : "-"}</h5>
                        </div>
                    </div>);
                }
            }] : []),
            {
                header: "Status",
                accessorKey: "Status",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,

                cell: (cellProps: any) => {
                    const statusValue = cellProps.row.original.Status;
                    return (
                        <span className={cellProps.row.original.is_archive === 0 ? statusValue : "text-danger"}>
                            {cellProps.row.original.is_archive === 0 ? statusValue : "Archived"}
                        </span>
                    );
                },
            },
            {
                header: "Action",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const itemId = cellProps.row.original.id;
                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <i onClick={() => toggleBottomCanvas(itemId)} className="ri-eye-line link-info p-0"
                                    style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            </div>
                            {
                                cellProps.row.original.is_archive == 1 ?
                                    <div className="edit">
                                        <i className="ri-restart-line link-success" onClick={() => handleRestoreStaff(itemId)} style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </div> :
                                    <div className="edit">
                                        <i onClick={() => handleArchiveStaff(itemId)} className="ri-delete-bin-line link-danger" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </div>
                            }
                            {/* <div className="edit">
                                <Link to="/staff-calendar" className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn">
                                    <i className="ri-calendar-event-line"></i>
                                </Link>
                            </div> */}
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            {StaffDetail.length > 0 ?
                <TableContainer
                    isPagination={true}
                    columns={(columns || [])}
                    data={StaffDetail}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
                :
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Staff Available</h5>

                    </div>
                </div>
            }

            <Offcanvas className="add-people" show={isBottom} onHide={toggleBottomCanvas} placement='bottom' style={{ minHeight: "90vh" }}>
                <Offcanvas.Header className="add-header border-bottom">
                    <Offcanvas.Title id="offcanvasBottomLabel" className='m-auto'>{StaffViewDetail?.first_name} {StaffViewDetail?.last_name} Profile</Offcanvas.Title>
                    <div>
                        <Button className='edit-btn' href='/edit-staff'>
                            Edit
                        </Button>
                        <Button className='close-btn' onClick={() => { setIsBottom(false) }}>
                            <i className='ri-close-line'></i>
                        </Button>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <div className='user-details-rs' style={{ flex: '0.5' }} >
                            <Card className='details-section'>
                                <Card.Body>
                                    <Row>
                                        <Col lg="12" className='edit-main'>
                                            {StaffViewDetail?.avatar ?
                                                <div className="profile-user position-relative d-inline-block mx-auto pb-3">
                                                    <img
                                                        src={imageSrc}
                                                        alt=""
                                                        className={`avatar-lg rounded-circle object-fit-cover img-thumbnail user-profile-image`}
                                                    />
                                                </div>
                                                :
                                                <div style={{ width: '6rem', height: '6rem' }} className="m-auto mb-3">
                                                    <div style={{ borderRadius: '50%', fontSize: '35px', background: '#d51820', color: '#fff' }} className="avatar-title">
                                                        {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                                    </div>
                                                </div>
                                            }
                                            <h5 className='propname'>{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</h5>
                                            <p className='propemployee'>{StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"}</p>
                                            <p className='proptype'>{StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"}</p>
                                        </Col>
                                        <Col lg="12">
                                            <p className='detail-people'><i className='ri-phone-line icon-profile'></i> {StaffViewDetail?.mobile_no ? StaffViewDetail?.mobile_no : "-"}</p>
                                            <p className='detail-people'><i className='ri-mail-line icon-profile'></i> {StaffViewDetail?.email ? StaffViewDetail?.email : "-"}</p>
                                            <p className='detail-people'><i className='ri-map-pin-line icon-profile'></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"}</p>
                                            <p className='detail-people'><i className='ri-cake-line icon-profile'></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"}</p>
                                            <p className='detail-people'><i className='mdi mdi-gender-transgender icon-profile'></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"}</p>
                                            <p className='detail-people'><i className='ri-calendar-event-line icon-profile'></i> {StaffViewDetail?.start_date ? StaffViewDetail?.start_date : "-"}</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                        <div style={{ flex: '2' }}>
                            <Row>
                                <Col className="user-details-section-col" lg={6}>
                                    <Card className='details-section-second'>
                                        <Card.Body>
                                            <h5>Emergency Details</h5>
                                            <Row>
                                                <Col lg={6} className='user-details-col'>
                                                    <p className='detail-title'>Name</p>
                                                    <p className='detail-value'>{StaffViewDetail?.emergency_name ? StaffViewDetail?.emergency_name : "-"}</p>
                                                </Col>
                                                <Col lg={6} className='user-details-col'>
                                                    <p className='detail-title'>Relation</p>
                                                    <p className='detail-value'>{StaffViewDetail?.relation ? StaffViewDetail?.relation : "-"}</p>
                                                </Col>
                                                <Col lg={6} className='emp-type user-details-col'>
                                                    <p className='detail-title'>Phone</p>
                                                    <p className='detail-value'>{StaffViewDetail?.emergency_number ? StaffViewDetail?.emergency_number : "-"}</p>
                                                </Col>
                                                <Col lg={6} className='emp-type user-details-col'>
                                                    <p className='detail-title'>Email</p>
                                                    <p className='detail-value'>{StaffViewDetail?.emergency_email ? StaffViewDetail?.emergency_email : "-"}</p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="user-details-section-col" lg={6}>
                                    <Card className='details-section-second'>
                                        <Card.Body>
                                            <h5>Professional Details</h5>
                                            <Row>
                                                <Col lg={6} className='user-details-col'>
                                                    <p className='detail-title'>Working Hours</p>
                                                    <p className='detail-value'>{StaffViewDetail?.weekly_working_hours ? `${StaffViewDetail?.weekly_working_hours} Hr` : "-"}</p>
                                                </Col>
                                                <Col lg={6} className='user-details-col'>
                                                    <p className='detail-title'>Working Locations</p>
                                                    <p className='detail-value'>{StaffViewDetail?.staff_locations_count ? StaffViewDetail?.staff_locations_count : "-"}</p>
                                                </Col>
                                                {/* <Col lg={6} className='emp-type user-details-col'>
                                                    <p className='detail-title'>Staff Type</p>
                                                    <p className='detail-value'>{StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"}</p>
                                                </Col> */}
                                                <Col lg={12} className='emp-type user-details-col'>
                                                    <p className='detail-title'>Leave Privileges</p>
                                                    <p className='detail-value'>
                                                        {displayText ?
                                                            <>
                                                                {displayText} &nbsp;
                                                                {leaveNames.length > 2 && (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                {leaveNames.join(', ')}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span
                                                                            className="btn btn-outline-primary btn-sm rounded-pill"
                                                                        >
                                                                            +{leaveNames.length - 2} More
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </>
                                                            : "-"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col className="user-details-section-col" lg="12">
                                    <Card className='details-section-four'>
                                        <Card.Body>
                                            <h5>Bank, Super and TFN Details</h5>
                                            <Row>
                                                <Col lg={3} className='user-details-col'>
                                                    <p className='detail-title'>Bank Name</p>
                                                    <p className='detail-value'>{StaffViewDetail?.bank_name ? StaffViewDetail?.bank_name : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='user-details-col'>
                                                    <p className='detail-title'>A/C Holder Name</p>
                                                    <p className='detail-value'>{StaffViewDetail?.bank_holder_name ? StaffViewDetail?.bank_holder_name : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='user-details-col'>
                                                    <p className='detail-title'>Account Number</p>
                                                    <p className='detail-value'>{StaffViewDetail?.acc_no ? StaffViewDetail?.acc_no : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='user-details-col'>
                                                    <p className='detail-title'>BSB Number</p>
                                                    <p className='detail-value'>{StaffViewDetail?.bsb_no ? StaffViewDetail?.bsb_no : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='rs-details user-details-col'>
                                                    <p className='detail-title'>Super Fund Name</p>
                                                    <p className='detail-value'>{StaffViewDetail?.superannuation_fund_name ? StaffViewDetail?.superannuation_fund_name : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='rs-details user-details-col'>
                                                    <p className='detail-title'>Super Account number</p>
                                                    <p className='detail-value'>{StaffViewDetail?.superannuation_account_no ? StaffViewDetail?.superannuation_account_no : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='rs-details user-details-col'>
                                                    <p className='detail-title'>TFN</p>
                                                    <p className='detail-value'>{StaffViewDetail?.tfn_no ? StaffViewDetail?.tfn_no : "-"}</p>
                                                </Col>
                                                <Col lg={3} className='rs-details user-details-col'>
                                                    <p className='detail-title'>Pay Schedule Name</p>
                                                    <p className='detail-value'>{StaffViewDetail?.pay_schedule_name ? StaffViewDetail?.pay_schedule_name : "-"}</p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="user-details-section-col" lg={8}>
                                    <Card className='details-section-third mb-0'>
                                        <Card.Body>
                                            <Row>
                                                <Col lg="12">
                                                    <h5>Documents</h5>
                                                </Col>
                                                <Col lg="12">
                                                    <SimpleBar
                                                        className="py-0"
                                                        style={{ height: "290px" }}
                                                    >
                                                        {(StaffDocumentDetail && StaffDocumentDetail.length > 0) ? (
                                                            <Table responsive className="table table-nowrap">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Document Type</th>
                                                                        <th scope="col">Document Name</th>
                                                                        <th scope="col">License No</th>
                                                                        <th scope="col">Expiry Date</th>
                                                                        <th scope="col">Action</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {StaffDocumentDetail.map((doc) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {
                                                                                        <>
                                                                                            <span>{doc.document_type}&nbsp;</span>
                                                                                            {doc.expired_status === "Expired" && (
                                                                                                <Badge>Expired</Badge>
                                                                                            )}
                                                                                        </>
                                                                                    }
                                                                                </td>
                                                                                <td>{doc.document_name ? doc.document_name : "-"}</td>
                                                                                <td>{doc.ref_no ? doc.ref_no : "-"}</td>
                                                                                <td>{doc.expiry_date}</td>
                                                                                <td>
                                                                                    <i style={{ background: 'none' }} onClick={() => handleOpenDocument(doc.document_path)} className='ri-eye-line link-info'></i>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                                <div className="flex-grow-1 d-flex justify-content-center">
                                                                    <h6 className="fs-md mb-1">No Documents Available</h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </SimpleBar>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" className="user-details-section-col">
                                    <Card className="details-section-third">
                                        <Card.Body>
                                            <Row>
                                                <Col lg="12">
                                                    <h5>
                                                        Working Location List
                                                    </h5>
                                                </Col>
                                                <Col lg="12">
                                                    <SimpleBar className="py-0" style={{ height: "290px" }}>
                                                        {(StaffViewDetail?.staff_locations && StaffViewDetail?.staff_locations?.length > 0) ? (
                                                            <div className="table-responsive mt-0">
                                                                <Table className="m-0">
                                                                    <tbody>
                                                                        {
                                                                            StaffViewDetail?.staff_locations?.map((location: any) => (
                                                                                <tr key={location.location_id}>
                                                                                    <td>
                                                                                        <div className="d-flex align-items-center gap-1">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="fs-md mb-1">
                                                                                                    <Link to="#" className="text-reset">
                                                                                                        {location.location_name}
                                                                                                    </Link>
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                                                                <div className="flex-grow-1 d-flex justify-content-center">
                                                                    <h6 className="fs-md mb-1">No Location Available</h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </SimpleBar>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    </Row>
                </Offcanvas.Body>
            </Offcanvas>



        </React.Fragment >
    );
}

export default StaffTable;