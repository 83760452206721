import React, { useState, useEffect } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Button,
    Offcanvas,
    Form,
    OverlayTrigger,
    Tooltip,
    Dropdown,
    ButtonGroup,
    Modal,
    ListGroup,
    Badge,
    Nav,
    Tab,
    Table,
    Spinner
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import CheckpointTable from "./CheckpointTable";
import Flatpickr from "react-flatpickr";
import CountUp from 'react-countup';
import Select from "react-select";
import { getCheckPointLogDetail, getClientDropdownIncidentReportDetail, getStaffDropdownList, exportCheckpointLogDetail } from "helpers/saibackend_helper";

interface PayloadValue {
    page: number;
    page_size: string;
    search_term: string;
    sort: {
        sort_order: string;
        sort_by: string;
    };
    is_archive: number;
    client_id: number | number[];
    staff_id: number | number[];
    start_date: string,
    end_date: string
}

interface ClientData {
    id: number,
    name: string,
    no_of_locations: number,
    value: number
}


const CheckPointLogTable = (() => {

    const [courseFilters, setCourseFilters] = useState<boolean>(false);
    const handleFilterShow = () => setCourseFilters(!courseFilters);
    document.title = "Checkpoint - Log | The Guard Duty";

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [checkpointDetail, setCheckpointDetail] = useState<any>([]);
    const [payloadValue, setPayloadValue] = useState<PayloadValue>({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0,
        client_id: [],
        staff_id: [],
        start_date: "",
        end_date: ""
    });

    const fetchCheckpointDetails = async () => {
        try {
            const response = await getCheckPointLogDetail(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
                payloadValue.is_archive,
                payloadValue.client_id,
                payloadValue.staff_id,
                payloadValue.start_date,
                payloadValue.end_date
            );
            setCheckpointDetail(response.data.data_list);
            setTotalPages(response.data.total_pages);
            setCurrentPage(payloadValue.page);
            setTotalRecords(response.data.total_records);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCheckpointDetails();
    }, [payloadValue]);

    const handleSortChange = (column: string) => {
        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
                sort_by: column
            },
            page: 1
        }));
    };


    const handlePageChange = (page: number) => {
        setPayloadValue({ ...payloadValue, page: page });
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayloadValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
                page: 1
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const [ClientDropdown, setClientDropdown] = useState<ClientData[]>([])
    const [selectedClient, setSelectedClient] = useState<number[]>([]);

    const fetchClientDropdownListDetails = async () => {
        try {
            const searchTerm = "";
            const is_archive = 0
            const response = await getClientDropdownIncidentReportDetail(searchTerm, is_archive);
            setClientDropdown(response.data.data_list.map((client: any) => ({
                value: client.id,
                label: client.name
            })));
        } catch (error) {
        }
    };

    const [StaffDropdown, setStaffDropdown] = useState<ClientData[]>([])
    const [selectedStaff, setSelectedStaff] = useState<number[]>([]);

    const fetchStaffDropdownListDetails = async () => {
        try {
            const searchTerm = "";
            const response = await getStaffDropdownList(searchTerm);
            setStaffDropdown(response.data.data_list.map((staff: any) => ({
                value: staff.id,
                label: staff.staff_name
            })));
        } catch (error) {
        }
    };


    useEffect(() => {
        if (courseFilters) {
            fetchClientDropdownListDetails()
            fetchStaffDropdownListDetails();
        }
    }, [courseFilters]);

    const [selectedFilterDates, setSelectedFilterDates] = useState<Date[]>([]);
    const [dateFilterKey, setDateFilterKey] = useState(0);

    const handleDateRangeFilterChange = (dates: Date[]) => {
        if (dates.length === 2) {
            setSelectedFilterDates(dates);
        } else {
            console.error('Invalid date range selection:', dates);
        }
    };


    const formatFilterDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const applyFilters = () => {
        if (selectedFilterDates.length === 2) {
            setPayloadValue((prevValue) => ({
                ...prevValue,
                start_date: formatFilterDate(selectedFilterDates[0]),
                end_date: formatFilterDate(selectedFilterDates[1]),
                client_id: selectedClient.map((selectedClient: any) => selectedClient.value),
                staff_id: selectedStaff.map((selectedStaff: any) => selectedStaff.value),
            }));
            setCourseFilters(false)
        } else {
            setPayloadValue((prevValue) => ({
                ...prevValue,
                client_id: selectedClient.map((selectedClient: any) => selectedClient.value),
                staff_id: selectedStaff.map((selectedStaff: any) => selectedStaff.value),
            }));
            setCourseFilters(false)
        }
    };

    const ClearAllFilter = () => {
        setDateFilterKey((prevKey) => prevKey + 1);
        setSelectedFilterDates([])
        setCourseFilters(false)
        setPayloadValue((prevValue) => ({
            ...prevValue,
            start_date: "",
            end_date: "",
            client_id: [],
            staff_id: [],
        }));
        setSelectedClient([])
        setSelectedStaff([])
    };

    const [expLoader, setExpLoader] = useState(false)

    const downloadcheckpoint = async () => {
        setExpLoader(true)
        try {
            await exportCheckpointLogDetail(
                payloadValue.page,
                payloadValue.page_size,
                payloadValue.search_term,
                payloadValue.sort.sort_order,
                payloadValue.sort.sort_by,
                payloadValue.is_archive,
                payloadValue.start_date,
                payloadValue.end_date,
                payloadValue.client_id,
                payloadValue.staff_id,
            );
            setExpLoader(false)
        } catch (error) {
            return;
        }
    }


    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Checkpoint - Log" pageTitle="Schedule" />
                        {
                            loading ?
                                <Row className="placeholder-glow">
                                    <Col lg={12} className="mt-4">
                                        <div className="placeholder bg-light table-placeholder col-12"></div>
                                    </Col>
                                </Row>
                                :
                                <Row className="schedule">
                                    <Col lg={12}>
                                        <Card className="staff-tabel-card" style={{ borderRadius: '16px' }}>
                                            <Card.Body>
                                                <Row className="align-items-center g-2">
                                                    <Col lg={12} className="btn-section mb-3">
                                                        <div className="app-search d-none d-md-inline-flex">
                                                            <div className="position-relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    id="search-options"
                                                                    value={inputValue}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                                                <span
                                                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                    id="search-close-options"
                                                                ></span>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            className="filter-btn"
                                                            onClick={() => handleFilterShow()}
                                                        >
                                                            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                                                            Filters
                                                        </Button>
                                                        {expLoader ? (
                                                            <Button
                                                                color="primary"
                                                                className="export-btn"
                                                                disabled
                                                            >
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={downloadcheckpoint} className='export-btn'>
                                                                <i className="bx bx-export me-1 "></i>  Export
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col lg={12} className="leave-table mt-0">
                                                        <CheckpointTable
                                                            data={checkpointDetail}
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                            handleSortChange={handleSortChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </div>
                <Offcanvas
                    className="filter-canvas"
                    show={courseFilters}
                    placement="end"
                    onHide={handleFilterShow}
                >
                    <Offcanvas.Header
                        id="courseFilters"
                        className="border-bottom filter-canvas"
                        closeButton
                    >
                        <Offcanvas.Title id="courseFiltersLabel">
                            {" "}
                            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                            Filters
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <Row className="filter-1">
                                        <Col lg={12}>
                                            <h5>Client Name</h5>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-icon">
                                                <div className={`react-select-container`}>
                                                    <Select
                                                        options={ClientDropdown}
                                                        isMulti
                                                        value={selectedClient}
                                                        onChange={(newValue: any) => setSelectedClient(newValue || [])}
                                                        getOptionLabel={(option: any) => option.label}
                                                        getOptionValue={(option: any) => option.value}
                                                        placeholder="Select clients"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <Row className="filter-2">
                                        <Col lg={12}>
                                            <h5>Staff Name</h5>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-icon">
                                                <div className={`react-select-container`}>
                                                    <Select
                                                        options={StaffDropdown}
                                                        isMulti
                                                        value={selectedStaff}
                                                        onChange={(newValue: any) => setSelectedStaff(newValue || [])}
                                                        getOptionLabel={(option: any) => option.label}
                                                        getOptionValue={(option: any) => option.value}
                                                        placeholder="Select Staff"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <Row className="filter-2">
                                        <Col lg={12}>
                                            <h5>Date Period</h5>
                                        </Col>
                                        <Col lg="12" className="mb-3">
                                            <Flatpickr
                                                className="form-control"
                                                key={dateFilterKey}
                                                placeHolder="Select a date range"
                                                options={{
                                                    mode: 'range',
                                                    dateFormat: 'd-m-Y',
                                                    defaultDate: selectedFilterDates.length ? selectedFilterDates : [null, null]
                                                }}
                                                value={selectedFilterDates}
                                                onChange={(dates: Date[]) => handleDateRangeFilterChange(dates)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Offcanvas.Body>
                    <Row>
                        <Col lg="12">
                            <div className="offcanvas-footer border-top-0 p-3 text-center">
                                <Row className="px-2 approve-reject-btn">
                                    <Col lg={6} className="clear-section">
                                        <Button
                                            onClick={ClearAllFilter}
                                            color="primary"
                                            className="clear-btn"
                                        >
                                            Clear Filters
                                        </Button>
                                    </Col>
                                    <Col lg={6} className="Apply-section">
                                        <Button
                                            onClick={applyFilters}
                                            color="primary" className="next-btn">
                                            Apply Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas>
            </React.Fragment>
        </>
    )
})

export default CheckPointLogTable;