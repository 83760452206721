import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Modal,
  Badge,
  Spinner,
  Dropdown
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import { addDays, parse, format, subDays } from 'date-fns';
import PayrollTable from "./PayrollTable";
import { getPayrollList, getPayScheduleDropdownDetail, addPayrollList, ArchivePayrollList } from "helpers/saibackend_helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

const PayrollPage = () => {
  document.title = "Payroll | The Guard Duty";

  const [isBottom, setIsBottom] = useState(false);
  const toggleBottomCanvas = () => {
    setIsBottom(!isBottom);
  };

  const [courseFilters, setCourseFilters] = useState<boolean>(false);
  const handleFilterShow = () => setCourseFilters(!courseFilters);

  const [PayrollDetail, setPayrollDetail] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [payloadValue, setPayloadValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "DESC",
      sort_by: ""
    },
    is_archive: 0,
    start_date: "",
    end_date: "",
    status: 3
  });

  const fetchPayrollDetails = async () => {
    try {
      const response = await getPayrollList(
        payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.start_date,
        payloadValue.end_date,
        payloadValue.status

      );
      setPayrollDetail(response.data.data_list);
      setTotalPages(response.data.total_pages);
      setCurrentPage(payloadValue.page);
      setTotalRecords(response.data.total_records);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPayrollDetails();
  }, [payloadValue]);


  const handleSortChange = (column: string) => {
    setPayloadValue(prevState => ({
      ...prevState,
      sort: {
        sort_order: prevState.sort.sort_by === column && prevState.sort.sort_order === "ASC" ? "DESC" : "ASC",
        sort_by: column
      },
      page: 1
    }));
  };


  const handlePageChange = (page: number) => {
    setPayloadValue({ ...payloadValue, page: page });
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setPayloadValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };


  const [PayScheduleList, SetPayScheduleList] = useState<any[]>([]);
  const [days, setDays] = useState<number>(0);

  const fetchScheduleDropdownDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getPayScheduleDropdownDetail(searchTerm);
      SetPayScheduleList(response.data.data_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBottom) {
      fetchScheduleDropdownDetails()
    }
  }, [isBottom]);

  const [payschedule, setpayschedule] = useState("")

  const handlePayScheduleChange = (selectedOption: { value: number; label: string; days: number } | null) => {
    if (selectedOption) {
      formik.setFieldValue('pay_schedule_id', selectedOption.value);
      setpayschedule(selectedOption.label)
      setDays(selectedOption.days);
    }
  };

  const validationSchema = Yup.object().shape({
    pay_schedule_id: Yup.number().positive('Pay Schedule is Required').required('Pay Schedule is Required'),
    pay_date: Yup.string().required("Pay Date is required"),
    period_start_date: Yup.string().required("Pay Start Date is required"),
    period_end_date: Yup.string().required("Pay End Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      pay_schedule_id: 0,
      pay_run_name: '',
      pay_date: '',
      period_start_date: '',
      period_end_date: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await addPayrollList(values);
        if (response.status === 1) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
          return;
        }
        setPayloadValue(prevState => ({
          ...prevState,
          sort: {
            sort_order: "DESC",
            sort_by: "id"
          },
          page: 1
        }));
        fetchPayrollDetails()
        toggleBottomCanvas()
        formik.resetForm();
      } catch (error) {
        console.error("Error Adding/Updating Payroll:", error);
        toast.error("Error Payroll. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const { period_start_date, period_end_date } = formik.values;
    if (period_start_date && period_end_date) {
      formik.setFieldValue('pay_run_name', `Pay Run: ${period_start_date} - ${period_end_date} (${payschedule})`);
    }
  }, [formik.values.period_start_date, formik.values.period_end_date]);

  useEffect(() => {
    if (formik.values.period_start_date && days > 0) {
      const startDate = parse(formik.values.period_start_date, 'dd-MM-yyyy', new Date());
      const endDate = subDays(addDays(startDate, days), 1);
      formik.setFieldValue('period_end_date', format(endDate, 'dd-MM-yyyy'));
    }
  }, [formik.values.period_start_date, days]);

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);

  const handleArchivePayroll = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }
  const [archiveLoader, setArchiveLoader] = useState(false)

  const archiveMaster = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await ArchivePayrollList(id);
      fetchPayrollDetails()
      toast.success(response.message);
      setmodal_center(false)
      setArchiveLoader(false)
    } catch (error) {
      console.error("Error Archive Payroll:", error);
      toast.error("Error Archive Please try again later.");
    }
  };


  const openAddForm = () => {
    formik.resetForm()
    setIsBottom(true);
    setpayschedule("")
  };


  // Filter //

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [dateKey, setDateKey] = useState(0);

  const formatDate = (date: Date) => {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleDateRangeChange = (dates: Date[]) => {
    if (dates.length === 2) {
      setSelectedDates(dates);
    } else {
      console.error('Invalid date range selection:', dates);
    }
  };

  const [paidID, setPaidID] = useState<number>(3);

  const handlePaidStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value);
    setPaidID(selectedId);
  };

  const applyFilters = () => {
    if (selectedDates.length === 2) {
      setPayloadValue((prevValue) => ({
        ...prevValue,
        start_date: formatDate(selectedDates[0]),
        end_date: formatDate(selectedDates[1]),
        status: paidID,
      }));
      setCourseFilters(false)
    } else {
      setPayloadValue((prevValue) => ({
        ...prevValue,
        status: paidID,
      }));
      setCourseFilters(false)
    }
  };


  const ClearAllFilter = () => {
    setDateKey((prevKey) => prevKey + 1);
    setSelectedDates([])
    setCourseFilters(false)
    setPayloadValue((prevValue) => ({
      ...prevValue,
      start_date: "",
      end_date: "",
      status: 3,
    }));
    setPaidID(3)
  };



  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Payroll" pageTitle="Dashboard" />
          {
            loading ?
              <Row className="placeholder-glow">
                <Col lg={12} className="mt-4">
                  <div className="placeholder bg-light table-placeholder col-12"></div>
                </Col>
              </Row>
              :
              <Row className="payroll">
                <Col lg={12}>
                  <Card className="staff-tabel-card">
                    <Card.Body>
                      <Row className="align-items-center g-2 mt-0">
                        <Col lg={3} className="mb-3">
                          <div className="app-search d-none d-md-inline-flex">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                id="search-options"
                                style={{ background: '#F5F6FA' }}
                                value={inputValue}
                                onChange={handleChange}
                              />
                              <span className="mdi mdi-magnify search-widget-icon"></span>
                              <span
                                className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                id="search-close-options"
                              ></span>
                            </div>
                          </div>
                        </Col>
                        <Col lg={9} className="btn-section mb-1 text-end">
                          <Button className="filter-btn" onClick={handleFilterShow}>
                            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                            Filters
                          </Button>
                          <Button href="/pending-timesheet" className="next-btn">Pending Timesheet</Button>
                          <Button onClick={openAddForm} className="next-btn">Generate New</Button>
                        </Col>
                        <Col lg={12}>
                          <div><h6>Total Records : <Badge>{totalRecords}</Badge></h6></div>
                        </Col>
                        <Col lg={12} className="payroll-table mt-0">
                          <PayrollTable
                            data={PayrollDetail}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            handleSortChange={handleSortChange}
                            handleArchivePayroll={handleArchivePayroll}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
      <Offcanvas
        className="add-role-canvas"
        show={isBottom}
        onHide={toggleBottomCanvas}
        placement="bottom"
        style={{ minHeight: "500px" }}
      >
        <Offcanvas.Header className="add-header border-bottom" closeButton>
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto"> Generate New Pay</Offcanvas.Title>
        </Offcanvas.Header>
        <>
          <Form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
            <Offcanvas.Body style={{ height: "100%" }}>
              <Row>
                <Col>
                  <Form.Group className="mb-3 form-icon">
                    <Form.Label>Pay Schedule</Form.Label>
                    <div className={`react-select-container ${formik.touched.pay_schedule_id && formik.errors.pay_schedule_id ? 'is-invalid' : ''}`}>
                      <Select
                        inputId="pay_schedule_id"
                        name="pay_schedule_id"
                        options={PayScheduleList}
                        value={PayScheduleList.find(option => option.value === formik.values.pay_schedule_id)}
                        onChange={handlePayScheduleChange}
                        onBlur={formik.handleBlur}
                        classNamePrefix="react-select"
                        className="react-select"
                      />
                      <i
                        style={{ fontSize: '16px' }}
                        className=" ri-bank-card-line mt-4"
                      ></i>
                      {formik.touched.pay_schedule_id && formik.errors.pay_schedule_id ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.pay_schedule_id}
                        </div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Pay Start Date</Form.Label>
                    <div className="position-relative form-icon">
                      <Flatpickr
                        className={`form-control form-control-lg form-control-icon ${formik.touched.period_start_date && formik.errors.period_start_date ? 'is-invalid' : ''}`}
                        name="date"
                        options={{
                          dateFormat: "d-m-Y",
                        }}
                        placeholder="Select Start date"
                        value={formik.values.period_start_date}
                        onChange={(dates: any[]) => {
                          const formattedDate = format(dates[0], 'dd-MM-yyyy');
                          formik.setFieldValue("period_start_date", formattedDate);
                        }}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className="ri-calendar-event-line"
                      ></i>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.period_start_date}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Pay End Date</Form.Label>
                    <div className="position-relative form-icon">
                      <Flatpickr
                        className={`form-control form-control-lg form-control-icon ${formik.touched.period_end_date && formik.errors.period_end_date ? 'is-invalid' : ''}`}
                        name="date"
                        options={{
                          dateFormat: "d-m-Y",
                        }}
                        placeholder="Select End date"
                        value={formik.values.period_end_date}
                        disabled
                        onChange={(dates: any[]) => {
                          const formattedDate = format(dates[0], 'dd-MM-yyyy');
                          formik.setFieldValue("period_end_date", formattedDate);
                        }}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className="ri-calendar-event-line"
                      ></i>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.period_end_date}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Pay Date</Form.Label>
                    <div className="position-relative form-icon">
                      <Flatpickr
                        className={`form-control form-control-lg form-control-icon ${formik.touched.pay_date && formik.errors.pay_date ? 'is-invalid' : ''}`}
                        name="date"
                        options={{
                          dateFormat: "d-m-Y",
                        }}
                        placeholder="Select date"
                        value={formik.values.pay_date}
                        onChange={(dates: any[]) => {
                          const formattedDate = format(dates[0], 'dd-MM-yyyy');
                          formik.setFieldValue("pay_date", formattedDate);
                        }}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className="ri-calendar-event-line"
                      ></i>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.pay_date}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Pay Run Name</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        type="text"
                        name="pay_run_name"
                        value={formik.values.pay_run_name}
                        onChange={formik.handleChange}
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Pay Run Name"
                      />
                      <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Offcanvas.Body>
            <Row>
              <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                  <Row>
                    <Col lg={12} className="off-bottom-staff justify-content-end">
                      <div className="off-confirm d-flex gap-2">
                        <Button
                          onClick={toggleBottomCanvas}
                          color="primary"
                          className="clear-btn"
                        >
                          Cancel
                        </Button>
                        {formik.isSubmitting ? (
                          <Button
                            color="primary"
                            className="next-btn"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </Button>
                        ) : (
                          <Button color="primary" style={{ marginLeft: 0 }} className="next-btn" type="submit">
                            Add
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      </Offcanvas>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to delete this Payroll.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
              {archiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => {
                  if (archiveId !== undefined) {
                    archiveMaster(archiveId);
                  } else {
                    toast.error("No Archive ID provided.");
                  }
                }}>Delete</Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas
        className="filter-canvas"
        show={courseFilters}
        placement="end"
        onHide={handleFilterShow}
      >
        <Offcanvas.Header
          id="courseFilters"
          className="border-bottom filter-canvas"
          closeButton
        >
          <Offcanvas.Title id="courseFiltersLabel">
            {" "}
            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
            Filters
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row>
              <Col lg={12}>
                <Row className="filter-1">
                  <Col lg={12}>
                    <h5>Pay Status</h5>
                  </Col>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Select
                        className="form-select-lg"
                        id="floatingSelect"
                        value={paidID}
                        onChange={handlePaidStatusChange}
                      >
                        <option value={3}>All</option>
                        <option value={0}>Draft</option>
                        <option value={2}>Pay Processed</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row className="filter-2">
                  <Col lg={12}>
                    <h5>Date Period</h5>
                  </Col>
                  <Col lg="12" className="btn-section mb-1 form-icon">
                    <Flatpickr
                      className="form-control form-control-lg form-control-icon"
                      key={dateKey}
                      placeHolder="Select a date range"
                      options={{
                        mode: "range",
                        dateFormat: "d-m-Y",
                        defaultDate: selectedDates.length ? selectedDates : [null, null]
                      }}
                      value={selectedDates}
                      onChange={(dates: Date[]) => handleDateRangeChange(dates)}
                    />
                    <i
                      style={{ fontSize: "18px" }}
                      className="ri-calendar-event-line"
                    ></i>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
        <Row>
          <Col lg="12">
            <div className="offcanvas-footer border-top-0 p-3 text-center">
              <Row className="px-2 approve-reject-btn">
                <Col lg={6} className="clear-section">
                  <Button
                    onClick={ClearAllFilter}
                    color="primary"
                    className="clear-btn"
                  >
                    Clear Filters
                  </Button>
                </Col>
                <Col lg={6} className="Apply-section">
                  <Button color="primary" onClick={applyFilters} className="next-btn">
                    Apply Filters
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Offcanvas>
    </>
  );
};

export default PayrollPage;
