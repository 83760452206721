
import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Form, Image } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

interface IncidentReportData {
    id: number;
    date: string;
    client_id: number;
    location_id: number,
    submitted_by: number,
    updated_by: null,
    status: string,
    share_with_client: number,
    incident_type: string,
    location_name: null,
    submitted_by_name: string,
    updated_by_name: string,
    client_name: null
}

type SortOrder = 'asc' | 'desc';

interface IncidentTableProps {
    data: IncidentReportData[];
    archiveMaster: (id: number) => Promise<void>;
    editIncidentReportMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
    handleArchiveReport : (id: number) => Promise<void>;
}

const IncidentTable: React.FC<IncidentTableProps> = ({ data, archiveMaster, editIncidentReportMaster, handlePageChange, handlePerRowsChange, paginationTotalRows,handleSort,handleArchiveReport }) => {

    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Report ID</span>,
            selector: (row: any) => row.id,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-sm'>Incident Type</span>,
            selector: (row: any) => row.incident_type,
            sortField: 'incident_type',
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-sm'>Date</span>,
            selector: (row: any) => row.date,
            sortField: 'date',
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-sm'>Client Name</span>,
            selector: (row: any) => row.client,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        <span>{row.client ? row.client : "-"}</span>
                    </>
                );
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Location Name</span>,
            selector: (row: any) => row.location,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        <span>{row.location ? row.location : "-"}</span>
                    </>
                );
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Submitted By</span>,
            selector: (row: any) => row.submitted_by,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-sm'>Modified By</span>,
            selector: (row: any) => row.updated_by,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        <span>{row.updated_by ? row.updated_by : "-"}</span>
                    </>
                );
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Status</span>,
            selector: (row: any) => row.status,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-sm'>Action</span>,
            sortable: false,

            cell: (row: any) => {
                return (
                    <>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <i onClick={() => editIncidentReportMaster(row.id)} className="ri-edit-2-line link-success" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            <i onClick={() => handleArchiveReport(row.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line link-danger"></i>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default IncidentTable