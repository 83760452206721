import React, { ChangeEvent, useMemo, useState } from "react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import { Form, Badge, Offcanvas, Button, Row, Col, Modal } from "react-bootstrap";
import ellipse from "assets/images/auth/blank.png";
import { getByTimeOffDetail, getStaffDocumentDetail, getByStaffDetail } from "helpers/saibackend_helper";
import SimpleBar from "simplebar-react";
import profile from "assets/images/auth/profile.png";
import Flatpickr from "react-flatpickr";

interface TimeoffData {
    id: number;
    staff_id: number;
    leave_type_id: number;
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
    status: string;
    note: string;
    leave_name: string;
    default_hours: number;
    first_name: string;
    last_name: string;
    status_text: string
}

const LeaveTable = ({
    leavedata,
    currentPage,
    totalPages,
    onPageChange,
    handleSortChange,
    editTimeOff,
    handleArchiveTimeOff,
    handleRestoreTimeoff,
    timeoffIds,
    selectAll,
    handleCheckboxChange,
    handleCheckAllChange,
}: {
    leavedata: any;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    handleSortChange: (column: string, direction: string) => void;
    editTimeOff: (id: number) => Promise<void>;
    handleArchiveTimeOff: (id: number) => Promise<void>;
    handleRestoreTimeoff: (id: number) => Promise<void>;
    timeoffIds: number[];
    selectAll: boolean;
    handleCheckboxChange: (
        event: ChangeEvent<HTMLInputElement>,
        id: number
    ) => void;
    handleCheckAllChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
    const [isRight, setIsRight] = useState(false);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const [selectedLeaveType, setSelectedLeaveType] = useState<any | null>(null);

    const handleViewTimesheet = (leavesheet: any) => {
        setSelectedLeaveType(leavesheet);
        toggleRightCanvas();
    };

    const [TimeoffDetails, setTimeoffDetails] = useState<TimeoffData | null>(
        null
    );

    const GetByTimeoff = async (id: number) => {
        try {
            const response = await getByTimeOffDetail(id);
            const data = response.data;
            console.log(data);
            setTimeoffDetails(data);
            setIsRight(true);
        } catch (error) {
            console.error("Error fetching Timeoff details:", error);
        }
    };


    const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

    const editMaster = async (id: any) => {
        try {
            const response = await getByStaffDetail(id);
            const data = response.data;
            setStaffViewDetail(data);
            tog_standard()
        } catch (error) {
            return;
        }
    };


    const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
        any[]
    >([]);

    const fetchStaffDocumentDetails = async (id: any) => {
        try {
            const response = await getStaffDocumentDetail("", id);
            setStaffDocumentDetail(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const doc_url = process.env.REACT_APP_IMAGE_URL
    const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

    const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

    const getAbbreviation = (name: string) => {
        return name
            ?.split(' ')
            ?.map(word => word.charAt(0))
            ?.join('')
            ?.toUpperCase()
            ?.slice(0, 2);
    };


    const columns = useMemo(
        () => [
            {
                header: (
                    <Form.Check>
                        <Form.Check.Input
                            type="checkbox"
                            id="checkAll"
                            value="option"
                            checked={selectAll}
                            onChange={handleCheckAllChange}
                        />
                    </Form.Check>
                ),
                cell: (cellProps: any) => {
                    const itemId = cellProps.row.original.time_id;
                    return (
                        <Form.Check>
                            <Form.Check.Input
                                type="checkbox"
                                name="chk_child"
                                value={itemId}
                                checked={timeoffIds.includes(itemId)}
                                onChange={(e) => handleCheckboxChange(e, itemId)}
                            />
                        </Form.Check>
                    );
                },
                id: "#",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Staff Name",
                accessorKey: "first_name",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (
                        <div>
                            <div className="d-flex gap-2">
                                <div>
                                    <h5 className="mb-0" style={{display:'flex', gap:'4px' , alignItems:'center'}}>
                                        {cell.row.original.first_name} {cell.row.original.last_name} <i onClick={() => {
                                            if (cell.row.original.staff_id) {
                                                editMaster(cell.row.original.staff_id);
                                                fetchStaffDocumentDetails(cell.row.original.staff_id);
                                            }
                                        }} className="ri-information-line link-info" style={{ cursor: 'pointer', fontSize: '18px' }} ></i>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: "Start Date",
                accessorKey: "start_date",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "End Date",
                accessorKey: "end_date",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Leave Type",
                accessorKey: "leave_name",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Leave Status",
                accessorKey: "status",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const statusValue = cellProps.row.original.status;

                    return (
                        <span className={`${statusValue}`}>
                            {cellProps.row.original.status}
                        </span>
                    );
                },
            },
            {
                header: "Action",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const leavesheet = cellProps.row.original;

                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            <div className="edit">
                                <i
                                    onClick={() => editTimeOff(cellProps.row.original.time_id)}
                                    style={{ fontSize: "18px", cursor: "pointer" }}
                                    className="ri-edit-2-line link-success"
                                ></i>
                            </div>
                            <div className="view">
                                <i
                                    onClick={() => GetByTimeoff(cellProps.row.original.time_id)}
                                    style={{ fontSize: "18px", cursor: "pointer" }}
                                    className="ri-eye-line link-info"
                                ></i>
                            </div>
                            {
                                cellProps.row.original.is_archive == 1 ?
                                    <div className="edit">
                                        <i className="ri-restart-line link-success" onClick={() => handleRestoreTimeoff(leavesheet.time_id)} style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </div> :
                                    <div className="edit">
                                        <i onClick={() => handleArchiveTimeOff(leavesheet.time_id)} className="ri-delete-bin-line link-danger" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                    </div>
                            }
                            {/* <li>
                                <Link to="#"
                                    className="btn btn-subtle-danger btn-icon btn-sm remove-item-btn">
                                    <i className=" ri-check-double-line"></i>
                                </Link>
                            </li> */}
                        </div>
                    );
                },
            },
        ],
        [timeoffIds, selectAll, leavedata]
    );

    return (
        <React.Fragment>
            {leavedata.length > 0 ? (
                <TableContainer
                    isPagination={true}
                    columns={columns || []}
                    data={leavedata}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
            ) : (
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Time Off Available</h5>
                    </div>
                </div>
            )}

            <Offcanvas
                className="leave-canvas"
                show={isRight}
                onHide={toggleRightCanvas}
                placement="end"
            >
                <Offcanvas.Header className="border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasRightLabel">
                        View Time Off Details
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0 overflow-hidden">
                    <SimpleBar
                        style={{
                            maxHeight:
                                selectedLeaveType?.status === "Pending"
                                    ? "calc(100vh - 30vh)"
                                    : "100%",
                        }}
                        className="px-3"
                    >
                        <Row>
                            <Col lg="12">
                                <Row className="leave-panel-status">
                                    <Col lg="12">
                                        <div className={TimeoffDetails?.status}>
                                            {TimeoffDetails?.status}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="leave-panel">
                                    <Col lg="6">
                                        <p className="emp-name">FirstName</p>
                                        <p className="emp-title">{TimeoffDetails?.first_name ? TimeoffDetails?.first_name : "-"}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p className="emp-name">LastName</p>
                                        <p className="emp-title">{TimeoffDetails?.last_name ? TimeoffDetails?.last_name : "-"}</p>
                                    </Col>
                                </Row>
                                <Row className="leave-panel">
                                    <Col lg="6">
                                        <p className="emp-name">Leave Type</p>
                                        <p className="emp-title">{TimeoffDetails?.leave_name ? TimeoffDetails?.leave_name : "-"}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p className="emp-name">Leave Balance</p>
                                        <p className="emp-title">{TimeoffDetails?.default_hours ? TimeoffDetails?.default_hours : "-"}</p>
                                    </Col>
                                </Row>
                                <Row className="leave-panel">
                                    <Col lg="6">
                                        <p className="emp-name">Start Date</p>
                                        <p className="emp-title mb-4">{TimeoffDetails?.start_date ? TimeoffDetails?.start_date : "-"}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p className="emp-name">Start Time</p>
                                        <p className="emp-title mb-4">{TimeoffDetails?.start_time ? TimeoffDetails?.start_time : "-"}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p className="emp-name">End Date</p>
                                        <p className="emp-title">{TimeoffDetails?.end_date ? TimeoffDetails?.end_date : "-"}</p>
                                    </Col>
                                    <Col lg="6">
                                        <p className="emp-name">End Time</p>
                                        <p className="emp-title">{TimeoffDetails?.end_time ? TimeoffDetails?.end_time : "-"}</p>
                                    </Col>
                                </Row>
                                <Row className="leave-panel">
                                    <Col lg="12">
                                        <p className="emp-name">Notes</p>
                                        <p className="emp-note">
                                            {TimeoffDetails?.note ? TimeoffDetails?.note : "-"}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </SimpleBar>
                </Offcanvas.Body>
            </Offcanvas>
            <Modal id="myModal" size="sm" show={modal_standard} onHide={() => { tog_standard(); }} >
                {/* <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                                {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                            </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex align-items-center" >
                        <div className="flex-shrink-0 position-relative">
                            {StaffViewDetail?.avatar ?
                                <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                :
                                <div className="avatar-sm">
                                    <div className="avatar-title rounded bg-danger-subtle text-danger">
                                        {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="flex-grow-1 ms-2">
                            <h5 className="fs-md mb-0"><Link to="/pages-profile" className="text-dark">{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</Link></h5>
                            <p className="text-muted mb-0"> {StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"} </p>
                            <p className="text-muted mb-0"> {StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"} </p>
                        </div>
                    </div>
                    <div style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '18px', cursor: 'pointer' }}>
                        <i onClick={tog_standard} className="ri-close-line"></i>
                    </div>
                    <div className="mt-4">
                        <p className="text-muted mb-2">
                            <i className="bi bi-telephone align-baseline me-1"></i>
                            {StaffViewDetail?.mobile_no ? (
                                <Link to={`tel:${StaffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                    {StaffViewDetail.mobile_no}
                                </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2">
                            <i className="bi bi-envelope align-baseline me-1"></i>
                            {StaffViewDetail?.email ? (
                                <Link to={`mailto:${StaffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{StaffViewDetail.email} </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"} </p>
                        <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"} </p>
                        <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"} </p>
                    </div>
                    {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses").length > 0 &&
                        <div className="border-bottom border-top border-dashed py-4 mt-4">
                            <h5 className="card-title mb-3">License</h5>
                            <div className="vstack gap-3">
                                {StaffDocumentDetail
                                    .filter((doc) => doc.document_type === "Licenses")
                                    .map((doc, index) => (
                                        <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-sm border border rounded">
                                                    <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                                        <i className="bi bi-file-pdf"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6>License No : {doc.ref_no}</h6>
                                                <p className="text-muted mb-0">Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <Link to={`#`} onClick={() => handleOpenDocument(doc.document_path)} className="icon-link text-reset stretched-link fs-xl" style={{ transform: "translate3d(0, -.125rem, 0)" }}>
                                                    <i className="ri-eye-line link-info"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default LeaveTable;
