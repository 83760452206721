// var date = new Date();
// var d = date.getDate();
// var m = date.getMonth();
// var y = date.getFullYear();

interface CalendarEvent {
  title: string;
  start: string;
  end: string;
  className: string;
}
const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;

const Data: CalendarEvent[] =[
  {
    title: '9am - 5pm',
    start: '2024-01-03T09:00:00',
    end: '2024-01-03T17:00:00',
    className: 'cancel-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-05T09:00:00',
    end: '2024-01-05T17:00:00',
    className: 'completed-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-09T09:00:00',
    end: '2024-01-09T17:00:00',
    className: 'cancel-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-12T09:00:00',
    end: '2024-01-12T17:00:00',
    className: 'completed-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-15T09:00:00',
    end: '2024-01-15T17:00:00',
    className: 'cancel-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-19T09:00:00',
    end: '2024-01-19T17:00:00',
    className: 'completed-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-20T09:00:00',
    end: '2024-01-20T17:00:00',
    className: 'cancel-shift',
  },
  {
    title: '9am - 5pm',
    start: '2024-01-25T09:00:00',
    end: '2024-01-25T17:00:00',
    className: 'completed-shift',
  },
]

const calenderdata = Data.filter(
  (event) => new Date(event.start).getMonth() + 1 === currentMonth
);
// const calenderdata = [
//   {
//     id: "1",
//     title: "Event 1",
//     start: new Date(),
//     end: new Date(new Date().getTime() + 3600000), // Example: 1 hour duration
//     className: "bg-soft-primary",
//     location: "San Francisco, US",
//     allDay: false,
//     extendedProps: {
//       department: "Some Department",
//     },
//     description: "Some description",
//   },
// ]
// const calenderdata = [
//   {
//       id: 153,
//       title: "All Day Event",
//       start: new Date(y, m, 1),
//       className: "bg-soft-primary",
//       location: "San Francisco, US",
//       allDay: false,
//       extendedProps: {
//           department: "All Day Event",
//       },
//       description:
//           "An all-day event is an event that lasts an entire day or longer",
//   },
//   {
//       id: 136,
//       title: "Visit Online Course",
//       start: new Date(y, m, d - 5),
//       end: new Date(y, m, d - 2),
//       allDay: false,
//       className: "bg-soft-warning",
//       location: "San Francisco, US",
//       extendedProps: {
//           department: "Long Event",
//       },
//       description:
//           "Long Term Event means an incident that last longer than 12 hours.",
//   },
//   {
//       id: 999,
//       title: "Client Meeting with Alexis",
//       start: new Date(y, m, d + 22, 20, 0),
//       end: new Date(y, m, d + 23, 16, 0),
//       allDay: false,
//       className: "bg-soft-danger",
//       location: "California, US",
//       extendedProps: {
//           department: "Meeting with Alexis",
//       },
//       description:
//           "A meeting is a gathering of two or more people that has been convened for the purpose of achieving a common goal through verbal interaction, such as sharing information or reaching agreement.",
//   },
//   {
//       id: 991,
//       title: "Repeating Event",
//       start: new Date(y, m, d + 4, 16, 0),
//       end: new Date(y, m, d + 8, 16, 0),
//       allDay: false,
//       className: "bg-soft-primary",
//       location: "Las Vegas, US",
//       extendedProps: {
//           department: "Repeating Event",
//       },
//       description:
//           "A recurring or repeating event is simply any event that you will occur more than once on your calendar. ",
//   },
//   {
//       id: 112,
//       title: "Meeting With Designer",
//       start: new Date(y, m, d, 12, 30),
//       allDay: false,
//       className: "bg-soft-success",
//       location: "Head Office, US",
//       extendedProps: {
//           department: "Meeting",
//       },
//       description: "Tell how to boost website traffic",
//   },
//   {
//       id: 113,
//       title: "Weekly Strategy Planning",
//       start: new Date(y, m, d + 9),
//       end: new Date(y, m, d + 11),
//       allDay: false,
//       className: "bg-soft-danger",
//       location: "Head Office, US",
//       extendedProps: {
//           department: "Lunch",
//       },
//       description: "Strategies for Creating Your Weekly Schedule",
//   },
//   {
//       id: 875,
//       title: "Birthday Party",
//       start: new Date(y, m, d + 1, 19, 0),
//       allDay: false,
//       className: "bg-soft-success",
//       location: "Los Angeles, US",
//       extendedProps: {
//           department: "Birthday Party",
//       },
//       description:
//           "Family slumber party – Bring out the blankets and pillows and have a family slumber party! Play silly party games, share special snacks and wind down the fun with a special movie.",
//   },
//   {
//       id: 783,
//       title: "Click for Google",
//       start: new Date(y, m, 28),
//       end: new Date(y, m, 29),
//       url: "http://google.com/",
//       className: "bg-soft-dark",
//   },
//   {
//       id: 456,
//       title: "Hybrix",
//       start: new Date(y, m, d + 23, 20, 0),
//       end: new Date(y, m, d + 23, 16, 0),
//       allDay: false,
//       className: "bg-soft-info",
//       location: "Head Office, US",
//       extendedProps: {
//           department: "Discussion",
//       },
//       description: "Tell how to boost website traffic",
//   },
// ];

export default calenderdata;
