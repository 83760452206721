import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    Row,
    Offcanvas,
    Modal,
    Nav,
    Tab
} from "react-bootstrap";
import BillingCategories from "./BillingCategories/BillingCategories";
import BillingGroup from "./BillingGroup/BillingGroup";

type SelectCallback = (eventKey: string | null) => void;

const BillingSetting = (() => {

    const [activeTab, setActiveTab] = useState<string>('home2');

    const handleTabSelect: SelectCallback = (eventKey) => {
        if (eventKey) {
            setActiveTab(eventKey);
        }
    };


    return (
        <>
            <Row>
                <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                    <h4 className="card-title mb-2">Billing Settings</h4>
                </Col>
                <Col lg="12">
                    <Row>
                        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                            <Col lg={12} className="btn-employee-section mb-3">
                                <Nav
                                    as="ul"
                                    variant="tabs"
                                    className="nav-border-top nav-border-top-primary"
                                    role="tablist"
                                >
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="home2">
                                            Billing Categories
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="home3">
                                            Billing Group
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg="12">
                                <Tab.Content className="text-muted">
                                    {activeTab === "home2" && (
                                        <Tab.Pane eventKey="home2">
                                            <BillingCategories/>
                                        </Tab.Pane>
                                    )}
                                    {activeTab === "home3" && (
                                        <Tab.Pane eventKey="home3">
                                            <BillingGroup/>
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </Col>
                        </Tab.Container>
                    </Row>
                </Col>
            </Row>
        </>
    )
})

export default BillingSetting;