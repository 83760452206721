import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ParticlesAuth from 'pages/AuthenticationInner/ParticlesAuth';
import SaliLogo from "assets/images/auth/sailogo.png"
import sucess from 'assets/images/auth/sucess.png'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import { changePassword } from 'helpers/saibackend_helper';
import Breadcrumb from 'Common/BreadCrumb';
import withRouter from "Common/withRouter";

const PasswordCreate = () => {

    // const [password, setpassword] = useState(0);
    // const updatepassword = (() => {
    //     setpassword(1);
    // })

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = (setPasswordVisibility: { (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; (arg0: (prevState: any) => boolean): void; }) => {
        setPasswordVisibility((prevState) => !prevState);
    };

    const validationSchema = Yup.object().shape({
        current_password: Yup.string().required("Current Password is required"),
        new_password: Yup.string()
            .required("New Password is required")
            .min(8, "Password must be at least 8 characters long"),
        confirm_password: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref('new_password')], "Passwords must match"),
    });

    const formik = useFormik({
        initialValues: {
            current_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                console.log(values);
                const response = await changePassword(values);
                if (response.status === 0) {
                    toast.error(response.message);
                } else {
                    toast.success(response.message);
                    formik.resetForm()
                }
            } catch (error) {
                console.error("Error Updating Password:", error);
                toast.error("Error Updating Password. Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });


    document.title = "Change Password | SAI";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Change Password" pageTitle="Dashboard" />
                    <Card>
                        <Card.Body >
                            {/* <div className='d-flex justify-content-center mb-5'>
                                    <img src={SaliLogo} />
                                </div>
                                <div className="text-center">
                                    <h5 className="fs-3xl">Change password</h5>
                                    <p className="text-muted mb-5">Your new password must be different from previous used password.</p>
                                </div> */}
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col lg="4">
                                        <div className="mb-4">
                                            <Form.Label htmlFor="current-password-input">Current Password</Form.Label>
                                            <div className="position-relative auth-pass-inputgroup">
                                                <Form.Control
                                                    type={showCurrentPassword ? 'text' : 'password'}
                                                    className="pe-5 password-input"
                                                    name="current_password"
                                                    placeholder="Enter password"
                                                    id="current-password-input"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.current_password}
                                                    isInvalid={!!(formik.touched.current_password && formik.errors.current_password)}
                                                />
                                                <Button
                                                    variant="link"
                                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                    type="button"
                                                    onClick={() => togglePasswordVisibility(setShowCurrentPassword)}
                                                >
                                                    <i className={`ri-eye${showCurrentPassword ? '-off' : ''}-fill align-middle`}></i>
                                                </Button>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.current_password}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div className="mb-4">
                                            <Form.Label htmlFor="new-password-input">New Password</Form.Label>
                                            <div className="position-relative auth-pass-inputgroup">
                                                <Form.Control
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    className="pe-5 password-input"
                                                    name="new_password"
                                                    placeholder="Enter password"
                                                    id="new-password-input"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.new_password}
                                                    isInvalid={!!(formik.touched.new_password && formik.errors.new_password)}
                                                />
                                                <Button
                                                    variant="link"
                                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                    type="button"
                                                    onClick={() => togglePasswordVisibility(setShowNewPassword)}
                                                >
                                                    <i className={`ri-eye${showNewPassword ? '-off' : ''}-fill align-middle`}></i>
                                                </Button>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.new_password}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div className="mb-4">
                                            <Form.Label htmlFor="confirm-password-input">Confirm Password</Form.Label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                <Form.Control
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    className="pe-5 password-input"
                                                    name="confirm_password"
                                                    placeholder="Confirm password"
                                                    id="confirm-password-input"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.confirm_password}
                                                    isInvalid={!!(formik.touched.confirm_password && formik.errors.confirm_password)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.confirm_password}
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                    </Col>
                                    <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                        <h5 className="fs-sm">Password must contain:</h5>
                                        <p id="pass-length" className="invalid fs-xs mb-2">
                                            Minimum <b>8 characters</b>
                                        </p>
                                        <p id="pass-lower" className="invalid fs-xs mb-2">
                                            At <b>lowercase</b> letter (a-z)
                                        </p>
                                        <p id="pass-upper" className="invalid fs-xs mb-2">
                                            At least <b>uppercase</b> letter (A-Z)
                                        </p>
                                        <p id="pass-number" className="invalid fs-xs mb-0">
                                            A least <b>number</b> (0-9)
                                        </p>
                                    </div>
                                    <Col lg="12">
                                    <div className="mt-4 text-center">
                                        <Button variant="primary" type="submit">
                                            Change Password
                                        </Button>
                                    </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(PasswordCreate);