
import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Form, Image } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';


interface SubComplianceMasterData {
    id: number;
    compliance_name: string;
    document_number: string,
    description: string;
    reminder_days: number;
    date: string,
    is_critical: number;
}

type SortOrder = 'asc' | 'desc';

interface SubComplianceTableProps {
    data: SubComplianceMasterData[];
    archiveMaster: (id: number) => Promise<void>;
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance :  (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
}


const SubcontractorComplianceTable: React.FC<SubComplianceTableProps> = ({ data, archiveMaster, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows,handleSort, handleArchiveCompliance }) => {

    const doc_url = process.env.REACT_APP_IMAGE_URL

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Compliance Name</span>,
            selector: (row: any) => row.compliance_name,
            sortable: true,
            sortField: 'compliance_name',
        },
        {
            name: <span className='font-weight-bold fs-sm'>Document Number</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.document_number ? row.document_number : "-"}
                    </>
                )
            },
        },
        // {
        //     name: <span className='font-weight-bold fs-sm'>Description</span>,
        //     sortable: true,
        //     cell: (row: any) => {
        //         return (
        //             <>
        //                 {row?.description ? row?.description : "-"}
        //             </>
        //         )
        //     },
        // },
        {
            name: <span className='font-weight-bold fs-sm'>Reminder</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.reminder_days ? row.reminder_days : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Date</span>,
            sortable: false,
            cell: (row: any) => {
                return (
                    <>
                        {row.date ? row.date : "-"}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-sm'>Critical</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row?.is_critical === 1 ? "Yes" : "No"}
                    </>
                )
            },
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-sm'>Action</span>,
            sortable: false,

            cell: (row: any) => {
                return (
                    <>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <i onClick={() => editMaster(row.id)} className="ri-edit-2-line link-success" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            <i onClick={() => handleOpenDocument(row.compliance_path)} className=" ri-eye-line link-info" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            <i onClick={() => handleArchiveCompliance(row.id)} style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line link-danger"></i>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default SubcontractorComplianceTable