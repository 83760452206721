import React, { useState, useEffect } from "react";
import { getHolidayMasterDetail, addHolidayMasterDetail, getStateMasterDetail, archiveHolidayMasterDetail, getByHolidayMasterDetail, updateHolidayMasterDetail } from "helpers/saibackend_helper";
import {
    Button,
    Col,
    Form,
    Row,
    Offcanvas,
    Modal,
    Spinner
} from "react-bootstrap";
import { format } from 'date-fns';
import HolidayTable from "./HolidayTable";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Flatpickr from "react-flatpickr";
import SimpleBar from "simplebar-react";
import Cleave from 'cleave.js/react';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';

interface HolidayMasterData {
    id: number;
    year: number;
    state_id: number;
    description: string;
    date: Date | null;
    start_time: string;
    finish_time: string;
}

interface StateMasterData {
    id: number;
    state_name: string
}

interface FormValues {
    year: number;
    state_id: number;
    description: string;
    date: string;
    start_time: string;
    finish_time: string;
    AllDay: boolean;
}


const HolidayMaster = (() => {

    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [HolidayMasterDetail, setHolidayMasterDetail] = useState<HolidayMasterData[]>([])
    const [StateMasterDetail, setStateMasterDetail] = useState<StateMasterData[]>([])
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);
    const [totalRows, setTotalRows] = useState(0);

    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveCompliance = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }


    const [payloadValue, setPayloadValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0,
        state_id: '2',
        year: '2024'
    })

    const handlePageChange = (page: number) => {
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayloadValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const [defaultPayloadStateValue, setDefaultPayloadStateValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort_order: "DESC",
        is_archive: 0
    })

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };

    const fetchHolidayDetails = async () => {
        try {
            const response = await getHolidayMasterDetail(payloadValue.page, payloadValue.page_size, payloadValue.search_term, payloadValue.sort.sort_order, payloadValue.sort.sort_by, payloadValue.is_archive, payloadValue.state_id, payloadValue.year);
            setHolidayMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            return;

        }
    };

    const fetchStateetails = async () => {
        try {
            const response = await getStateMasterDetail(defaultPayloadStateValue.page, defaultPayloadStateValue.page_size, defaultPayloadStateValue.search_term, defaultPayloadStateValue.sort_order, defaultPayloadStateValue.is_archive);
            setStateMasterDetail(response.data.data_list);
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        fetchHolidayDetails();
    }, [payloadValue]);

    useEffect(() => {
        fetchStateetails()
    }, []);

    const [formValues, setFormValues] = useState<FormValues>({
        state_id: 0,
        year: 0,
        description: '',
        date: '',
        start_time: '',
        finish_time: '',
        AllDay: false
    });

    const [isAllDay, setIsAllDay] = useState(false);


    const handleTimeChange = (time: string, field: string) => {
        const formattedTime = time.replace(/^(\d{2})(\d{2})$/, '$1:$2');
        formik.setFieldValue(field, formattedTime);
    };


    const handleAllDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;

        formik.setFieldValue('AllDay', isChecked);

        if (isChecked) {
            formik.setFieldValue('start_time', '00:00');
            formik.setFieldValue('finish_time', '23:59');
        } else {
            formik.setFieldValue('start_time', '');
            formik.setFieldValue('finish_time', '');
        }

        setIsAllDay(isChecked);
    };

    const getStateName = (id: number): string => {
        const state = StateMasterDetail.find(state => state.id === id);
        return state ? state.state_name : '';
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        description: Yup.string().required("Holiday name is required"),
        date: Yup.string().required("Date is required"),
        start_time: Yup.string().required("Start time is required"),
        finish_time: Yup.string().required("End time is required"),
    });

    // Formik hook
    const formik = useFormik({
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let response;
                values.start_time = moment(values.start_time, 'HH:mm').format('HH:mm');
                values.finish_time = moment(values.finish_time, 'HH:mm').format('HH:mm');
                values.state_id = Number(selectedState);
                values.year = Number(selectedYear);
                //   const formattedDate = values.date ? values.date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '';

                if (isEditing && editId !== null) {
                    response = await updateHolidayMasterDetail(
                        editId,
                        values.year,
                        values.state_id,
                        values.description,
                        values.date,
                        values.start_time,
                        values.finish_time
                    );
                } else {
                    response = await addHolidayMasterDetail(values);
                }

                if (response.status === 0) {
                    toast.error(response.message);
                } else {
                    toast.success(response.message);
                    fetchHolidayDetails();
                    toggleEditCanvas();
                    formik.resetForm()
                }
            } catch (error) {
                return;
            } finally {
                setSubmitting(false);
            }
        },
    });

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveHolidayMasterDetail(id);
            fetchHolidayDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            return;
            toast.error("Error Archive Please try again later.");
        }
    };

    const years = [2021, 2022, 2023, 2024];

    const [selectedYear, setSelectedYear] = useState('2024');

    const [selectedState, setSelectedState] = useState<string>('2');

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected_id = event.target.value;
        setSelectedState(selected_id);
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            state_id: selected_id,
        }));
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected_year = event.target.value;
        setSelectedYear(selected_year);
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            year: selected_year.toString(),
        }));
    };

    const formatTime = (time: string) => {
        return time.substring(0, 5);
    };

    const toggleEditCanvas = () => {
        if (isEditing && editId !== null) {
            setIsEditing(false);
            setEditId(null);
            setFormValues({
                state_id: Number(selectedState),
                year: Number(selectedYear),
                description: '',
                date: '',
                start_time: '',
                finish_time: '',
                AllDay: false
            })
        }
        setIsEdit(!isEdit);
    };

    const editMaster = async (id: number) => {
        try {
            const response = await getByHolidayMasterDetail(id);
            const data = response.data;
            const formattedStartTime = formatTime(data.start_time);
            const formattedFinishTime = formatTime(data.finish_time);
            formik.setValues({
                ...formik.values,
                state_id: data.state_id,
                year: data.year,
                description: data.description,
                date: data.date,
                start_time: formattedStartTime,
                finish_time: formattedFinishTime,
                AllDay: (formattedStartTime === '00:00' && formattedFinishTime === '23:59')
            });

            setSelectedYear(data.year.toString());
            setSelectedState(data.state_id.toString());

            formik.setFieldValue('state_id', data.state_id.toString());
            formik.setFieldValue('year', data.year.toString());

            setPayloadValue(prevPayload => ({
                ...prevPayload,
                state_id: data.state_id,
                year: data.year,
            }));

            setIsAllDay(formattedStartTime === '00:00' && formattedFinishTime === '23:59');
            setIsEditing(true);
            setIsEdit(true);
            setEditId(id);
            toggleEditCanvas();
        } catch (error) {
            toast.error("Error fetching details. Please try again later.");
        }
    };

    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                            <h4 className="card-title mb-2"> Holiday Master</h4>
                            <div className="d-flex">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="5">
                            <Form.Group className="mb-3 form-icon">
                                <Form.Label>State/Province</Form.Label>
                                <Form.Select
                                    className="form-select-lg form-control-icon"
                                    id="state"
                                    value={selectedState}
                                    onChange={handleStateChange}
                                >
                                    {/* <option value="">Select State</option> */}
                                    {StateMasterDetail.map((stateDetail) => (
                                        <option key={stateDetail.id} value={stateDetail.id.toString()}>
                                            {stateDetail.state_name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <i
                                    style={{ fontSize: '16px' }}
                                    className="ri-map-pin-line mt-4"
                                ></i>
                            </Form.Group>
                        </Col>
                        <Col lg="5">
                            <Form.Group className="mb-3 form-icon">
                                <Form.Label>Year</Form.Label>
                                <Form.Select
                                    className="form-select-lg form-control-icon"
                                    id="year"
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                >
                                    {/* <option defaultValue="">
                                Select Year
                            </option> */}
                                    {years.map((year, index) => {
                                        return (
                                            <option key={index} value={year}>{year}</option>
                                        )
                                    })}
                                </Form.Select>
                                <i
                                    style={{ fontSize: "16px" }}
                                    className="  ri-calendar-line mt-4"
                                ></i>
                            </Form.Group>
                        </Col>
                        <Col lg="2" style={{ marginTop: '1.7rem' }}>
                            {/* <Button
                        variant="primary"
                        onClick={clearAllFilter}
                        className="add-company"
                    >
                        Clear All
                    </Button> */}
                            <div className="d-flex">
                                <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={toggleEditCanvas}
                                >
                                    <i className=" ri-add-fill"></i> Add
                                </Button>
                            </div>
                        </Col>
                        <Col lg="12">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <HolidayTable editMaster={editMaster} data={HolidayMasterDetail} handleArchiveCompliance={handleArchiveCompliance} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} handleSort={handleSort} />
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={isEdit}
                onHide={toggleEditCanvas}
                placement="bottom"
                style={{ minHeight: "500px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Holiday
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="mt-4 px-4">
                        <Row>
                            <Col md="3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Year</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Form.Control
                                            type="text"
                                            name="year"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Compliance name"
                                            value={selectedYear || "Select Year"}
                                            disabled
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md="3">
                                <Form.Group className="mb-3">
                                    <Form.Label>State</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Form.Control
                                            type="text"
                                            name="year"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Compliance name"
                                            value={
                                                selectedState
                                                    ? getStateName(Number(selectedState))
                                                    : "Select State"
                                            }
                                            disabled
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md="3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Holiday name</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            className="form-control-lg form-control-icon name-input"
                                            placeholder="Enter Holiday name"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.description && formik.errors.description)}
                                        />
                                        <i style={{ fontSize: '16px' }} className="ri-book-2-fill"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md="3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Date</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Flatpickr
                                            className={`form-control form-control-lg form-control-icon ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                            name="date"
                                            value={formik.values.date}
                                            onChange={(dates: any[]) => {
                                                const formattedDate = format(dates[0], 'dd-MM-yyyy');
                                                formik.setFieldValue("date", formattedDate);
                                            }}
                                            options={{
                                                dateFormat: "d-m-Y",
                                                monthSelectorType: 'static'
                                            }}
                                            placeholder="Select date"
                                        />
                                        <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-calendar-event-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.date}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <div className="form-check form-switch form-switch-custom form-switch-md mb-2">
                                        <input
                                            type="checkbox"
                                            name="AllDay"
                                            id="AllDay"
                                            className="form-check-input"
                                            onChange={handleAllDayChange}
                                            checked={
                                                formik.values.start_time === "00:00" &&
                                                formik.values.finish_time === "23:59"
                                            }
                                        />
                                        <label
                                            htmlFor="AllDay"
                                            className="form-check-label"
                                        >
                                            All Day
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Time</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Cleave
                                            placeholder="hh:mm"
                                            options={{
                                                time: true,
                                                timePattern: ['h', 'm']
                                            }}
                                            value={formik.values.start_time}
                                            onChange={(e: any) => handleTimeChange(e.target.rawValue, "start_time")}
                                            className={`form-control form-control-lg form-control-icon ${formik.touched.start_time && formik.errors.start_time ? 'is-invalid' : ''}`}
                                        />
                                        <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-calendar-event-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.start_time}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md="3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Finish Time</Form.Label>
                                    <div className="position-relative form-icon">
                                        <Cleave
                                            placeholder="hh:mm"
                                            options={{
                                                time: true,
                                                timePattern: ['h', 'm']
                                            }}
                                            value={formik.values.finish_time}
                                            onChange={(e: any) => handleTimeChange(e.target.rawValue, "finish_time")}
                                            className={`form-control form-control-lg form-control-icon ${formik.touched.finish_time && formik.errors.finish_time ? 'is-invalid' : ''}`}
                                        />
                                        <i
                                            style={{ fontSize: "16px" }}
                                            className="ri-calendar-event-line"
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.finish_time}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg="12" style={{ position: 'absolute', bottom: '0' }}>
                            <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center">
                                <Row>
                                    <Col
                                        lg={12}
                                        className="off-bottom-staff justify-content-end"
                                    >
                                        <div className="off-confirm">
                                            <Button
                                                onClick={toggleEditCanvas}
                                                color="secondary"
                                                className="clear-btn me-2"
                                                disabled={formik.isSubmitting}
                                            >
                                                Cancel
                                            </Button>
                                            {formik.isSubmitting ? (
                                                <Button
                                                    color="primary"
                                                    className="next-btn"
                                                    disabled
                                                >
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    className="next-btn"
                                                    disabled={formik.isSubmitting}
                                                >
                                                    {isEditing ? "Update" : "Add"}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete Holiday.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})


export default HolidayMaster