import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Offcanvas,
  Form,
  Badge,
  Table,
  Modal,
  Spinner
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoiceListTable from "./ClientTables/InvoiceListTable";
import Flatpickr from "react-flatpickr";
import SimpleBar from "simplebar-react";
import {
  getInvoiceList,
  getClientListInoviceDetail,
  TimesheetInvoiceDetail,
  getTemplateInoviceDetail,
  getPaymentTermInoviceDetail,
  addInvoiceDetail,
  getByInvoiceList,
  updateInvoiceDetail,
  PaidByInvoiceList,
  archiveInvoiceList,
  DownloadInvoice,
  getClientListFilterInoviceDetail,
  ClientLocationDropdownDetail,
  ExportInvoice
} from "helpers/saibackend_helper";

interface InvoiceData {
  id: number;
  location: string;
  department: string;
  shiftTime: string;
  payRate: string;
  billableHours: string;
  unitPrice: string;
  gst: string;
  amt: string;
}

interface InvoiceNewData {
  id: number;
  client_name: string;
  invoice_number: string;
  invoice_date: string;
  sub_total: string;
  gst_percentage: number;
  gst_amt: number;
  total_amt: number;
  invoice_period_start_date: string;
  invoice_period_end_date: string;
  payment_term: string;
  invoice_template: string;
  invoice_paid_date: string;
  remarks: string;
}

interface LocationData {
  value: number;
  label: string;
}


interface ClientData {
  id: number;
  name: string;
  value: number
}

interface TemplateData {
  id: number;
  template_name: string;
  value: number;
}

interface TermData {
  id: number;
  term_name: string;
  value: number;
}

interface InvocieSummary {
  total_invoices_generated: number;
  total_paid_amount: number;
  total_outstanding_Amount: number;
}

interface Invoicedata {
  id: number;
  invoice_number: string;
  invoice_date: string;
  client_id: number;
  client_name: string;
  client_address: string;
  sub_total: string;
  gst_percentage: number;
  gst_amt: number;
  total_amt: number;
  invoice_period_start_date: string;
  invoice_period_end_date: string;
  payment_term_id: number;
  payment_term: string;
  invoice_template_id: number;
  invoice_template: string;
  invoice_paid_date: string;
  remarks: string;
  invoice_data_new: [
    {
      invoice_data_new_id: number;
      key_id: number;
      invoice_id: number;
      location_id: number;
      description: string;
      timesheet_ids: any;
      total_hrs: number;
      rate: number;
      amount: number;
    },
  ];
}

const ClientList = () => {
  document.title = "Invoice | The Guard Duty";
  const [showButton, setShowButton] = useState<boolean>(false);

  const [courseFilters, setCourseFilters] = useState<boolean>(false);
  const handleFilterShow = () => setCourseFilters(!courseFilters);

  const [sendInvoice, setSendInvoice] = useState<boolean>(false);
  const sendInvoiceShow = () => setSendInvoice(!sendInvoice);

  const [addInvoice, setAddInvoice] = useState<boolean>(false);

  const [viewInvoice, setViewInvoice] = useState<boolean>(false);
  const viewInvoiceShow = () => setViewInvoice(!viewInvoice);

  const handleSelectedItemsChange = (selectedItems: any[]) => {
    // Check if there are any selected items
    const newIsAnyItemSelected = selectedItems.length > 0;
    setShowButton(newIsAnyItemSelected);
  };

  // Filters //
  // =============================================================================================================//

  const [ClientFilterDetail, setClientFilterDetail] = useState<ClientData[]>([]);

  const [defaultClientFilterValue, setDefaultClientFilterValue] = useState({
    search_term: "",
    filter: 1
  });

  const fetchClientFilterDetails = async () => {
    try {
      const response = await getClientListFilterInoviceDetail(
        defaultClientFilterValue.search_term,
        defaultClientFilterValue.filter
      );
      setClientFilterDetail(response.data.map((client: any) => ({
        value: client.id,
        label: client.name
      })));
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchClientFilterDetails = (
  //   selectedOption: { value: number; label: string } | null
  // ) => {
  //   if (selectedOption) {
  //   }
  // };

  useEffect(() => {
    if (courseFilters) {
      fetchClientFilterDetails();
    }
  }, [defaultClientFilterValue, courseFilters]);

  const [selectedFilterDates, setSelectedFilterDates] = useState<Date[]>([]);
  const [dateFilterKey, setDateFilterKey] = useState(0);
  const [paidID, setpaidID] = useState(0)
  const [termID, setTermID] = useState(0)
  const [templateID, setTemplateID] = useState(0)
  const [clientID, setClientID] = useState(0)

  const formatFilterDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const applyFilters = () => {
    if (selectedFilterDates.length === 2) {
      setPayloadValue((prevValue) => ({
        ...prevValue,
        start_date: formatFilterDate(selectedFilterDates[0]),
        end_date: formatFilterDate(selectedFilterDates[1]),
        client_id: clientID,
        payment_term_id: termID,
        invoice_template_id: templateID,
        is_paid: paidID,
      }));
      setCourseFilters(false)
    } else {
      setPayloadValue((prevValue) => ({
        ...prevValue,
        client_id: clientID,
        payment_term_id: termID,
        invoice_template_id: templateID,
        is_paid: paidID,
      }));
      setCourseFilters(false)
    }
  };


  const handleDateRangeFilterChange = (dates: Date[]) => {
    if (dates.length === 2) {
      setSelectedFilterDates(dates);
    } else {
      console.error('Invalid date range selection:', dates);
    }
  };

  // const handleClientFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedId = Number(event.target.value);
  //   if (selectedId) {
  //     setClientID(selectedId)
  //   }
  // };

  const handleClientFilterChange = (
    selectedOption: { value: number; label: string } | null
  ) => {
    if (selectedOption) {
      setClientID(selectedOption.value)
    }
  };


  const handlePaidFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value);
    if (selectedId) {
      setpaidID(selectedId)
    }
  };

  const handleTermFilterChange = (
    selectedOption: { value: number; label: string } | null
  ) => {
    if (selectedOption) {
      setTermID(selectedOption.value)
    }
  };

  const handleTemplateFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value);
    if (selectedId) {
      setTemplateID(selectedId)
    }
  };

  const ClearAllFilter = () => {
    setDateFilterKey((prevKey) => prevKey + 1);
    setSelectedFilterDates([])
    setCourseFilters(false)
    setPayloadValue((prevValue) => ({
      ...prevValue,
      start_date: "",
      end_date: "",
      client_id: 0,
      payment_term_id: 0,
      invoice_template_id: 0,
      is_paid: 0,
    }));
    setpaidID(0)
    setTermID(0)
    setTemplateID(0)
    setClientID(0)
  };

  // List Invoice API Integration //
  // =============================================================================================================//

  const [InvoiceDetail, setInvoiceDetail] = useState<InvoiceNewData[]>([]);

  const [InvoiceSummary, setInvoiceSummary] = useState<InvocieSummary>({
    total_invoices_generated: 0,
    total_paid_amount: 0,
    total_outstanding_Amount: 0,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [payloadValue, setPayloadValue] = useState({
    page: 1,
    page_size: "10",
    search_term: "",
    sort: {
      sort_order: "DESC",
      sort_by: "",
    },
    is_archive: 0,
    start_date: "",
    end_date: "",
    client_id: 0,
    payment_term_id: 0,
    invoice_template_id: 0,
    is_paid: 0,
  });

  const fetchInvoiceDetails = async () => {
    try {
      const response = await getInvoiceList(
        payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.start_date,
        payloadValue.end_date,
        payloadValue.client_id,
        payloadValue.payment_term_id,
        payloadValue.invoice_template_id,
        payloadValue.is_paid
      );
      setInvoiceDetail(response.data.data_list);
      setTotalPages(response.data.total_pages);
      setInvoiceSummary(response.data.summary);
      setLoading(false);
      setCurrentPage(payloadValue.page);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, [payloadValue]);

  const handleSortChange = (column: string) => {
    setPayloadValue((prevState) => ({
      ...prevState,
      sort: {
        sort_order:
          prevState.sort.sort_by === column &&
            prevState.sort.sort_order === "ASC"
            ? "DESC"
            : "ASC",
        sort_by: column,
      },
      page: 1,
    }));
  };

  const handlePageChange = (page: number) => {
    setPayloadValue({ ...payloadValue, page: page });
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setPayloadValue((prevPayload) => ({
        ...prevPayload,
        search_term: inputValue,
      }));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // Client Dropdown //
  // =============================================================================================================//

  const [ClientDetail, setClientDetail] = useState<ClientData[]>([]);

  const [defaultClientValue, setDefaultClientValue] = useState({
    search_term: "",
    start_date: "",
    end_date: "",
  });

  const fetchClientDetails = async () => {
    try {
      const response = await getClientListInoviceDetail(
        defaultClientValue.search_term,
        defaultClientValue.start_date,
        defaultClientValue.end_date
      );
      setClientDetail(response.data.map((client: any) => ({
        value: client.id,
        label: client.name
      })));
    } catch (error) {
      console.log(error);
    }
  };



  // useEffect(() => {
  //   fetchClientDetails();
  // }, [defaultClientValue]);

  const [clientLocationID, setClientLocationID] = useState(0)

  const handleClientInvoiceChange = (
    selectedOption: { value: number; label: string } | null
  ) => {
    if (selectedOption) {
      formik.setFieldValue("client_id", selectedOption.value);
      setClientLocationID(selectedOption.value);
      // setPayloadTimesheetValue((prevValue) => ({
      //   ...prevValue,
      //   client_id: selectedOption.value,
      // }));
    }
  };

  // Template Dropdown //
  // =============================================================================================================//

  const [TemplateDetail, setTemplateDetail] = useState<TemplateData[]>([]);

  const [defaultValue, setDefaultValue] = useState({
    search_term: "",
  });

  const fetchTemplateDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getTemplateInoviceDetail(searchTerm);
      setTemplateDetail(response.data.map((temp: any) => ({
        value: temp.id,
        label: temp.template_name
      })));
    } catch (error) {
      console.log(error);
    }
  };


  const handleInvoiceTemplateChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      formik.setFieldValue('invoice_template_id', selectedOption.value);
    }
  };

  // useEffect(() => {
  //   fetchTemplateDetails();
  // }, [defaultValue]);

  // Payment Term Dropdown //
  // =============================================================================================================//

  const [TermDetail, setTermDetail] = useState<TermData[]>([]);
  const [TermFilterDetail, setTermFilterDetail] = useState<TermData[]>([]);

  const fetchTermDetails = async () => {
    try {
      const searchTerm = "";
      const response = await getPaymentTermInoviceDetail(
        searchTerm
      );
      setTermFilterDetail(response.data.map((term: any) => ({
        value: term.id,
        label: term.term_name
      })));
      setTermDetail(response.data.map((term: any) => ({
        value: term.id,
        label: term.term_name
      })));
    } catch (error) {
      console.log(error);
    }
  };


  const handlePaymentTermChange = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      formik.setFieldValue('payment_term_id', selectedOption.value);
    }
  };


  // useEffect(() => {
  //   fetchTermDetails();
  // }, [defaultValue]);

  // Add Invoice API Integration //
  // =============================================================================================================//

  const validationSchema = Yup.object().shape({
    client_id: Yup.number().positive('Client is Required'),
    payment_term_id: Yup.number().positive('Payment Term is Required'),
    // invoice_template_id: Yup.number().positive('Invoice Template is Required'),
    invoice_date: Yup.string().required("Date is required"),
    invoice_period_start_date: Yup.string().required("Start Date is required"),
    invoice_period_end_date: Yup.string().required("End Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      client_id: 0,
      payment_term_id: 0,
      invoice_template_id: 0,
      location_ids: [],
      invoice_date: "",
      gst_percentage: 10,
      gst_amt: "",
      total_amt: "",
      sub_total: "",
      invoice_period_start_date: "",
      invoice_period_end_date: "",
      invoice_paid_date: "",
      remarks: "",
      client_name: "",
      invoice_data_new: [
        {
          invoice_data_new_id: 0,
          key_id: 0,
          location_id: 0,
          description: "",
          timesheet_ids: [],
          total_hrs: 0,
          rate: 0,
          amount: 0
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }: any) => {

      if (formik.values.invoice_data_new.length === 0) {
        toast.error("Please Select Timesheet");
        return;
      }
      try {
        if (isEditing && editId !== null) {
          const updatedvalue = {
            ...values,
            id: editId,
          };
          const response = await updateInvoiceDetail(updatedvalue);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        } else {
          const response = await addInvoiceDetail(values);
          if (response.status === 1) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
            return;
          }
        }
        formik.resetForm();
        setPayloadValue((prevState) => ({
          ...prevState,
          sort: {
            sort_order: "DESC",
            sort_by: ""
          },
          page: 1,
        }));
        setAddInvoice(false);
        setSelectedLocation([])
        setSelectedDates([]);
        setDateKey((prevKey) => prevKey + 1);
        setPayloadTimesheetValue((prevPayload) => ({
          ...prevPayload,
          client_id: 0,
          start_date: "",
          end_date: "",
        }));
      } catch (error) {
        console.error("Error Adding/Updating Timesheet:", error);
        toast.error("Error Timesheet. Please try again later.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  // =============================================================================================================//

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [dateKey, setDateKey] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const parseDate = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const handleDateRangeChange = (dates: Date[]) => {
    if (dates.length === 2) {
      setSelectedDates(dates);
      setPayloadTimesheetValue((prevValue) => ({
        ...prevValue,
        start_date: formatDate(dates[0]),
        end_date: formatDate(dates[1]),
      }));
      setDefaultClientValue((prevValue) => ({
        ...prevValue,
        start_date: formatDate(dates[0]),
        end_date: formatDate(dates[1]),
      }));
      formik.setFieldValue("invoice_period_start_date", formatDate(dates[0]));
      formik.setFieldValue("invoice_period_end_date", formatDate(dates[1]));
    } else {
      console.error("Invalid date range selection:", dates);
    }
  };

  const [payloadTimesheetValue, setPayloadTimesheetValue] = useState({
    client_id: 0,
    start_date: "",
    end_date: "",
    location_ids: 0,
  });
  const fetchTimesheetDetails = async () => {
    try {
      if (clientLocationID && editId == null && payloadTimesheetValue.start_date && payloadTimesheetValue.end_date) {
        const response = await TimesheetInvoiceDetail(
          clientLocationID,
          payloadTimesheetValue.start_date,
          payloadTimesheetValue.end_date,
          selectedLocation.map((location: any) => location.value)
        );
        formik.setFieldValue("invoice_data_new", response.data, false);
        formik.setFieldValue("location_ids", selectedLocation.map((location: any) => location.value));
      } else {
        toast.error("Please Select Client ")
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (courseFilters || addInvoice) {
      fetchTermDetails();
      fetchTemplateDetails();
      fetchClientDetails();
    }
  }, [courseFilters, addInvoice, defaultClientValue, payloadTimesheetValue]);

  const handleRateChange = (id: number, total_hrs: string, rate: string) => {
    // Parse the new rate
    const parsedRate = (rate != "") ? parseFloat(rate) : 0;
    const parsedHrs = (total_hrs != "") ? parseFloat(total_hrs) : 0;

    // Get the current invoice_data_new from Formik
    const updatedInvoiceData = formik.values.invoice_data_new.map((detail) => {
      if (detail.key_id === id) {
        // Calculate the new amount based on the updated rate
        const amount = !isNaN(parsedRate) ? parsedHrs * parsedRate : 0;
        return {
          ...detail,
          total_hrs,
          rate,
          amount,
        };
      }
      return detail;
    });

    // Update Formik with the new invoice_data_new
    formik.setFieldValue("invoice_data_new", updatedInvoiceData);
  };

  const subTotal = useMemo(() => {
    return formik.values.invoice_data_new.reduce((total, detail) => {
      const amount = detail.amount || 0;
      return total + amount;
    }, 0);
  }, [formik.values.invoice_data_new]);

  const gstPercentage = 10;
  const gstAmount = useMemo(() => (subTotal * gstPercentage) / 100, [subTotal]);

  const totalAmount = useMemo(
    () => subTotal + gstAmount,
    [subTotal, gstAmount]
  );

  useEffect(() => {
    const formattedSubTotal = isNaN(subTotal) ? "0.00" : subTotal.toFixed(2);
    const formattedGstAmount = isNaN(gstAmount) ? "0.00" : gstAmount.toFixed(2);
    const formattedTotalAmount = isNaN(totalAmount)
      ? "0.00"
      : totalAmount.toFixed(2);
    if (formik.values.sub_total !== formattedSubTotal) {
      formik.setFieldValue("sub_total", formattedSubTotal, false);
    }
    if (formik.values.gst_amt !== formattedGstAmount) {
      formik.setFieldValue("gst_amt", formattedGstAmount, false);
    }
    if (formik.values.total_amt !== formattedTotalAmount) {
      formik.setFieldValue("total_amt", formattedTotalAmount, false);
    }
  }, [subTotal, gstAmount, totalAmount, formik.values]);

  const handleAddRow = () => {
    const newRow = {
      invoice_data_new_id: Date.now(),
      key_id: formik.values.invoice_data_new.length + 1,
      location_id: 0,
      description: "",
      timesheet_ids: [],
      total_hrs: 0,
      rate: 0,
      amount: 0,
      is_new: 1
    };
    formik.setFieldValue("invoice_data_new", [...formik.values.invoice_data_new, newRow]);
  };

  const handleDeleteRow = (id: number) => {
    const updatedInvoiceData = formik.values.invoice_data_new.filter((row) => row.key_id !== id);
    formik.setFieldValue("invoice_data_new", updatedInvoiceData);
  };

  const handleDescriptionChange = (id: number, description: string) => {
    const updatedInvoiceData = formik.values.invoice_data_new.map((detail) => {
      if (detail.key_id === id) {
        return { ...detail, description };
      }
      return detail;
    });
    formik.setFieldValue("invoice_data_new", updatedInvoiceData);
  };

  // Get By Id Invoice //
  // =============================================================================================================//

  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);
  const [InvoiceIDDetail, setInvoiceIDDetail] = useState<Invoicedata | null>(null);

  const getInvoiceListDetail = async (id: number) => {
    try {
      if (id) {
        const response = await getByInvoiceList(id);
        const data = response.data;
        setInvoiceIDDetail(data)
        setViewInvoice(true)
      }
    } catch (error) {
      console.error("Error fetching Invoice details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  }


  const editMaster = async (id: number) => {
    try {
      if (!id) {
        return;
      }
      const response = await getByInvoiceList(id);
      const data = response.data;

      const mappedBreakRows = data.invoice_data_new.map((row: any) => ({
        invoice_data_new_id: row.invoice_data_new_id,
        key_id: row.invoice_data_new_id,
        location_id: row.location_id,
        description: row.description,
        timesheet_ids: row.timesheet_ids,
        total_hrs: row.total_hrs,
        rate: row.rate,
        amount: row.amount,
      }));

      formik.setValues({
        client_id: data.client_id,
        payment_term_id: data.payment_term_id,
        invoice_template_id: data.invoice_template_id,
        location_ids: data.location_ids,
        invoice_date: data.invoice_date,
        gst_percentage: data.gst_percentage,
        gst_amt: data.gst_amt,
        total_amt: data.total_amt,
        sub_total: data.sub_total,
        invoice_period_start_date: data.invoice_period_start_date,
        invoice_period_end_date: data.invoice_period_end_date,
        invoice_paid_date: data.invoice_paid_date,
        remarks: data.remarks,
        client_name: data.client_name,
        invoice_data_new: mappedBreakRows,
      });
      const startDate = parseDate(data.invoice_period_start_date);
      const endDate = parseDate(data.invoice_period_end_date);
      if (startDate && endDate) {
        setSelectedDates([startDate, endDate]);
      } else {
        console.error("Invalid start or end date received from API.");
      }
      setDefaultClientValue((prevValue) => ({
        ...prevValue,
        start_date: data.invoice_period_start_date,
        end_date: data.invoice_period_end_date,
      }));
      fetchClientDetails();
      setIsEditing(true);
      // setCheckedItems(mappedBreakRows);
      setEditId(id);
      setAddInvoice(true);
    } catch (error) {
      console.error("Error fetching Invoice details:", error);
      toast.error("Error fetching details. Please try again later.");
    }
  };

  // Get By Id Invoice //
  // =============================================================================================================//

  const [paidId, setPaidId] = useState<number | undefined>(undefined);

  const handlePaid = async (id: number): Promise<void> => {
    setPaidId(id);
    setmodal_paid_center(true);
  };

  const [modal_paid_center, setmodal_paid_center] = useState<boolean>(false);
  function tog_paid_center() {
    setmodal_paid_center(!modal_paid_center);
  }

  const PaidTimesheet = async (id: number) => {
    try {
      const response = await PaidByInvoiceList(id);
      fetchInvoiceDetails();
      toast.success(response.message);
      setmodal_paid_center(false);
    } catch (error) {
      console.error("Error Paid Invoice:", error);
      toast.error("Error Please try again later.");
    }
  };

  // Archive By Id Invoice //
  // =============================================================================================================//

  const [archiveId, setArchiveId] = useState<number | undefined>(undefined);
  const [archiveLoader, setArchiveLoader] = useState(false)

  const handleArchiveInvoice = async (id: number): Promise<void> => {
    setArchiveId(id);
    setmodal_center(true);
  };

  const [modal_center, setmodal_center] = useState<boolean>(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const archiveInvoice = async (id: number) => {
    setArchiveLoader(true)
    try {
      const response = await archiveInvoiceList(id);
      fetchInvoiceDetails();
      toast.success(response.message);
      setmodal_center(false);
      setArchiveLoader(false)
    } catch (error) {
      console.error("Error Archive Invoice:", error);
      toast.error("Error Archive Please try again later.");
    }
  };

  const openAddForm = () => {
    setIsEditing(false);
    setEditId(null);
    formik.resetForm();
    setAddInvoice(true);
    setSelectedDates([]);
    setDateKey((prevKey) => prevKey + 1);
    formik.setFieldValue("invoice_data_new", [], false);
    setPayloadTimesheetValue((prevPayload) => ({
      ...prevPayload,
      client_id: 0,
      start_date: "",
      end_date: "",
    }));
  };

  // Download Invoice
  // =============================================================================================================//
  const [loadingInvoices, setLoadingInvoices] = useState<{ [id: number]: boolean }>({});

  const downloadInvoice = async (id: number) => {
    setLoadingInvoices((prev) => ({ ...prev, [id]: true }));
    try {
      await DownloadInvoice(id);
    } catch (error) {
      console.error("Error downloading Invoice:", error);
      toast.error("Error downloading invoice. Please try again later.");
    } finally {
      setLoadingInvoices((prev) => ({ ...prev, [id]: false }));
    }
  };

  // Export Invoice //
  // =============================================================================================================//

  const [expLoader, setExpLoader] = useState(false)

  const exportnvoice = async () => {
    setExpLoader(true)
    try {
      await ExportInvoice(
        payloadValue.page,
        payloadValue.page_size,
        payloadValue.search_term,
        payloadValue.sort.sort_order,
        payloadValue.sort.sort_by,
        payloadValue.is_archive,
        payloadValue.start_date,
        payloadValue.end_date,
        payloadValue.client_id,
        payloadValue.payment_term_id,
        payloadValue.invoice_template_id,
        payloadValue.is_paid
      );
      setExpLoader(false)
    } catch (error) {
      console.error("Error downloading Invoice:", error);
      toast.error("Error downloading Invoice. Please try again later.");
    }
  }


  const [selectedLocation, setSelectedLocation] = useState<number[]>([]);
  const [LocationDropdown, setLocationDropdown] = useState<LocationData[]>([])

  const fetchLocation = async () => {
    try {
      if (clientLocationID && defaultClientValue.start_date && defaultClientValue.end_date) {
        const response = await ClientLocationDropdownDetail(
          clientLocationID,
          defaultClientValue.search_term,
          defaultClientValue.start_date,
          defaultClientValue.end_date
        );
        formik.setFieldValue("invoice_data_new", [], false);
        setSelectedLocation([]);
        setLocationDropdown(response.data)
      } else {
        formik.setFieldValue("invoice_data_new", [], false);
        setSelectedLocation([]);
        setLocationDropdown([])
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchLocation()
  }, [clientLocationID])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Invoice List" pageTitle="Dashboard" />
          {
            loading ?
              <Row className="placeholder-glow">
                <Col lg={4}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={4}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={4}>
                  <div className="placeholder bg-light card-placeholder col-12"></div>
                </Col>
                <Col lg={12} className="mt-4">
                  <div className="placeholder bg-light table-placeholder col-12"></div>
                </Col>
              </Row>
              :
              <Row className="client-module">
                <Col lg={4}>
                  <Card className="overflow-hidden">
                    <Card.Body>
                      <div className="avatar-sm float-end">
                        <div className="avatar-title bg-success-subtle text-success fs-3xl rounded">
                          <i
                            className="ri-file-text-line"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </div>
                      </div>
                      <h4>
                        <span className="counter-value" data-target="368.24">
                          {InvoiceSummary?.total_invoices_generated
                            ? InvoiceSummary.total_invoices_generated
                            : "-"}
                        </span>
                      </h4>
                      <p className="text-muted mb-4">Total Invoices Generated</p>
                    </Card.Body>
                    <div
                      className="progress progress-sm rounded-0"
                      role="progressbar"
                    >
                      <div
                        className="progress-bar bg-success"
                        style={{
                          width: `${InvoiceSummary?.total_invoices_generated}%`,
                        }}
                      ></div>
                    </div>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="overflow-hidden">
                    <Card.Body>
                      <div className="avatar-sm  float-end">
                        <div
                          className="avatar-title fs-3xl rounded"
                          style={{ background: "#DFEBFC", color: "#2272EA" }}
                        >
                          <i
                            className="ri-money-dollar-circle-line"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </div>
                      </div>
                      <h4>
                        <span className="counter-value" data-target="368.24">
                          {InvoiceSummary?.total_paid_amount
                            ? InvoiceSummary.total_paid_amount
                            : "-"}
                        </span>
                      </h4>
                      <p className="text-muted mb-4">Total Paid Amount</p>
                    </Card.Body>
                    <div
                      className="progress progress-sm rounded-0"
                      role="progressbar"
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: `${InvoiceSummary?.total_paid_amount}%`,
                          background: "#2272EA",
                        }}
                      ></div>
                    </div>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="overflow-hidden">
                    <Card.Body>
                      <div className="avatar-sm float-end">
                        <div className="avatar-title bg-warning-subtle fs-3xl rounded">
                          <i
                            className="ri-file-text-line text-warning"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </div>
                      </div>
                      <h4>
                        <span className="counter-value" data-target="368.24">
                          {InvoiceSummary?.total_outstanding_Amount
                            ? InvoiceSummary.total_outstanding_Amount
                            : "-"}
                        </span>
                      </h4>
                      <p className="text-muted mb-4">Total Outstanding Amount</p>
                    </Card.Body>
                    <div
                      className="progress progress-sm rounded-0"
                      role="progressbar"
                    >
                      <div
                        className="progress-bar bg-warning"
                        style={{
                          width: `${InvoiceSummary?.total_outstanding_Amount}%`,
                          background: "#2272EA",
                        }}
                      ></div>
                    </div>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="client-list-card">
                    <Card.Body>
                      <Row>
                        <Col lg={6} className="mb-3">
                          <div className="app-search d-none d-md-inline-flex">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                id="search-options"
                                value={inputValue}
                                onChange={handleChange}
                              />
                              <span className="mdi mdi-magnify search-widget-icon"></span>
                              <span
                                className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                id="search-close-options"
                              ></span>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} className="btn-section mb-3">
                          <Button className="filter-btn" onClick={handleFilterShow}>
                            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
                            Filters
                          </Button>
                          <Button className="next-btn" onClick={openAddForm}>
                            <i className="ri-add-line me-1 "></i> Add New
                          </Button>
                          {expLoader ? (
                            <Button
                              color="primary"
                              className="export-btn"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          ) : (
                            <Button onClick={exportnvoice} className='export-btn'>
                              <i className="bx bx-export me-1 "></i>  Export
                            </Button>
                          )}
                          {/* <Button
                        className="next-btn"
                        disabled={!showButton}
                        onClick={sendInvoiceShow}
                      >
                        <i className="ri-send-plane-fill"></i> Send Invoice
                      </Button> */}
                        </Col>
                        <Col lg={12} className="client-list-table">
                          <InvoiceListTable
                            Data={InvoiceDetail}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            viewInvoiceBtn={viewInvoiceShow}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            handleSortChange={handleSortChange}
                            editMaster={editMaster}
                            handlePaid={handlePaid}
                            handleArchiveInvoice={handleArchiveInvoice}
                            getInvoiceListDetail={getInvoiceListDetail}
                            downloadInvoice={downloadInvoice}
                            fetchInvoiceDetails={fetchInvoiceDetails}
                            loadingInvoices={loadingInvoices}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
      <Offcanvas
        className="filter-canvas"
        show={courseFilters}
        placement="end"
        onHide={handleFilterShow}
      >
        <Offcanvas.Header
          id="courseFilters"
          className="border-bottom filter-canvas"
          closeButton
        >
          <Offcanvas.Title id="courseFiltersLabel">
            {" "}
            <i className="ri-list-settings-line align-baseline me-1"></i>{" "}
            Filters
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row>
              <Col lg={12}>
                <Row className="filter-1">
                  <Col lg={12}>
                    <h5>Client Name</h5>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-icon">
                      <div className={`react-select-container`}>
                        <Select
                          inputId="client_id"
                          name="client_id"
                          options={ClientFilterDetail}
                          value={ClientFilterDetail.find(option => option.value === clientID)}
                          onChange={handleClientFilterChange}
                          classNamePrefix="react-select"
                          className="react-select"
                        />
                        <i
                          style={{ fontSize: "18px" }}
                          className="bx bx-face"
                        ></i>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row className="filter-2">
                  <Col lg={12}>
                    <h5>Payment Terms</h5>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-icon">
                      <div className={`react-select-container`}>
                        <Select
                          inputId="term_id"
                          name="term_id"
                          options={TermFilterDetail}
                          value={TermFilterDetail.find(option => option.value === termID)}
                          onChange={handleTermFilterChange}
                          classNamePrefix="react-select"
                          className="react-select"
                        />
                        <i
                          style={{ fontSize: "18px" }}
                          className="ri-file-text-line"
                        ></i>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg={12}>
                <Row className="filter-2">
                  <Col lg={12}>
                    <h5>Invoice Template</h5>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Select
                        className="form-select-lg"
                        id="floatingSelect"
                        value={templateID}
                        onChange={handleTemplateFilterChange}
                      >
                        <option defaultValue="">Select Template</option>
                        {TemplateDetail.map((TemplateDetail) => {
                          return (
                            <option
                              key={TemplateDetail.id}
                              value={TemplateDetail.id}
                            >
                              {TemplateDetail.template_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col> */}
              <Col lg={12}>
                <Row className="filter-2">
                  <Col lg={12}>
                    <h5>Paid / Unpaid</h5>
                  </Col>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Select
                        className="form-select-lg"
                        id="floatingSelect"
                        value={paidID}
                        onChange={handlePaidFilterChange}
                      >
                        <option value="0">All</option>
                        <option value="2">Paid</option>
                        <option value="1">Unpaid</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row className="filter-2">
                  <Col lg={12}>
                    <h5>Date Period</h5>
                  </Col>
                  <Col lg="12" className="mb-3">
                    <Flatpickr
                      className="form-control"
                      key={dateFilterKey}
                      placeHolder="Select a date range"
                      options={{
                        mode: 'range',
                        dateFormat: 'd-m-Y',
                        defaultDate: selectedFilterDates.length ? selectedFilterDates : [null, null]
                      }}
                      value={selectedFilterDates}
                      onChange={(dates: Date[]) => handleDateRangeFilterChange(dates)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
        <Row>
          <Col lg="12">
            <div className="offcanvas-footer border-top-0 p-3 text-center">
              <Row className="px-2 approve-reject-btn">
                <Col lg={6} className="clear-section">
                  <Button
                    onClick={ClearAllFilter}
                    color="primary"
                    className="clear-btn"
                  >
                    Clear Filters
                  </Button>
                </Col>
                <Col lg={6} className="Apply-section">
                  <Button onClick={applyFilters} color="primary" className="next-btn">
                    Apply Filters
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Offcanvas>
      <Offcanvas
        className="add-invoice"
        show={addInvoice}
        onHide={() => setAddInvoice(false)}
        placement="bottom"
        style={{ height: "100%" }}
      >
        <Offcanvas.Header className="add-header border-bottom">
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            {isEditing ? "Edit" : "Add"} Invoice
          </Offcanvas.Title>
          <div>
            <Button
              className="close-btn"
              onClick={() => {
                setAddInvoice(false);
              }}
            >
              <i className="ri-close-line"></i>
            </Button>
          </div>
        </Offcanvas.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Offcanvas.Body>
            <SimpleBar style={{ height: "620px" }} className="px-3">
              <Row>
                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Billing Period</Form.Label>
                    <div className="position-relative">
                      <Flatpickr
                        className={`form-control form-control-lg ${formik.touched.invoice_period_start_date &&
                          formik.errors.invoice_period_start_date
                          ? "is-invalid"
                          : ""
                          }`}
                        key={dateKey}
                        placeholder="Select a date range"
                        options={{
                          mode: "range",
                          dateFormat: "d-m-Y",
                          defaultDate: selectedDates.length
                            ? selectedDates
                            : [null, null],
                        }}
                        disabled={!!editId}
                        value={selectedDates}
                        onChange={(dates: Date[]) =>
                          handleDateRangeChange(dates)
                        }
                      />
                    </div>
                    <div className="d-flex gap-3">

                      {formik.touched.invoice_period_start_date &&
                        formik.errors.invoice_period_start_date ? (
                        <div style={{ fontSize: '11.37px' }} className="text-danger">
                          {formik.errors.invoice_period_start_date}
                        </div>
                      ) : null}
                      {formik.touched.invoice_period_end_date &&
                        formik.errors.invoice_period_end_date ? (
                        <div style={{ fontSize: '11.37px' }} className="text-danger">
                          {formik.errors.invoice_period_end_date}
                        </div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                {!editId ? (
                  <Col lg={3}>
                    <Form.Group className="mb-3 form-icon">
                      <Form.Label>Client Name</Form.Label>
                      <div className={`react-select-container ${formik.touched.client_id && formik.errors.client_id ? 'is-invalid' : ''}`}>
                        <Select
                          inputId="client_id"
                          name="client_id"
                          options={ClientDetail}
                          value={ClientDetail.find(option => option.value === formik.values.client_id)}
                          onChange={handleClientInvoiceChange}
                          isDisabled={!!editId || selectedDates.length === 0}
                          onBlur={formik.handleBlur}
                          classNamePrefix="react-select"
                          className="react-select"
                        />
                        <i
                          style={{ fontSize: "18px" }}
                          className="bx bx-face mt-4"
                        ></i>
                        {formik.touched.client_id && formik.errors.client_id ? (
                          <div className="invalid-feedback d-block">
                            {formik.errors.client_id}
                          </div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Client Name</Form.Label>
                      <div className="position-relative form-icon">
                        <Form.Control
                          type="text"
                          className="form-control-lg form-control-icon name-input"
                          value={formik.values.client_name}
                          disabled
                        />
                        <i
                          style={{ fontSize: "16px" }}
                          className=" bx bx-face"
                        ></i>
                      </div>
                    </Form.Group>
                  </Col>
                )}
                {!editId &&
                  <Col lg="3">
                    <Form.Group className="mb-1">
                      <Form.Label>Location</Form.Label>
                      <Select
                        options={LocationDropdown}
                        isMulti
                        value={selectedLocation}
                        isDisabled={!!editId}
                        onChange={(newValue: any) => setSelectedLocation(newValue || [])}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        placeholder="Leave blank for all location"
                      />
                    </Form.Group>
                  </Col>
                }
                {!editId &&
                  <Col lg="3" className="d-flex align-items-center">
                    <Button onClick={fetchTimesheetDetails}> Get Timesheet </Button>
                  </Col>
                }
                <Col lg={3}>
                  <div className="mb-3 d-flex flex-column form-icon">
                    <Form.Label htmlFor="JoiningdatInput">Invoice Date</Form.Label>
                    <Flatpickr
                      className={`form-control form-control-lg form-control-icon ${formik.touched.invoice_date &&
                        formik.errors.invoice_date
                        ? "is-invalid"
                        : ""
                        }`}
                      options={{
                        dateFormat: "d-m-Y",
                        monthSelectorType: 'static'
                      }}
                      name="invoice_date"
                      placeholder="Select Invoice date"
                      value={formik.values.invoice_date}
                      onChange={(dates: any[]) => {
                        const formattedDate = format(dates[0], "dd-MM-yyyy");
                        formik.setFieldValue("invoice_date", formattedDate);
                      }}
                    />
                    <i
                      style={{ fontSize: "16px" }}
                      className="ri-calendar-event-line mt-4"
                    ></i>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.invoice_date}
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3 form-icon">
                    <Form.Label>Payment Terms</Form.Label>
                    <div className={`react-select-container ${formik.touched.payment_term_id && formik.errors.payment_term_id ? 'is-invalid' : ''}`}>
                      <Select
                        inputId="payment_term_id"
                        name="payment_term_id"
                        options={TermDetail}
                        value={TermDetail.find(option => option.value === formik.values.payment_term_id)}
                        onChange={handlePaymentTermChange}
                        onBlur={formik.handleBlur}
                        classNamePrefix="react-select"
                        className="react-select"
                      />
                      <i
                        style={{ fontSize: "18px" }}
                        className="ri-file-text-line mt-4"
                      ></i>
                      {formik.touched.payment_term_id && formik.errors.payment_term_id ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.payment_term_id}
                        </div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                {editId &&
                  <Col lg={3}>
                    <Form.Group className="mb-3 form-icon">
                      <Form.Label>Invoice Template</Form.Label>
                      <div className={`react-select-container ${formik.touched.invoice_template_id && formik.errors.invoice_template_id ? 'is-invalid' : ''}`}>
                        <Select
                          inputId="invoice_template_id"
                          name="invoice_template_id"
                          options={TemplateDetail}
                          value={TemplateDetail.find(option => option.value === formik.values.invoice_template_id)}
                          onChange={handleInvoiceTemplateChange}
                          onBlur={formik.handleBlur}
                          classNamePrefix="react-select"
                          className="react-select"
                        />
                        <i
                          style={{ fontSize: "18px" }}
                          className="ri-file-text-line mt-4"
                        ></i>
                        {/* {formik.touched.invoice_template_id && formik.errors.invoice_template_id ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.invoice_template_id}
                        </div>
                      ) : null} */}
                      </div>
                    </Form.Group>
                  </Col>
                }
                <Col lg="3">
                  <Form.Group className="mb-3">
                    <Form.Label>Remarks</Form.Label>
                    <div className="position-relative form-icon">
                      <Form.Control
                        type="text"
                        name="remarks"
                        className="form-control-lg form-control-icon name-input"
                        placeholder="Enter Remarks.."
                        value={formik.values.remarks}
                        onChange={formik.handleChange}
                      />
                      <i
                        style={{ fontSize: "16px" }}
                        className=" ri-message-3-line"
                      ></i>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" >
                  {formik.values.invoice_data_new.length > 0 ? (
                    <Table className="table-borderless align-middle table-nowrap mb-0 table-1">
                      <thead className="table-header">
                        <tr>
                          <th style={{ width: '5%' }}>Sr No.</th>
                          <th scope="col">Description</th>
                          <th style={{ width: '10%' }} className="text-end">Total Hours</th>
                          <th style={{ width: '10%' }} className="text-end">Rate</th>
                          <th style={{ width: '10%' }} className="text-end">Amount</th>
                          <th style={{ width: '5%' }} scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values.invoice_data_new.map((data, index) => (
                          <tr key={data.key_id}>
                            <td>{index + 1}</td>
                            <td>
                              {
                                data.timesheet_ids.length == 0 ?
                                  <Form.Control
                                    type="text"
                                    className="form-control-lg text-start form-control-icon name-input"
                                    style={{ minHeight: "0", padding: "8px 16px" }}
                                    placeholder="Enter Description"
                                    value={data.description}
                                    onChange={(e) => handleDescriptionChange(data.key_id, e.target.value)}
                                  />
                                  : data.description
                              }

                            </td>
                            <td align="right">
                              <Form.Control
                                type="text"
                                className="form-control-lg text-end form-control-icon name-input"
                                style={{ minHeight: "0", padding: "8px 16px" }}
                                placeholder="Enter Hrs"
                                value={data.total_hrs ? data.total_hrs : ""}
                                onChange={(e) => handleRateChange(data.key_id, e.target.value, data.rate + "")}
                              />
                            </td>
                            <td className="p-2">
                              <Form.Control
                                type="text"
                                className="form-control-lg text-end form-control-icon name-input"
                                style={{ minHeight: "0", padding: "8px 16px" }}
                                placeholder="Enter Rate"
                                value={data.rate ? data.rate : ""}
                                onChange={(e) => handleRateChange(data.key_id, data.total_hrs + "", e.target.value)}
                              />
                            </td>
                            <td className="p-2">
                              <Form.Control
                                type="text"
                                className="form-control-lg text-end form-control-icon name-input"
                                style={{ minHeight: "0", padding: "8px 16px" }}
                                placeholder="Total Amount"
                                value={data.amount != null ? Number(data.amount).toFixed(2) : "0.00"}
                                disabled
                              />
                            </td>
                            <td>
                              {data.timesheet_ids.length == 0 &&
                                <Button
                                  onClick={() => handleDeleteRow(data.key_id)}
                                  className="btn btn-subtle-danger btn-icon btn-md remove-item-btn"
                                >
                                  <i
                                    className="ph-trash"
                                    style={{ fontSize: "15px" }}
                                  ></i>
                                </Button>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center gap-1 p-4">
                      <div className="flex-grow-1 d-flex justify-content-center">
                        <h6 className="fs-md mb-1">No Timesheet Available</h6>
                      </div>
                    </div>
                  )}
                </Col>
                {formik.values.invoice_data_new.length > 0 &&
                  <Row className="justify-content-start">
                    <Col lg={12}>
                      <Button className="add-timesheet-btn" onClick={handleAddRow}>
                        <i className="ri-add-fill"></i> Add More
                      </Button>
                    </Col>
                  </Row>
                }
                {formik.values.invoice_data_new.length > 0 && (
                  <Row className="justify-content-end" style={{ marginBottom: '10%' }}>
                    <Col lg={3}>
                      <Table className="table-borderless align-middle table-nowrap mb-0 table-2">
                        <tbody>
                          <tr>
                            <th scope="col">Sub Total</th>
                            <th className="text-end">${isNaN(subTotal) ? "0.00" : subTotal.toFixed(2)}</th>
                          </tr>
                          <tr>
                            <td>GST ({gstPercentage}%)</td>
                            <td align="right">$ {isNaN(gstAmount) ? "0.00" : gstAmount.toFixed(2)}</td>
                          </tr>
                          <tr className="table-header">
                            <th>Total Amount</th>
                            <th className="text-end">$ {isNaN(totalAmount) ? "0.00" : totalAmount.toFixed(2)}</th>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}
              </Row>
            </SimpleBar>
          </Offcanvas.Body>
          <Row>
            <Col lg="12" style={{ position: "absolute", bottom: "0" }}>
              <div className="offcanvas-footer border-top-0 p-3 pb-0 text-center" style={{ background: '#fff' }}>
                <Row>
                  <Col
                    lg={12}
                    className="off-bottom-staff justify-content-end footer-add-button"
                  >
                    <div className="off-confirm d-flex gap-2">
                      <Button
                        onClick={() => setAddInvoice(false)}
                        color="primary"
                        className="clear-btn"
                      >
                        Cancel
                      </Button>
                      {formik.isSubmitting ? (
                        <Button
                          color="primary"
                          className="next-btn"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      ) : (
                        <Button color="primary" type="submit" className="add-btn">
                          {isEditing ? "Update" : "Add"}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </Offcanvas>
      <Offcanvas
        className="view-invoice"
        show={viewInvoice}
        onHide={viewInvoiceShow}
        placement="bottom"
      >
        <Offcanvas.Header className="add-header border-bottom">
          <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
            <>
              {InvoiceIDDetail ? InvoiceIDDetail?.invoice_number : "-"}
              {InvoiceIDDetail?.invoice_paid_date ? <Badge className="success-badge">Paid</Badge> : <Badge className="danger-badge">Unpaid</Badge>}
            </>
          </Offcanvas.Title>
          <div>
            <Button
              className="close-btn"
              onClick={() => {
                setViewInvoice(false);
              }}
            >
              <i className="ri-close-line"></i>
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <>
            <Card className="detail-box">
              <Card.Body className="p-0">
                <h5>Invoice Details</h5>
                <Row>
                  <Col>
                    <p className="title">Invoice Number</p>
                    <p className="data">{InvoiceIDDetail?.invoice_number ? InvoiceIDDetail?.invoice_number : "-"}</p>
                  </Col>
                  <Col>
                    <p className="title">Billing Period</p>
                    <p className="data">{InvoiceIDDetail?.invoice_period_start_date} - {InvoiceIDDetail?.invoice_period_end_date}</p>
                  </Col>
                  <Col>
                    <p className="title">Invoice Date</p>
                    <p className="data">{InvoiceIDDetail?.invoice_date ? InvoiceIDDetail?.invoice_date : "-"}</p>
                  </Col>
                  <Col>
                    <p className="title">Invoice Template</p>
                    <p className="data">{InvoiceIDDetail?.invoice_template ? InvoiceIDDetail?.invoice_template : "-"}</p>
                  </Col>
                  <Col>
                    <p className="title">Payment Terms</p>
                    <p className="data">{InvoiceIDDetail?.payment_term ? InvoiceIDDetail?.payment_term : "-"}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="detail-box">
              <Card.Body className="p-0">
                <h5>Client Details</h5>
                <Row>
                  <Col lg={2}>
                    <p className="title">Client Name</p>
                    <p className="data">{InvoiceIDDetail?.client_name ? InvoiceIDDetail?.client_name : "-"}</p>
                  </Col>
                  <Col lg={6}>
                    <p className="title">Client Address</p>
                    <p className="data">
                      {InvoiceIDDetail?.client_address ? InvoiceIDDetail.client_address : "-"}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <p className="title">Remarks</p>
                    <p className="data">
                      {InvoiceIDDetail?.remarks ? InvoiceIDDetail.remarks : "-"}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Table
              style={{ tableLayout: "fixed" }}
              className="table-borderless align-middle table-nowrap mb-0 table-1"
            >
              <thead className="table-header">
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Description</th>
                  <th scope="col" className="text-end">
                    Total Hours
                  </th>
                  <th scope="col" className="text-end">
                    Rate
                  </th>
                  <th scope="col" className="text-end">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {InvoiceIDDetail?.invoice_data_new?.map((data, index) => (
                  <tr key={data.invoice_data_new_id}>
                    <td>{index + 1}</td>
                    <td>{data.description}</td>
                    <td align="right">{data.total_hrs ? data.total_hrs : "0"}</td>
                    <td align="right">{data.rate ? data.rate : "0.00"}</td>
                    <td align="right">{data.amount ? data.amount : "0.00"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row className="justify-content-end">
              <Col lg={3}>
                <Table className="table-borderless align-middle table-nowrap mb-0 table-2">
                  <tbody>
                    <tr>
                      <th scope="col">Sub Total</th>
                      <th className="text-end">{InvoiceIDDetail?.sub_total ? `$ ${InvoiceIDDetail?.sub_total}` : "$ 0.00"}</th>
                    </tr>
                    <tr>
                      <td>GST(%)</td>
                      <td align="right">{InvoiceIDDetail?.gst_amt ? `$ ${InvoiceIDDetail?.gst_amt}` : "$ 0.00"}</td>
                    </tr>
                    <tr className="table-header">
                      <th>Total Amount</th>
                      <th className="text-end">{InvoiceIDDetail?.total_amt ? `$ ${InvoiceIDDetail?.total_amt}` : "$ 0.00"}</th>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={modal_paid_center}
        onHide={() => {
          tog_paid_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-money-dollar-circle-line text-success display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">You want to paid this Invoice.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button
                variant="light"
                onClick={() => setmodal_paid_center(false)}
              >
                Close
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  if (paidId !== undefined) {
                    PaidTimesheet(paidId);
                  } else {
                    toast.error("No Paid ID provided.");
                  }
                }}
              >
                Paid
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center();
        }}
        size="sm"
        centered
      >
        <Modal.Body className="text-center p-4">
          <i className="ri-delete-bin-line text-danger display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Are you sure?</h4>
            <p className="text-muted mb-4">
              You want to delete permanently this Invoice.
            </p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>
                Close
              </Button>
              {archiveLoader ? (
                <Button
                  color="primary"
                  className="next-btn"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    if (archiveId !== undefined) {
                      archiveInvoice(archiveId);
                    } else {
                      toast.error("No Archive ID provided.");
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ClientList;
