// HolidayTable.tsx

import React from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

interface HolidayMasterData {
    id: number;
    year: number;
    state_id: number;
    description: string;
    date: Date|null;
    start_time: string;
    finish_time: string;
}
type SortOrder = 'asc' | 'desc';

interface HolidayTableProps {
    data: HolidayMasterData[];
    editMaster: (id: number) => Promise<void>;
    handlePageChange: (page: number) => void;
    handlePerRowsChange: (newPerPage: number, page: number) => void;
    paginationTotalRows: number;
    handleArchiveCompliance: (id: number) => Promise<void>;
    handleSort: (column: string, sortDirection: 'asc' | 'desc') => void;
}

const HolidayTable: React.FC<HolidayTableProps> = ({ data, editMaster, handlePageChange, handlePerRowsChange, paginationTotalRows,handleArchiveCompliance , handleSort}) => {
    const columns = [
        {
            name: <span className='font-weight-bold fs-sm'>Holiday Name</span>,
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
        },
        {
            name: <span className='font-weight-bold fs-sm'>State</span>,
            selector: (row: any) => row.state_name,
            // sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Date</span>,
            selector: (row: any) => row.date,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Time</span>,
            cell: (row: any) => {
                return (
                    <>
                        {row.start_time} - {row.finish_time}
                    </>
                );
            },
            // sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Year</span>,
            selector: (row: any) => row.year,
            // sortable: true
        },
        {
            name: <span className='font-weight-bold fs-sm'>Action</span>,
            // sortable: true,
            cell: (row: any) => {
                return (
                    <>
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            {/* <div className="edit"> */}
                                {/* <Link to="#" onClick={() => editMaster(row.id)} className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"> */}
                                    <i className="ri-edit-2-line link-success" onClick={() => editMaster(row.id)}  style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                {/* </Link>
                            </div>
                            <div className="edit"> */}
                                {/* <Link to="#" onClick={() => archiveMaster(row.id)} className="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"> */}
                                    <i style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => handleArchiveCompliance(row.id)} className="ri-delete-bin-line link-danger"></i>
                                {/* </Link>
                            </div> */}
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={(column: any, sortDirection: SortOrder) => {
                if (column.sortField) {
                    handleSort(column.sortField, sortDirection);
                }
            }}
            sortServer
        />
    );
};

export default HolidayTable;
