import React, { ChangeEvent, useState, useEffect } from "react";
import { getCompanyDocumentMasterDetail, addCompanyDocumentMasterDetail, archiveCompanyDocumentMasterDetail, getByCompanyDocumentMasterDetail, updateCompanyDocumentMasterDetail } from "helpers/saibackend_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Nav,
    Row,
    Tab,
    Dropdown,
    Image,
    Offcanvas,
    Table,
    Modal,
    Spinner
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import CompanyDocumentTable from "./CompanyDocumentTable";

interface CompanyDocumentMasterData {
    id: number;
    document_name: string;
    document_path: string;
    date_added: string,
    allow_satff_to_view: number,
    requires_acknowledgement: number,
    requires_signature: number
}
interface FormValues {
    document_name: string;
    document_path: File | null;
    allow_satff_to_view: string;
    requires_acknowledgement: string;
    requires_signature: string;
    is_requires_renewal: string
    renewal_period: string
}

const CompanyDocumentMaster = (() => {

    const [loading, setLoading] = useState(true);
    const [CompanyDocumentMasterDetail, setCompanyDocumentMasterDetail] = useState<CompanyDocumentMasterData[]>([])
    const [totalRows, setTotalRows] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);
    const [archiveId, setArchiveId] = useState<number | undefined>(undefined);


    const handleArchiveCompliance = async (id: number): Promise<void> => {
        setArchiveId(id);
        setmodal_center(true);
    };


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [payloadValue, setPayloadValue] = useState({
        page: 1,
        page_size: "10",
        search_term: "",
        sort: {
            sort_order: "ASC",
            sort_by: ""
        },
        is_archive: 0
    })

    const fetchCompanyDocumentDetails = async () => {
        try {
            const response = await getCompanyDocumentMasterDetail(payloadValue.page, payloadValue.page_size, payloadValue.search_term, payloadValue.sort.sort_order, payloadValue.sort.sort_by, payloadValue.is_archive);
            setCompanyDocumentMasterDetail(response.data.data_list);
            setTotalRows(response.data.total_records);
            setLoading(false)
        } catch (error) {
            return;

        }
    };

    useEffect(() => {
        fetchCompanyDocumentDetails();
    }, [payloadValue]);

    const handlePageChange = (page: number) => {
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            page: page
        }));
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPayloadValue(prevPayload => ({
            ...prevPayload,
            page_size: String(newPerPage),
            page: page
        }));
    };

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const handler = setTimeout(() => {
            setPayloadValue((prevPayload) => ({
                ...prevPayload,
                search_term: inputValue,
            }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const [archiveLoader, setArchiveLoader] = useState(false)

    const archiveMaster = async (id: number) => {
        setArchiveLoader(true)
        try {
            const response = await archiveCompanyDocumentMasterDetail(id);
            fetchCompanyDocumentDetails();
            toast.success(response.message);
            setmodal_center(false)
            setArchiveLoader(false)
        } catch (error) {
            toast.error("Error Archive Please try again later.");
        }
    };

    const handleSort = (column: any, sortDirection: 'asc' | 'desc') => {
        const columnSelector = typeof column === 'function' ? column.name : column;

        setPayloadValue(prevState => ({
            ...prevState,
            sort: {
                sort_by: columnSelector,
                sort_order: sortDirection.toUpperCase(),
            }
        }));
    };

    const editMaster = async (id: number) => {
        try {
            const response = await getByCompanyDocumentMasterDetail(id);
            const data = response.data;
            formik.setValues({
                document_name: data.document_name,
                document_path: data.document_path,
                allow_satff_to_view: data.allow_satff_to_view,
                requires_acknowledgement: data.requires_acknowledgement,
                requires_signature: data.requires_signature,
                is_requires_renewal: data.is_requires_renewal,
                renewal_period: data.renewal_period,
            });
            setIsEditing(true);
            setEditId(id);
            toggleleaveCanvas();
            setShowUploadButton(!data.document_path && !file);

        } catch (error) {
            toast.error("Error fetching details. Please try again later.");
        }
    };

    const [requiresRenewal, setRequiresRenewal] = useState<string>('0');

    const handleRenewalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setRequiresRenewal(event.target.value);
        formik.setFieldValue('is_requires_renewal', event.target.value);

        if (event.target.value === '0') {
            formik.setFieldValue('renewal_period', '');
        }
    };


    const validationSchema = Yup.object().shape({
        document_name: Yup.string().required('Document name is required'),
        document_path: Yup.mixed()
            .required('Document is required')
            .test('fileType', 'The document must be a file of type: jpeg, jpg, png, pdf', (value: any) => {
                if (typeof value === 'string') return true;
                return value && value instanceof File && ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(value.type);
            })
            .test('fileSize', 'Ensure the file size is not greater than 2MB', (value: any) => {
                if (typeof value === 'string') return true;
                return value && value instanceof File && value.size <= 2 * 1024 * 1024;
            }),
        // allow_satff_to_view: Yup.string().required('Visible to Staff is required'),
        // requires_acknowledgement: Yup.string().required('Requires Acknowledgement is required'),
        // requires_signature: Yup.string().required('Requires Signature is required'),
        // is_requires_renewal: Yup.number().required('Renewal Period is required'),
    });

    const [file, setFile] = useState<File | null>(null);
    const [showUploadButton, setShowUploadButton] = useState<boolean>(true);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setShowUploadButton(false);
            formik.setFieldValue('document_path', selectedFile);
        }
    };
    const handleRemoveFile = () => {
        setFile(null);
        setShowUploadButton(true);
        formik.setFieldValue('document_path', null);
    };

    const formik = useFormik<FormValues>({
        initialValues: {
            document_name: "",
            document_path: null,
            allow_satff_to_view: "0",
            requires_acknowledgement: "0",
            requires_signature: "0",
            is_requires_renewal: "0",
            renewal_period: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let response;
                if (isEditing && editId !== null) {
                    response = await updateCompanyDocumentMasterDetail(
                        editId,
                        values.document_name,
                        values.document_path,
                        values.allow_satff_to_view,
                        values.requires_acknowledgement,
                        values.requires_signature,
                        values.is_requires_renewal,
                        values.renewal_period,
                    );
                    if (response.status === 0) {
                        toast.error(response.message);
                        setSubmitting(false);
                        return;
                    } else {
                        toast.success(response.message);
                    }
                } else {
                    response = await addCompanyDocumentMasterDetail(values);
                    if (response.status === 0) {
                        toast.error(response.message);
                        setSubmitting(false);
                        return;
                    } else {
                        toast.success(response.message);
                    }
                }
                fetchCompanyDocumentDetails();
                setFile(null);
                setleave(false);
                formik.resetForm();
            } catch (error) {
                toast.error("Error Document Add . Please try again later.");
            } finally {
                setSubmitting(false);
            }
        }
    });

    const [leave, setleave] = useState(false);
    const toggleleaveCanvas = () => {
        setleave(!leave);
    };

    const openAddForm = () => {
        setIsEditing(false);
        setEditId(null);
        setShowUploadButton(true);
        formik.resetForm()
        setleave(true);
    };

    return (
        <>
            {
                loading ?
                    <Row className="placeholder-glow">
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={6}>
                            <div className="placeholder bg-light card-placeholder col-12"></div>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="placeholder bg-light table-placeholder col-12"></div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                            <h4 className="card-title mb-2">Company Document Master</h4>
                            <div className="d-flex gap-2">
                                <div className="app-search d-none d-md-inline-flex">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            id="search-options"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                        <span
                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                            id="search-close-options"
                                        ></span>
                                    </div>
                                </div>
                                <Button
                                    variant="primary"
                                    className="add-company"
                                    onClick={openAddForm}
                                >
                                    <i className=" ri-add-fill"></i> Add
                                </Button>
                            </div>
                        </Col>
                        <Col lg="12">
                            <SimpleBar
                                style={{ maxHeight: "589px" }}
                            >
                                <CompanyDocumentTable data={CompanyDocumentMasterDetail} editMaster={editMaster} handleSort={handleSort} handleArchiveCompliance={handleArchiveCompliance} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} />
                            </SimpleBar>
                        </Col>
                    </Row>
            }
            <Offcanvas
                className="add-role-canvas"
                show={leave}
                onHide={toggleleaveCanvas}
                placement="bottom"
                style={{ minHeight: "496px" }}
            >
                <Offcanvas.Header className="add-header border-bottom" closeButton>
                    <Offcanvas.Title id="offcanvasBottomLabel" className="m-auto">
                        {isEditing ? "Edit" : "Add"} Company Document
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Offcanvas.Body>
                        <SimpleBar style={{ maxHeight: "350px" }} className="px-3">
                            <Row>
                                <Col md="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Document name
                                        </Form.Label>
                                        <div className="position-relative form-icon">
                                            <Form.Control
                                                type="name"
                                                name="document_name"
                                                className="form-control-lg form-control-icon name-input"
                                                placeholder="Enter Document name"
                                                value={formik.values.document_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!(formik.touched.document_name && formik.errors.document_name)}
                                            />
                                            <i
                                                style={{ fontSize: "16px" }}
                                                className=" ri-file-list-line"
                                            ></i>
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.document_name}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label className="doc-label">Document Upload</Form.Label>
                                        <div className={`uploadsection ${!!(formik.touched.document_path && formik.errors.document_path) ? 'invalid-border' : ''}`}>
                                            {showUploadButton ? (
                                                <div className="d-flex align-items-center">
                                                    <Form.Label htmlFor={`document_path`} className="uploadlabel">
                                                        <Form.Control
                                                            type="file"
                                                            name="document_path"
                                                            className="form-control-lg form-control-icon name-input"
                                                            style={{ display: "none" }}
                                                            id={`document_path`}
                                                            onChange={handleFileChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!(formik.touched.document_path && formik.errors.document_path)}
                                                        />
                                                        <i className="bx bx-plus"></i> Choose File
                                                    </Form.Label>
                                                </div>
                                            ) : (
                                                <Col lg="12" className="closeuser py-0">
                                                    <p>{file ? file.name : formik.values.document_path ? formik.values.document_path.toString() : ''}</p>
                                                    <Button onClick={handleRemoveFile} variant="danger">X</Button>
                                                </Col>
                                            )}
                                        </div>
                                        {formik.touched.document_path && formik.errors.document_path ? (
                                            <div className="invalid-feedback d-block">{formik.errors.document_path}</div>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-3 form-icon form-select-icon">
                                        <Form.Label>Visible to Staff</Form.Label>
                                        <Form.Select
                                            className="form-select-lg form-control-icon"
                                            id="floatingSelect"
                                            name="allow_satff_to_view"
                                            value={formik.values.allow_satff_to_view}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.allow_satff_to_view && formik.errors.allow_satff_to_view)}
                                        >
                                            <option value="1">
                                                Yes
                                            </option>
                                            <option value="0">
                                                No
                                            </option>
                                        </Form.Select>
                                        <i className=" ri-eye-line"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.allow_satff_to_view}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-3 form-icon form-select-icon">
                                        <Form.Label>Requires Signature</Form.Label>
                                        <Form.Select
                                            className="form-select-lg form-control-icon"
                                            id="floatingSelect"
                                            name="requires_signature"
                                            value={formik.values.requires_signature}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.requires_signature && formik.errors.requires_signature)}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                        <i className="ri-edit-box-line"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.requires_signature}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-3 form-icon form-select-icon">
                                        <Form.Label>Requires Acknowledgement</Form.Label>
                                        <Form.Select
                                            className="form-select-lg form-control-icon"
                                            id="floatingSelect"
                                            name="requires_acknowledgement"
                                            value={formik.values.requires_acknowledgement}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!(formik.touched.requires_acknowledgement && formik.errors.requires_acknowledgement)}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                        <i className="ri-edit-box-line"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.requires_acknowledgement}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-3 form-icon form-select-icon">
                                        <Form.Label>Requires Renewal</Form.Label>
                                        <Form.Select
                                            className="form-select-lg form-control-icon"
                                            id="floatingSelect"
                                            name="is_requires_renewal"
                                            onChange={handleRenewalChange}
                                            value={requiresRenewal}
                                            isInvalid={!!(formik.touched.is_requires_renewal && formik.errors.is_requires_renewal)}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                        <i className="ri-chat-new-line"></i>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.is_requires_renewal}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {requiresRenewal === '1' && (
                                    <Col md="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Renewal Period</Form.Label>
                                            <div className="position-relative form-icon">
                                                <Form.Control
                                                    type="text"
                                                    name="renewal_period"
                                                    className="form-control-lg form-control-icon name-input"
                                                    placeholder="Enter Renewal Period (in Months)"
                                                    value={formik.values.renewal_period}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <i
                                                    style={{ fontSize: '16px' }}
                                                    className="ri-time-line"
                                                ></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                        </SimpleBar>
                    </Offcanvas.Body>
                    <Row>
                        <Col lg="12">
                            <div className="offcanvas-footer pb-0 text-center" style={{ position: 'fixed', bottom: '0', width: '100%' }}>
                                <Row>
                                    <Col lg={12} className="off-bottom-staff justify-content-end">
                                        <div className="off-confirm">
                                            <Button
                                                onClick={toggleleaveCanvas}
                                                color="primary"
                                                className="clear-btn"
                                            >
                                                Cancel
                                            </Button>
                                            {formik.isSubmitting ? (
                                                <Button
                                                    color="primary"
                                                    className="next-btn"
                                                    disabled
                                                >
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            ) : (
                                                <Button color="primary" disabled={formik.isSubmitting} type="submit" className="next-btn">
                                                    {isEditing ? "Update" : "Add"}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas>
            <Modal
                show={modal_center}
                onHide={() => {
                    tog_center();
                }}
                size="sm"
                centered
            >

                <Modal.Body className="text-center p-4">
                    <i className="ri-delete-bin-line text-danger display-5"></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">You want to delete Document.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button variant="light" onClick={() => setmodal_center(false)}>Close</Button>
                            {archiveLoader ? (
                                <Button
                                    color="primary"
                                    className="next-btn"
                                    disabled
                                >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={() => {
                                    if (archiveId !== undefined) {
                                        archiveMaster(archiveId);
                                    } else {
                                        toast.error("No Archive ID provided.");
                                    }
                                }}>Delete</Button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default CompanyDocumentMaster;