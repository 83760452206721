import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    Row,
    Offcanvas,
    Modal,
    Nav,
    Tab
} from "react-bootstrap";
import PayCalender from "./PayCalender/PayCalender";
import PayCategories from "./PayCategories/PayCategories";
import PayGroup from "./PayGroup/PayGroup";

type SelectCallback = (eventKey: string | null) => void;

const Paysetting = (() => {

    const [activeTab, setActiveTab] = useState<string>('home1');

    const handleTabSelect: SelectCallback = (eventKey) => {
        if (eventKey) {
            setActiveTab(eventKey);
        }
    };


    return (
        <>
            <Row>
                <Col lg="12" className="d-flex align-items-center justify-content-between role-navbar">
                    <h4 className="card-title mb-2">Pay Settings</h4>
                </Col>
                <Col lg="12">
                    <Row>
                        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                            <Col lg={12} className="btn-employee-section mb-3">
                                <Nav
                                    as="ul"
                                    variant="tabs"
                                    className="nav-border-top nav-border-top-primary"
                                    role="tablist"
                                >
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="home1">
                                            Pay Schedule
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="home2">
                                            Pay Categories
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="home3">
                                            Pay Group
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg="12">
                                <Tab.Content className="text-muted">
                                    {activeTab === "home1" && (
                                        <Tab.Pane eventKey="home1">
                                            <PayCalender />
                                        </Tab.Pane>
                                    )}
                                    {activeTab === "home2" && (
                                        <Tab.Pane eventKey="home2">
                                            <PayCategories />
                                        </Tab.Pane>
                                    )}
                                    {activeTab === "home3" && (
                                        <Tab.Pane eventKey="home3">
                                            <PayGroup />
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </Col>
                        </Tab.Container>
                    </Row>
                </Col>
                {/* <Col lg="12">
                    <SimpleBar
                        style={{ maxHeight: "589px" }}
                    >
                        <ComplianceTable editMaster={editMaster} data={ComplianceMasterDetail} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} paginationTotalRows={totalRows} handleArchiveCompliance={handleArchiveCompliance} handleSort={handleSort} />
                    </SimpleBar>
                </Col> */}
            </Row>
        </>
    )
})

export default Paysetting;