import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NotificationList, NotificationRemoveList } from 'helpers/saibackend_helper';

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {

    const [NotificationDataList, SetNotificationDataList] = useState<any[]>([]);
    const [TotalNotifiaction, SetTotalNotifiaction] = useState(0)
    const [TotalUnreadNotifiaction, SetTotalUnreadNotifiaction] = useState(0)

    const fetchNotificationListDetails = async () => {
        try {
            const response = await NotificationList();
            console.log(response);
            SetNotificationDataList(response.data.data_list);
            SetTotalNotifiaction(response.data.total_records)
            SetTotalUnreadNotifiaction(response.data.total_unread_records)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchNotificationListDetails();
    }, []);

    const newNotifications = NotificationDataList.filter(notification => notification.is_read === 0);
    const readBeforeNotifications = NotificationDataList.filter(notification => notification.is_read === 1);

    const removeNotificationListDetails = async () => {
        try {
            await NotificationRemoveList();
            fetchNotificationListDetails();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Dropdown className="topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                <Dropdown.Toggle id="notification" type="button" className="btn btn-icon btn-topbar btn-ghost-dark rounded-circle arrow-none">
                    <i className='bi bi-bell fs-2xl'></i>
                    {TotalUnreadNotifiaction !== 0 &&
                        <span className="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger">
                            <span className="notification-badge">{TotalUnreadNotifiaction}</span>
                            <span className="visually-hidden">unread messages</span>
                        </span>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
                    <div className="dropdown-head rounded-top">
                        <div className="p-3 border-bottom border-bottom-dashed">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="mb-0 fs-lg fw-semibold">
                                        Notifications {TotalNotifiaction !== 0 && <span className="badge bg-danger-subtle text-danger fs-sm notification-badge"> {TotalNotifiaction}</span>}
                                    </h6>
                                    <p className="fs-md text-muted mt-1 mb-0">
                                        You have <span className="fw-semibold notification-unread">{TotalUnreadNotifiaction}</span> unread messages
                                    </p>
                                </Col>
                                <Dropdown className="col-auto">
                                    <Dropdown.Toggle as="a" data-bs-toggle="dropdown" className="link-secondary fs-md bg-transparent border-0 arrow-none">
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <li><Dropdown.Item onClick={removeNotificationListDetails}>All Clear</Dropdown.Item></li>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                        </div>
                    </div>
                    {NotificationDataList.length > 0 &&
                        <div className="py-2 ps-2" id="notificationItemsTabContent">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {newNotifications.length > 0 && (
                                    <h6 className="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">New</h6>
                                )}
                                {NotificationDataList.filter(notification => notification.is_read === 0).map(notification => (
                                    notification.title === "" ? (
                                        <div key={notification.id} className="text-reset notification-item d-block dropdown-item position-relative unread-message">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3 flex-shrink-0">
                                                    <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                                                        <i className='bx bx-message-square-dots'></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#!" className="stretched-link">
                                                        <h6 className="mt-0 fs-md mb-2 lh-base">
                                                            {notification.message}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                                        <span><i className="mdi mdi-clock-outline"></i> {notification.created_at_time}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={notification.id} className="text-reset notification-item d-block dropdown-item position-relative unread-message">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3 flex-shrink-0">
                                                    <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                                                        <i className='bx bx-message-square-dots'></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#!" className="stretched-link">
                                                        <h6 className="mt-0 mb-1 fs-md fw-semibold">{notification.title}</h6>
                                                    </Link>
                                                    <div className="fs-sm text-muted">
                                                        <p className="mb-1">{notification.message}</p>
                                                    </div>
                                                    <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                                        <span><i className="mdi mdi-clock-outline"></i> {notification.created_at_time}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                                {readBeforeNotifications.length > 0 && (
                                    <h6 className="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">Read Before</h6>
                                )}
                                {NotificationDataList.filter(notification => notification.is_read === 1).map(notification => (
                                    notification.title === "" ? (
                                        <div key={notification.id} className="text-reset notification-item d-block dropdown-item position-relative read-message">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3 flex-shrink-0">
                                                    <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                                                        <i className='bx bx-message-square-dots'></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#!" className="stretched-link">
                                                        <h6 className="mt-0 fs-md mb-2 lh-base">
                                                            {notification.message}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                                        <span><i className="mdi mdi-clock-outline"></i> {notification.created_at_time}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={notification.id} className="text-reset notification-item d-block dropdown-item position-relative read-message">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3 flex-shrink-0">
                                                    <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                                                        <i className='bx bx-message-square-dots'></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#!" className="stretched-link">
                                                        <h6 className="mt-0 mb-1 fs-md fw-semibold">{notification.title}</h6>
                                                    </Link>
                                                    <div className="fs-sm text-muted">
                                                        <p className="mb-1">{notification.message}</p>
                                                    </div>
                                                    <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                                        <span><i className="mdi mdi-clock-outline"></i> {notification.created_at_time}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </SimpleBar>
                            <div className="notification-actions" id="notification-actions">
                                <div className="d-flex text-muted justify-content-center align-items-center">
                                    Select <div id="select-content" className="text-body fw-semibold px-1">0</div> Result <Button variant='link-danger' className="btn-link p-0 ms-2" data-bs-toggle="modal" data-bs-target="#removeNotificationModal">Remove</Button>
                                </div>
                            </div>
                        </div>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
}

export default NotificationDropdown;