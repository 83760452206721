import React, { ChangeEvent, useMemo, useState } from 'react';
import TableContainer from "Common/TableContainer";
import {
    Form, Offcanvas, Button, Row, Col, Nav, Tab, Table, Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getByTimesheetDetail, getHistoryDetail, getByStaffDetail, getStaffDocumentDetail } from 'helpers/saibackend_helper';
import noDataImg from "assets/images/no-data.png"

interface TimesheetTableProps {
    timesheetdata: any;
    currentPage: number,
    totalPages: number,
    onPageChange: (page: number) => void
    handleSortChange: (column: string, direction: string) => void;
    editTimesheet: (id: number) => Promise<void>;
    timesheetIds: number[];
    selectAll: boolean;
    handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
    handleCheckAllChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
interface BreakRow {
    isChecked: boolean;
}

interface TimesheetData {
    id: number,
    date: string,
    shift_id: number,
    client_id: number,
    client_name: string,
    location: string,
    location_id: number,
    staff_id: number,
    start_time: string,
    end_time: string,
    total_work_hrs: number,
    total_break_hrs: number,
    total_hrs: number,
    employee_notes: string,
    notes: string,
    is_approve: number,
    shfit_start_time: string,
    shfit_end_time: string,
    shift_notes: string,
    shift_created_by: string,
    staff: string,
    status: string,
    timesheet_breaks: [
        {
            id: number,
            timesheet_id: number,
            break_type: number,
            is_hrs: number,
            start_time: string,
            end_time: string,
            break_hrs: number,
            is_paid_break: number
        },
    ]
}

const TimesheetTable: React.FC<TimesheetTableProps> = ({
    timesheetdata,
    currentPage,
    totalPages,
    onPageChange,
    handleSortChange,
    editTimesheet,
    timesheetIds,
    selectAll,
    handleCheckboxChange,
    handleCheckAllChange

}) => {

    const [courseFilters, setCourseFilters] = useState<boolean>(false);
    const handleFilterShow = () => setCourseFilters(!courseFilters);

    // Get By Details //
    // ==========================================================================================================================//

    const [TimesheetDetails, setTimesheetDetails] = useState<TimesheetData | null>(null);
    const [TimesheetHistoryDetails, setTimesheetHistoryDetails] = useState<any[]>([]);

    const GetByTimesheet = async (id: number) => {
        try {
            const response = await getByTimesheetDetail(id);
            const history = await getHistoryDetail(id)
            const historyData = history?.data ? history.data : null
            setTimesheetHistoryDetails(historyData)
            const data = response.data;
            console.log(data);
            setTimesheetDetails(data)
            setCourseFilters(true)

        } catch (error) {
            console.error("Error fetching Timesheet details:", error);
        }
    };

    const [activeTab, setActiveTab] = useState<string>("home1");

    const handleTabChange = (key: string | null) => {
        if (key) {
            setActiveTab(key);
        }
    };


    const [StaffViewDetail, setStaffViewDetail] = useState<any | null>(null);

    const editMaster = async (id: any) => {
        try {
            const response = await getByStaffDetail(id);
            const data = response.data;
            setStaffViewDetail(data);
            tog_standard()
        } catch (error) {
            return;
        }
    };


    const [StaffDocumentDetail, setStaffDocumentDetail] = useState<
        any[]
    >([]);

    const fetchStaffDocumentDetails = async (id: any) => {
        try {
            const response = await getStaffDocumentDetail("", id);
            setStaffDocumentDetail(response.data.data_list);
        } catch (error) {
            console.log(error);
        }
    };

    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleOpenDocument = (path: string) => {
        const fullPath = `${doc_url}/${path}`;
        window.open(fullPath, '_blank');
    };

    const doc_url = process.env.REACT_APP_IMAGE_URL
    const imageSrc = `${doc_url}/${StaffViewDetail?.avatar}`

    const leaveNames = StaffViewDetail?.staff_leaves?.map((leave: { leave_name: string }) => leave.leave_name) || [];

    const getAbbreviation = (name: string) => {
        return name
            ?.split(' ')
            ?.map(word => word.charAt(0))
            ?.join('')
            ?.toUpperCase()
            ?.slice(0, 2);
    };



    const columns = useMemo(
        () => [
            {
                header: (
                    <Form.Check>
                        <Form.Check.Input
                            type="checkbox"
                            id="checkAll"
                            value="option"
                            checked={selectAll}
                            onChange={handleCheckAllChange}
                        />
                    </Form.Check>
                ),
                cell: (cellProps: any) => {
                    const itemId = cellProps.row.original.id;
                    const Is_invoice = cellProps.row.original.is_invoice;
                    const Is_payroll = cellProps.row.original.is_payroll
                    // const itemStatus = cellProps.row.original.status;
                    return (
                        (Is_invoice === 0 && Is_payroll === 0) ? (
                            <Form.Check>
                                <Form.Check.Input
                                    type="checkbox"
                                    name="chk_child"
                                    value={itemId}
                                    checked={timesheetIds.includes(itemId)}
                                    onChange={(e) => handleCheckboxChange(e, itemId)}
                                />
                            </Form.Check>
                        ) : null
                    );
                },
                id: '#',
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Staff Name",
                accessorKey: "staff",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    const staff_name = cellProps.row.original.staff;
                    const subAbb = cellProps.row.original.sub_abb;
                    const shift = cellProps.row.original.shift_id;
                    return (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>{staff_name + " " + subAbb} <i onClick={() => {
                                    if (cellProps.row.original.staff_id) {
                                        editMaster(cellProps.row.original.staff_id);
                                        fetchStaffDocumentDetails(cellProps.row.original.staff_id);
                                    }
                                }} className="ri-information-line link-info" style={{ cursor: 'pointer', fontSize: '18px' }} ></i></span>
                                {shift === 0 && <span style={{ width: 'fit-content' }} className="badge bg-info-subtle text-info">Manual</span>}
                            </div>
                        </>
                    )
                },
            },
            {
                header: "Client Name",
                accessorKey: "client_name",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    const client_name = cellProps.row.original.client_name;
                    return client_name ?? "Internal Location"
                },
            },
            {
                header: "Location Name",
                accessorKey: "location",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Date",
                accessorKey: "date",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Time Period",
                accessorKey: "time",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Total Hours",
                accessorKey: "total_hrs",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Total Break Minutes",
                accessorKey: "total_break_hrs",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: () => <div style={{ textAlign: 'center' }}>Status</div>,
                accessorKey: "is_approve",
                disableFilters: false,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const Is_invoice = cellProps.row.original.is_invoice;
                    const statusValue = cellProps.row.original.status;
                    const Is_payroll = cellProps.row.original.is_payroll
                    return (
                        <>
                            <div style={{ textAlign: 'center' }}>
                                <span className={`${statusValue} mb-1`}>
                                    {cellProps.row.original.status}
                                </span><br />
                                {Is_invoice === 1 &&
                                    <>
                                        <span className="badge bg-body-secondary rounded-pill border border-info text-info mb-1">Invoice Generated</span> <br />
                                    </>
                                }
                                {Is_payroll === 1 &&
                                    <span style={{ color: '#3762EA', border: '1px solid #3762EA' }} className="badge bg-body-secondary rounded-pill mb-1">Payroll Generated</span>
                                }
                                {/* <span class="badge bg-body-secondary rounded-pill border border-danger text-danger">Danger</span> */}
                            </div>
                        </>
                    );
                },
            },
            {
                header: "Action",
                disableFilters: true,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const Is_invoice = cellProps.row.original.is_invoice;
                    const Is_payroll = cellProps.row.original.is_payroll
                    const item = cellProps.row.original.id
                    return (
                        <div className="d-flex gap-2 list-unstyled mb-0">
                            {(Is_invoice === 0 && Is_payroll === 0) &&
                                <div className="edit">
                                    <i onClick={() => editTimesheet(item)} className="ri-edit-2-line link-success" style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                                </div>
                            }
                            <div className="edit">
                                <i onClick={() => GetByTimesheet(item)} className="ri-eye-line link-info p-0"
                                    style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                            </div>
                            {/* <div className="edit">
                                <i style={{ fontSize: '18px', cursor: 'pointer' }} className="ri-delete-bin-line link-danger"></i>
                            </div> */}
                        </div>
                    );
                },
            },
        ],
        [timesheetIds, selectAll, timesheetdata]
    );

    return (
        <React.Fragment>
            {timesheetdata.length > 0 ?
                <TableContainer
                    isPagination={true}
                    columns={(columns || [])}
                    data={timesheetdata}
                    isBordered={false}
                    customPageSize={10}
                    PaginationClassName="align-items-center mt-4 pt-2"
                    tableClass="table-centered align-middle table-custom-effect table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onSortChange={handleSortChange}
                />
                :
                <div className="noresult">
                    <div className="text-center py-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <h5 className="mt-2">No Timesheet Available</h5>
                    </div>
                </div>
            }
            <Offcanvas
                className="timesheet-canvas"
                show={courseFilters}
                placement="end"
                onHide={handleFilterShow}
            >
                <Offcanvas.Header
                    id="courseFilters"
                    className="border-bottom add-header"
                >
                    <Offcanvas.Title id="courseFiltersLabel">
                        View Timesheet Details
                    </Offcanvas.Title>
                    <div>
                        <Button
                            className="close-btn"
                            onClick={() => setCourseFilters(false)}
                        >
                            <i className="ri-close-line"></i>
                        </Button>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <Tab.Container
                                    defaultActiveKey="home1"
                                    activeKey={activeTab}
                                    onSelect={handleTabChange}
                                >
                                    <Nav as="ul" variant="tabs" className="nav-border-top nav-border-top-primary mb-4" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="home1">
                                                <i className="ri-file-text-line"></i> Details
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="profile1">
                                                <i className="ri-history-line"></i> History
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className="text-muted">
                                        <Tab.Pane eventKey="home1">
                                            <Row>
                                                <Col lg="12">
                                                    <h5> Scheduled Shift details</h5>
                                                </Col>
                                                <Col lg='6' className="status-col">
                                                    <p className="status-title">Shift created by</p>
                                                    <p className="status-value">{TimesheetDetails?.shift_created_by ? TimesheetDetails?.shift_created_by : "-"}</p>
                                                </Col>
                                                <Col lg='6' className="status-col">
                                                    <p className="status-title">Created for</p>
                                                    <p className="status-value">{TimesheetDetails?.staff ? TimesheetDetails?.staff : "-"}</p>
                                                </Col>
                                                <Col lg='6' className="status-col">
                                                    <p className="status-title">Shift date</p>
                                                    <p className="status-value">{TimesheetDetails?.date ? TimesheetDetails?.date : "-"}</p>
                                                </Col>
                                                <Col lg='6' className="status-col">
                                                    <p className="status-title">Shift Start & End Time</p>
                                                    <p className="status-value">{TimesheetDetails?.shfit_start_time ? TimesheetDetails?.shfit_start_time : "-"} - {TimesheetDetails?.shfit_end_time ? TimesheetDetails?.shfit_end_time : "-"}</p>
                                                </Col>
                                                <Col lg='12' className="status-col">
                                                    <p className="status-title">Shift notes</p>
                                                    <p className="status-value">
                                                        {TimesheetDetails?.shift_notes ? TimesheetDetails?.shift_notes : "-"}
                                                    </p>
                                                </Col>
                                                <Col lg="12" className='my-4'>
                                                    <h5>Timesheet details</h5>
                                                </Col>
                                                {TimesheetDetails?.status && (
                                                    <Col lg="12">
                                                        <div className={TimesheetDetails?.status}>
                                                            {TimesheetDetails?.status}
                                                        </div>
                                                    </Col>
                                                )}
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Staff Name</p>
                                                    <p className="status-value">{TimesheetDetails?.staff ? TimesheetDetails?.staff : "-"}</p>
                                                </Col>
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Date</p>
                                                    <p className="status-value">{TimesheetDetails?.date ? TimesheetDetails?.date : "-"}</p>
                                                </Col>
                                                <Col lg='6' className="status-col">
                                                    <p className="status-title">Shift Start & End Time</p>
                                                    <p className="status-value">{TimesheetDetails?.start_time ? TimesheetDetails?.start_time : "-"} - {TimesheetDetails?.end_time ? TimesheetDetails?.end_time : "-"}</p>
                                                </Col>
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Total Hours</p>
                                                    <p className="status-value">{TimesheetDetails?.total_work_hrs ? `${TimesheetDetails?.total_work_hrs} Hours` : "-"}</p>
                                                </Col>
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Total Working Hours</p>
                                                    <p className="status-value">{TimesheetDetails?.total_hrs ? `${TimesheetDetails?.total_hrs} Hours` : "-"}</p>
                                                </Col>
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Total Break Minutes</p>
                                                    <p className="status-value">{TimesheetDetails?.total_break_hrs ? `${TimesheetDetails?.total_break_hrs} Minutes` : "-"}</p>
                                                </Col>
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Location</p>
                                                    <p className="status-value">{TimesheetDetails?.location ? TimesheetDetails?.location : "-"}</p>
                                                </Col>
                                                <Col lg="6" className="status-col">
                                                    <p className="status-title">Client Name</p>
                                                    <p className="status-value">{TimesheetDetails?.client_name ? TimesheetDetails?.client_name : "-"}</p>
                                                </Col>
                                                <Col lg="12" className="status-col">
                                                    <p className="status-title">Timesheet Comment</p>
                                                    <p className="status-value">
                                                        {TimesheetDetails?.notes ? TimesheetDetails?.notes : "-"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="profile1">
                                            <Row>
                                                {TimesheetHistoryDetails === null ?
                                                    <Col lg={12} className="text-center mt-5 pt-5">
                                                        <img src={noDataImg} style={{ width: '100px' }} />
                                                        <div className='d-flex justify-content-center'>No history available</div>
                                                    </Col>
                                                    :
                                                    <Col lg="12" className="status-table">
                                                        <Table className="align-middle table-wrap mb-0" responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col User">User</th>
                                                                    <th scope="col User">Field</th>
                                                                    <th scope="col Previous">Previous Value</th>
                                                                    <th scope="col Previous">Updated Value</th>
                                                                    <th scope="col Previous">Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {TimesheetHistoryDetails?.map((time: any) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{time?.user_name}</td>
                                                                            <td>{time?.field}</td>
                                                                            <td>{time?.prev_val}</td>
                                                                            <td>{time?.updated_val}</td>
                                                                            <td>{time?.created_date}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                }
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <Modal id="myModal" size="sm" show={modal_standard} onHide={() => { tog_standard(); }} >
                {/* <Modal.Header className="modal-title fs-xl" id="myModalLabel">
                                {StaffViewDetail?.first_name} {StaffViewDetail?.last_name}
                            </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex align-items-center" >
                        <div className="flex-shrink-0 position-relative">
                            {StaffViewDetail?.avatar ?
                                <img src={imageSrc} alt="" className="avatar-sm rounded" />
                                :
                                <div className="avatar-sm">
                                    <div className="avatar-title rounded bg-danger-subtle text-danger">
                                        {getAbbreviation(StaffViewDetail?.employee_name ? StaffViewDetail?.employee_name : "-")}
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="flex-grow-1 ms-2">
                            <h5 className="fs-md mb-0"><Link to="/pages-profile" className="text-dark">{StaffViewDetail?.first_name} {StaffViewDetail?.last_name}</Link></h5>
                            <p className="text-muted mb-0"> {StaffViewDetail?.access_level ? StaffViewDetail?.access_level : "-"} </p>
                            <p className="text-muted mb-0"> {StaffViewDetail?.employee_status ? StaffViewDetail?.employee_status : "-"} </p>
                        </div>
                    </div>
                    <div style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '18px', cursor: 'pointer' }}>
                        <i onClick={tog_standard} className="ri-close-line"></i>
                    </div>
                    <div className="mt-4">
                        <p className="text-muted mb-2">
                            <i className="bi bi-telephone align-baseline me-1"></i>
                            {StaffViewDetail?.mobile_no ? (
                                <Link to={`tel:${StaffViewDetail.mobile_no}`} className="text-muted" style={{ paddingLeft: '4px' }}>
                                    {StaffViewDetail.mobile_no}
                                </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2">
                            <i className="bi bi-envelope align-baseline me-1"></i>
                            {StaffViewDetail?.email ? (
                                <Link to={`mailto:${StaffViewDetail.email}`} className="text-muted" style={{ paddingLeft: '4px' }}>{StaffViewDetail.email} </Link>
                            ) : (
                                "-"
                            )}
                        </p>
                        <p className="text-muted mb-2"><i className="bi bi-geo-alt align-baseline me-1"></i> {StaffViewDetail?.address ? StaffViewDetail?.address : "-"} </p>
                        <p className="text-muted mb-2"><i className="mdi mdi-gender-transgender align-baseline me-1"></i> {StaffViewDetail?.gender ? StaffViewDetail?.gender : "-"} </p>
                        <p className="text-muted mb-0"><i className="ri-cake-line align-baseline me-1"></i> {StaffViewDetail?.dob ? StaffViewDetail?.dob : "-"} </p>
                    </div>
                    {StaffDocumentDetail.filter((doc) => doc.document_type === "Licenses").length > 0 &&
                        <div className="border-bottom border-top border-dashed py-4 mt-4">
                            <h5 className="card-title mb-3">License</h5>
                            <div className="vstack gap-3">
                                {StaffDocumentDetail
                                    .filter((doc) => doc.document_type === "Licenses")
                                    .map((doc, index) => (
                                        <div key={index} className="d-flex gap-2 align-items-center position-relative">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-sm border border rounded">
                                                    <div className="avatar-title bg-body-secondary text-secondary rounded fs-lg">
                                                        <i className="bi bi-file-pdf"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6>License No : {doc.ref_no}</h6>
                                                <p className="text-muted mb-0">Expiry Date : {doc.expiry_date ? doc.expiry_date : "-"}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <Link to={`#`} onClick={() => handleOpenDocument(doc.document_path)} className="icon-link text-reset stretched-link fs-xl" style={{ transform: "translate3d(0, -.125rem, 0)" }}>
                                                    <i className="ri-eye-line link-info"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default TimesheetTable;